<template>
  <svg
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.62838 2L5.36043 1L7.09076 3.99702L8.81393 1.01241L10.546 2.01241L9.39847 3.99996H11.9167C12.515 3.99996 13 4.48499 13 5.0833V5.91663C13 6.51494 12.515 6.99996 11.9167 6.99996H12V10.8333C12 12.0299 11.03 13 9.83337 13H4.1667C2.97009 13 2.00004 12.0299 2.00004 10.8333V6.99996H2.08333C1.48502 6.99996 1 6.51494 1 5.91663V5.0833C1 4.48499 1.48502 3.99996 2.08333 3.99996H4.78306L3.62838 2Z"
      fill="currentColor"
    />
  </svg> 
</template>
  
<script setup>
  
</script>
  
  

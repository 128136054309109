<template>
  <div
    class="checkout-summary-price"
    tabindex="0"
  >
    <!-- 无地址展示购物车totalPrice字段 -->
    <template v-if="caculateData.address_id == 0">
      <div
        v-if="
          caculateInfo.originPrice &&
            caculateInfo.totalPrice &&
            caculateInfo.originPrice.amountWithSymbol !=
            caculateInfo.totalPrice.amountWithSymbol
        "
        class="summary-item she-clearfix"
      >
        <span class="she-fl">{{ language.SHEIN_KEY_PC_14749 }}:</span>
        <del class="she-fr">{{
          caculateInfo.originPrice.amountWithSymbol
        }}</del>
      </div>

      <div
        v-if="caculateInfo.totalPrice"
        class="summary-item total-item she-clearfix"
      >
        <span class="she-fl">{{ language.SHEIN_KEY_PC_14750 }}:</span>
        <span class="she-fr total">{{
          caculateInfo.totalPrice.amountWithSymbol
        }}</span>
      </div>
    </template>
    <template v-else>
      <div
        v-if="
          caculateInfo.originPrice &&
            caculateInfo.newSubTotal &&
            caculateInfo.originPrice.amountWithSymbol !=
            caculateInfo.newSubTotal.amountWithSymbol
        "
        class="summary-item she-clearfix"
      >
        <span class="she-fl">{{ language.SHEIN_KEY_PC_14749 }}:</span>
        <del class="she-fr">{{ caculateInfo.originPrice.amountWithSymbol }}</del>
      </div>
      
      <!-- subTotal -->
      <div 
        v-if="caculateInfo.newSubTotal"
        class="summary-item she-clearfix" 
        :class="{ 'summary-item__subtotal': hasSubTotalDetails }"
        @click="hasSubTotalDetails && (showSubTotalDetails = !showSubTotalDetails)"
      >
        <span class="she-fl">{{ language.SHEIN_KEY_PC_14750 }}:</span>
        <span class="she-fr">
          <span>{{ caculateInfo.newSubTotal.amountWithSymbol }}</span>
          <component
            :is="showSubTotalDetails ? 'sui_icon_more_up_12px_1' : 'sui_icon_more_down_12px_1'"
            v-if="hasSubTotalDetails"
            :is-responsive-name="true"
            size="13px"
            color="#222222"
          />
        </span>
      </div>
      <!-- subTotal details -->
      <ItemDetails
        v-if="showSubTotalDetails"
        :details="getItemDetails(PriceItemType.subTotal)"
      />
      
      <!-- 个人税费 -->
      <OrderSummaryTaxes
        v-if="showMultiTax"
        :taxes="newTaxList2"
        :language="language"
        :is-multi-tax="isMultiTaxCountry"
        :is-show-ca-gst="isShowCaGST"
        :part-order-info="partOrderInfo"
        :taxes-m-y-info="taxesMYInfo"
        :taxes-m-x-info="taxesMXInfo"
        :taxes-b-r-info="taxesBRInfo"
      />
      
      <!-- Shipping fee -->
      <div 
        v-if="caculateInfo.actual_shipping_price"
        class="summary-item she-clearfix" 
        :class="{ 'summary-item__subtotal': hasShippingFeeDetails }"
        @click="hasShippingFeeDetails && (showShippingFeeDetails = !showShippingFeeDetails)"
      >
        <span class="she-fl">
          {{ language.SHEIN_KEY_PC_14751 }}:
          <template v-if="showShippingFeeTips">
            <sui_icon_doubt_14px_1
              class="summary-gray"
              color="#767676"
              size="15px"
              @click="clickShippingFee"
            />
          </template>
          <template v-else-if="mallCartsList.length > 1">
            <s-popover
              class="summary-popover"
              :hide-close-icon="true"
              placemen="bottom"
              trigger="hover"
              :content="language.SHEIN_KEY_PC_22202"
            >
              <template #reference>
                <sui_icon_doubt_14px_1
                  class="summary-gray"
                  color="#767676"
                  size="15px"
                />
              </template>
            </s-popover>
          </template>
        </span>
        <span
          class="she-fr"
          :class="{'price-prime': isUsePrimeCoupon }"
        >
          <sui_icon_club_logo_fill_15px
            v-if="isUsePrimeCoupon"
            size="15px"
            color="#c96e3f"
          />
          <template v-if="isFreePrice">
            <em
              class="free-price"
              :class="{'free-prime-color': isUsePrimeCoupon}"
            >{{ language.SHEIN_KEY_PC_24091 }}</em>
          </template>
          <template v-else>
            {{ caculateInfo.actual_shipping_price.amountWithSymbol }}
          </template>
          <del
            v-if="isShowShipOrigin"
            class="origin-price"
          >{{ caculateInfo.origin_shipping_price.amountWithSymbol }}</del>
          <component
            :is="showShippingFeeDetails ? 'sui_icon_more_up_12px_1' : 'sui_icon_more_down_12px_1'"
            v-if="hasShippingFeeDetails"
            :is-responsive-name="true"
            size="12px"
            color="#222222"
          />
        </span>
      </div>
      <!-- Shipping fee details -->
      <ItemDetails
        v-if="showShippingFeeDetails"
        :details="getItemDetails(PriceItemType.shippingFee)"
      />
      
      <!-- qs运费 -->
      <div
        v-if="caculateInfo.qs_shipping_price && +caculateInfo.qs_shipping_price.amount > 0"
        class="summary-item she-clearfix"
      >
        <span class="she-fl">
          {{ language.SHEIN_KEY_PC_24989 }}
          <s-popover
            v-if="qsFreightTips"
            class="summary-popover"
            :hide-close-icon="true"
            placemen="bottom"
            trigger="hover"
          >
            <div v-html="qsFreightTips"></div>
            <template #reference>
              <sui_icon_doubt_14px_1
                class="summary-gray"
                size="15px"
                color="#767676"
              />
            </template>
          </s-popover>
        </span>
        <span class="she-fr">
          {{ caculateInfo.qs_shipping_price.amountWithSymbol }}
        </span>
      </div>
      
      <!-- 超省卡开通费用 -->
      <div 
        v-if="caculateInfo.save_card_price && +caculateInfo.save_card_price.amount > 0"
        class="summary-item she-clearfix"
      >
        <span class="she-fl">{{ language.SHEIN_KEY_PC_27044 }}</span>
        <span class="she-fr">{{ caculateInfo.save_card_price.amountWithSymbol }}</span>
      </div>
      
      <!-- 付费会员价格 -->
      <div
        v-if="caculateInfo.prime_price && +caculateInfo.prime_price.amount > 0"
        class="summary-item she-clearfix"
      >
        <span class="she-fl">{{ language.SHEIN_KEY_PC_22967 }}</span>
        <span class="she-fr">
          <template v-if="caculateInfo.prime_price_discount && +caculateInfo.prime_price_discount.amount > 0">
            <span class="price-prime">
              <primeVipIcon :is-middle="1" />
              {{ caculateInfo.prime_price.amountWithSymbol }}
            </span>
            <del class="origin-price">{{ caculateInfo.origin_prime_price.amountWithSymbol }}</del>
          </template>
          <template v-else>
            <span>{{ caculateInfo.prime_price.amountWithSymbol }}</span>
          </template>
        </span>
      </div>
      
      <div
        v-if="
          caculateInfo.retail_delivery_fee &&
            parseFloat(caculateInfo.retail_delivery_fee.amount) > 0
        "
        class="summary-item she-clearfix"
      >
        <!-- 美国CO新增消费税提示展示 -->
        <span class="she-fl">
          {{ RDFTitle }}
          <s-popover
            :content="RDFPopuText"
            trigger="hover"
            placemen="bottom"
          >
            <template #reference>
              <sui_icon_doubt_14px_1
                class="summary-gray"
                color="#767676"
                size="15px"
              />
            </template>
          </s-popover>
        </span>
        <span class="she-fr">{{
          caculateInfo.retail_delivery_fee.amountWithSymbol
        }}</span>
      </div>
      
      <!-- 运费险退货险 -->
      <div
        v-if="showInsurancePrice.length && caculateInfo.insurancePrice"
        class="summary-item she-clearfix"
      >
        <span class="she-fl">{{ showReturnInsurance }}:</span>
        <span :class="['she-fr', {'sui-label__success':isFreeInfo.isFREE}]">
          {{ isFreeInfo.isFREE == 'FREE' ? language.SHEIN_KEY_PC_25900 : caculateInfo.insurancePrice.amountWithSymbol }}
        </span>
      </div>
      
      <!-- 处置费 -->
      <div
        v-if="caculateInfo.handling_fee && +caculateInfo.handling_fee.amount > 0"
        class="summary-item she-clearfix"
      >
        <s-popover
          v-if="getHandlingDescText"
          class="summary-popover"
          :hide-close-icon="true"
          placemen="bottom"
          trigger="hover"
        >
          <div v-html="getHandlingDescText"></div>
          <template #reference>
            <sui_icon_doubt_14px_1
              class="summary-gray"
              size="15px"
              color="#767676"
            />
          </template>
        </s-popover>

        <span class="she-fl">{{ language.SHEIN_KEY_PC_19486 }}:</span>
        <span class="she-fr">{{
          caculateInfo.handling_fee.amountWithSymbol
        }}</span>
      </div>
      
      <div
        v-if="codPrice"
        class="summary-item she-clearfix"
      >
        <span class="she-fl">{{ language.SHEIN_KEY_PC_14765 }}</span>
        <span
          class="she-fr"
          v-html="codPrice"
        ></span>
      </div>

      <!-- token 卡 bin 优惠 -->
      <div 
        v-if="(isShowTokenBinDiscountBlock && (isRouterPay || isInstallmentPay) && tokenBinDiscountType !== 3) || isShowCardBinDiscountDescInPriceDetail"
        class="price-detail__item"
        tabindex="0"
      >
        <span class="price-detail__item-left">{{ language.SHEIN_KEY_PC_25758 }}</span>
        <code
          class="price-detail__item-right"
        >
          <em class="price-detail__item-right-discount">{{ resultTokenBinDiscountDesc }}</em>
        </code>
      </div>
      
      <!-- 优惠金额券 优惠免邮券 抵扣金额 -->
      <div
        v-if="showDiscount"
        class="summary-item she-clearfix"
      >
        <p class="summary-coupon">
          <span class="she-fl">{{ language.SHEIN_KEY_PC_14698 }}</span>
          <span class="she-fr price-color">-{{ caculateInfo.couponPrice.amountWithSymbol }}</span>
        </p>
        <p
          v-if="discountCouponCode"
          class="summary-coupon-txt"
        >
          <span>{{ language.SHEIN_KEY_PC_22170 }}:{{ discountCouponCode }}</span>
        </p>
      </div>
      
      <div
        v-if="caculateInfo.pointPrice && +caculateInfo.pointPrice.amount > 0"
        class="summary-item she-clearfix"
      >
        <span class="she-fl">{{ language.SHEIN_KEY_PC_14707 }}:</span>
        <span class="she-fr price-color">-{{ caculateInfo.pointPrice.amountWithSymbol }}</span>
      </div>
     
      <!-- 付费会员 优惠了多少价格 -->
      <template v-if="caculateInfo.prime_deduce_price && +caculateInfo.prime_deduce_price.amount > 0">
        <div class="summary-item she-clearfix prime-flex">
          <span class="she-fl prime-max">
            {{ language.SHEIN_KEY_PC_22970 }}
            <s-popover
              :hide-close-icon="true"
              placemen="bottom"
              trigger="hover"
              :content="primeDeduceTips"
            >
              <template #reference>
                <sui_icon_doubt_14px_1
                  class="summary-gray"
                  size="15px"
                  color="#767676"
                />
              </template>
            </s-popover>
          </span>
          <span class="she-fr prime-color">
            <primeVipIcon :is-middle="1" />
            -{{ caculateInfo.prime_deduce_price.amountWithSymbol }}
          </span>
        </div>
        <div
          v-if="primeDiscountTotal"
          class="prime-discount-info"
        >
          {{ primeDiscountTotal }}
        </div>
      </template>
     
      <!-- 支付方式优惠 -->
      <div
        v-if="
          caculateInfo.onlinePayDiscountInfo &&
            caculateInfo.onlinePayDiscountInfo.onlinePayDiscountInfo &&
            caculateInfo.onlinePayDiscountInfo.onlinePayDiscountInfo
              .isEnjoyedDiscount == 1 &&
            caculateInfo.onlinePayDiscountInfo.onlinePayDiscountInfo
              .discountType != 2
        "
        class="summary-item she-clearfix"
      >
        <span class="she-fl">{{ language.SHEIN_KEY_PC_25757 }}</span>
        <span class="she-fr price-color">-{{
          caculateInfo.onlinePayDiscountInfo.onlinePayDiscountInfo.discountPrice
            .amountWithSymbol
        }}</span>
      </div>
      
      <div
        v-if="caculateInfo.walletPrice && +caculateInfo.walletPrice.amount > 0"
        class="summary-item she-clearfix"
      >
        <span class="she-fl">
          {{ language.SHEIN_KEY_PC_18434.replace(':', '') }}
        </span>
        <span class="she-fr price-color">-{{ caculateInfo.walletPrice.amountWithSymbol }}</span>
      </div>

      <div
        v-if="
          caculateInfo.giftcard &&
            caculateInfo.giftcard.giftCardUsedPrice &&
            caculateInfo.giftcard.giftCardUsedCurrencyAmount > 0
        "
        class="summary-item she-clearfix"
      >
        <span class="she-fl">{{ language.SHEIN_KEY_PC_15587 }}:</span>
        <span class="she-fr price-color">-{{ caculateInfo.giftcard.giftCardUsedPrice.amountWithSymbol }}</span>
      </div>
      
      <div
        v-if="caculateInfo.taxEnabled == 1"
        class="summary-item she-clearfix"
      >
        <span class="she-fl">
          <template v-if="caculateInfo.isReachTax == 0">{{ language.SHEIN_KEY_PC_14792 }}:</template>
          <template v-else>{{ language.SHEIN_KEY_PC_14793 }}:</template>
          <s-popover
            :hide-close-icon="true"
            placemen="bottom"
            trigger="hover"
            :content="language.SHEIN_KEY_PC_14794"
          >
            <template #reference>
              <sui_icon_doubt_14px_1
                class="summary-gray"
                size="15px"
                color="#767676"
              />
            </template>
          </s-popover>
        </span>
        <span class="she-fr">{{ caculateInfo.warPrice.amountWithSymbol }}</span>
      </div>

      <!--isValidated 1=yes 0=no-->
      <div
        v-if="
          caculateInfo.extraPromotion &&
            caculateInfo.extraPromotion.marketFission &&
            caculateInfo.extraPromotion.marketFission.isValidated == 1 &&
            caculateInfo.extraPromotion.marketFission.amount &&
            caculateInfo.extraPromotion.marketFission.amount.amount > 0
        "
        class="summary-item she-clearfix"
      >
        <span class="she-fl">{{ language.SHEIN_KEY_PC_16286 }}:</span>
        <span class="she-fr price-color">-{{
          caculateInfo.extraPromotion.marketFission.amount.amountWithSymbol
        }}</span>
      </div>
      <slot></slot>

      <div
        v-if="isInstallmentPay && installments"
        class="summary-item she-clearfix installments"
      >
        <span class="she-fl">{{ language.SHEIN_KEY_PC_17705 }}:</span>
        <span class="she-fr">
          {{ installments }}
        </span>
      </div>


      <div
        v-if="isInstallmentPay && installmentFee"
        class="summary-item she-clearfix installmentFee"
      >
        <span class="she-fl">{{ language.SHEIN_KEY_PC_16443 }}:</span>
        <span class="she-fr">
          {{ installmentFee }}
        </span>
      </div>

      <!-- 个人税费 -->
      <OrderSummaryTaxes
        v-if="!isBrOrderInSheinWithMultiTax && !isClOrderInSheinWithMultiTax && !isMxOrderInShein && newTaxList2 && newTaxList2.length"
        :taxes="newTaxList2"
        :language="language"
        :is-multi-tax="isMultiTaxCountry"
        :is-show-ca-gst="isShowCaGST"
        :part-order-info="partOrderInfo"
        :taxes-m-y-info="taxesMYInfo"
      />
      <div
        v-if="caculateInfo.grandTotalPrice"
        class="summary-item total-item total-item-wrap she-clearfix"
      >
        <span class="item-left">
          {{ language.SHEIN_KEY_PC_14752 }}<template v-if="cccTaxIncludedTips">&nbsp;{{ cccTaxIncludedTips }}</template>:
        </span>
        <span class="total item-right">
          <span :class="{'total-color-discount': isDiscountTotalColor }">
            {{ grandTotalPrice }}
          </span>
          <!-- 税费 S -->
          <span
            v-if="checkShowTotalFee"
            class="summary-gov"
          >
            + {{ govTaxPrice }}
            <s-popover
              :hide-close-icon="true"
              placemen="bottom"
              trigger="hover"
              :content="language.SHEIN_KEY_PC_19814"
            >
              <template #reference>
                <sui_icon_doubt_14px_1
                  class="summary-gray"
                  size="16px"
                  color="#767676"
                />
              </template>
            </s-popover>
          </span>
          <!-- 税费 E -->
          <span
            v-if="caculateInfo.subGrandTotalPrice"
            class="sub-price"
          >( {{ caculateInfo.subGrandTotalPrice.amountWithSymbol }} )</span>
        </span>
        <!-- cod后端转换货币提示 -->
        <div
          v-if="codCurrencyTip"
          class="cod-change-currency-tip"
        >
          <s-popover
            trigger="hover"
            placemen="bottom"
            class="not-fsp-element"
          >
            <template #reference>
              <sui_icon_closed_14px_1
                size="12px"
                color="#000000"
                tabindex="0"
                :aria-label="codCurrencyTip"
                @click="updateCheckoutState({ key: 'codCurrencyTip', val: '' })"
              />
            </template>
            <em class="tip-bottom-right with-arrow">{{ codCurrencyTip }}</em>
          </s-popover>
        </div>
      </div>
      <div
        v-if="checkShowTotalFee"
        class="summary-item gov-tips"
      >
        <span
          v-html="template(govTaxPrice, language.SHEIN_KEY_PC_19813)"
        ></span>
      </div>
      <!-- 公司税费 1:GST 2:ST 3:VAT -->
      <OrderSummaryTaxes
        v-if="!isBrOrderInSheinWithMultiTax && !isClOrderInSheinWithMultiTax && !isMxOrderInShein && newTaxList && newTaxList.length"
        :taxes="newTaxList"
        :language="language"
        :is-multi-tax="false"
        :is-show-ca-gst="isShowCaGST"
        :part-order-info="partOrderInfo"
        :taxes-m-y-info="taxesMYInfo"
      />
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { template } from '@shein/common-function'
import OrderSummaryTaxes from '../../../vue_tpls/OrderSummaryTaxes.vue'
import primeVipIcon from 'public/src/pages/common/prime/primeVipIcon.vue'
import ItemDetails from '../CheckoutSummaryPriceItemDetails'

import priceMixin from '../../../mixins/priceMixin'
import { mallMerge, handleShipFeeGoods } from '../../../config/tools'
import { sui_icon_doubt_14px_1, sui_icon_club_logo_fill_15px, sui_icon_closed_14px_1, sui_icon_more_up_12px_1, sui_icon_more_down_12px_1 } from '@shein-aidc/icon-vue3'

const PriceItemType = {
  subTotal: 'subTotal',
  shippingFee: 'shippingFee'
}

export default {
  components: {
    primeVipIcon,
    OrderSummaryTaxes,
    ItemDetails,
    sui_icon_doubt_14px_1,
    sui_icon_club_logo_fill_15px,
    sui_icon_closed_14px_1,
    sui_icon_more_up_12px_1, 
    sui_icon_more_down_12px_1
  },
  mixins: [priceMixin],
  props: {
    isDiscountTotalColor: {
      type: Boolean,
      default: false,
    },
    prePayUnPayParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      PriceItemType,
      showSubTotalDetails: false,
      showShippingFeeDetails: false
    }
  },
  computed: {
    ...mapState([
      'codCurrencyTip',
      'checkout',
      'defaultShipping',
      'language',
      'coupon',
      'qsFreightTips',
      'isPrimeMember',
      'rightConfigList',
      'usedPrimeCode',
      'caculateData',
      'mallCartsList',
      'locals',
      'isShowTokenBinDiscountBlock',
      'tokenBinDiscountDesc',
      'status',
      'tokenBinDiscountType'
    ]),
    ...mapGetters([
      'isFreePrice',
    ]),
    isUsMNState() {
      // !MN 的情况用默认文案兜底
      return this.checkout?.default_address?.stateId == '64712'
    },
    RDFTitle() {
      return this.isUsMNState ? this.language.SHEIN_KEY_PC_31534 : this.language.SHEIN_KEY_PC_22171
    },
    RDFPopuText() {
      return this.isUsMNState ? this.language.SHEIN_KEY_PC_31533 : this.language.SHEIN_KEY_PC_22206
    },
    SiteUID() {
      return this.locals.SiteUID
    },
    IS_RW() {
      return this.locals.IS_RW
    },
    isRouterPay() {
      return this.status?.cardPay === 'routepay-card'
    },
    isInstallmentPay() {
      return this.status?.cardPay === 'routepay-cardinstallment'
    },
    partOrderInfo() {
      return {
        countryCode: this.orderCountryCode,
        person_all_tax: this.caculateInfo?.personal_tax,
        total_vat_tax: this.caculateInfo?.total_vat_tax || {}
      }
    },
    taxesMYInfo(){
      return {
        companyTax: this.caculateInfo?.company_tax,
        personTax: this.caculateInfo?.total_tax,
        totalTax: this.caculateInfo?.personal_tax,
      }
    },
    taxesMXInfo() {
      return {
        personTax: this.caculateInfo?.total_tax,
        companySubsidy: this.caculateInfo?.tax?.find(item => item.taxPayType == 1 && item.taxType == 1 && +item.taxPrice.amount > 0)
      }
    },
    taxesBRInfo() {
      return {
        personTax: this.caculateInfo?.before_discount_tax,
        companySubsidy: this.caculateInfo?.tax?.find(item => item.taxPayType == 1 && item.taxType == 1 && +item.taxPrice.amount > 0),
        taxAdicional: this.caculateInfo?.tax?.find(item => item.taxPayType == 2 && item.taxType == 9 && +item.taxPrice.amount > 0),
      }
    },
    isBrOrderInSheinWithMultiTax() {
      return !this.IS_RW && this.isMultiTaxCountry && ['BR'].includes(this.orderCountryCode)
    },
    isClOrderInSheinWithMultiTax() {
      return !this.IS_RW && ['CL'].includes(this.orderCountryCode)
    },
    isMxOrderInShein() {
      return !this.IS_RW && ['MX'].includes(this.orderCountryCode)
    },
    showMultiTax() {
      // br 税费明细
      const brTaxesVisible = this.isBrOrderInSheinWithMultiTax && this.newTaxList2?.length
      // cl 税费明细
      const clTaxesVisible = this.isClOrderInSheinWithMultiTax && this.newTaxList2?.length
      // mx 税费明细
      const mxTaxesVisible = this.isMxOrderInShein && +this.caculateInfo?.total_tax?.amount > 0
      return brTaxesVisible || clTaxesVisible || mxTaxesVisible
    },
    ICMSTax() {
      const { tax } = this.caculateInfo
      // 公司支付，且税费类型为商品增值税ICMS，且不为0
      const ICMSTax = tax.filter(item => item.taxPayType == 1 && item.taxType == 3 && +item.taxPrice.amount > 0)
      return ICMSTax || []
    },
    FVATTax() {
      const { tax } = this.caculateInfo
      // 公司支付，且税费类型为巴西新增运费增值税FVAT，且不为0
      const FVATTax = tax.filter(item => item.taxPayType == 1 && item.taxType == 8 && +item.taxPrice.amount > 0)
      return FVATTax || []
    },
    hasSubTotalDetails() {
      const { newSubTotalWithoutVat } = this.caculateInfo

      // br订单，公司支付，且有商品增值税ICMS，才有取值，但没有过滤税费为0的情况
      const taxFulfilled = +newSubTotalWithoutVat?.amount > 0
      // BR订单：shein && 一单多税 && BR订单 && 有税费 && 税费不为0
      let brHasSubTotalDetails = this.isBrOrderInSheinWithMultiTax && taxFulfilled && this.ICMSTax.length > 0

      return brHasSubTotalDetails
    },
    hasShippingFeeDetails() {
      const { actual_shipping_price_without_vat } = this.caculateInfo

      // br订单，公司支付，且有巴西新增运费增值税FVAT，才有取值，但没有过滤税费为0的情况
      const taxFulfilled = +actual_shipping_price_without_vat?.amount > 0
      // shein && 一单多税 && BR订单 && 有税费 && 税费不为0
      return this.isBrOrderInSheinWithMultiTax && taxFulfilled && this.FVATTax.length > 0
    },
    shippingFeeData() {
      return this.handleShippingFeeData()
    },
    showShippingFeeTips() {
      if(this.IS_RW) return false

      const { mall_list = [] } = this.shippingFeeData
      let rule_id_list = []
      mall_list.forEach(mall => { 
        mall.rule_list?.forEach(item => {
          rule_id_list.push(+item.freight_free_rule_id)
        })
        
      })
      rule_id_list = [...new Set(rule_id_list)]
      return rule_id_list.length > 1
    },
    isShowShipOrigin() {
      const showSaveAmount = this.checkout.checkoutBFFAbtInfo?.Shownewsaveamount?.param?.show_new_save_amount == 'on' ? true : false
      if(showSaveAmount) {
        const { actual_shipping_price, origin_shipping_price } = this.caculateInfo
        return (origin_shipping_price?.amountWithSymbol && actual_shipping_price?.amountWithSymbol) && (+origin_shipping_price?.amount > +actual_shipping_price?.amount)
      } else {
        return false
      }
    },
    primeDeduceTips() {
      let tips = this.language.SHEIN_KEY_PC_25403 || ''
      if(!!this.usedPrimeCode) { // 随单购场景
        if(!!this.rightConfigList.length) { // 存在当前勾选产品包权益列表
          const discountRight = this.rightConfigList.filter(item => item.right_type_code == 'EXCLUSIVE_DISCOUNTS' && item.is_has_quota == 1) || []
          const right_quota_info = discountRight[0]?.right_quota_info || {}
          const { grant_threshold, grant_threshold_with_symbol } = right_quota_info

          if(+grant_threshold > 0 && !!grant_threshold_with_symbol) tips = this.template(grant_threshold_with_symbol, this.language.SHEIN_KEY_PC_25402)
        }
      } else if(this.isPrimeMember) { // 付费会员场景（注：试用会员+随单购 逻辑在随单购场景取值）
        const primeInfo = this.caculateInfo?.promotion_grant_amount_info || {}
        const totalAmount = primeInfo?.cycle_grant_amount?.amountWithSymbol || ''
        const totalAmountNum = primeInfo?.cycle_grant_amount?.amount || '0'

        if(+totalAmountNum > 0 && !!totalAmount) tips = this.template(totalAmount, this.language.SHEIN_KEY_PC_25402)
      }

      return tips
    },
    primeDiscountTotal() {
      const primeInfo = this.caculateInfo?.promotion_grant_amount_info || {}
      const totalAmount = primeInfo?.cycle_grant_amount?.amountWithSymbol || ''
      const totalAmountNum = primeInfo?.cycle_grant_amount?.amount || '0'
      const usedAmount = primeInfo?.cycle_used_grant_amount?.amountWithSymbol || ''
      if (!totalAmount || +totalAmountNum == 0) return ''
      return this.template(usedAmount, totalAmount, this.language.SHEIN_KEY_PC_24014) 
    },
    caculateInfo() {
      return this.checkout?.mall_caculate_info || {}
    },
    isFreeInfo(){
      const { insurancePrice } = this.checkout?.mall_caculate_info || {}
      const isFREE = [0, 1].includes(+this.mallInsuranceCheck?.[0]?.insurance_type) && insurancePrice?.amount == 0 ? 'FREE' : ''
      return {
        isFREE
      }
    },
    newTaxList() {
      let newTax = []
      const taxList = this.caculateInfo.tax
      if (taxList?.length) {
        taxList.forEach((tax) => {
          if (tax.taxPrice && tax.taxPrice.amount > 0 && tax.taxPayType == 1) {
            newTax.push(tax)
          }
        })
      }
      return newTax
    },
    newTaxList2() {
      let newTax = []
      const taxList = this.caculateInfo.tax
      if (taxList?.length) {
        taxList.forEach((tax) => {
          if (tax.taxPrice && tax.taxPrice.amount > 0 && tax.taxPayType == 2) {
            newTax.push(tax)
          }
          if(this.isClOrderInSheinWithMultiTax && tax.taxPrice.amount > 0 && tax.taxPayType == 1) {
            newTax.push(tax)
          }
        })
      }
      return newTax
    },
    codPrice() {
      if (this.caculateData.payment_code_unique !== 'cod') {
        return ''
      }
      const {
        show_type = '',
        codPrice: {
          amountWithSymbol: currentPrice,
          amount: currentPriceAmount,
        } = {},
      } = this.caculateInfo
      const cur =
        Number(currentPriceAmount) > 0
          ? currentPrice || ''
          : this.language.SHEIN_KEY_PC_19647
      if (show_type === 2) {
        return cur
      }
      if (show_type === 1) {
        const {
          origin_service_fee: {
            amountWithSymbol: originPrice,
            amount: originPriceAmount,
          } = {},
        } = this.caculateInfo
        const origin =
          Number(originPriceAmount) > 0
            ? originPrice || ''
            : this.language.SHEIN_KEY_PC_19647
        return `${cur} <del style="color: #767676;">${origin}</del>`
      }
      return ''
    },
    checkShowTotalFee() {
      return (
        this.isShowTotalFee &&
        this.caculateInfo?.pay_method_gov_tax_info?.pay_method_gov_tax_rate > 0 &&
        Number(this.caculateInfo?.pay_method_gov_tax_info?.pay_method_gov_tax_amount?.amount || '0') > 0
      )
    },
    govTaxPrice() {
      return (
        this.caculateInfo?.pay_method_gov_tax_info?.pay_method_gov_tax_amount
          ?.amountWithSymbol || 0
      )
    },
    getHandlingDescText() {
      let { mall_list = [] } = this.caculateInfo || {}
      let newList = mallMerge(mall_list, this.defaultShipping)
      if (newList?.length) {
        let handlingDescList = newList?.filter((mall) => !!mall.handling_desc)
        let handlingDescArr = handlingDescList?.map(
          (handlingDescObj) =>
            `<div>${handlingDescObj?.shipping_method?.title}: ${handlingDescObj.handling_desc}</div>`
        )
        return handlingDescArr.join('')
      }
      return ''
    },
    discountCouponCode() {
      if (this.showDiscount) {
        const filterCoupon = this.coupon?.applyCouponsSuccess?.filter(item => item.applyFor != 5 && item.applyFor != 6 && item.applyFor != 9) || []
        return filterCoupon.map(item => item.couponCode).join()
      }
      return ''
    },
    isUsePrimeCoupon() {
      const filterCoupon = this.coupon?.applyCouponsSuccess?.filter(item => item.applyFor == 9 && item.typeId == 53) || []
      return !!filterCoupon.length
    },
    isMultiTaxCountry () {
      if(this.isClOrderInSheinWithMultiTax) return true
      if (this.isMxOrderInShein) return true
      return this.locals?.MULTIPLE_TAXES_SPLIT_DISPLAY?.includes(this.orderCountryCode)
    },
    // 判断是否展示ca gst 提示文案
    isShowCaGST() {
      const { countryId } = this.checkout?.default_address || {}
      return countryId == '38' || this.SiteUID == 'ca'
    },
    orderCountryCode () {
      return this.checkout?.default_address?.value || ''
    },
    cccTaxIncludedTips () {
      return this.checkout?.results?.cccTaxIncludedInfo?.taxIncludesTips || ''
    },
    isShowTotalFee() {
      return this.checkout?.checkoutBFFAbtInfo?.TotalFee?.param == 'ShowTotalFee' ? true : false
    },
    installmentFee() {
      return this.prePayUnPayParams?.installmentsInfo?.installment_fee
    },

    installments() {
      return this.prePayUnPayParams?.installments
    },
    isShowCardBinDiscountDescInPriceDetail() {
      return this.prePayUnPayParams?.isShowCardBinDiscountDescInPriceDetail
    },

    cardBinDiscountDesc() {
      return this.prePayUnPayParams?.cardBinDiscountDesc
    },

    resultTokenBinDiscountDesc() {
      return this.isShowCardBinDiscountDescInPriceDetail ? this.cardBinDiscountDesc : this.tokenBinDiscountDesc
    },

    grandTotalPrice() {
      const totalPrice = this.caculateInfo?.grandTotalPrice?.amountWithSymbol

      if (this.isInstallmentPay && Object.keys(this.prePayUnPayParams?.installmentsInfo || {}).length > 0) {
        return this.prePayUnPayParams?.installmentsInfo?.total_amount
      }
      return totalPrice
    },
  },
  methods: {
    ...mapMutations(['updateCheckoutState', 'assignState']),
    template,
    getItemDetails(itemType) {
      const { actual_shipping_price_without_vat } = this.caculateInfo
      switch (itemType) {
        case PriceItemType.subTotal: 
          return [
            { 
              title: this.language.SHEIN_KEY_PC_27347, 
              price: this.caculateInfo.newSubTotal.amountWithSymbol
            },
            {
              title: this.language.SHEIN_KEY_PC_26613,
              price: this.ICMSTax[0]?.taxPrice?.amountWithSymbol,
              tips: this.language.SHEIN_KEY_PC_27376,
            },
            {
              title: this.language.SHEIN_KEY_PC_27506,
              price: '- ' + this.ICMSTax[0]?.taxPrice?.amountWithSymbol,
              tips: this.language.SHEIN_KEY_PC_27507,
            }
          ]
        case PriceItemType.shippingFee:
          return [
            { 
              title: this.language.SHEIN_KEY_PC_27350, 
              price: actual_shipping_price_without_vat?.amountWithSymbol 
            },
            {
              title: this.language.SHEIN_KEY_PC_27348,
              price: this.FVATTax[0]?.taxPrice?.amountWithSymbol,
              tips: this.language.SHEIN_KEY_PC_27349
            }
          ]
      }
    },
    async mergeShipFeeSpuInfo() {
      const { mall_list = [] } = this.shippingFeeData
      let requestArr = []
      mall_list.forEach(async mall => {
        if(!!mall.rule_list?.length) {
          requestArr.push(handleShipFeeGoods(mall.rule_list))
        } else {
          requestArr.push(Promise.resolve({ code: -1, info: {} }))
        }
      })

      await Promise.all(requestArr)
    },
    async clickShippingFee() {
      await this.mergeShipFeeSpuInfo()
      this.assignState({
        showShippingFeeDrawer: true,
        shippingFeeRuleInfo: this.shippingFeeData
      })
    },
    handleShippingFeeData() {
      if(!this.mallCartsList.length) return {}

      let arr = []
      const { mall_list = [] } = this.checkout?.mall_caculate_info || {}
      this.mallCartsList.forEach(mall => {
        const mall_code = mall?.[0]?.mall_code || ''
        const cartsMallInfo = this.checkout.results?.carts?.mall_list?.find(item => item.mall_code == mall_code) || {}

        const filterCalcu = mall_list.filter(item => item.mall_code == mall_code)
        const shipping_price_all = filterCalcu[0]?.shipping_price_all || []

        const filterShip = this.defaultShipping?.filter(item => item.mall_code == mall_code) || []
        const shipping_method = filterShip?.[0]?.shipping_method || {}

        const filterRule = shipping_price_all.filter(item => item.transport_type == shipping_method.transport_type) || []
        const ruleList = filterRule[0]?.shipping_free_rule_info_list || []

        arr.push({
          mall_code: cartsMallInfo.mall_code || '1',
          mall_name: cartsMallInfo.mall_name || '',
          rule_list: ruleList,
        })
      })

      return { 
        mall_list: arr, 
        total_price: this.caculateInfo.actual_shipping_price,
        save_price: this.caculateInfo.shipping_price_diff
      }
    },
  },
}
</script>

<style lang="less">
.checkout-order-summary__body {
  .order-summary-taxes.order-summary-taxes__new {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .summary-item{
    &__subtotal {
      cursor: pointer;
      .she-fr > i {
        margin-left: 2px;
        font-size: 14px;
      }
    }
		&.gov-tips {
			.txt-r();
			margin-top: -5px;
			color: #767676;
			b {
				color: #222;
			}
		}
		>span{
			display: inline-block;
			&.total{
				font-size: 20px;
				font-weight: bold;
			}
			.sub-price {
				display: block;
				font-size: 10px;
				line-height: 11px;
				font-weight: normal;
				color: #767676;
        & when (@IS_RW) {
          color: #bbbbbb;
        }
			}
		}
		.she-fr {
			.txt-r();
		}
		>del{
			display: inline-block;
			color: #767676;
		}
		&.total-item{
			line-height: 24px;
			position: relative;
		}
		&.gst-item{
			margin-top: -10px;
		}
		&.total-item-wrap {
			line-height: 19px;
			font-weight: bold;
			color: #000;
			.item-left {
				font-size: 14px;
			}
			.item-right {
				font-size: 16px;
			}
		}
	}
  
}

.summary-gray {
  cursor: pointer;
}
.summary-popover {
  padding: 0 2px;
}
.summary-coupon {
  overflow: hidden;
}
.summary-coupon-txt {
  .text-overflow();
  .txt-r();
  margin-top: 5px;
  span {
    color: @sui_color_gray_light1;
  }
}
.prime-discount-info {
  color: @sui_color_gray_dark3;
  margin: -5px 0 10px 0;
}
.price-prime {
  color: @sui_color_club_rosegold_dark2;
}
.origin-price {
  color: @sui_color_gray_light1;
  .margin-l(4px);
}
.free-price {
	color: @sui_color_safety;
}
.free-prime-color {
	color: #C96E3F;
}
.total-color-discount {
  color: @sui_color_discount;
}
</style>

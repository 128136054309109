import { reactive } from 'vue'


export default () => {
  const challengeModalOptions = reactive({
    adyenChallengeShow: false,
    wpChallengeShow: false
  })

  return {
    challengeModalOptions
  }
}

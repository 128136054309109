<template>
  <div
    v-if="btnBenefitInfo.length"
    v-expose="{ id: '1-11-1-236' }"
    class="checkout-btn-benefit"
  >
    <swiper-container 
      ref="btnBenefitSwiperRef"
      init="false"
      class="checkout-btn-benefit__swiper-container"
    >
      <swiper-slide
        v-for="item in btnBenefitInfo"
        :key="item.name"
        class="swiper-item-benefit"
      >
        <Icon
          class="swiper-item-benefit__tips-icon"
          :name="item.icon"
          :color="item.iconColor"
          size="12"
        />
        <span class="swiper-item-benefit__tips">
          <span class="swiper-item-benefit__tips-text">{{ item.tip }}</span>
          <span v-if="item.timedown"> | {{ item.timedown }}</span>
        </span>
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue3'
import { template, CountDown } from '@shein/common-function'
import { register } from 'swiper/element'
import { Autoplay } from 'swiper/modules'

typeof window !== 'undefined' && register()
const { GB_cssRight } = gbCommonInfo
const btnBenefitMap = {
  FLASH_SALE: 'Flash_sale', // 普通闪购
  ORDER_DISCOUNT: 'order_discount', // 整单折扣
  LOW_STOCK: 'low_stock', // 低库存
  CHEAPER_THAN_ADDED_CART: 'cheaper_than_added_cart', // 商品相比加车降价
  LOWEST_PRICE: 'lowest_prices', // 最低价
  SECURE: 'Secure', // 支付安全
  REWARD_CREDIT: 'reward_credit', // 付费会员 credit 返还
}
export default {
  name: 'CheckoutBtnBenefit',
  components: {
    Icon,
  },
  props: {
  },
  data() {
    return {
      timer: null,
    }
  },
  computed: {
    ...mapGetters(['orderBtnActualBenefitInfo']),
    ...mapState([ 'language', 'checkout', 'promotionList', 'integrityPromotionInfo']),
    carts() {
      return this.checkout?.results?.carts?.carts || []
    },
    caculateInfo() {
      return this.checkout?.mall_caculate_info || {}
    },
    promotionCarts() {
      const promotion_id_list = this.promotionList.map(item => item.id) || []
      return this.carts.filter(item => {
        const product_promotion_info = item.product?.product_promotion_info || []
        const isFlashType = Number(item.flashType) == 1
        const SamePromotion = product_promotion_info.find(promoption => promoption.type_id == 10 && isFlashType && promotion_id_list.includes(promoption.promotion_id))
        return !!SamePromotion
      })
    },
    countDownData() {
      if(!this.promotionCarts.length) return ''
      
      const endTimeList = []
      const timeThreshold = 48 * 60 * 60 * 1000 // 48小时的阈值

      this.promotionCarts?.forEach(item => {
        const promotion = item.product?.product_promotion_info?.find(_ => _.type_id == 10)
        if(promotion) {
          let targetPromotion = this.integrityPromotionInfo?.[promotion.promotion_id]
          if(targetPromotion?.is_count_down){
            let endTime = targetPromotion.end_time
              
            if (endTime.toString().length < 13) {
              endTime *= 1000
            }
            // 剩余的时间 - 当前的时间戳 的结果与时间阈值进行对比
            const differenceInMs = endTime - new Date().getTime()
            if (differenceInMs > 0 && differenceInMs <= timeThreshold) {
              endTimeList.push(+endTime)
            }
          }
        }
      })

      return endTimeList.sort((a, b) => a - b)?.[0] || ''
    },
    // 获取当前下单的利益点信息
    btnBenefitInfo() {
      return this.orderBtnActualBenefitInfo.map( i => {
        let icon = 'sui_icon_decrease_12px'
        let iconColor = '#FA6338'
        let tip = ''
        let timedown = ''

        if (i == btnBenefitMap.LOWEST_PRICE) {
          tip = this.template(this.lowestPriceBySomeDays?.days, this.language.SHEIN_KEY_PC_31435)
        } else if (i == btnBenefitMap.FLASH_SALE ) {
          iconColor = '#FACF19'
          icon = 'sui_icon_flashsale_16px'
          tip = this.language.SHEIN_KEY_PC_14588
          timedown = this.getTimeDownData()
        } else if (i == btnBenefitMap.ORDER_DISCOUNT) {
          const totalDiscount = (this.caculateInfo.total_discount * 100)?.toFixed(0)
          tip = this.template(GB_cssRight ? `%${totalDiscount}` : `${totalDiscount}%`, this.language.SHEIN_KEY_PC_31439)
        } else if (i == btnBenefitMap.LOW_STOCK) {
          iconColor = '#FA6338'
          icon = 'sui_icon_hourglass_12px_1'
          tip = this.language.SHEIN_KEY_PC_31176
        } else if (i == btnBenefitMap.CHEAPER_THAN_ADDED_CART) {
          tip = this.language.SHEIN_KEY_PC_31440
        } else if (i == btnBenefitMap.SECURE) {
          iconColor = '#198055',
          icon = 'sui_icon_guarantee_18px',
          tip = this.language.SHEIN_KEY_PC_31441
        } else if (i == btnBenefitMap.REWARD_CREDIT) {
          iconColor = '#C96E3F',
          icon = 'sui_icon_club_logo_fill_14px',
          tip = this.template(
            this.caculateInfo.credit_total_amount?.amountWithSymbol, 
            this.language.SHEIN_KEY_PC_32393
          )
        }

        return { icon, iconColor, tip, timedown, name: i }
      })
    },
  },
  watch: {
    btnBenefitInfo: {
      handler(newVal, oldVal) {
        if (typeof window != 'undefined' && newVal?.length > 1 && newVal != oldVal) {
          this.initSwiper()
        }
      },
      immediate: true
    }
  },
  methods: {
    template,
    initSwiper() {
      this.$nextTick(() => {
        if (this.$refs.btnBenefitSwiperRef) {
          Object.assign(this.$refs.btnBenefitSwiperRef, {
            direction: 'vertical',
            modules: [Autoplay],
            spaceBetween: 2,
            autoplay: {
              delay: 2000,
            },
            loop: true,
          })
          this.$refs.btnBenefitSwiperRef.initialize()
        }
      })
    },
    getTimeDownData() {
      if (!this.countDownData) return

      const newDate = new Date().getTime()
      const countdown = this.countDownData - newDate
      
      this.timer = new CountDown()
      if (!this.timer) return ''
      
      this.timer.start({ seconds: Math.floor(countdown / 1000) })
      const { H, M, S } = this.timer?.timeObj || {}
      return `${H}:${M}:${S}`
    },
  }
}
</script>

<style lang="less" scoped>
.checkout-btn-benefit {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 14px;
  &__swiper-container {
    width: 100%;
  }
}
.swiper-item-benefit {
  display: flex;
  justify-content: center;
  align-items: center;
  &__tips-icon {
    margin-right: 2px;
    /* stylelint-disable-next-line declaration-no-important */
    line-height: 1 !important;
  }
  &__tips {
    display: flex;
    max-width: calc(100% - 12px);
    color: var(---sui_color_white, #FFF);
    font-family: "SF Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__tips-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>

export const getDefaultPrePayUnPayParams = () => ({
  cardBin: '',
  routeId: '',
  lastFourNo: '',
  cardNumber: '',
  countryCode: '',
  expireMonth: '',
  expireYear: '',
  cvv: '',
  cpfNumber: '',
  cardHolderName: '',
  rememberCard: 0,
  paymentCode: '',
  installments: 0,
  cardType: '',
  installmentsInfo: {},
  isSelectedInstallment: true,
  isHadBinDiscount: false,
  cardBinDiscountDesc: '',
  autoBindCard: '', // 用户未主动选择了记住卡号
  isShowCardBinDiscountDescInPriceDetail: false,
})

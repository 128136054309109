import schttp from 'public/src/services/schttp'
const { SiteUID } = gbCommonInfo
// 获取阿波罗配置信息
export const getApolloConfig = async () => {
  const apolloKeys = 'SHEINCLUB_DISCOUNT_TYPE'
  const res = await schttp({
    method: 'POST',
    url: '/api/config/apollo/get',
    data: { apolloKeys },
  })
  // const res = { code: 0, info: { SHEINCLUB_DISCOUNT_TYPE: { us: [1,2,3,4,5] } } }
  return res?.info?.[apolloKeys]?.[SiteUID] || []
}

// 省钱计算-查询用户已省金额明汇总
export const getSavingsSummary = async data => {
  try {
    const res = await schttp({
      method: 'POST',
      url: '/api/prime/savingsSummary/get',
      data,
    })
    return res
  } catch (error) {
    return {}
  }
}

// 省钱计算-查询用户已省金额明汇总
export const getSavingsDetails = async data => {
  try {
    const res = await schttp({
      method: 'POST',
      url: '/api/prime/savingsDetails/query',
      data,
    })
    return res
  } catch (error) {
    return {}
  }
}

// 获取续费信息
export const getRenewInfo = async () => {
  const res = await schttp({
    method: 'POST',
    url: '/api/prime/postRenewBuyProduct/get',
  })
  return res
}

export const getPaidInfo = async () => {
  const res = await schttp({
    method: 'POST',
    url: '/api/prime/postPaidDetail/get',
  })
  return res
}

// 查询优惠券可用列表
export const getCouponList = async () => {
  const res = await schttp({
    method: 'POST',
    url: '/api/user/coupon/couponList/get',
    data: { typeId: 53 },
  })
  return res
}

// 查询权益优惠券列表
export const getCouponByCodes = async codes => {
  const res = await schttp({
    method: 'POST',
    url: '/api/prime/postCouponByCouponCodes/query',
    data: { codes },
  })
  return res
}

// 查询退订资格
export const isRefoundable = async params => {
  const res = await schttp({
    method: 'POST',
    url: '/api/prime/postRefundableAndAmount/get',
    data: params,
  })
  return res
}

// 查询退订订单信息
export const getRefondOrderInfo = async params => {
  const res = await schttp({
    method: 'POST',
    url: '/api/prime/postRefundOrderInfo/get',
    data: params,
  })
  return res
}

// 用户退订
export const userRefoundale = async params => {
  const res = await schttp({
    method: 'POST',
    url: '/api/prime/postUnionRevokeRefund/update',
    data: params,
  })
  return res
}

// 查询活动积分详情
export const getPointInfo = async params => {
  const res = await schttp({
    method: 'POST',
    url: '/api/prime/postBatchQueryActivityDetail/get',
    data: params,
  })
  return res
}

// 获取历史记录列表
export const getHistoryList = async params => {
  const res = await schttp({
    method: 'POST',
    url: '/api/prime/postPaidRecord/get',
    data: params,
  })

  if (res?.code == '0') {
    return res?.info?.data || []
  }
}

// 是否可购买
export const getCanBuyInfo = async params => {
  const res = await schttp({
    method: 'POST',
    url: '/api/prime/postPidCheck/get',
    data: params,
  })

  if (res?.code == '0') {
    return res?.info || {}
  }
}

// 获取商品列表
export const getProductList = async promotionId => {
  const res = await schttp({
    method: 'POST',
    url: '/api/prime/postGoodsList/get',
    data: {
      promotionId,
      limit: 10,
    },
  })

  return res
}

// 获取试用资格
export const postCheckFreeTrial = async data => {
  const res = await schttp({
    method: 'POST',
    url: '/api/prime/postCheckFreeTrial/get',
    data,
  })
  return res
}

// 领取试用
export const getTrialStatus = async (data, errCb) => {
  try {
    const res = await schttp({
      method: 'POST',
      url: '/api/prime/postFreeTrial/create',
      data,
    })
    return res
  } catch (error) {
    errCb()
  }
}

// 查询试算产品包权益价值
export const getProductEquityValue = async data => {
  const res = await schttp({
    method: 'POST',
    url: '/api/prime/queryProductEquityValue/get',
    data,
  })
  return res
}

// 非付费会员试算历史订单可省金额
export const getOrderSaveAmount = async () => {
  const res = await schttp({
    method: 'POST',
    url: '/api/prime/calculateRecentOrderCanSavingAmount/get',
  })
  return res
}

// 付费展示场景图片与文案获取接口
export const getPopupDetails = async data => {
  try {
    const res = await schttp({
      method: 'POST',
      url: '/api/prime/postPopupDetail/get',
      data,
    })
    return res
  } catch (error) {
    return {}
  }
}


// 获取推荐商品列表
export const getPaidRecommendList = async data => {
  try {
    const res = await schttp({
      method: 'POST',
      url: '/api/prime/getPaidRecommendList/get',
      data
    })
    return res
  } catch (error) {
    return {}
  }
}

// CLUB签约信息修改
export const updateContract = async data => {
  const res = await schttp({
    method: 'POST',
    url: '/api/prime/updateContract/post',
    data,
  })
  return res
}

// CLUB签约信息取消
export const cancelContract = async () => {
  const res = await schttp({
    method: 'POST',
    url: '/api/prime/cancelContract/post',
  })
  return res
}
// 根据产品包促销id查询免费礼商品列表
export const getGiftProductList = async data => {
  const res = await schttp({
    data,
    method: 'POST',
    url: '/api/prime/product/get_gift_product_list/post',
  })
  return res
}
// 获取赠品产品列表
export const getGiftOrderCard = async data => {
  const res = await schttp({
    data,
    method: 'POST',
    url: '/api/prime/order/cart/index',
  })
  return res
}

// 查询用户返现总额接口
export const postMemberSummary = async data => {
  const res = await schttp({
    data,
    method: 'POST',
    url: '/api/prime/creditReward/memberSummary',
  })
  return res
}

<template>
  <div 
    v-if="policyTip"
    class="agree-policy-tip" 
    da-expose-code="agree-policy-tip-expose"
  >
    <p
      class="agree-policy-content" 
      @click="handleNewPrivacyClick" 
      v-html="policyTip"
    ></p>
  </div>
</template>

<script>
import { toRefs, onBeforeMount, nextTick, computed } from 'vue'
import { useStore } from 'vuex'
import { template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import {
  handleNewPrivacyClick,
} from 'public/src/pages/components/login/util'


daEventCenter.addSubscriber({ modulecode: '2-7' })
export default {
  props: {
    language: {
      type: Object,
      default: () => ({})
    }
  },
  setup (props) {
    let store = useStore()

    function initExposeEvents () {
      nextTick(() => {
        const daEventExpose = daEventCenter.getExposeInstance()
        daEventExpose.subscribe({
          once: false,
          keycode: 'agree-policy-tip-expose`2-7-60|2-7-62',
        })
      })
    }

    const isShownewtcpptip = computed(() => {
      return store.state.checkout?.checkoutBFFAbtInfo?.Shownewtcpptip?.param == 'on'
    })

    const policyTip = computed(() => {
      if(!isShownewtcpptip.value) return ''

      const location = 'page_checkout'
      const IS_RW = store.state.locals.IS_RW
      const tip = IS_RW ? props.language?.SHEIN_KEY_PC_24287 : props.language?.SHEIN_KEY_PC_24286
      const terms = `<a tabindex="0" href="javascript:void(0);" class="j-terms-and-conditions" da-event-click="2-7-61" da-event-expose="2-7-62" data-location="${location}">${props.language.SHEIN_KEY_PC_24290}</a>`
      const privacy = `<a href="javascript:void(0);" class="j-privacy-and-cookies" da-event-click="2-7-59" da-event-expose="2-7-60" data-location="${location}" tabindex="0">${props.language.SHEIN_KEY_PC_24291}</a>`

      return template(terms, privacy, tip)
    })

    function initCombinePolicyTip () {
      onBeforeMount(() => {
        if (policyTip.value) {
          initExposeEvents()
        }
      })
    }

    return {
      policyTip,
      handleNewPrivacyClick,
      ...toRefs(initCombinePolicyTip())
    }

  },
}
</script>

<style lang="less">
.agree-policy-tip {
  p.agree-policy-content {
    font-size: 12px;
    margin-top: 15px;
    color: @sui_color_gray_dark3;
  }
  a {
    color: @sui_color_link;
  }
}
</style>

<template>
  <div class="multiple-container">
    <horizontalRecommend
      ref="hotSaleRecoomendRef"
      :new-poskey="poskey"
      :code="code"
      :config="getResultConfig"
      :analysis-info="analysisInfo"
      :title-config="titleConfig"
      :swiper-config="swiperConfig"
      :goods-item-config="goodsItemConfig"
      :bottom-info-style="bottomInfoStyle"
      @recommend-list-ready="recommendListReady"
    />
  </div>
</template>
<script>
import horizontalRecommend from 'public/src/services/productRecommend/components/horizontalRecommend.vue'
import schttp from 'public/src/services/schttp'
import { getGoodsListForAnalysis } from 'public/src/pages/checkout/utils.js'
import { goodsItem_config, daEventCenterMap } from './config'
import { formatFn } from 'public/src/pages/checkout/hooks/useTogether.js'

let QuickViewPopover = null

const { IMG_LINK } = gbCommonInfo
import { mapMutations, mapState } from 'vuex'

export default {
  components: {
    horizontalRecommend,
  },
  emits: ['isActiveEmit'],
  data() {
    return {
      IMG_LINK,
      code: 'checkout',
      poskey: 'PickupRec',
      swiperConfig: {
        // slide之间的距离
        spaceBetween: 10,
        // slider容器能够同时显示的slides数量
        slidesPerView: 6,
        // 定义slides的数量多少为一组
        slidesPerGroup: 6,
      },
      bottomInfoStyle: {
        minHeight: '70px'
      },
      beforeInfo: '',
      analysisInfo: {
        recommendName: '',
        from: 'add_more_items',
        position: 1,
      },
    }
  },
  computed: {
    ...mapState(['checkout', 'language', 'togetherBuy', 'defaultShipping',  'usableCouponList', 'buyNowNo']),
    titleConfig() {
      return {
        enValue: 'add_more_items',
      }
    },
    newCardAbt() {
      const { newProductCard, buyNewCardAbt } = this.togetherBuy
      // 新商卡：随手购商卡开关开启 && 商卡开关开启
      return buyNewCardAbt == 1 && newProductCard 
    },
    // 商品项配置
    goodsItemConfig() {
      const _this = this
      const otherParams = this.newCardAbt ? { ...goodsItem_config['newCard']['common'], isNew: true, showABPriceOnSale: ['Have_Threshold', 'Non_Threshold', 'A', 'B', 'C', 'D'].includes(this.checkout?.checkoutBFFAbtInfo?.EstimatedPrice?.p?.E_Price_Cal) } : goodsItem_config['oldCard']
      return {
        isModule: true,
        ...otherParams,
        handleItemClick: (item, el) => {
          const { goods_id, mall_code, sale_attr_list, imgRatio, index } = item || {}
          const { skc_sale_attr = [], sku_list = [],  } = sale_attr_list || {}
          let isOneSku = false
          // 没有次级销售属性，但会有一个默认的sku_code，sku_code作为入参直接加车
          if ((!skc_sale_attr.length || skc_sale_attr.length == 1) && sku_list.length == 1) {
            isOneSku = true
          }
          const goods_list = getGoodsListForAnalysis([{ ...item, swiperIndex: index }], +this.togetherBuy.OrderGoodsLocation)
          // 单尺码直接加车，多尺码拉起购物车弹窗
          daEventCenterMap['click-together'](goods_list)
          // 加车后的相关埋点数据
          const analysisConfig = {
            code: 'checkout',
            activity_from: 'add_more_items',
            goods_list,
            button_type: 'add_to_bag',
            content_id: 1,
            ...(isOneSku ? { size: skc_sale_attr[0]?.attr_value_list[0]?.attr_value_id } : {})
          }
          return new Promise((resolve) => {
            const quickViewData = {
              goodsId: goods_id,
              mallCode: mall_code,
              imgRatio,
              lockmall: true,
              extendForm: null, // 尺码配置
              gaTitle: _this.titleConfig.enValue,
              activity_from: 'add_more_items',
              config: {
                isUpdateButton: false,
                hideViewFullDetail: true, // 隐藏商详
                hideWishlistBtn: true, // 不展示收藏功能按钮
                reportData: {
                  location: 'popup'
                },
                customSubmitParam: {
                  // 页面来源：下单页随手购
                  add_source: 12
                },
                isBuyNow: !!this.buyNowNo,
                casualCheckoutNo: this.buyNowNo,
              },
              analysis: {
                sourceTarget: null,
                pageType: 'checkout',
                sa: analysisConfig
              },
              callback: {
                handleAddToCartDone: (data) => {
                  if (data?.code == '0') {
                    // 存储加车信息
                    if(!this.buyNowNo) {
                      _this.changeTogetherBuy({
                        quickViewCartData: data
                      })
                    } else {
                      _this.changeTogetherBuy({
                        quickViewCartData: {
                          cart: data.info?.carts?.[0],
                          info: data.info.info
                        }
                      })
                    }
                    _this.addQuickCall({ goods_id, el })
                  }
                  resolve()
                }
              },
            }

            if (QuickViewPopover) {
              QuickViewPopover.show(quickViewData)
              return
            }

            import('public/src/pages/common/quickView').then(_ => {
              QuickViewPopover = _.default
              QuickViewPopover.show(quickViewData)
            })
          })
        },
      }
    },
    // 推荐数据请求配置
    getResultConfig() {
      const exposedGoodsId = this.togetherBuy?.exposedGoodsId
      const params = formatFn({ abt: this.checkout.checkoutBFFAbtInfo, carts: this.checkout?.results?.carts?.carts, exposedGoodsId })
      return {
        abtParamsMatchConfig: [
          { 
            notFaultTolerance: true,
            type: 'request',
            matchRule: () => true,
            method: 'POST',
            url: `/api/checkout/recommendProducts/get`,
            atomic: false, // 商品接口才需要
            params
          }
        ]
      }
    },
  },
  mounted() {
    this.doRecommend()
  },
  methods: {
    ...mapMutations(['changeTogetherBuy']),
    // 加车结果后埋点
    reportAnalysisData(analysisConfig) {
      const { result, ...argumentsParams } = analysisConfig
      const { info, cart, msg, code } = result
      let findData = {}
      if(code == 0){
        findData = info.carts.find(f => f.id == cart.id)
      }
      const params = {
        goods_id: findData?.product.goods_id,
        mall_code: findData?.mall_code,
        quickship_tp: findData?.real_quick_ship,
        size: findData?.product.size,
        sku_code: findData?.product.sku_code,
        result_reason: msg,
        result: code == 0 ? 1 : 2,
        ...argumentsParams
      }
      daEventCenterMap['report-analysis-data'](params)
    },
    getAnalysisInfo() {
      const ActivityType = {
        Freeshipping: 'freeshipping',
        Coupon: 'coupon',
        Quickship: 'quickship'
      }
      const info = [ActivityType.Freeshipping, ActivityType.Coupon, ActivityType.Quickship]
      const { carts = [] } = this.checkout?.results?.carts || {}
      const { mall_list = [], actual_shipping_price } = this.checkout?.mall_caculate_info || {}
      const quickshipNum = carts.filter(cart => +cart.real_quick_ship).length
      let freeShipping = +actual_shipping_price <= 0 ? 1 : 0
      // 如果不是整车免运费，则需要分 mall 进行判断
      if (freeShipping === 0) {
        const transportType = this.defaultShipping[0]?.shipping_method?.transport_type
        const freeShippingNum = mall_list.filter(mall => (
          Array.isArray(mall?.shipping_price_all) && mall.shipping_price_all.some(item => item.transport_type == transportType && item.price.amount <= 0 ) 
        )).length
        // 0: 整车运费不为0；1: 整车运费为0；2: 部分 mall 运费为0
        if (freeShippingNum.length === 0) {
          freeShipping = 0
        } else if (freeShippingNum === mall_list.length) {
          freeShipping = 1
        } else {
          freeShipping = 2
        }
      }

      const map = {
        [ActivityType.Freeshipping]: freeShipping,
        [ActivityType.Coupon]: this.usableCouponList.length,
        // 0: 都不是 qs 商品；1: 都是 qs 商品；2: 部分商品为 qs 商品
        [ActivityType.Quickship]: quickshipNum ? (quickshipNum === carts.length ? 1 : 2) : 0
      }
      return info.map(type => `${type}\`${map[type]}`).join(',')
    },
    // 加车成功后埋点
    reportSuccessData() {
      const afterInfo = this.getAnalysisInfo()
      daEventCenterMap['report-success-data']({ beforeInfo: this.beforeInfo, afterInfo })
      this.beforeInfo = afterInfo
    },
    // 推荐数据
    doRecommend(fn) {
      this.changeTogetherBuy({
        recommendProducts: []
      })
      this.$refs?.['hotSaleRecoomendRef'].initRecommend()
      this.$refs?.['hotSaleRecoomendRef']?.doRecommend?.(fn)
    },
    async handleRequestAdd(data, el, analysisConfig){
      const messageError = (message) => {
        this.$message({
          message: message,
          type: 'error'
        })
      }
      try {
        const res = await schttp({
          method: 'POST',
          url: '/api/cart/add_mall/create',
          data
        })
        // 直接加车和购物车快捷加车返回不一样，需要注意
        Object.assign(analysisConfig, { result: { ...res, cart: res.info.cart, info: res.info.info } })
        this.reportAnalysisData(analysisConfig)
        if (res.code == 0) {
          // 存储加车信息
          this.changeTogetherBuy({
            quickViewCartData: { cart: res.info.cart, info: res.info.info }
          })
          this.addQuickCall({ goods_id: data.goods_id, el })
        } else if (res?.code == -2) {
          messageError(this.language.SHEIN_KEY_PC_28078)
        } else {
          messageError(this.language.SHEIN_KEY_PC_28079)
        }
      } catch (err) {
        messageError('err')
      }
    },

    addQuickCall({ goods_id, el }){
      this.$emit('isActiveEmit', {
        el: el.$parent.$el,
        goods_id
      })
      this.$message({
        message: this.language.SHEIN_KEY_PC_28077,
        type: 'success'
      })
      this.reportSuccessData()
    },

    recommendListReady(value){
      // 获取刷推荐接口数据回调
      const { list, recommendInfo } = value
      let listStr = list.map(r => r.goods_id).join(',')
      const togetherModules = recommendInfo?.responseInfo?.info || {}
      const {  promotionInfoFromServer, togetherBuyType, isRegulations, isPaid } = togetherModules
      if(this.togetherBuy?.exposedGoodsId){
        listStr = listStr + ',' + this.togetherBuy.exposedGoodsId
      }
      this.changeTogetherBuy({
        exposedGoodsId: listStr,
        recommendProducts: list,
        promotionInfoFromServer, togetherBuyType, isRegulations, isPaid
      })
    }
  }
}
</script>
<style lang="less" scoped>
.multiple-container{
  margin-top: 10px;
  margin-bottom: -15px;
  min-height: 261px;
}
  .cart-hot-sale {
    &__top {
      display: flex;
      align-items: center;
      padding: 50px 0 20px;
      .img {
        width: 28px;
        height: auto;
      }
      .txt {
        margin-left: 4px;
        font-size: 24px;
        font-family: "Arial Black" /* rw: Adieu */;
      }
    }
  }
</style>

<template>
  <div>
    <s-alert
      v-if="shippingDelayHtml"
      v-cloak
      class="checkout-top-notice"
      type="info"
      :closeable="true"
      :show-icon="true"
      :rtl-icon-roate="true"
      :max-rows="3"
      :load-more-txt="language.SHEIN_KEY_PC_15600"
      :description="shippingDelayHtml"
      @close="shippingDelayHtmlNoticeClose"
      @on-load-more-click="onLoadMoreClick"
    />

    <s-dialog
      v-model:visible="isShowNotic"
      type="W720"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="notice-tips-wrapper">
        {{ shippingDelayHtml }}
      </div>
      <template #footer>
        <s-button
          :type="['primary', 'H36PX']"
          @click="noticClosehandle"
        >
          {{ language.SHEIN_KEY_PC_15216 }}
        </s-button>
      </template>
    </s-dialog>
  </div>
</template>

<script>
import { htmlDecode } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

export default {
  name: 'MoreAlertCom',
  props: {
    checkout: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      shippingDelayHtml: '',
      isShowNotic: false,
    }
  },
  created() {
    this.shippingDelayHtml = htmlDecode({
      text: this.checkout.communalNotice || '',
      isHtml: true,
    })
  },
  mounted() {
    if(this.shippingDelayHtml) daEventCenter.triggerNotice({ daId: '1-11-1-36' })
  },
  methods: {
    shippingDelayHtmlNoticeClose() {
      daEventCenter.triggerNotice({ daId: '1-11-1-37' })
    },
    onLoadMoreClick() {
      daEventCenter.triggerNotice({ daId: '1-11-1-38' })
      this.isShowNotic = true
    },
    noticClosehandle() {
      daEventCenter.triggerNotice({ daId: '1-11-1-39' })
      this.isShowNotic = false
    },
  },
}
</script>

<style lang="less" scoped>
.place-order__effiency {
  .checkout-top-notice {
    margin-bottom: 20px;
    max-height: 72px;
    padding: 12px 16px 12px 24px;
    overflow: hidden;
    /deep/ .sui-alert__description {
      max-height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
}
.notice-tips-wrapper {
	width: 624px;
	max-height: 430px;
	overflow: auto;
	background-color: #FAFAFA;
	padding: 24px 5px 24px 5px;
	line-height: 24px;
	font-size: 14px;
}
</style>


export const PRE_PAY_INJECTION_KEY = Symbol('prePayInjectionKey')
export const PRE_PAY_EVENT_BUS_KEY = Symbol('prePayEventBusKey')

// 卡种枚举
export const CARD_TYPE_ENUM = {
  AMEX: 'amex'
}

// export const VIRTUAL_TOKEN_ID = '00_9969900'

export const events = new Map()

export const FORMAT_TAX_NUMBER = [
  'ebanx-clcardinstallment'
]

export function getSupportAutoRenewalPayLogoList({
  limitedPayTypeList,
  paymentMethods,
  paymentMethodsProps,
  banksConfig
}) {
  // 获取支付方式logo
  const getPaymentLogoUrl = item => {
    // 1.如果是cod支付方式，直接返回logo
    if (item.code?.toLowerCase() == 'cod') {
      return item.logo_url
    }
    // 2.如果支付方式enabled不为0，返回银行配置的logo或者支付方式logo
    if (item.enabled != 0) {
      return banksConfig?.[item.code]?.bankLogo || item.logo_url
    }
    // 3.其他情况返回支付方式logo
    return item.logo_url
  }
  
  const paymentDisplay = code => {
    if (
      paymentMethodsProps.paymentDisplayAgain &&
        typeof paymentMethodsProps.paymentDisplayAgain == 'function'
    ) {
      return paymentMethodsProps.paymentDisplayAgain(code)
    } else {
      return true
    }
  }
  
  // 过滤掉不可用的支付方式
  paymentMethods = paymentMethods?.filter(item => {
    if (item.code === 'PayPal-GApaypal') {
      const express = paymentMethodsProps?.paypalGaVault?.express
      const valid = paymentMethodsProps?.paypalGaVault?.valid
      const isShow = express && valid
      const otherShow =
          paymentMethodsProps?.paypalGaVault?.valid &&
          item.enabled == 1 &&
          paymentDisplay(item.code) &&
          paymentMethodsProps?.needPayInlinePaymentMethods?.indexOf(item.code) >
            -1 &&
          paymentMethodsProps?.paypalGaVault?.visibleArrow
  
      return item.enabled != 0 && (isShow || otherShow)
    } else if (['afterpay-card', 'afterpay-cashapp', 'dlocal-nupay'].includes(item.code)) {
      // 不支持自动续费
      return false
    } else {
      return item.enabled != 0
    }
  })
  
  // 过滤自动续费限制支付方式
  // （1）支付方式code数组
  const tempList = limitedPayTypeList?.map(payType => {
    return payType.code
  })
  // （2）筛选出符合条件的支付方式
  let logoListTemp = paymentMethods?.filter(item => {
    if (tempList.includes(item.code)) {
      return true
    } else {
      return false
    }
  })
  
  // （3）取出支付方式logo
  logoListTemp = logoListTemp?.map(item => {
    return getPaymentLogoUrl(item)
  })
  
  return logoListTemp || []
}
  

<template>
  <BaseBinDiscountFreezeFailDialog
    v-model:visible="isShow"
    :language="language"
    @close="close"
    @click-continue="createOrder"
    @click-cancel="close"
  />
</template>

<script setup>
import BaseBinDiscountFreezeFailDialog from '@/public/src/pages/components/prePay/components/BaseBinDiscountFreezeFailDialog.vue'
import { ref, watch, computed } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
  language: {
    type: Object,
    default: () => {}
  }
})

const store = useStore()
const createOrderBinDialogShow = computed(() => {
  return store?.state?.createOrderBinDialogShow
})

const isUsePrePayForCheckout = computed(() => {
  return store?.getters?.isUsePrePayForCheckout
})

const isShow = ref(false)

const emits = defineEmits(['create-order'])

watch(
  () => createOrderBinDialogShow.value,
  () => {
    if (createOrderBinDialogShow.value) {
      isShow.value = true
    }
  },
  {
    immediate: true,
    deep: true
  }
)

const close = () => {
  isShow.value = false
  store.commit('assignState', {
    createOrderBinDialogShow: false
  })
}

// 注意！！！此处不要有任何return事件，统一收口至最尾节点处理！！！下单按钮点击事件需要上报埋点及监控！！！
const createOrder = () => {
  store.commit('assignState', {
    isNoNeedCardBin: true
  })
  let opt = {}
  if (isUsePrePayForCheckout.value) {
    opt.skipPrePayReady = true
  }
  emits('create-order', opt)
  close()
}
</script>
<!-- 
<style lang="less" scoped>
.bin-discount-dialog {
  .title {
    color: black;
    font-size: 16px;
    font-family: SF UI Text;
    font-weight: 700;
    word-wrap: break-word;
    margin-bottom: 8px;
    text-align: center;
  }
  .bin-discount {
    color: #666666;
    font-size: 16px;
    font-family: Arial;
    font-weight: 400;
    line-height: 17px;
    word-wrap: break-word;
    text-align: center;
  }

  .create-order-btn {
    margin-bottom: 20px;
  }
}
</style> -->

// 活动类型
const ActivityType = {
  // 折扣活动价
  Discount: 'discount',
  // 买一赠一
  Gift: 'gift'
}

// 样式类型
const StyleType = {
  // 新样式
  New: 'New',
  Old: 'Old',
  // 续费样式
  NewAutoRenewal: 'NewAutoRenewal',
  OldAutoRenewal: 'OldAutoRenewal'
}

const newStyleConfig = {
  // 产品包名文字颜色
  '--nameColor': '#000000',
  // 产品包名背景颜色
  '--nameBgColor': 'linear-gradient(to right, #FFEFC9 0.01%, #E9C997 99.84%)',
  // 产品包名背景前缀颜色
  '--nameTriangleBgColor': '#EACA98',
  // 已省金额提示颜色
  '--savedTextColor': '#000000',
  // 已省金额提示背景颜色
  '--savedBgColor': 'linear-gradient(270deg, #FFE1B3 2.92%, rgba(255, 225, 179, 0.00) 99.9%)',
  // 权益颜色（包含icon、文字）
  '--benefitColor': '#FFFFFF',
  // 活动腰带文字颜色
  '--activityColor': '#D43E2A',
  // 活动腰带文字阴影
  '--activityTextShadow': 'none',
  // 活动腰带倒计时颜色
  '--activityTimerColor': '#D43E2A',
  // 活动腰带背景颜色
  '--activityBgColor': 'linear-gradient(180deg, #FFEFC9 0%, #E9C997 122.92%)'
}

const oldStyleConfig = {
  '--nameColor': 'rgba(135, 60, 0, .7)',
  '--nameBgColor': 'linear-gradient(to right, #FFE2D3 0.46%, #F5B08D 99.54%)',
  '--nameTriangleBgColor': '#F5B08D',
  '--savedTextColor': '#873C00',
  '--savedBgColor': 'linear-gradient(270deg, #FFDBC9 2.92%, rgba(255, 219, 201, 0.00) 99.9%)',
  '--benefitColor': '#873C00',
  '--activityColor': '#FFE9DD',
  '--activityTextShadow': '0 1px 1px rgba(70, 0, 23, 0.4)',
  '--activityTimerColor': '#C96E3F',
  '--activityBgColor': 'linear-gradient(90.8deg, #E68A5C 28.67%, #E69F7C 50.13%, #E6895C 82.96%)'
}

const newAutoRenewalConfig = {
  ...newStyleConfig,
  '--nameColor': '#FFFFFF',
  '--nameBgColor': 'linear-gradient(to right, #FF8A5F 5.49%, #FB4E1D 98.64%)',
  '--nameTriangleBgColor': '#FB4E1D'
}

const oldAutoRenewalConfig = {
  ...oldStyleConfig,
  '--nameColor': '#F17E53',
  '--nameSuffixColor': '#FF5656',
  '--nameBgColor': '#FFC7C3',
  '--nameTriangleBgColor': '#FFC7C3',
  '--activityBgColor': 'linear-gradient(180deg, #FF652B 0%, #FFA786 100%)'
}

const styleConfig = {
  [StyleType.New]: newStyleConfig,
  [StyleType.Old]: oldStyleConfig,
  [StyleType.NewAutoRenewal]: newAutoRenewalConfig,
  [StyleType.OldAutoRenewal]: oldAutoRenewalConfig,
}

export {
  ActivityType,
  StyleType,
  styleConfig
}

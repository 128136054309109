const deepCopy = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  let copy = Array.isArray(obj) ? [] : {}
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      copy[key] = deepCopy(obj[key])
    }
  }
  return copy
}

const scenceBuildStatus = (xtraAllproduct = []) => {
  const sceneResultAll = deepCopy(xtraAllproduct)
  let resultObjct = {
    'default': {
      isVisible: false,
      iconDoubt: false,
      isTips: false,
      isLeg: false
    }
  }
  if(!sceneResultAll?.length) return resultObjct
  sceneResultAll.forEach(m => {
    const result = {
      isVisible: [10, 12, 13, 15].includes(m._xtraSceneDiscount) ? true : false, // 点击是否出优惠弹窗
      iconDoubt: [13, 15].includes(m._xtraSceneDiscount) ? true : false, // 优惠弹窗里面是否展示法务弹窗的问号
      isTips: m.product_activity_type == 1 ? [7, 8, 11, 14].includes(m._xtraSceneDiscount) : [8, 11, 14].includes(m._xtraSceneDiscount), // 跳转到券中心（如果是自动续费产品包的时候，需要增加场景7）
      isLeg: [5, 6].includes(m._xtraSceneDiscount) ? true : false, //点击是否出现法务弹窗
      productInfo: m, // 包体信息
      _xtraSceneDiscount: m._xtraSceneDiscount, // 当前是在哪个场景
    }
    resultObjct[m?.product_code] = result
  })
  return resultObjct
}

export {
  scenceBuildStatus,
  deepCopy,
}

<template>
  <div class="xtra_product-container">
    <div class="xtra_autoItemContainer">
      <div class="xtra_auto-item">
        <p>
          {{ language.SHEIN_KEY_PC_31218 }}
        </p>
        <p class="f-size700">
          {{ currentProduct.product_price_info.arrival_price_with_symbol }}
        </p>
        <del
          v-if="currentPriceDiscount && isSuggested"
          class="del"
        >
          {{ currentProduct.product_price_info.price_local_with_symbol }}
        </del>
      </div>
      <p class="text">
        {{ language.SHEIN_KEY_PC_31087 }}
      </p>
    </div>
  
    <Icon
      name="sui_icon_nav_back_24px"
      size="24px"
      color="#C96E3F"
      class="icon-derection"
    />
  
    <div class="xtra_autoItemContainer">
      <div class="xtra_auto-item next_active">
        <p>
          {{ language.SHEIN_KEY_PC_31219 }}
        </p>
        <p
          class="f-size700"
          :style="[autoType == 'common' && {color:'#F82854'}]"
        >
          {{ currentProductCycleDays.product_price_info.arrival_price_with_symbol }}
        </p>
        <del
          v-if="currentDaysPriceDiscount && isSuggested"
          class="del"
          style="color: #959595"
        >
          {{ currentProductCycleDays.product_price_info.price_local_with_symbol }}
        </del>
        <img
          v-if="autoType != 'common'"
          class="xtra_select-icon"
          src="https://img.ltwebstatic.com/images3_ccc/2024/06/20/f7/171888375563ab29e1cce0046eae3c8127100c13e1.png"
        />
      </div>
      <p
        class="text"
        style="color: #240B44"
      >
        {{ language.SHEIN_KEY_PC_31088 }}
      </p>
    </div>
  </div>
</template>
<script setup>
import { defineProps, inject } from 'vue'
const { language } = inject('autoDwFatherProp') // 使用 inject 接收父组件提供的数据
import { Icon } from '@shein-aidc/icon-vue3'

defineProps({
  currentProduct: {
    type: Object,
    default: () => ({})
  },
  currentProductCycleDays: {
    type: Object,
    default: () => ({})
  },
  currentPriceDiscount: {
    type: Boolean,
    default: false
  },
  currentDaysPriceDiscount: {
    type: Boolean,
    default: false
  },
  autoType: {
    type: String,
    default: 'prime'
  },
})

</script>
<style lang="less" scoped>
.xtra_product-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .xtra_autoItemContainer{
      display: flex;
      flex-direction: column;
      color: var(---sui_color_gray_dark3, #767676);
      font-family: "SF Pro";
      font-style: normal;
      font-size: 14px;
      line-height: 1.3;
      p{
        text-align: center;
      }
      .text{
        margin-top: 10px;
      }
    }
    .xtra_auto-item{
      background: rgba(255, 246, 248, 0.88);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 3px;
      position: relative;
      width: 292px;
      height: 95px;
      .xtra_select-icon{
        position: absolute;
        width: 24px;
        height: 18px;
        bottom: -2px;
        right: -2px;
      }
    }
    .derection{
      margin: 0 8px;
    }
    .next_active{
      color: #240B44;
      border: 2px solid #F82854;
    }
}
.icon-derection{
    margin: 0 8px;
}
.f-size700{
    font-size: 22px;
    font-weight: 700;
}
</style>

<template>
  <li 
    v-show="morePosition == 0 || ((index < +morePosition) || isClickMore) || changeShopType"
    v-enterkey
    tabindex="0"
    :data-available="item.is_available"
    :type="item.type"
    :aria-checked="userChecked"
    role="radio"
    class="shipping-item__wrap"
    :class="{ 'is-disabled': isDisabled }"
  >
    <div 
      v-expose="{
        id: '1-11-1-54',
        data: shippingItemAnalysis
      }" 
      v-tap="{
        id: '1-11-1-103',
        data: shippingItemAnalysis
      }" 
      :class="['shipping-item', {'check-available': isDisabled, 'shipping-selected': userChecked}]"
      @click="clickShipping"
    >
      <div class="option-r">
        <s-radio
          :model-value="userChecked"
          :label="true"
          :disabled="isDisabled"
          :theme="'icon'"
          :size="'radio20'"
          gap="0"
        />
      </div>
      <div class="option-des">
        <p>
          <span 
            class="name" 
            v-html="shippingItemTitle"
          >
          </span>

          <s-label
            v-if="isPrimeShip"
            type="promo"
            class="prime-label shipping-item-label"
          >
            <sui_icon_club_logo_14px 
              size="14px" 
              color="#873c00"
            />
            <span>{{ +handlerShippingPrice.shipPrice === 0 ? language.SHEIN_KEY_PC_15911 : language.SHEIN_KEY_PC_24013 }}</span>
          </s-label>
          <s-label
            v-else-if="+handlerShippingPrice.shipPrice === 0"
            class="shipping-item-label"
            :class="{'free-shipping-enhance': shippingTipEnhanceByAbt}"
            type="success"
          >
            {{ language.SHEIN_KEY_PC_15911 }}
          </s-label>

          <!-- 增加环保标签 -->
          <span
            v-if="isEnvironMental.isTransport_type"
            :class="['environmental-label', {'activeM-l': isEnvironMental.noOther}]"
          >
            <sui_icon_environmental_16px 
              size="16px" 
              color="#008C00"
            />
            <span class="environmental-text">{{ language.SHEIN_KEY_PC_25668 }}</span>
            <s-popover
              :content="language.SHEIN_KEY_PC_25667"
              placemen="bottom"
              trigger="hover"
              :fix-disappear-position="true"
              :prop-style="{
                width: '260px'
              }"
              @click.stop
            >
              <template #reference>
                <sui_icon_doubt_16px_1 
                  size="15px" 
                  color="#959595"
                />
              </template>
            </s-popover>
          </span> 
        </p>

        <p
          v-show="item.is_available == 0"
          class="tips-red"
        >
          <template v-if="item.is_available_msg == 1">
            ({{ language.SHEIN_KEY_PC_14795 }})
          </template>
          <template v-if="item.is_available_msg == 2">
            ({{ language.SHEIN_KEY_PC_16463 }})
          </template>
          <template v-if="item.is_available_msg == 3">
            {{ language.SHEIN_KEY_PC_16312 }}
          </template>
          <template v-if="item.is_available_msg == 4">
            {{ language.SHEIN_KEY_PC_16464 }}
          </template>
          <template v-if="item.is_available_msg == 8">
            {{ language.SHEIN_KEY_PC_25662 }}
          </template>
          <template v-if="isShowOrderInterceptMsg">
            ({{ item.rule_info.msg }})
          </template>
          <template v-if="item.is_available_msg == 14">
            ({{ language.SHEIN_KEY_PC_31831 }})
          </template>
        </p>

        <!-- shipping options -->
        <div class="ship-type-list">
          <span
            v-if="handlerShippingPrice.shipPriceWithSymbol"
            class="price"
          >
            <template v-if="isPrimeShip">
              <span class="price-free price-prime">
                <span>{{ handlerShippingPrice.shipPriceWithSymbol }}</span>
              </span>
              <span
                v-if="!isSuggested && isFreeShipLine"
                class="price-line"
              >{{ selectedShippingPrice.amountWithSymbol }}</span>
            </template>
            <template v-else-if="isFreeShip">
              <span class="price-free">
                <span>{{ handlerShippingPrice.shipPriceWithSymbol }}</span>
              </span>
              <span
                v-if="!isSuggested && isFreeShipLine"
                class="price-line"
              >{{ selectedShippingPrice.amountWithSymbol }}</span>
            </template>
            <template v-else-if="freightActivityType">
              <span class="price-flash">
                <sui_icon_flashsale_16px 
                  size="16px" 
                  color="#facf19"
                />
                <span>{{ handlerShippingPrice.shipPriceWithSymbol }}</span>
              </span>
              <span
                v-if="!isSuggested && isPriceLine"
                class="price-line"
              >{{ handlerShippingPrice.policyPriceWithSymbol }}</span>
            </template>
            <template v-else>
              <span class="price-normal">{{ handlerShippingPrice.shipPriceWithSymbol }}</span>
            </template>
          </span>

          <template v-if="descQuickShipTime || largeShipTime|| shippingDescribe(item)">
            <template v-if="isAllQuickShip">
              <span class="price-des">
                (<span
                  class=""
                  v-html="descQuickShipTime"
                ></span>)
              </span>
              <sui_icon_doubt_14px_1 
                size="15px" 
                color="#bbbbbb"
                @click.stop="showQuickPop"
              />
            </template>
            <template v-else>
              <span class="price-des">
                (<span
                  class=""
                  v-html="largeShipTime || shippingDescribe(item)"
                ></span>)
              </span>
              <s-popover
                v-if="isShowDayPercent"
                placemen="bottom"
                trigger="hover"
                :fix-disappear-position="true"
                @opened="handlePercentOpen"
                @click.stop
              >
                <ShippingDayPercent
                  :item="item"
                  :language="language"
                />
                <template #reference>
                  <sui_icon_doubt_14px_1 
                    size="15px" 
                    color="#bbbbbb"
                  />
                </template>
              </s-popover>
            </template>
          </template>
        </div>

        <div 
          v-if="isShowShippingFee"
          v-expose="{
            id: '1-11-1-153',
            data: {
              mall_code: shippingItemAnalysis.mall_code,
              shipping_method: shippingItemAnalysis.shipping_method
            }
          }"
          v-tap="{
            id: '1-11-1-154',
            data: {
              mall_code: shippingItemAnalysis.mall_code,
              shipping_method: shippingItemAnalysis.shipping_method
            }
          }"
          class="shipping-fee-info"
          @click.stop="clickShippingFeeDrawer"
          v-html="language.SHEIN_KEY_PC_26689"
        >
        </div>

        <ClientOnly>
          <!-- 凑单组件 -->
          <AddOnItem 
            v-if="showAddEntrance"
            :add-item-content-info="addItemContentInfo"
            :mall-code="mallCode"
            @add-success="addSuccess"
          />
        </ClientOnly>

        <!-- 大件海陆运提示 -->
        <LargeShipTips 
          v-if="showBigSizeEnter"
          :lagre-ship-info="lagreShipInfo"
        />
      </div>
    </div>
  </li>
</template>

<script>
import checkoutMixin from 'public/src/pages/checkout/checkoutMixin.js'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import { TRANSPORT_ENVIRONMENTAL, TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'
import { handleShipFeeGoods, isShippingMethodDisabled, handleLargeShipInfo } from 'public/src/pages/checkout/config/tools.js'

import ShippingDayPercent from 'public/src/pages/components/ShippingDayPercent.vue'
import AddOnItem from './AddItem.vue'
import LargeShipTips from './large_ship_info/LargeShipTips.vue'
import { sui_icon_club_logo_14px, sui_icon_environmental_16px, sui_icon_doubt_16px_1, sui_icon_flashsale_16px, sui_icon_doubt_14px_1 } from '@shein-aidc/icon-vue3'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

export default {
  name: 'ShippingOptionsItem',
  directives: { tap, expose },
  components: {
    ShippingDayPercent,
    AddOnItem,
    LargeShipTips,
    sui_icon_club_logo_14px,
    sui_icon_environmental_16px,
    sui_icon_doubt_16px_1,
    sui_icon_flashsale_16px,
    sui_icon_doubt_14px_1,
  },
  mixins: [checkoutMixin],
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    },
    defaultShippingItem: {
      type: Object,
      default: () => {}
    },
    isClickMore: {
      type: Boolean,
      default: false
    },
    isAllQuickShip: {
      type: Boolean,
      default: false
    },
    descQuickShipTime: {
      type: String,
      default: ''
    },
    mallCode: {
      type: [String, Number],
      default: ''
    },
    primeCouponCount: {
      type: Number,
      default: 0
    },
    isQuickShipTitle: {
      type: Boolean,
      default: false
    },
    morePosition: {
      type: Number,
      default: 0
    },
    quickShipInfo: {
      type: Object,
      default: () => {}
    },
    addItemContentInfo: {
      type: Object,
      default: () => {}
    },
    defaultSelectedIndex: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      addOnDialogVisible: false,
    }
  },
  computed: {
    ...mapState([
      'language',
      'checkout',
      'changeShopType', 
      'selectStoreAddressShippingInfo',
      'shippingAddStatusInfo',
      'forceUpdateShippingMethod',
      'locals',
      'mallCartsList',
    ]),
    ...mapGetters([
      'orderInterceptByAbt',
      'timePercentAbt',
      'shipTimeDesList',
      'shippingTipEnhanceByAbt',
      'showAddEntranceByAbt',
      'newAddressShipByAbt',
      'isSuggested',
      'shippingSlideByAbt',
      'isSiteMallList',
      'largeShipExtendsInfo',
    ]),
    cartsData() {
      return this.mallCartsList.find(mall => mall?.[0]?.mall_code == this.mallCode) || []
    },
    lagreShipInfo() {
      const { 
        largeShipTimeDesc = '', 
        largeShipTitle = '', 
        largeShipGoods = [], 
        largeShipType = '', 
        largeShipIcon = '',
      } = handleLargeShipInfo({ 
        isSiteMallList: this.isSiteMallList, 
        largeShipExtendsInfo: this.largeShipExtendsInfo, 
        mallCode: this.mallCode, 
        carts: this.cartsData
      })
      return {
        largeShipTimeDesc,
        largeShipTitle,
        largeShipGoods,
        largeShipType,
        largeShipIcon,
      }
    },
    isLargeShipTitle() {
      const isAllLarge = this.cartsData?.length == this.lagreShipInfo?.largeShipGoods?.length
      return !!this.lagreShipInfo.largeShipTimeDesc && isAllLarge
    },
    largeShipTime() {
      return !!this.isLargeShipTitle ? this.lagreShipInfo.largeShipTimeDesc : ''
    },
    showBigSizeEnter() {
      // 只展示当前选中的
      if(!this.checked) return false
      // 全部为大件商品时不展示入口
      return !!this.lagreShipInfo.largeShipTimeDesc && !!this.lagreShipInfo.largeShipGoods?.length && !this.isLargeShipTitle
    },
    // qs优先级高于大件
    shippingItemTitle() {
      let title = this.item.title
      if(this.isQuickShipTitle) { // qs时效标题
        title = this.language.SHEIN_KEY_PC_23485
      } else if(this.isLargeShipTitle) { // 大件海陆运时效标题
        title = this.lagreShipInfo.largeShipTitle
      }
      return title
    },
    IS_RW() {
      return this.locals.IS_RW
    },
    SERVER_TYPE() {
      return this.locals.SERVER_TYPE
    },
    caculateInfo() {
      return this.checkout?.mall_caculate_info || {}
    },
    showAddEntrance() {
      if(!this.showAddEntranceByAbt) return false
      
      // 凑单组件打开时不能隐藏凑单入口
      if(this.shippingAddStatusInfo?.transportType == this.item?.transport_type) return true
      // 已经完成一次凑单流程后不再露出凑单入口
      if(!this.shippingAddStatusInfo?.getStatus || this.shippingAddStatusInfo?.addStatus) return false
      return this.addItemContentInfo?.transportType == this.item?.transport_type
    },
    checked() {
      return this.index == this.defaultShippingItem.index
    },
    userChecked() { // 用户选中展示勾选
      // 选择店配运输方式时不存在店配地址 -> 新增店配地址后选中的逻辑，是当前mall且运输方式相同
      const { mallCode, shippingMethod } = this.selectStoreAddressShippingInfo || {}
      // 完成凑单关闭弹窗时触发的下单限制不展示限制弹窗，且运输方式展示勾选态
      const { mallCode: mall_code, shippingMethod: shipping_method } = this.forceUpdateShippingMethod || {}
      if (+this.mallCode === +mallCode && shippingMethod?.transport_type === this.item?.transport_type) return this.checked
      if (+this.mallCode === +mall_code && shipping_method?.transport_type === this.item?.transport_type) return this.checked

      if(this.item.is_available == 1 || !this.orderInterceptByAbt) return this.checked

      return false
    },
    isDisabled() {
      return isShippingMethodDisabled({ item: this.item, orderInterceptByAbt: this.orderInterceptByAbt })
    },
    isShowOrderInterceptMsg() {
      return this.orderInterceptByAbt && this.item.is_available_msg == 9 && +this.item.rule_info?.hint_type == 0
    },
    shippingFeeRuleList() {
      const originList = JSON.parse(JSON.stringify(this.shippingPriceItem.shipping_free_rule_info_list || []))
      return originList.sort((a, b) => +a.freight_activity_type - +b.freight_activity_type)
    },
    isShowShippingFee() {
      if(this.IS_RW || !this.shippingFeeRuleList.length) return false

      const { mall_list = [] } = this.checkout?.mall_caculate_info || {}
      let rule_id_list = []
      mall_list.forEach(mall => { 
        mall.shipping_price_all?.forEach(ship => {
          ship.shipping_free_rule_info_list?.forEach(item => {
            rule_id_list.push(+item.freight_free_rule_id)
          })
        })
      })
      rule_id_list = [...new Set(rule_id_list)]
      return rule_id_list.length > 1
    },
    shippingItemAnalysis() {
      if(typeof window == 'undefined') return {}
      
      const filterMall = this.shipTimeDesList?.filter(item => item.mall_code == this.mallCode) || []
      const shipping_time_desc = filterMall?.[0]?.shipping_methods?.filter(method => method.transport_type == this.item.transport_type)?.[0]?.shipping_time_desc || '-'
      const interceptList = [0, 1, 2, 3, 7]
      const scenes = this.item.is_available == 0 && this.item.is_available_msg == 9 && interceptList.includes(+this.item.rule_info?.hint_type) ? 'interceptionRules' : '-'
      const horizontalLayout = this.shippingSlideByAbt
      const isUseCacheShipping = this.checkout?.cacheInfo?.mall_transport_type?.used
      const currentIndexIsDefault = this.defaultSelectedIndex == this.index

      // 初始化是不折叠的
      let isFolded = 0
      let shippingLayoutStyle = 'list'

      if (!this.newAddressShipByAbt && !horizontalLayout && this.index > 0 ) {
        isFolded = 1
      }

      if (horizontalLayout) {
        shippingLayoutStyle = 'card'
      }

      return { 
        is_default: currentIndexIsDefault ? '1' : '0',
        shipping_method: this.item.transport_type, 
        shipping_time: this.isAllQuickShip ? this.quickShipInfo.quick_ship_time : this.item.shipping_time || '-', 
        shipping_fee: this.handlerShippingPrice?.shipUsdPrice, 
        mall_code: this.mallCode, 
        shipping_time_desc, 
        scenes,
        is_folded: isFolded,
        sequence: this.index + 1,
        style: shippingLayoutStyle,
        default_type: currentIndexIsDefault ? (isUseCacheShipping ? 2 : 1) : 0,
      }
    },
    isShowDayPercent() {
      return this.timePercentAbt && !!this.item?.day_percents?.length
    },
    selectedShippingPrice() {
      const { mall_list = [] } = this.checkout?.mall_caculate_info || {}
      const filterCalcu = mall_list.filter(mall => mall.mall_code == this.mallCode)
      return filterCalcu[0]?.shipping_price || {}
    },
    isPrimeShip() {
      const { index, defaultShippingItem, primeCouponCount } = this
      if (index != defaultShippingItem.index) return false
      if (+primeCouponCount > 0) return true
      return false
    },
    isFreeShip() {
      const { index, defaultShippingItem, handlerShippingPrice } = this
      if (index != defaultShippingItem?.index) return false
      const { shipPrice } = handlerShippingPrice
      return +shipPrice === 0
    },
    freightActivityType() {
      const { policyPrice } = this.handlerShippingPrice
      // 当前用policy_price 不为0判断是否闪购，待后期优化调整
      return +policyPrice > 0
    },
    isFreeShipLine() { // 活动用券
      const { shipPrice } = this.handlerShippingPrice
      const originShipPirce = this.selectedShippingPrice?.amount
      if (+shipPrice < +originShipPirce) return true
      return false
    },
    isPriceLine() { // 闪购
      const { policyPrice, shipPrice } = this.handlerShippingPrice
      if (+policyPrice <= 0) return false
      if (+policyPrice > +shipPrice) {
        this.assignState({ showMarkedPrice: 1 })
        return true
      }
      return false
    },
    shippingPriceItem() {
      const item = this.item
      const { mall_list = [] } = this.checkout?.mall_caculate_info || {}
      const filterCalcu = mall_list.filter(mall => mall.mall_code == this.mallCode)
      const shipping_price_all = filterCalcu[0]?.shipping_price_all || []
      const shipping_price = shipping_price_all.filter(price => price.transport_type == item.transport_type) || []

      return shipping_price[0] || {}
    },
    handlerShippingPrice() {
      return { 
        shipPriceWithSymbol: this.shippingPriceItem.price?.amountWithSymbol || '', 
        shipPrice: this.shippingPriceItem.price?.amount || '',
        shipUsdPrice: this.shippingPriceItem.price?.usdAmount || '',
        policyPriceWithSymbol: this.shippingPriceItem.policy_price?.amountWithSymbol || '',
        policyPrice: this.shippingPriceItem.policy_price?.amount || ''
      }
    },
    // 判断是否展示环保图标
    isEnvironMental(){
      const isTransport_type = this.SERVER_TYPE !== 'eur' && TRANSPORT_ENVIRONMENTAL.includes(this.item.transport_type)

      return { 
        isTransport_type,
        noOther: !(this.handlerShippingPrice.shipPrice == 0) && !this.isPrimeShip
      }
    }
  },
  methods: {
    ...mapMutations(['assignState', 'updateShippingAddStatusInfo']),
    ...mapActions(['updateCart']),
    /**
     * status 当前是否完成凑单（0未完成、1已完成）
     */
    addSuccess({ status = 0 }) {
      this.updateCart({ noUpdataShipping: 1 })
      /**
       * 1.未完成凑单保留当前选中的运输方式（如果当前选中的受限了，不弹受限弹窗）
       * 2.完成凑单切换为凑单的运输方式
       * 2.1宅店配切换场景，不论切换的是否受限都切过去，不弹受限弹窗
       * 2.2未触发宅店配切换，切换的受限则返回当前选中的，切换的不受限帮用户切过去，不弹受限弹窗
       */
      const isPrevStoreAddress = TRANSPORT_TYPE_SHOP.includes(this.defaultShippingItem.shipping_method.transport_type)
      const isStoreAddress = TRANSPORT_TYPE_SHOP.includes(this.item.transport_type)
      const isChangeType = isPrevStoreAddress != isStoreAddress
      this.clickShipping({ addItemUpdate: true, item: status == 0 ?  this.defaultShippingItem?.shipping_method : this.item, skipShipIntercept: true, isInterceptBack: (status == 1 && !isChangeType) })

      if(status == 1) {
        sessionStorage.setItem('checkout_shipping_additem_success', 1)
        this.updateShippingAddStatusInfo({
          addStatus: true
        })
      }
    },
    async clickShippingFeeDrawer() {
      const cartsMallInfo = this.checkout.results?.carts?.mall_list?.find(item => item.mall_code == this.mallCode) || {}
      const limitGoods = await handleShipFeeGoods(this.shippingFeeRuleList || [])
      const ruleInfo = {
        mall_list: [
          {
            mall_code: this.mallCode,
            mall_name: cartsMallInfo.mall_name || '',
            rule_list: limitGoods,
          }
        ],
        total_price: this.shippingPriceItem.price,
        save_price: this.shippingPriceItem.price_diff
      }
      this.assignState({
        showShippingFeeDrawer: true,
        shippingFeeRuleInfo: ruleInfo
      })
    },
    handlePercentOpen() {
      daEventCenter.triggerNotice({
        daId: '1-11-1-102',
        extraData: {
          mall_code: this.mallCode,
          shipping_method: this.item.transport_type
        }
      })
    },
    /**
     * addItemUpdate 是否由凑单触发强制更新运输方式接口
     * item 当前要切换的运输方式（默认当前item，强制更新时可传入其他运输方式）
     * isInterceptBack 即将切换的运输方式受限是否回退到之前选中的
     * skipShipIntercept 触发下单限制前置是否不展示限制弹窗
     */
    clickShipping({ addItemUpdate = false, item = this.item, isInterceptBack = false, skipShipIntercept = false } = {}) {
      // 强制更新时跳过校验
      if(!addItemUpdate) {
        const cannotChecked = item.is_available != 1 && this.orderInterceptByAbt
        if ((!cannotChecked && this.index == this.defaultShippingItem.index) || this.isDisabled) return
      }

      this.$emit('clickShipping', { item, addItemUpdate, isInterceptBack, skipShipIntercept })
    },
    showQuickPop() {
      daEventCenter.triggerNotice({
        daId: '1-11-1-90',
        extraData: {
          quickship_tp: '1'
        }
      })
      this.assignState({
        isShowQuickPop: true
      })
    },
  },
  emits: ['clickShipping'],
}
</script>

<style lang="less" scoped>
.ship-type-list {
  .price-normal {
    font-weight: bold;
  }
  .price-free {
    span {
      color: @sui_color_gray_dark1;
      font-weight: bold;
    }
  }
  .price-flash {
    span {
      color: @sui_color_discount_dark;
    }
  }
  .price-prime {
    span {
      color: @sui_color_club_rosegold_dark1;
    }
  }
  .price-line {
    text-decoration: line-through;
    color: @sui_color_gray_light1;
  }
  .price-des {
    color: @sui_color_gray_dark3;
  }
}
.prime-label {
  color: @sui_color_club_rosegold_dark1;
  span {
    font-weight: 500;
    display: inline-block;
    .margin-l(4px);
  }
}
.shipping-item-label {
  .margin-l(4px);
}
.free-shipping-enhance {
  background: @sui_color_safety;
  color: @sui_color_white;
  border-radius: 2px;
}
.tips-red{
  color: @sui_color_highlight;
}
.environmental-label{
  color: #222222;
  .environmental-text{
    margin: 0 3px;
  }
}
.activeM-l{
  margin-left: 8px;
}

.shipping-fee-info {
  color: @sui_color_link;
  font-size: 13px;
  margin-top: 8px;
}

.shipping-options-right {
  .shipping-item__wrap {
    margin-bottom: 8px;
    .padding-r(16px);
    &:last-child {
      margin-bottom: 0;
    }
  }
  .shipping-item {
    border: 1px solid #e5e5e5;
  }
}

.shipping-item__wrap {
  width:100%;
  .option-des {
    width: 100%;
  }
}

.c-place-order .option-des{
  color: #222;
  line-height: 16px;
  margin-bottom: 16px;
  .name{
    font-weight: bold;
  }
  .ship-type-list {
    line-height: 14px;
  }
}

.shipping-item{
  position: relative;
  padding: 16px 12px;
  border: 1px solid transparent;
  &:hover{
    cursor: pointer;
  }
  &.no-border-b{
    border-bottom: none;
  }
  a {
    text-decoration: underline;
  }
  >div{
    display: table-cell;
    vertical-align: middle;
  }
  .option-r{
    width: 28px;
  }
  .option-des{
    >p{
      /*padding: 3px 0;*/
      .name {
        font-size: 14px;
      }
      .red {
        color: @sui_color_highlight;
        .margin-l(15px);
      }
      .price {
        .margin-r(6px);
      }
    }
  }
  .drop-arrow{
    position: absolute;
    .right(20px);
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    padding: 10px;
  }

  &.shipping-default{
    .name{
      font-weight: bold;
    }
    .drop-arrow{
      position: absolute;
      .right(20px);
      top: 50%;
      transform: translateY(-50%) rotate(-90deg);
      padding: 10px;
    }
  }
  .ship-type-list {
    line-height: 14px;
    margin-top: 8px;
  }
}
</style>
<style lang="less">
@import '../../../checkout/vue_tpls/style/svgicon.less';
</style>

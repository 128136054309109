<template>
  <s-dialog
    v-model:visible="show"
    :append-to-body="true"
    :close-on-click-modal="true"
    type="W720"
    class="pay-list-dialog"
    @close="close"
  >
    <div class="paid-member-pay">
      <PayDrawerHeader
        :language="language"
        :pay-methods-list-drawer-header-data="payMethodsListDrawerHeaderData"
        :auto-type="autoType"
        :is-suggested="isSuggested"
        @close="close"
      />

      <PayMethodsList
        ref="payMethodsListRef"
        :payment-methods-props="paymentMethodsProps"
        :language="language"
        :now-select-pay="nowSelectPay"
        :pay-methods-list-drawer-header-data="payMethodsListDrawerHeaderData"
        :is-auto-renewal="true"
        :auto-type="autoType"
        :is-suggested="isSuggested"
        @change-pay="changePay"
      />
    </div>

    <template #footer>
      <s-button
        class="confirm-btn"
        :width="'100%'"
        :type="['primary']"
        :disabled="isBtnDisable"
        @click="handlerConfirm"
      >
        {{ language.SHEIN_KEY_PC_14474 }}
      </s-button>
    </template>
  </s-dialog>
</template>

<script setup>
// components
import { SDialog } from '@shein-aidc/sui-dialog'
import { SButton } from '@shein-aidc/sui-button'
import PayDrawerHeader from './pay_dialog_header.vue'
import PayMethodsList from './pay_methods_list.vue'

// utils
import { ref, watch, defineExpose, computed } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { useStore } from 'vuex'

const props = defineProps({
  isShowPayMethodsListDrawer: {
    type: Boolean,
    default: false,
  },
  paymentMethods: {
    type: Array,
    default: () => {
      ;[]
    },
  },
  paymentMethodsProps: {
    type: Object,
    default: () => {},
  },
  commonPyaList: {
    type: Array,
    default: () => {
      ;[]
    },
  },
  payMethodsListDrawerHeaderData: {
    type: Object,
    default: () => {},
  },
  language: {
    type: Object,
    default: () => {},
  },
  choosePrime: {
    type: Function,
    default: () => {},
  },
  updatePrimeIndex: {
    type: Function,
    default: () => {},
  },
  autoType: {
    type: String,
    default: 'prime'
  },
  autoPaymentSubmitEmit: {
    type: Function,
    default: () => {}
  }
})

let show = ref(false)
const isBtnDisable = ref(true)
const payMethodsListRef = ref(null)
const isConfirm = ref(false)
const nowSelectPay = ref({})
const isHandlerDa = ref(false)
const store = useStore()

const HIDE_SAVER_STRIKETHROUGH_PRICE = store.state.checkout.HIDE_SAVER_STRIKETHROUGH_PRICE

const isSuggested = computed(() => {
  // abt ！== ’noshow 并且不在合规站点里，展示
  const commonPrime = props.autoType == 'prime' && !store.state.checkout?.checkoutBFFAbtInfo?.ClubScribePrice?.param?.ClubScribePrice == 'noshow'
  // 阿波罗 == ‘off 并且不在合规站点里，展示
  const commonXtra = props.autoType == 'xtra' && HIDE_SAVER_STRIKETHROUGH_PRICE == 'off'
  return (commonXtra || commonPrime) && !store.getters?.isSuggested
})

watch(
  () => show.value,
  val => {
    if (val) {
      isHandlerDa.value = false
      props.paymentMethodsProps.openDelayChangeBanks()
      isConfirm.value = false

      const { paymentMethods = [] } =
        props?.paymentMethodsProps?.checkout.results

      nowSelectPay.value = paymentMethods?.find(
        item => item.code === props.paymentMethodsProps.status?.cardPay
      )

      handlerShowDa()
    }
  }
)

const handlerShow = () => {
  show.value = true
}

const close = () => {
  handlerClickDa(isConfirm.value)
  payMethodsListRef.value?.initData()
  props.paymentMethodsProps.closeDelayChangeBanks()
  show.value = false
  isBtnDisable.value = true
}

const changePay = () => {
  isBtnDisable.value = false
}

const handlerConfirm = () => {
  payMethodsListRef.value?.changePpgvType()
  payMethodsListRef.value?.changeAfterpayType()
  // payMethodsListRef.value?.changeSignupPayType(props.paymentMethodsProps.status?.cardPay)
  payMethodsListRef.value?.changeSelectPayment()
  payMethodsListRef.value?.changeBank()
  isConfirm.value = true
  if(props.autoType == 'xtra'){
    props?.autoPaymentSubmitEmit()
  }else{
    props?.updatePrimeIndex?.()
    props?.choosePrime?.({
      flag: true,
      fromLocation: 3,  //选包模块：location (1-随单购，2-弹窗，3-支付方式，4-下单页挽留弹窗)
    })
  }

  close()
}

const handlerShowDa = () => {
  daEventCenter.triggerNotice({
    daId: '1-11-1-181',
    extraData: {
      renew_type: props.autoType == 'prime' ? '2' : '1'
    }
  })
}

const handlerClickDa = isConfirm => {
  if (isHandlerDa.value) return
  daEventCenter.triggerNotice({
    daId: '1-11-1-182',
    extraData: {
      click_type: isConfirm ? 'confirm' : 'close',
      renew_type: props.autoType == 'prime' ? '2' : '1'
    },
  })
  isHandlerDa.value = true
}

defineExpose({
  handlerShow,
})
</script>

<style lang="less">
.pay-list-dialog {
  .sui-dialog__title-holder {
    padding: unset !important;
  }

  .sui-dialog__body {
    padding: unset !important;
  }

  .sui-dialog__footer {
    padding: 16px 48px !important;
  }

  .payment-item__effiency-right {
    vertical-align: top;
  }
}
</style>

<template>
  <div class="tips-container">
    <div :class="['select-contentPro', `${autoType}-select-bg`]">
      <div
        v-if="paymentInfo && currentProduct"
        :class="['pay-container', `${autoType}-pay-container`]"
      >
        <div class="paymentContainer">
          <img :src="paymentInfo.logo" />
          <p>{{ paymentInfo.title }}</p>
        </div>
        <p class="warn-tips">
          <Icon
            name="sui_icon_caution_18px_1"
            size="18px"
            color="#BF4123"
            class="warn-icon"
          />
          <span class="warnTipsDom">{{ warnTips }}</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { Icon } from '@shein-aidc/icon-vue3'
import { inject, computed } from 'vue'
import { template as templateText } from '@shein/common-function'

const { language } = inject('autoDwFatherProp') // 使用 inject 接收父组件提供的数据

const props = defineProps({
  paymentInfo: {
    type: Object,
    default: () => ({})
  },
  currentProduct: {
    type: Object,
    default: () => ({})
  },
  autoType: {
    type: String,
    default: 'prime'
  }
})
const warnTips = computed(() => {
  const tips = {
    'common': language.SHEIN_KEY_PC_31240,
    'xtra': language.SHEIN_KEY_PC_31082,
    'prime': templateText(props.currentProduct.product_name_language, language.SHEIN_KEY_PC_29644)
  }
  return tips[props.autoType]
})
</script>
<style lang="less" scoped>
.prime-select-bg{
  background: url('/she_dist/images/checkout/primce_rectangle-e1fac4a531.png') no-repeat;
}
.xtra-select-bg{
  background: url('https://img.ltwebstatic.com/images3_ccc/2024/06/18/a2/1718681980f671a2d61825c4b86d832ca61c61333d.png') no-repeat;
  // width: 624px;
  background-size: 624px 72px;
  margin-bottom: 20px;
  // background: url('/she_dist/images/checkout/primce_rectangle-e1fac4a531.png') no-repeat;
}
.common-select-bg{
  // background: url('https://img.ltwebstatic.com/images3_ccc/2024/06/18/a2/1718681980f671a2d61825c4b86d832ca61c61333d.png') no-repeat;
  width: 624px;
  // height: 72px;
  border: 1px solid #C3C3C3;
  background: #fff;
  margin-bottom: 20px;
}
.xtra-pay-container{
  top: 0;
}
.common-pay-container{
  top: 0;
  height: 100%;
}
</style>

import { ref } from 'vue'
const showRiskDialog = ref(false)
export default function useCheckoutDialog() {
  
  const setShowRiskDialog = (value) => {
    showRiskDialog.value = value
  }
  return {
    showRiskDialog,
    setShowRiskDialog
  }
}

<template>
  <div class="checkout-store-address">
    <store-address-new-card 
      v-if="!IS_RW && newAddressShipByAbt"
      :language="language"
      :store-data="storeData"
      :adr-name-verified-title-tip="adrNameVerifiedTitleTip"
      :status="status"
      :checkout="checkout"
      :default-shipping="defaultShipping"
    />
    <store-address-card 
      v-else
      :language="language"
      :store-data="storeData"
      :adr-name-verified-title-tip="adrNameVerifiedTitleTip"
      :status="status"
      :checkout="checkout"
      :default-shipping="defaultShipping"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import StoreAddressCard from '../../../vue_tpls/StoreAddressCard.vue'
import StoreAddressNewCard from '../../../vue_tpls/StoreAddressNewCard.vue'

export default {
  name: 'StoreAddress',
  components: {
    StoreAddressCard,
    StoreAddressNewCard,
  },
  props: {
    adrNameVerifiedTitleTip: {
      type: String,
      default: ''
    },
  },
  computed: {
    ...mapState([
      'checkout',
      'defaultShipping',
      'language',
      'status',
      'storeData',
      'locals',
    ]),
    ...mapGetters([
      'newAddressShipByAbt',
    ]),
    IS_RW() {
      return this.locals.IS_RW
    },
  },
}
</script>

<template>
  <div :class="['auto__dw-containerHer',{ 'headerHeight': !normalShow}, `${autoType}-bg-container`]">
    <img
      v-if="autoType == 'prime'"
      :src="logo"
      class="bg-img"
    />
    <div class="dw-header">
      <div
        v-if="normalShow"
        class="normal"
      >
        {{ autoType == 'prime' ? language.SHEIN_KEY_PC_29647 : autoType == 'common' ? language.SHEIN_KEY_PC_31239 : language.SHEIN_KEY_PC_31086 }}
      </div>
      <div
        v-else
        :class="['one-bank', GB_cssRight && 'right-one']"
      >
        <div class="payBack-container">
          <span
            class="container-color"
            v-html="oneOrderText"
          ></span>
        </div>
        <div class="dw-headerTips">
          <div class="triangle-content">
            {{ templateText(discountPrice && discountPrice.price_with_symbol, language.SHEIN_KEY_PC_29649) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { template as templateText } from '@shein/common-function'
import { inject, ref } from 'vue'
const { PUBLIC_CDN, GB_cssRight } = gbCommonInfo

defineProps({
  oneOrderText: {
    type: String,
    default: () => ''
  },
  normalShow: {
    type: Boolean,
    default: false
  },
  discountPrice: {
    type: Object,
    default: () => ({})
  },
  autoType: {
    type: String,
    default: 'prime'
  }
})
const logo = ref(`${PUBLIC_CDN}/she_dist/images/checkout/auto_dw_logo-87f3402e69.png`)
const { language } = inject('autoDwFatherProp') // 使用 inject 接收父组件提供的数据
</script>
<style lang="less" scoped>
.prime-bg-container{
  background: linear-gradient(244deg, #FFF3D2 0%, #FEFBF3 25%, #FFF3D2 50.52%, #FFFCF3 77.08%, #FEEAB6 100%);
}
.xtra-bg-container{
  background: linear-gradient(181deg, #FFEEF3 2.94%, #FDD2DB 98.75%);
}
.common-bg-container{
  background: #fff;
  }
</style>

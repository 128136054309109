<template>
  <div 
    v-expose="{
      id: '1-11-1-210',
      data: shippingAddItemAnalysis
    }"
    v-tap="{
      id: '1-11-1-211',
      data: shippingAddItemAnalysis
    }"
    class="shipping-method-item__add not-fsp-element"
    @click.stop="handleAddFree"
  >
    <div 
      class="shipping-method-item__add-desc" 
      v-html="hints"
    >
    </div>
    <span class="shipping-method-item__add-btn">
      {{ language.SHEIN_KEY_PC_29848 }}
    </span>
  </div>
  <AddOnDialog
    v-model:visible="conponFreeDialogVisible"
    :coupon="saInfo.promotion_code"
    :query-params="addConponFreeParams"
    :sa-info="saInfo"
    :on-update-cart="addOnUpdateCarts"
    :config="addOnConfig"
    :on-close="changeModelCartBag"
    type="coupon"
  />
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, ref, defineAsyncComponent, watch  } from 'vue'
import { template } from '@shein/common-function'
import { deepCopy } from 'public/src/pages/checkout/utils.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
// 免邮凑单
import { showAddOnDialog, updateAddOndialogOpts, closeAddOnDialog } from 'public/src/pages/common/add-on-dialog/index.js'
import { getOpts4FreeShippingFromCheckout } from 'public/src/pages/common/add-on-dialog/utils/helper.js'
import { formatCoupon } from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/utils/index.js'
const AddOnDialog =  defineAsyncComponent(() => import('public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog.vue'))

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

const store = useStore()

const props = defineProps({
  addItemContentInfo: {
    type: Object,
    default: () => {}
  },
  mallCode: {
    type: [String, Number],
    default: ''
  }
})

let conponFreeDialogVisible = ref(false)
let saInfo = ref({
  promotion_code: '',
  activity_from: 'checkout_shipping_coupon_add',
  abtest: 'addnewhotJson',
  state: 'checkout_shipping_coupon_add'
})
let cartsGoodsIdAll = ref([])
let addFreeStatus = ref(0)
let deepCopyTransType = ref('')
let deepCopyType = ref('')
let visibleStatus = ref(false)

const language = computed(() => store.state.language)
const checkout = computed(() => store.state.checkout)
const buyNowNo = computed(() => store.state.buyNowNo)
const shippingAddStatusInfo = computed(() => store.state.shippingAddStatusInfo)

const contentInfo = computed(() => {
  // 当前凑单弹窗组件未打开
  if(!shippingAddStatusInfo?.value?.transportType){
    return props.addItemContentInfo
  }

  let data = {
    transportType: deepCopyTransType.value,
    type: deepCopyType.value,
    progress: props.addItemContentInfo?.progress
  }

  let info, 
      diffPrice,
      detail
  if(data.type == 'activity') {
    if(!!props.addItemContentInfo?.transportType && props.addItemContentInfo?.type == data.type) {
      info = props.addItemContentInfo?.info
      diffPrice = props.addItemContentInfo?.diffPrice
      detail = props.addItemContentInfo?.detail
    } else {
      info = props.addItemContentInfo?.activityContent
      diffPrice = props.addItemContentInfo?.activityContent?.[props.mallCode]?.shippingInfoAvailable?.price
      detail = props.addItemContentInfo?.activityContent?.[props.mallCode]
    }
  } else {
    if(!!props.addItemContentInfo?.transportType && props.addItemContentInfo?.type == data.type) {
      info = props.addItemContentInfo?.info
      diffPrice = props.addItemContentInfo?.diffPrice
    } else {
      info = props.addItemContentInfo?.couponContent
      diffPrice = props.addItemContentInfo?.couponContent?.needPrice
    }
  }
  
  return Object.assign(data, { info, diffPrice, detail })
})
// activity, coupon
const type = computed(() => { 
  return contentInfo?.value?.type || ''
})
const diffPriceAmount = computed(() => {
  return contentInfo?.value?.diffPrice?.amount
})
const hints = computed(() => {
  // 优惠券门槛
  let minOrderPrice = contentInfo?.value?.info?.other_coupon_rule?.[0]?.min_order?.price || ''
  // 0门槛优惠券的优惠券凑单文案
  if(parseFloat(minOrderPrice) === 0 && type.value != 'activity') {
    return template(`<em>${language.value.SHEIN_KEY_PC_30305}</em>`, language.value.SHEIN_KEY_PC_30911)
  }
  return template(`<em>${contentInfo?.value?.diffPrice?.amountWithSymbol}</em>`, `<em>${language.value.SHEIN_KEY_PC_30305}</em>`, type.value == 'activity' ? language.value.SHEIN_KEY_PC_30306 : language.value.SHEIN_KEY_PC_30307)
})

const shippingAddItemAnalysis = computed(() => {
  return {
    type: contentInfo?.value?.type || '-',
    mall_code: props.mallCode || '-',
    origin_price: (contentInfo?.value?.type == 'activity' ? contentInfo?.value?.detail?.origin_price?.amountWithSymbol : contentInfo?.value?.info?.other_coupon_rule?.[0]?.minOrderPrice?.amountWithSymbol) || '-',
    shipping_method: contentInfo?.value?.transportType || '-',
    // coupon_dimension 1平台券，2mall券
    goods_range: (contentInfo?.value?.type == 'activity' ? 1 : (contentInfo?.value?.info?.coupon_dimension == 1 ? 0 : 1)) || '-',
    progress: contentInfo?.value?.progress || '-',
  }
})
const Need_Qs_Freight = computed(() => {
  return [ 108 ].includes(+checkout.value?.default_address?.countryId)
})
// 当前mall下判别quickShip商品
const isProductQuickShip = computed(() => {
  const currentMall = (checkout.value?.results?.mallShippingMethods?.mall_list || []).find(f => f.mall_code == props.mallCode)
  return Need_Qs_Freight.value ? 0 : (currentMall?.desc_quick_ship_time || currentMall?.quick_ship_time)
})
// 购物车整个数据
const cartsInfo = computed(() => {
  const resultCarts = checkout.value.results?.carts?.data || {}
  return {
    carts: resultCarts?.source || [],
    data: {
      ...resultCarts,
      carts: resultCarts?.source
    }
  }
})

// 优惠券凑单参数
const addConponFreeParams = computed(() => {
  return {
    addOnType: 6,
    sceneId: 152,
    goodsIds: cartsInfo.value.carts?.map?.(item => item?.product?.goods_id), // 商品ID,
    cateIds: cartsInfo.value.carts?.map?.(item => item?.product?.cat_id), // 类目ID
    quickship_prior: isProductQuickShip.value ? 1 : 0
  }
})

const addOnConfig = computed(() => ({
  isBuyNow: !!buyNowNo.value,
  continueBtnUseLangKey: 'SHEIN_KEY_PC_30310',
  isCloseIncentivePoint: true,
  continueBtnToClose: true,
  casualCheckoutNo: buyNowNo.value,
}))

watch([diffPriceAmount], async ([newDiffPriceAmount], [oldDiffPriceAmount]) => {
  if(visibleStatus.value && newDiffPriceAmount != oldDiffPriceAmount){
    const params = await addActivityParams()
    updateAddOndialogOpts(params)
  }
})

const addActivityParams = async () => {
  const cartsApiData = cartsInfo.value.data
  const params = {
    mallCode: props.mallCode,
    transportType: contentInfo.value.transportType,
    isQuickship: !!isProductQuickShip.value, 
    isBuyNow: !!buyNowNo.value,
    casualCheckoutNo: buyNowNo.value,
    cartsApiData,
    mallShippingInfo: contentInfo?.value.info,
  }
  const activityFreeParams = await getOpts4FreeShippingFromCheckout(params)
  addFreeStatus.value = activityFreeParams.isActivityStop ? 1 : 0

  return activityFreeParams
}

// 调取计价接口，购物车接口
const changeDataApi = async () => {
  return new Promise((resolve) => {
    window.checkoutEventCenter.emit('orign-update-total', ({
      opt: {
        ...store.state.caculateData,
        pureCallBack: (res) => {
          if(res.code != 0){
            closeAddOnDialog()
          }
        }
      }
    }))
    // 更新购物车
    store.dispatch('updateCart', { noUpdataShipping: 1, cartCallback: (res) => {
      if(res?.code){
        return resolve(true)
      } else {
        return resolve(false)
      }
    } })
  })
}
// 免邮凑单弹窗 activity（活动免邮凑单） | coupon（优惠券免邮凑单）
const handleAddFree = async () => {
  changeIsshow(true)
  if(type.value == 'coupon'){
    const couponInfo = contentInfo?.value?.info
    const couponCode = couponInfo?.coupon || ''
    if(!couponCode) throw new Error('couponCode no Data')
    conponFreeDialogVisible.value = true
    saInfo.value.promotion_code = couponCode
  } else {
    const getOpts = await addActivityParams()

    showAddOnDialog({
      ...getOpts,
      onUpdateCart: async (cartDatas) => {
        await changeDataApi()
        addOnUpdateCarts(cartDatas)
      },
      onClose: () => {
        changeModelCartBag()
      }
    })
  }
}
// 免邮凑单加车数据更新
const addOnUpdateCarts = (cartDatas = []) => {
  cartDatas && cartsGoodsIdAll.value.push(1)
}
// 关闭免邮弹窗，刷新接口
const changeModelCartBag = async (val) => {
  if(val && type.value == 'coupon'){
    const couponFreeInfo = formatCoupon(val)
    addFreeStatus.value = [1, 2].includes(+couponFreeInfo._data.is_satisfied) ? 1 : 0
  }
  changeIsshow(false)

  if(cartsGoodsIdAll.value.length){
    cartsGoodsIdAll.value = []
    // 凑单弹窗关闭后的回调
    emits('addSuccess', { status: addFreeStatus.value })
  }
}

const changeIsshow = (val) => {
  visibleStatus.value = val
  if(val){
    deepCopyTransType.value = deepCopy(props.addItemContentInfo.transportType)
    deepCopyType.value = deepCopy(props.addItemContentInfo.type)
  }
  store.commit('updateShippingAddStatusInfo', {
    transportType: val ? contentInfo?.value.transportType : '',
  })
}

const emits = defineEmits(['addSuccess'])

</script>

<style lang="less" scoped>
.shipping-method-item__add {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 8px;
  padding: 4px 8px;
  background: #F3FAF6;
  font-size: 12px;
  font-weight: 700;
  position: relative;

  &::before {
    position: absolute;
    width: 10px;
    height: 10px;
    content: '';
    background: #F3FAF6;
    top: 0;
    .left(20px);
    transform: translateY(-50%) rotate(45deg);
  }

  .shipping-method-item__add-desc {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .shipping-method-item__add-btn {
    display: inline-block;
    .margin-l(4px);
    font-weight: 500;
    padding: 4px 10px;
    min-width: 44px;
    max-width: 70px;
    border-radius: 100px;
    border: 1px solid @sui_color_brand;

    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
</style>

<style lang="less">
.shipping-method-item__add {
  .shipping-method-item__add-desc {
    em {
      color: @sui_color_safety;
    }
  }
}
</style>

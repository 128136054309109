<template>
  <div class="coupon-small-wrap">
    <!-- 金👑 -->
    <template v-if="isShowCrown && !couponExpireDate">
      <div
        v-if="GB_cssRight"
        class="gold-crown ar"
        :style="{
          background: `url(${PUBLIC_CDN}/she_dist/images/coupon/crown_ar-fd8729cd8f.png) no-repeat center center`,
          backgroundSize: '100%',
        }"
      ></div>
      <div
        v-else
        class="gold-crown"
        :style="{
          background: `url(${PUBLIC_CDN}/she_dist/images/coupon/crown-90911eb62d.png) no-repeat center center`,
          backgroundSize: '100%',
        }"
      ></div>
    </template>
    <div
      class="coupon-small"
      :class="[
        isPayed ? 'hasUseButton' : '',
        couponRightType,
        inCheckout ? 'inCheckout' : '',
        (SiteUID == 'us' && appLanguage == 'es') ? 'spanish-coupon-small' : ''
      ]"
      :style="{
        backgroundColor: couponRightType == 'crown' ? '#FBF1FF' : '#ffeef3',
        backgroundImage:
          isPayed && couponRightType == 'crown'
            ? `url(${PUBLIC_CDN}/she_dist/images/coupon/coupon_start_big-7f8833f221.png)`
            : couponRightType == 'crown'
              ? `url(${PUBLIC_CDN}/she_dist/images/coupon/coupon_starBg_pwa-54ec2e00c1.png)`
              : '',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: couponRightType == 'crown' ? '#FBF1FF' : '#ffeef3',
        borderColor: couponRightType == 'crown' ? '#f1daff' : '#fdd2db',
      }"
    >
      <!-- 有效期提示 -->
      <div
        v-if="couponExpireDate"
        class="expiration-tips"
      >
        <img
          class="clock-icon"
          :src="PUBLIC_CDN + '/she_dist/images/coupon/coupon-clock-icon-b7c10fa82e.png'"
        />
        <div class="expiration-tips__text">
          {{ template(couponExpireDate, language.SHEIN_KEY_PC_29398) }}
        </div>
      </div>
      <!-- 顶部梯形title -->
      <div
        v-if="!inCheckout"
        class="title-wrap"
        :class="[couponType, couponRightType]"
      >
        <div
          class="left"
          :class="{ 'is-ar': GB_cssRight }"
        >
          <RoundedTriangle
            position="left"
            :fill-color="leftTitleFillColor"
          />
        </div>
        <div class="title-wrap-text">
          <!-- 这里创建一个占位的文字，用来给svg, 支撑盒子宽度 -->
          <div
            v-if="couponRightType == 'crown'"
            ref="svgPos"
            class="svg_pos"
          >
            {{ couponTitle }}
          </div>
          <template v-if="couponRightType == 'crown'">
            <svg
              id="svgTitle"
              ref="svgTitle"
              width="100px"
              height="18"
              viewBox="0 0 100 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <linearGradient
                  id="color_text"
                  x1="3.68354"
                  y1="6.49999"
                  x2="93.3165"
                  y2="6.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop
                    offset="0.397975"
                    stop-color="#EE04C2"
                  />
                  <stop
                    offset="1"
                    stop-color="#700FED"
                  />
                </linearGradient>
              </defs>
              <text
                x="50"
                y="12"
                text-anchor="middle"
                font-size="12px"
                font-weight="bolder"
                letter-spacing="-1px"
                fill="url(#color_text)"
              >
                {{ couponTitle }}
              </text>
            </svg>
          </template>
          <template v-else>
            {{ couponTitle }}
          </template>
        </div>
        <div
          class="right"
          :class="{ 'is-ar': GB_cssRight }"
        >
          <RoundedTriangle
            position="right"
            :fill-color="rightTitleFillColor"
          />
        </div>
      </div>
      <!-- 折扣金额 -->
      <div
        v-if="couponRightType == 'crown'"
        ref="discountInfo"
        class="discount-info"
        :class="[
          hasExpective ? 'expect' : 'expectnone',
          GB_cssRight ? 'is-ar' : '',
        ]"
      >
        <div
          ref="discountText"
          class="discount-wrap"
          :class="(!isPayed || inCheckout) && SiteUID == 'us' && appLanguage == 'es' ? 'discount-wrap-spanish' : ''"
        >
          <span
            class="stroke"
            :text="couponDiscountInfo"
          >
            {{ couponDiscountInfo }}
          </span>
          <span
            class="text"
            :text="couponDiscountInfo"
          >
            {{ couponDiscountInfo }}
          </span>
        </div>
      </div>
      <div
        v-else
        ref="discountInfo"
        class="discount-info"
        :class="[
          hasExpective ? 'expect' : 'expectnone',
          GB_cssRight ? 'is-ar' : '',
        ]"
      >
        <p
          ref="discountText"
          :class="[
            (!isPayed || inCheckout) && SiteUID == 'us' && appLanguage == 'es' ? 'spanish-discountText' : ''
          ]"
        >
          {{ couponDiscountInfo }}
        </p>
      </div>
      <!-- 使用门槛 -->
      <div
        class="use-rules"
        :class="[couponRightType, hasExpective ? 'expect' : 'expectnone', breakeven && SiteUID == 'us' && appLanguage == 'es' ? 'spanish-use-rules' : ''
        ]"
      >
        <p
          ref="userRule"
          :style="!breakeven && SiteUID == 'us' && appLanguage == 'es' && (!isPayed || inCheckout) ? { marginBottom: '5px' } : {}"
        >
          {{ couponSubTitle }}
        </p>
      </div>
      <div
        v-if="inCheckout && hasExpective"
        class="expective"
        :class="[hasExpective ? 'expect' : 'expectnone']"
      >
        <p>{{ expectInfo }}</p>
      </div>
      <!-- 底部状态按钮，使用/过期 -->
      <div
        v-if="isPayed && !inCheckout"
        class="payed-wrap"
      >
        <div
          class="topline"
          :class="[couponRightType]"
        ></div>
        <div
          class="use-btn"
          :class="[couponRightType, isUsed]"
          @click="handleClickUse"
        >
          {{ handleButtonText }}
        </div>
      </div>
      <!-- 优惠券标签 -->
      <div
        v-if="breakeven"
        class="coupon-tip"
      >
        {{ language.SHEIN_KEY_PC_28581 }}
      </div>
      <div
        class="circle-wrap-left"
        :class="couponRightType"
      ></div>
      <div
        class="circle-wrap-right"
        :class="couponRightType"
      ></div>
    </div>
  </div>
</template>

<script>
import { souceFormat } from 'public/src/pages/components/coupon/mall/utils'
import { htmlDecode, template } from '@shein/common-function'
import RoundedTriangle from './rounded_triangle'
import { mapState } from 'vuex'


export default {
  name: 'XtraSmallCoupon',
  components: {
    RoundedTriangle,
  },
  props: {
    item: {
      // 优惠券 数据
      type: Object,
      default: () => { },
    },
    inCheckout: {
      type: Boolean,
      default: false,
    },
    hasCrown: { // 是否有皇冠，目前用于下单页面透出的优惠劵
      type: Boolean,
      default: true,
    },
    hasExpective: {
      // 是否有底下期待一行
      type: Boolean,
      default: false,
    },
    expectiveNumber: {
      type: String,
      default: '', // 空不展示
    },
    language: {
      // 多语言
      type: Object,
      default: () => { },
    },
    couponRightType: {
      // 券权益 // 必用券/ 普通券
      type: String,
      default: 'crown', // crown normal  券带👑 =必用券
    },
    frontStatus: {
      type: Number,
      default: 0,
    },
    isUsed: {
      // 券状态 unuse, used, expired
      type: String,
      default: 'unuse',
    },
    breakeven: {
      // 组件使用模块
      type: Boolean,
      default: false
    },
    couponExpireDate: {
      // 优惠券有效期
      type: Number,
      default: 0
    },
  },

  data() {
    return {
      couponType: 'discount',
      isPayed: false, // 是否购买决定是否有底部按钮
      ruleHeight: 18,
    }
  },

  computed: {
    ...mapState([
      'locals',
    ]),
    PUBLIC_CDN() {
      return this.locals.PUBLIC_CDN
    },
    GB_cssRight() {
      return this.locals.GB_cssRight
    },
    SiteUID() {
      return this.locals.SiteUID
    },
    appLanguage() {
      return this.locals.appLanguage
    },
    isShowCrown() {
      return this.couponRightType === 'crown' && this.hasCrown
    },
    _item() {
      return this.souceFormat(this.item)
    },
    handleButtonText() {
      const statusLang = {
        unuse: 'SHEIN_KEY_PC_26965',
        used: 'SHEIN_KEY_PC_26966',
        expired: 'SHEIN_KEY_PC_26967',
        upcoming: 'SHEIN_KEY_PC_26968',
      }
      return this.language[statusLang[this.isUsed]]
    },
    expectInfo() {
      return this.template(
        this.expectiveNumber,
        this.language.SHEIN_KEY_PC_26926
      )
    },
    // 优惠信息
    couponDiscountInfo() {
      if (['5', '6', '9'].includes(this._item.apply_for)) {
        if (this._item.shipping_discount_type == '1') {
          //  1部分抵扣
          if (this.item.ruleDimension == '1') {
            return this.template(
              this.item.rule[0].valueAmount.amountWithSymbol,
              this.language.SHEIN_KEY_PC_15957
            )
          } else if (
            this.item.ruleDimension == '2' ||
            this.item.ruleDimension == '5'
          ) {
            return this.template(
              this.percentFormat(this.item.rule[0].value),
              this.language.SHEIN_KEY_PC_15957
            )
          }
        } else if (this._item.shipping_discount_type == '0') {
          // 0全免
          return this.language.SHEIN_KEY_PC_15911
        }
      } else if (this.item.ruleDimension == '1') {
        // 减元
        return this.template(
          this.item.rule[0].valueAmount.amountWithSymbol,
          this.language.SHEIN_KEY_PC_15957
        )
      } else if (
        this.item.ruleDimension == '2' ||
        this.item.ruleDimension == '5'
      ) {
        return this.template(
          this.percentFormat(this.item.rule[0].value),
          this.language.SHEIN_KEY_PC_15957
        )
      }
      return ''
    },
    // 优惠券门槛信息
    couponSubTitle() {
      const min = this._item.coupon_rule[0]?.min || ''
      return this.template(
        min.amountWithSymbol,
        this.language.SHEIN_KEY_PC_15958
      )
    },
    couponTitle() {
      if (['5', '6', '9'].includes(this._item.apply_for)) {
        return this.language.SHEIN_KEY_PC_26964
      } else {
        return this.language.SHEIN_KEY_PC_26963
      }
    },
    leftTitleFillColor() {
      let couponTitleColor = {
        crown: this.GB_cssRight ? '#f0d9ff' : '#ffd6f9',
        freeExpress: '#d6e7e0',
        discount: '#fdd2db',
      }
      if (this.GB_cssRight && this.couponRightType == 'crown') {
        return '#f0d9ff'
      } else {
        return this.couponRightType == 'crown'
          ? '#ffd6f9'
          : couponTitleColor[this.couponType]
      }
    },
    rightTitleFillColor() {
      let couponTitleColor = {
        crown: this.GB_cssRight ? '#ffd6f9' : '#f0d9ff',
        freeExpress: '#d6e7e0',
        discount: '#fdd2db',
      }
      if (this.GB_cssRight && this.couponRightType == 'crown') {
        return '#ffd6f9'
      } else {
        return this.couponRightType == 'crown'
          ? '#f0d9ff'
          : couponTitleColor[this.couponType]
      }
    },
  },

  watch: {
    ruleHeight() {
      this.setCouponStyle()
    },
  },

  mounted() {
    this.setSvgTitleWidth()
    this.setCouponStyle()
    this.setFontSize()
  },

  methods: {
    souceFormat,
    htmlDecode,
    template,
    setFontSize() {
      const dom = this.$refs.discountText
      if (dom) {
        const { paddingLeft } = window.getComputedStyle(dom)
        // paddingLeft 是一个包含 px 的字符串，转化为数字
        const padding = parseFloat(paddingLeft) || 0
        if (dom.scrollWidth > dom.offsetWidth) {
          // 设置新的字体大小
          if (((dom.offsetWidth - padding * 2) / dom.scrollWidth) * 20 <= 12) {
            dom.style.fontSize = '12px'
            dom.style.textOverflow = 'ellipsis'
            dom.style.overflowX = 'initial'
            dom.style.overflow = 'hidden'
          } else {
            dom.style.fontSize = `${((dom.offsetWidth - padding * 2) / dom.scrollWidth) * 20
              }px`
          }
        }
      }
    },
    // 百分比格式化
    percentFormat(num) {
      const newNum = (num * 100) / 100
      const percentNum = this.locals.GB_cssRight ? `%${newNum}` : newNum + '%'
      return percentNum
    },
    setCouponStyle() {
      if (['5', '6', '9'].includes(this._item.apply_for)) {
        this.couponType = 'freeExpress'
      } else {
        this.couponType = 'discount'
      }
      // 是否购买决定是否有底部按钮
      this.isPayed = this.frontStatus == 1
      // this.isPayed = this._item.isPayed
      // 券状态 unuse, used, expired
      // this.useStatus = this.isUsed

      let rules = this.$refs.userRule
      // let discountInfo = this.$refs.discountInfo
      if (rules) {
        this.ruleHeight = parseFloat(window.getComputedStyle(rules).height)
        if (parseFloat(window.getComputedStyle(rules).height) > 20) {
          if (this.isPayed) {
            // discountInfo.style.marginTop = '22px'
            rules.parentElement.style.bottom = '44px'
          } else {
            // discountInfo.style.marginTop = '22px'
            rules.parentElement.style.bottom = '0.2rem'
          }
        }
      }
    },
    setSvgTitleWidth() {
      // 动态改变svg title的宽度
      this.$nextTick(() => {
        let svgTitle = this.$refs.svgTitle
        let svgPos = this.$refs.svgPos
        if (svgTitle) {
          svgTitle.setAttribute('width', svgPos.offsetWidth)
        }
      })
    },
    handleClickUse() {
      if (this.isUsed == 'unuse') {
        this.$emit('handleUse', this._item)
      } else {
        this.$emit('handleUse')
      }
    }
  },
  emits: ['handleUse'],
}
</script>

<style lang="less" scoped>
.overText {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.over2Text {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.title-wrap-text {
  height: 19px;
}

.coupon-small-wrap {
  position: relative;

  .gold-crown {
    width: 36px;
    height: 28px;
    position: absolute;
    top: -13px;
    right: -15px;
    z-index: 1;
  }
  .coupon-small__nums {
    top: -10px;
    right: -10px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: 12px;
    background: linear-gradient(90deg, #f82854 0%, #d52bff 100%, #df1dff 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      background: linear-gradient(
        122deg,
        #ffcbad 12.48%,
        #fff7eb 52.33%,
        #ffc19e 89%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.discount-wrap {
  position: relative;
  font-size: 22px;
  font-weight: 700;

  &-spanish {
    line-height: 1.8;
  }

  .stroke {
    padding: 0 10px;
    -webkit-text-stroke: 5px #f80bcb;

    &::before {
      content: attr(text);
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      margin: auto;
      -webkit-text-stroke: 5px #700fed;
      -webkit-mask: linear-gradient(to right, transparent, black);
    }
  }

  .text {
    color: #ffde9e;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;

    &::before {
      content: attr(text);
      position: absolute;
      color: #fffce4;
      -webkit-mask: linear-gradient(to right, transparent, #ffd89e);
    }
  }
}

.coupon-small {
  width: 194px;
  height: 88px;
  border: 2px solid #ffd6f9;
  background: #fbf1ff;
  position: relative;
  border-radius: 8px;
  .expiration-tips {
    width: 100%;
    height: 19px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    border-radius: 6px 6px 0px 0px;
    background: linear-gradient(90deg, #FFD6F9 0%, #F0D9FF 100%);
    text-align: center;
    font-family: "SF Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    .expiration-tips__text {
      background-image: linear-gradient(96deg, #F82854 0.82%, #F80BCB 51.08%, #7729F6 100.59%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .clock-icon {
      width: 10px;
      height: 10px;
      margin-right: 3px;
    }
  }
  .title-wrap {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -2px;
    height: 19px;
    line-height: 19px;
    background: #fdd2db;
    color: #f82854;
    text-align: center;
    font-weight: bold;

    .left,
    .right {
      width: 9px;
      height: 19px;
      position: absolute;
      top: 0px;
    }

    .left {
      left: -8px;
    }

    .right {
      right: -8px;
    }

    .left.is-ar {
      left: 94px;
    }

    .right.is-ar {
      right: 94px;
    }
  }

  .title-wrap.crown,
  .title-wrap.crown.freeExpress {
    background: linear-gradient(to right, #ffd6f9, #f0d9ff);
  }

  .title-wrap.freeExpress {
    background-color: #d6e7e0;
    color: #198055;
  }

  .title-wrap.discount {
    background-color: #fdd2db;
    color: #f82854;
  }

  .title-wrap-text {
    min-width: 95px;
    max-width: 160px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    font-size: 12px;
    padding: 0 2px;
    // height: 19px;
  }

  .discount-info {
    width: 100%;
    text-align: center;
    margin-top: 30px;
    color: #f82854;
    font-weight: 800;
    white-space: nowrap;
    font-size: 22px;
  }

  .is-ar.discount-info.expectnone {
    margin-top: 25px;
  }

  .use-rules {
    p {
      box-sizing: border-box;
      padding: 0 10px;
      .over2Text();
      opacity: 0.7;
      line-height: 1;
    }

    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    color: #f82854;
    font-size: 12px;
  }

  .spanish-use-rules {
    margin-bottom: 10px;
  }

  .use-rules.crown {
    p {
      box-sizing: border-box;
      padding: 0 10px;
      .over2Text();
      opacity: 0.7;
      line-height: 1;
    }

    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    color: #a20fe3;

    .spanish {
      margin-bottom: 10px;
    }
  }
}
.spanish-coupon-small {
  height: 102px;
}

.hasUseButton {
  .use-rules {
    bottom: 46px;
  }

  .use-rules.crown {
    p {
      box-sizing: border-box;
      padding: 0 10px;
      .over2Text();
      opacity: 0.7;
    }

    position: absolute;
    bottom: 46px;
    left: 50%;
    transform: translateX(-50%);
    color: #a20fe3;
  }
}

.coupon-small.crown {
  .svg_pos {
    color: transparent;
  }

  #svgTitle {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    margin-top: 2px;
  }
}

.coupon-small {
  mask-image: radial-gradient(circle at 6px 49%, transparent 6px, #fff 6px);
  mask-position: -6px;
}

.circle-wrap-left {
  position: absolute;
  top: calc(50% - 9px);
  left: -10px;
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fdd2db;
}

.circle-wrap-right {
  position: absolute;
  top: calc(50% - 9px);
  right: -10px;
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fdd2db;
}

.circle-wrap-right.crown {
  background: #f1daff;
}

.circle-wrap-left.crown {
  background: #f1daff;
}

.coupon-tip {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.9rem;
  border-radius: 0px 0px 6px 6px;
  background: rgba(255, 226, 167, 0.7);
  color: #f83a3a;
  text-align: center;
  text-shadow: 0px 1px 6px rgba(227, 167, 148, 0.3);
  font-family: SF Pro;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 510;
  line-height: 1;
}

.coupon-small.hasUseButton {
  mask-image: radial-gradient(circle at 6px 65%, transparent 6px, #fff 6px);
  mask-position: -6px;

  .circle-wrap-right {
    // 含有button的缺口样式
    top: calc(65% - 7px);
  }

  .circle-wrap-left {
    // 含有button的缺口样式
    top: calc(65% - 7px);
  }
}

.coupon-small.hasUseButton {
  // 有use按钮就需要确定高度和缺口位置
  height: 123px;
  position: relative;

  .payed-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 41px;
    display: flex;
    place-items: center;
    justify-content: center;

    // border-top: 2px dashed #ccc;
    // background: #ccc;
    .topline {
      position: absolute;
      top: 0;
      width: 95%;
      height: 2px;
      background-image: linear-gradient(
        to right,
        #fdd2db 0%,
        #fdd2db 50%,
        transparent 50%
      );
      background-size: 6px 2px;
      background-repeat: repeat-x;
    }

    .topline.crown {
      background-image: linear-gradient(
        to right,
        #ffd7fa 0%,
        #ffd7fa 50%,
        transparent 50%
      );
      background-size: 6px 2px;
      background-repeat: repeat-x;
    }
  }

  .use-btn {
    // left: 50%;
    // transform: translateY(-50%);
    width: 171px;
    height: 25px;
    background: #f82854;
    border-radius: 12.5px;
    color: #fff;
    text-align: center;
    line-height: 25px;
    font-size: 14px;
    font-weight: 800;
  }

  .use-btn.unuse {
    cursor: pointer;
  }

  .use-btn.crown {
    background: linear-gradient(to right, #f82854, #f80bcb, #7729f6);
  }

  .use-btn.expired {
    background: #d5d5d5;
    color: rgba(255, 255, 255, 0.65);
  }

  .use-btn.used,
  .use-btn.upcoming {
    background: rgba(248, 40, 84, 0.5);
    color: rgba(255, 255, 255, 0.6);
  }

  .use-btn.crown.used {
    background: linear-gradient(
      90deg,
      #f592c5 3.8%,
      #f38bee 49.39%,
      #c58cf9 96.2%
    );
    color: rgba(255, 255, 255, 0.6);
  }

  .use-btn.upcoming.crown {
    background: linear-gradient(
      90deg,
      #f592c5 3.8%,
      #f38bee 49.39%,
      #c58cf9 96.2%
    );
    color: rgba(255, 255, 255, 0.6);
  }
}

.coupon-small.inCheckout {
  width: 192px;
  height: 61px;
  mask-image: radial-gradient(circle at 6px 50%, transparent 6px, #fff 6px);
  mask-position: -6px;

  .circle-wrap-left {
    width: 12px;
    height: 12px;
  }

  .circle-wrap-right {
    width: 12px;
    height: 12px;
  }

  .discount-info {
    margin-top: 2px;
    white-space: nowrap;
  }

  .discount-info.expectnone {
    margin-top: 10px;
    white-space: nowrap;
  }

  .expectnone.use-rules,
  .expectnone.use-rules.crown {
    bottom: 5px;
  }

  .use-rules {
    bottom: 10px;

    p {
      box-sizing: border-box;
      padding: 0 10px;
      .overText();
      opacity: 0.7;
    }
  }

  .discount-info.expect {
    margin-top: 4px;
    white-space: nowrap;
  }

  .use-rules.crown.expect {
    bottom: 14px;

    p {
      box-sizing: border-box;
      padding: 0 10px;
      .overText();
      opacity: 0.7;
    }
  }

  .expective {
    margin-top: 15px;

    p {
      padding: 0 10px;
      width: 100%;
      .overText();
      font-size: 12px;
      color: #fb1b4c;
      text-align: center;
    }
  }
}

.discountText {
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
}

.spanish-discountText {
  line-height: 1.8;
}
</style>

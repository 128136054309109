import { parseQueryString, getLocalStorage } from '@shein/common-function'
var page_from = window && window.sessionStorage && window.sessionStorage.getItem('page_from') || ''
var referrer = ''
var pageName = ''
const { page_from: _page_from } = parseQueryString(window.location.search)
pageName = _page_from


if( document && document.referrer ){
  if( document.referrer.indexOf('/user/auth/login') > -1 ){
    referrer = 'login'

  } else if( document.referrer.indexOf('/cart') > -1 ){
    referrer = 'page_cart'
  }
}
if( page_from == 'login' ){
  referrer = 'login'
}
window && window.sessionStorage && window.sessionStorage.removeItem('page_from')
if(!!pageName) {
  referrer = pageName
}

//页面信息
window.SaPageInfo = {
  page_id: 11,
  page_name: 'page_checkout',
  page_param: {
    page_from: referrer,
    abtest: '',
    is_combine: getLocalStorage('is_combine') === '0' ? 0 : 1
  },
  start_time: new Date().getTime(),
}

window.GB_analysis_obj = {
  _clickStatus: {
    payment: false,
    shipping: false,
    checkout: false
  },
  clickLoading: function (type) {
    var self = this
    if(self._clickStatus[type]) {
      return true
    } else {
      self._clickStatus[type] = true
      setTimeout(function() {
        self._clickStatus[type] = false
      }, 300)
      return false
    }
  },
  exposeConstomsinfoGuide: function () {
    sa('send', { activity_name: 'expose_constomsinfo_guide' })
  },
  clickConstomsinfoGuide: function () {
    sa('send', { activity_name: 'click_constomsinfo_guide' })
  },
  exposeFirstAddress: function() {
    sa('send', { activity_name: 'expose_add_first_address_form' })
  },
  saveFirstAddress: function(code) {
    var result = code == 0 ? 1 : 2
    sa('send', { activity_name: 'click_save_first_address', activity_param: { result: result } })
  },
  exposeAddressFrom: function(type) {
    sa('send', { activity_name: 'expose_' + type + '_address_form' })
  },
}
window.GB_sa_analysis_obj = {
  saveAddress: function(result, reason, address) {
    sa('send', { activity_name: 'click_add_a_shipping_address', activity_param: { result: result, result_reason: reason || '', longitude: address && address.lng || 0, latitude: address && address.lat || 0, associationAddress: address && +address.tag === 2 ? 'Y' : 'N' } })
  },
  editAddress: function(result, reason, address) {
    sa('send', { activity_name: 'click_edit_a_shipping_address', activity_param: { result: result, result_reason: reason || '', longitude: address && address.lng || 0, latitude: address && address.lat || 0, associationAddress: address && +address.tag === 2 ? 'Y' : 'N' } })
  }
}
  
//进入页面
sa('set', 'setPageData', window.SaPageInfo)
//退出页面
window.onbeforeunload = function(){ sa('send', 'pageLeave', { end_time: new Date().getTime() }) }

<template>
  <div class="autoRenewal-bubble not-fsp-element">
    <div class="logo">
      <template
        v-for="(item, idx) in logoList"
        :key="idx"
      >
        <img
          :src="item"
          :class="{ third: idx === 2 }"
          alt="logo"
        />
      </template>
    </div>
    <div
      v-if="logoList && logoList.length"
      class="detail"
      @click.stop="handleClick"
    >
      {{ bubbleText }}
    </div>
    <div class="arrow">
      >
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, defineProps, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { template } from '@shein/common-function'
const emit = defineEmits(['handleBubbleClick', 'filterPaymentFinish'])
const props = defineProps({
  index: {
    type: Number,
    default: -1,
  },
  handleBubbleClick: {
    type: Function,
    default: () => {},
  },
  paypalGaVault: {
    type: Object,
    default: () => {}
  },
  banksConfig: {
    type: Object,
    default: () => {},
  },
  status: {
    type: Object,
    default: () => {},
  },
  paymentMethodsProps: {
    type: Object,
    default: () => {}
  },
  autoType: {
    type: String,
    default: 'prime'
  }
})

// 支付方式logo
const logoList = ref([])
// 所有限制的支付方式
const payTypeList = ref([])

const store = useStore()
const language = computed(() => store.state.language)
const checkout = computed(() => store.state.checkout)

// 气泡展示文案
const bubbleText = computed(() => {
  return template(payTypeList?.value?.length, language.value?.SHEIN_KEY_PC_29641)
})

// 超省卡自动续费快捷支付方式
const xtraAutoPayLimitInfo = computed(() => checkout.value?.results?.xtraProductInfo?.limitedPayTypeList)

// 点击弹出支付方式弹框
const handleClick = () => {
  if (props.index === -1) {
    emit('handleBubbleClick')
  } else {
    emit('handleBubbleClick', props.index)
  }
}

// 获取支付方式logo
const getPaymentLogoUrl = item => {
  if (item.enabled != 0) {
    return props.banksConfig?.[item.code]?.bankLogo || item.logo_url
  }
  return item.logo_url
}

// 过滤支付方式
const filterPayMethods = () => {
  // 自动续费限制支付方式
  const limitedPayTypeList = props.autoType == 'xtra' ? xtraAutoPayLimitInfo.value : checkout.value?.results?.primeProductInfo?.limitedPayTypeList

  // 支付方式列表
  let paymentMethods = checkout.value?.results?.paymentMethods
  // 过滤掉不可用的支付方式
  paymentMethods = paymentMethods?.filter((item) => {
    if(item.code === 'PayPal-GApaypal'){
      const express = props.paypalGaVault?.express
      const valid = props.paypalGaVault?.valid
      const isShow = express && valid
      const other =
      props.paypalGaVault?.valid &&
        item.enabled == 1 &&
        !props.status?.disabled?.[item.code] &&
        props.paymentMethodsProps?.needPayInlinePaymentMethods?.indexOf(item.code) > -1 &&
        props.paypalGaVault?.visibleArrow
      return item.enabled != 0 && (isShow || other)
    } else if(item.code === 'afterpay-card') {
      const switchVal = props.paymentMethodsProps?.afterpayVault?.switch
      const other = item.enabled == 1 &&
        !props.status?.disabled?.[item.code]
      return switchVal && other
    } else if (item.code === 'dlocal_nupay') {
      return false
    } else {
      return item.enabled != 0
    }
  })

  // 过滤自动续费限制支付方式
  // （1）支付方式code数组
  const tempList = limitedPayTypeList?.map(payType => {
    return payType.code
  })
  // （2）筛选出符合条件的支付方式
  let logoListTemp = paymentMethods?.filter(item => {
    if (tempList?.includes(item.code)) {
      return true
    } else {
      return false
    }
  })
  payTypeList.value = [...logoListTemp]
  // （3）取出支付方式logo
  logoListTemp = logoListTemp?.map(item => {
    return getPaymentLogoUrl(item)
  })
  // （4）限制最多显示3个支付方式
  if (logoListTemp?.length > 3) {
    logoListTemp = logoListTemp?.slice(0, 3)
  }
  logoList.value = logoListTemp

  emit('filterPaymentFinish', logoList.value)
}

onMounted(() => {
  filterPayMethods()
})

watch(() => props.paymentMethodsProps, () => {
  filterPayMethods()
})
</script>

<style lang="less" scope>
.autoRenewal-bubble {
  display: flex;
  flex-direction: row;
  align-items: center;

  .logo {
    display: flex;
    flex-direction: row;
    img {
      width: 24px;
      height: 16px;
      margin-right: 4px;
      z-index: 9999;
      background-color: #fff;
      border-radius: 2px;
    }
    .third {
      opacity: 0.6;
      width: 21px;
      height: 14px;
      z-index: 9998;
      margin-top: -2px;
      margin-left: -23px;
      opacity: 0.6;
    }
  }
  .detail {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 4px;
    color: var(---sui_color_white, #fff);
    font-family: 'SF UI Text';
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .arrow {
    color: var(---sui_color_white, #fff);
    font-family: 'SF UI Text';
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
</style>

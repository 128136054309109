/**
 * 获取促销活动满足情况
 * isFullPromotion 0: 满足 1: 不满足 2: 满足部分
 * ---------------
 * 1 isPromotion
 * 3 10 11 12 24 29 30恒满足
 * 2 买赠
 * 8 恒不满足
 * 4, 9, 13, 14, 15, 20, 21, 22, 25, 26 promotion_range_info （range + next)判断
 * 18, 19 range isPromotion
 * cartList: 下单页埋点传 默认空
 */
export function cartPromotionMapHandle({ integrityPromotionInfo  = {}, cartList = [], checkedCartPromotionIds } = {}) {
  const IS_FULL = 0
  const IS_NOT_FULL = 1
  const IS_FULL_PART = 2

  //all 1 2 3 4(全场) 8 9 10 11 12 13 14(全场) 15 18 19 20 21(全场) 22(全场) 24 25 26(全场)
  // check isFullPromotion
  // 特殊处理 2
  const isFull = [3, 10, 11, 12, 24, 29, 30]
  const isNotFull = [8]
  const checkIsPromotion = [1]
  const checkRangeIsPromotion = [18, 19]
  const checkRangeNext = [4, 9, 13, 14, 15, 20, 21, 22, 25, 26, 28]

  const map = {
    // id: {
    //   isFullPromotion: 0,
    //   typeId: xxxx
    // }
    //
  }

  const initMap = ({ typeId, id }) => {
    if (map[id] === undefined) {
      map[id] = {
        isFullPromotion: isFull.includes(typeId) ? IS_FULL : isNotFull.includes(+typeId) ? IS_NOT_FULL : 1,
        typeId
      }
    }
  }

  const checkPromotionRangeNext = (id, promotion_range_info) => {
    if (map[id].isFullPromotion === IS_NOT_FULL) {
      let { range, next } = promotion_range_info
      if (range == 0) {
        map[id].isFullPromotion = IS_NOT_FULL
      } else if (range > 0 && next != 0) {
        map[id].isFullPromotion = IS_FULL_PART
      } else if (range > 0 && next == 0) {
        map[id].isFullPromotion = IS_FULL
      }
    }
  }

  Object.values(integrityPromotionInfo).forEach(promotion => {
    let { type_id, promotion_id, promotion_range_info = {}, list = cartList, content = {} } = promotion
    if(checkedCartPromotionIds && !checkedCartPromotionIds.includes(promotion_id)) return
    initMap({ typeId: +type_id, id: promotion_id })
    if (type_id == 2) {
      if (content.action == 2) {
        map[promotion_id].isFullPromotion = IS_FULL
      } else {
        map[promotion_id].isFullPromotion = IS_NOT_FULL
      }
    } else if (checkIsPromotion.includes(+type_id)) {
      if (map[promotion_id].isFullPromotion == IS_NOT_FULL) {
        let isPromotion = list.some(item => item.product?.product_promotion_info?.some(info => info.is_promotion == 1))
        map[promotion_id].isFullPromotion = isPromotion ? 0 : 1
      }
    } else if (checkRangeIsPromotion.includes(+type_id)) {
      if (map[promotion_id].isFullPromotion == IS_NOT_FULL) {
        if (promotion_range_info.range == 0) { // 未满足最高梯度
          let isPromotion = list.some(item => item.product?.product_promotion_info?.some(info => info.is_promotion == 1))
          if (isPromotion) {
            map[promotion_id].isFullPromotion = IS_FULL_PART
          } else {
            map[promotion_id].isFullPromotion = IS_NOT_FULL

          }
        } else if (promotion_range_info.range > 0) { // 满足最高梯度
          map[promotion_id].isFullPromotion = IS_FULL
        }
      }
    } else if (checkRangeNext.includes(+type_id)) {
      checkPromotionRangeNext(promotion_id, promotion_range_info)
    }
  })
  if(typeof window != 'undefined'){
    window.GB_CartPromotionMap = map
  }

  return map
}

<template>
  <div :class="['autoPaymenodsGuide_container', `${options.guideType}_container`]">
    <div
      v-expose="{
        id: '2-43-10',
        data: {
          renew_type: props.autoType == 'prime' ? '2' : '1'
        }
      }"
      :class="['bubble-wrapper', `${options.guideType}_wrapper-active`]"
      :style="options.guideType == 'normal' && {bottom: currentXtraCouponFooterHeight}"
      @click.stop="handleBubbleClick"
    >
      <Icon
        class="bubble-wrapper__close-btn"
        name="sui_icon_closed_filter_12px_1"
        size="10px"
        color="#fff"
        @click.stop="handleBubbleCloseBtnClick"
      />
      <RenewalPayMethod
        :logo-list="logoList"
        :text="bubbleText"
        :on-click="handleBubbleClick"
      />
    </div>
  </div>
</template>
<script setup>
import { Icon } from '@shein-aidc/icon-vue3'
import { defineProps, ref } from 'vue'
import RenewalPayMethod from '@/public/src/pages/common/product-package/shared/RenewalPayMethod.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '2-43' })

const props = defineProps({
  logoList: {
    type: Array,
    default: () => []
  },
  bubbleText: {
    type: String,
    default: ''
  },
  productCode: {
    type: String,
    default: ''
  },
  options: {
    type: Object,
    default: () => ({
      guideType: 'bubble', // 气泡展示还是正常显示 bubble/normal
      iconShow: true
    })
  },
})

const emits = defineEmits(['bubbleClose', 'handlePayMethodsListDrawerShow'])
const currentXtraCouponFooterHeight = ref(0)

const handleBubbleCloseBtnClick = () => {
  emits('bubbleClose', false)
}

const handleBubbleClick = () => {
  emits('handlePayMethodsListDrawerShow', props.productCode, props.options.guideType == 'normal')
  daEventCenter.triggerNotice({
    daId: '2-43-11',
  })
}

</script>
<style lang="less" scoped>
  .bubble-wrapper {
    padding: 8px;
    border-radius: 2px;
    box-sizing: border-box;
    background: var(---sui_color_black_alpha80, rgba(0, 0, 0, 0.80));
    color: #FFF;
    font-size: 12px;
    max-width: 270px;
    width: 220px;
    &__close-btn {
      position: absolute;
      top: 0;
      right: 0;;
    }
  }
  .bubble_wrapper-active{
    position: relative;
    left: -1px;
    top: -1rem;
    border-radius: 2px;

    &::before {
      position: absolute;
      bottom: -5px;
      left: 7px;
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid rgba(0, 0, 0, 0.80);
    }
  }
  .normal_wrapper-active{
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    margin-bottom: 3px;
    max-width: 100%;
  }
  
</style>

<template>
  <div class="price-detail-discount">
    <span class="price-detail-discount__item-left">{{ discountTitle }}</span>
    
    <template v-if="canShowDetail">
      <s-popover
        class="checkout-summary-discount__popover not-fsp-element"
        theme="light"
        placemen="bottom-end"
        :append-to-body="false"
        :show-close-icon="false"
        trigger="hover"
        :prop-style="propStyle"
        @opened="openPopover"
        @closed="closePopover"
      >
        <div class="checkout-summary-discount__popover-content">
          <div class="checkout-summary-discount__popover-content-body">
            <div class="checkout-summary-discount__popover-content-detail">
              <span class="price-detail-discount__item-left">{{ discountTitle }}</span>
              <div 
                class="price-detail-discount__item-right price-detail-discount__right-hover"
                @click="showDiscountDetails = !showDiscountDetails"
              >
                <div class="price-detail-discount__discount-text">
                  -{{ amountWithSymbol }}
                </div>
                <Icon
                  :name="showDiscountDetails ? 'sui_icon_more_up_12px_1' : 'sui_icon_more_down_12px_1'"
                  :is-responsive-name="true"
                  size="12px"
                  color="#959595"
                />
              </div>
            </div>
            <DiscountList
              v-show="showDiscountDetails"
              :language="language"
              :discount-list="couponDiscountList"
              :carts-list="checkout.results.carts.carts"
              type="coupon"
            />
          </div>
        </div>
        <template #reference>
          <div class="price-detail-discount__item-right price-detail-discount__right-hover">
            <div class="price-detail-discount__discount-text">
              -{{ amountWithSymbol }}
            </div>
            <Icon
              :name="showCounponDiscountDetails ? 'sui_icon_more_up_12px_1' : 'sui_icon_more_down_12px_1'"
              :is-responsive-name="true"
              size="12px"
              color="#959595"
            />
          </div>
        </template>
      </s-popover>
    </template>
    <template v-else>
      <div class="price-detail-discount__item-right">
        <div class="price-detail-discount__discount-text">
          -{{ amountWithSymbol }}
        </div>
      </div>
    </template>
  </div>
</template>


<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { isToLowerCase } from 'public/src/pages/checkout/config/tools.js'
import DiscountList from './discount_goods_info/DiscountList.vue'
import { Icon } from '@shein-aidc/icon-vue3'

let showDiscountDetails = ref(true)
let showCounponDiscountDetails = ref(false)


const store = useStore()
const language = computed(() => store.state.language)
const checkout = computed(() => store.state.checkout)
const promotionList = computed(() => store.state.promotionList || [])
const GB_cssRight = computed(() => store.state.locals?.GB_cssRight)
const caculateInfo = computed(() => checkout.value?.mall_caculate_info || {})
const coupon = computed(() => store.state.coupon)
const primeProductList = computed(() => store.state.primeProductList)
const usedPrimeIndex = computed(() => store.state.usedPrimeIndex)
const xtraProductInfo = computed(() => store.state.xtraProductInfo)

const propStyle = computed(() => {
  return GB_cssRight.value ? {
    width: '360px',
    paddingLeft: '6px'
  } : {
    width: '360px',
    paddingRight: '6px'
  }
})

const amountWithSymbol = computed(() => {
  return caculateInfo.value?.couponPrice?.amountWithSymbol
})

const discountTitle = computed(() => {
  return !!store.getters?.isSuggested ? language.value?.SHEIN_KEY_PC_30556 : language.value?.SHEIN_KEY_PC_14698
})

const couponDiscountList = computed(() => {
  let couponDiscountInfo = caculateInfo?.value?.coupon_discount_info || []
  // 会费券（付费会员58、超省卡59）商品图逻辑单独处理
  const virCouponList = coupon.value?.applyCouponsSuccess?.filter(_ => [58, 59].includes(+_.typeId)) || []
  if(!!virCouponList.length) {
    couponDiscountInfo = couponDiscountInfo.map(item => {
      const targetCoupon = virCouponList.find(_ => isToLowerCase(_.couponCode, item.coupon_code))
      const src = targetCoupon ? (targetCoupon.typeId == 58 ? primeProductList?.value?.[usedPrimeIndex.value]?.product_icon : xtraProductInfo?.value?.product_icon ) : ''

      return targetCoupon ? Object.assign(item, { couponTypeId: targetCoupon.typeId, src }) : item
    })
  }

  // 32 ab价伪装成券展示
  let promotionDiscountInfo = []
  const discountList = caculateInfo.value?.promotion_discount_info || []
  if(discountList) {
    let promotionInfo = promotionList.value?.find(_ => _.typeId == 32)
    const target = discountList.find(_ => _.typeId == 32)
    target && promotionDiscountInfo.push(Object.assign(target, { coupon_code: promotionInfo?.name }))
  }

  const detailList = !!promotionDiscountInfo.length ? couponDiscountInfo.concat(promotionDiscountInfo) : couponDiscountInfo

  // 多个券码的排序为抵扣金额大的优先
  return detailList.sort((a, b) => +b?.discount_price?.amount - +a?.discount_price?.amount)
})

const canShowDetail = computed(() => {
  const detail = couponDiscountList?.value || []
  // 会费券（付费会员58、超省卡59）
  const virCouponList = coupon.value?.applyCouponsSuccess?.filter(_ => [58, 59].includes(+_.typeId)) || []
  const target = detail.find(item => {
    const targetCoupon = virCouponList.find(_ => isToLowerCase(_.couponCode, item.coupon_code))
    
    return !item.discount_price?.amountWithSymbol || (targetCoupon ? !item.couponTypeId : !item.cart_ids?.length)
  })
  return !!detail.length && !target
})

const openPopover = () => {
  showCounponDiscountDetails.value = true
}

const closePopover = () => {
  showCounponDiscountDetails.value = false
  showDiscountDetails.value = true
}
</script>
<style lang="less" scoped>
.price-detail-discount {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 13px;
  color: var(---sui_color_gray_dark1, #000);
  &__item-right {
    i {
      color: @sui_color_gray_light1;
    }
  }
  &__right-hover {
    cursor: pointer;
  }
  &__discount-text {
    display: inline-block;
    color: var(---sui_color_discount, #FA6338);
  }
}
.checkout-summary-discount__popover-content {
  .checkout-summary-discount__popover-content-body {
    max-height: 312px;
    overflow-y: scroll;
    .padding-r(10px);
  }
  .checkout-summary-discount__popover-content-detail {
    font-size: 13px;
    padding-bottom: 12px;
    color: @sui_color_gray_dark1;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
  }
}
</style>
<style lang="less">
.checkout-summary-discount__popover {
  .sui-popover__content {
    max-width: unset;
  }
}
</style>

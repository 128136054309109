<template>
  <div class="pick-gift" v-if="pickGiftType">
    <div class="pick-gift-left">
      <h6 class="pick-gift-title">{{ language.SHEIN_KEY_PC_16033 }}</h6>
      <p class="pick-gift-desc">
        {{
          pickGiftType == 2
            ? language.SHEIN_KEY_PC_18191
            : language.SHEIN_KEY_PC_17968
        }}
      </p>
    </div>
    <div class="pick-gift-right" @click="pick">
      <span v-if="pickGiftType == 2">
        {{ language.SHEIN_KEY_PC_15969 }}
        <sui_icon_more_right_12px
          name="sui_icon_more_right_12px"
          size="12px"
        />
      </span>
      <s-button :type="['H28PX', 'primary']" v-else>{{
        language.SHEIN_KEY_PC_15968
      }}</s-button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { sui_icon_more_right_12px } from '@shein-aidc/icon-vue3'

export default {
  name: 'PickCouponGift',
  components: {
    sui_icon_more_right_12px,
  },
  props: {
    language: {
      type: Object,
      default: () => {
        return {}
      },
    },
    carts: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  computed: {
    pickGiftType: function () {
      return this.carts.find((item) => item.promotion_type_id == '1000') ? 2 : 1
    },
  },
  methods: {
    ...mapMutations(['updateCheckoutState']),
    pick: function () {
      this.updateCheckoutState({
        key: 'couponGiftsAddItemShow',
        val: true,
      })
    },
  },
}
</script>

import { mallMerge } from '../config/tools'

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['checkout', 'mallInsuranceCheck', 'coupon', 'language']),
    showInsurancePrice() {
      // <!-- isShippingInsuranceChecked: 1 运费不管是否为0展示运费险  -->
      const { mall_list = [] } = this.checkout?.mall_caculate_info || {}
      let useInsurance = mallMerge(mall_list, this.mallInsuranceCheck)

      return useInsurance.filter(
        (mall) =>
          mall.use_insurance == 1 ||
          (mall.insurance_type != 1 && mall.isShippingInsuranceChecked == 1)
      )
    },
    showDiscount() {
      return this.checkout?.mall_caculate_info?.couponPrice?.amountWithSymbol && +this.checkout?.mall_caculate_info?.couponPrice?.amount != 0
    },
    discountInfoPrice() {
      return this.checkout?.mall_caculate_info?.couponPrice?.amount || ''
    },
    showReturnInsurance() {
      // <!-- mallInsuranceCheck和产品确认过，如果有退货险就都是退货险，否则都是运费险 -->
      let tip = this.language.SHEIN_KEY_PC_16676
      if (this.mallInsuranceCheck?.[0]?.insurance_type == 1) tip = this.language.SHEIN_KEY_PC_17100
      if (this.mallInsuranceCheck?.[0]?.insurance_type == 3) tip = this.language.SHEIN_KEY_PC_23901
      if (this.mallInsuranceCheck?.[0]?.insurance_type == 4) tip = this.language.SHEIN_KEY_PC_24380
      return tip
    },
    // 站点mall 多mall站样式按多mall来.
    isSiteMallList() {
      return this.checkout?.results?.mallInfo?.mallInfoForSite?.length > 1
    },
  },
}

<template>
  <div class="store-address-card">
    <div 
      v-if="!smallAddress"
      class="store-address-card__header" 
      tabindex="0"
    >
      <h4 class="store-address-card__title">
        {{ language.SHEIN_KEY_PC_16785 }}
      </h4>
    </div>

    <div
      v-if="storeData && storeData.status == 1"
      class="store-address-card__body"
    >
      <div class="store-address-item">
        <div class="store-address-item__content">
          <div 
            class="store-address-item__header" 
            tabindex="0"
          >
            <h5 class="store-address-item__name">
              <template v-if="storeData.countryId == '209'">
                {{ storeData.lname }} {{ storeData.fname }}
              </template>
              <template v-else>
                {{ storeData.fname }} {{ storeData.lname }}
              </template>
            </h5>
            <div class="store-address-item__phone">
              {{ storeData.tel }}
            </div>
          </div>

          <p 
            class="store-address-item__address" 
            tabindex="0"
          >
            <template v-if="storeData.countryId == 209">
              {{ storeData.storeName }}
              {{ storeData.storeType == 1 ? '(7-ELEVEN)' : '(全家便利商店)' }}
            </template>
            <template v-else>
              {{ storeData.storeName }} {{ storeData.address1 }}
              {{ storeData.address2 }}
            </template>
          </p>

          <p 
            class="store-address-item__address" 
            tabindex="0"
          >
            <template v-if="storeData.countryId == 209">
              {{ storeData.address1 }} {{ storeData.address2 }}
            </template>
            {{ storeData.street }} {{ storeData.district }}
            {{ storeData.city }} {{ storeData.state }}
            {{ storeData.countryName }}
            {{ storeData.countryId != 209 ? storeData.postcode : '' }}

            <s-label
              class="store-address-item__tag"
              type="neutral"
              tabindex="0"
            >
              {{ language.SHEIN_KEY_PC_16785 }}
            </s-label>
          </p>
          <div
            v-if="sensitiveNote && !smallAddress"
            class="store-address-item__error-tips"
            tabindex="0"
          >
            {{ sensitiveNote }}
          </div>
        </div>

        <s-button
          v-if="!smallAddress"
          :type="['H28PX']"
          class="store-address-item__edit"
          @click="clickEditStoreAddress"
        >
          {{ language.SHEIN_KEY_PC_17140 }}
        </s-button>
      </div>

      <p
        v-if="
          checkout.default_address.countryId == '209' && status.adrTitleTipsShow && !smallAddress
        "
        class="address-title-tips-screen-efficiency"
        v-html="adrNameVerifiedTitleTip"
      ></p>
    </div>

    <div 
      v-else 
      class="store-address-add-btn" 
      @click="clickAddStoreAddress"
    >
      +{{ language.SHEIN_KEY_PC_16789 }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'StoreAddressCard',
  emits: ['click-edit-store-address'],
  props: {
    storeData: {
      type: [Object, String],
      default: () => {}
    },
    checkout: Object,
    status: Object,
    adrNameVerifiedTitleTip: {
      type: String,
      default: ''
    },
    defaultShipping: Array,
    language: {
      type: Object,
      default: () => {}
    },
    smallAddress: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    sensitiveNote () {
      const sensitiveRuleVoList = this.storeData?.sensitiveRuleVoList || []
      if (!sensitiveRuleVoList.length) {
        return ''
      }
      return sensitiveRuleVoList?.[0]?.rule_content || ''
    }
  },
  methods: {
    clickAddStoreAddress() {
      window.checkoutEventCenter.emit('click-edit-store-address', { type: 'add' })
    },
    clickEditStoreAddress() {
      window.checkoutEventCenter.emit('click-edit-store-address', { type: 'edit' })
    }
  }
}
</script>

<style lang="less">
.border-left() {
  position: relative;
  border: 1px solid #e5e5e5;
  border-left: none;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-image: url('/she_dist/images/border2-6cd4ecac71.png');
  }
}

.store-address-card {
  padding: 16px;
  color: @sui_color_gray_dark1;
  margin-bottom: 10px;
  background: #fff;
  margin-bottom: 10px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  &__title {
    font-size: 20px;
    line-height: 28px;
    font-family: Arial Black;
    text-transform: capitalize;

    & when (@IS_RW) {
      font-family: Adieu;
    }
  }

  &__body {
    padding: 12px 12px 12px 16px;
    .border-left();
  }

  .address-title-tips-screen-efficiency {
    margin-top: 11px;
    font-size: 12px;
    line-height: 14px;
    padding: 10px;
    color: #666;
    background: #f6f6f6;

    a {
      color: #1860a7;
    }
  }
}

.store-address-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-all;

  &__content {
    flex: auto;
    overflow: hidden;
    margin-right: 12px;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__name {
    font-size: 16px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
  }

  &__phone {
    font-size: 16px;
    line-height: 18px;
    white-space: nowrap;
    margin-left: 4px;
  }

  &__address {
    font-size: 13px;
    line-height: 15px;
    margin-top: 4px;
  }

  &__tag {
    text-transform: capitalize;
    margin-left: 4px;
  }

  &__edit {
    flex-shrink: 0;
  }
  &__error-tips {
    font-size: 13px;
    line-height: 15px;
    color: @sui_color_unusual;
    margin-top: 11px;
  }
}

.store-address-add-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  .border-left();
  cursor: pointer;
}
</style>

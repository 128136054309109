import { ref, onMounted } from 'vue'
import schttp from 'public/src/services/schttp'
import { autoPrimeProducfilter } from 'public/src/pages/checkout/utils.js'
import { useMapState, useMapMutation as useMapMutations, useMapGetters, useMapActions } from 'public/src/pages/store_pages/store/mapHook'

export default () => {
  const { checkout, primeProductList, usedPrimeCode, selectdPrimeIndex } = useMapState(['checkout', 'price', 'usedPrimeIndex', 'showPrimeDetail', 'lowPrimeProduct', 'usedPrimeCode', 'selectdPrimeIndex', 'primePopFrom', 'primeProductList'])
  const { upDatePrimeSceneStatus, assignState, changeParamsStatus, updateSelectedPrime } = useMapMutations(['upDatePrimeSceneStatus', 'changeParamsStatus', 'assignState', 'updateSelectedPrime'])
  const { showXtra } = useMapGetters([ 'showXtra' ])
  const { updateXtraPaymentsRight, getPrimeRightCouponInfo } = useMapActions(['updateXtraPaymentsRight', 'getPrimeRightCouponInfo'])  

  const eventInfo = ref({})

  const queryMemberBuyProduct = async () => {
    await schttp({
      method: 'POST',
      url: `/api/checkout/memberBuyProduct/get`,
      data: {
        scene: 1,
      },
    }).then((res) => {
      if (res.code == 0 && res.info) {
        autoPrimePackagefilter({ product_info_list: res.info?.product_info_list })
        // 会费券更新数据
        upDatePrimeSceneStatus()
      } else {
        assignState({ primeProductList: [] })
      }
      // club 包到手价最低包
      assignState({
        lowPrimeProduct: JSON.parse(JSON.stringify(primeProductList.value.filter(item => +item.product_price_info?.special_price > 0) || [])).sort((a, b) =>  +a.product_price_info?.special_price - +b.product_price_info?.special_price)[0] || {},
      })
    })
  }
  const getPrimeSavingSummary = (params) => {
    return new Promise(resolve => {
      schttp({
        method: 'POST',
        url: '/api/prime/savingsSummary/get',
        data: params
      }).then(res => {
        if(res.code == 0) {
          resolve(res.info)
          return
        }
        resolve({})
      }).catch(() => {
        resolve({})
      })
    })
  }
  // abt/没有快捷/置灰/不满足金额门槛/业务分流 => 自动续费包过滤掉
  const autoPrimePackagefilter = ({ product_info_list } = { product_info_list: null }) => {
    const paymentMethods = checkout.value?.results?.paymentMethods || []
    const primeProductList = autoPrimeProducfilter( { 
      sheinClubRenew: checkout.value?.checkoutBFFAbtInfo?.sheinClubRenew?.param?.sheinClubRenewStatus || '', 
      primeProductInfo: { ...checkout.value.results?.primeProductInfo, primeProductList: product_info_list || checkout.value.results?.primeProductInfo.primeProductList || {} }, 
      paymentMethods 
    } )
    checkout.value?.results?.primeProductInfo && Object.assign(checkout.value?.results?.primeProductInfo, { primeProductList })
    assignState({ primeProductList })
  }
  // 付费会员价格接口调用失败，取消/勾选,还原选择
  const handlePrimeErrCb = ({ flag }) => {
    if (flag) {
      assignState({ usedPrimeIndex: -1, usedPrimeCode: '' })
      changeParamsStatus({
        type: 'caculateData',
        params: { prime_product_code: usedPrimeCode.value }
      })

      changePrimeUpdateCalcu({ primeSeleted: false, alreadyReset: true })
    } else { 
      assignState({ usedPrimeIndex: selectdPrimeIndex.value, usedPrimeCode: primeProductList.value[selectdPrimeIndex.value].product_code, })

      changeParamsStatus({
        type: 'caculateData',
        params: { prime_product_code: usedPrimeCode.value }
      })
    }
  }
  // 更新付费会员包信息
  const updatePrime = async () => {
    await queryMemberBuyProduct()
    let selectdPrimeIndex = -1
    if (usedPrimeCode.value) {
      const usedPrimeIndex = primeProductList.value.findIndex(
        (item) => item.product_code == usedPrimeCode.value
      )
      assignState({ usedPrimeIndex: usedPrimeIndex })
      if (usedPrimeIndex == -1) {
        // 重置初始状态
        assignState({ usedPrimeCode: '' })
        selectdPrimeIndex = primeProductList.value.findIndex( (item) => +item.is_recommend)
      
        changePrimeUpdateCalcu({ primeSeleted: false })
      } else {
        selectdPrimeIndex = usedPrimeIndex
      }
    } else {
      selectdPrimeIndex = primeProductList.value.findIndex(
        (item) => +item.is_recommend
      )
    }
    updateSelectedPrime({ index: selectdPrimeIndex == -1 ? 0 : selectdPrimeIndex, isUpdateRightList: true })
  }
  // 付费会员已省金额
  const updatePrimeSavingSummary = async () => {
    const params = { query_dimension: 1 }
    const primeSavingSummary = await getPrimeSavingSummary(params)
    assignState({ primeSavingSummary })
  }
  // 勾选/取消勾选，触发更新计价，支付方式
  const changePrimeUpdateCalcu = ({ primeSeleted, alreadyReset, cancelAutoUse, recommendProductCode }) => {
    let params = {
      prime_product_code: usedPrimeCode.value,
      trial_prime_product_code: ''
    }
    if(!primeSeleted){
      params.prime_product_code = ''
      params.trial_prime_product_code = recommendProductCode
    }
    changeParamsStatus({
      type: 'caculateData', params
    })

    // 更新支付方式
    eventInfo.value.updatePayment(checkout.value.default_address)
    // 如果可以购买超省卡，更改付费会员包需要重新调用超省卡试算
    if(showXtra.value) updateXtraPaymentsRight()

    if (primeSeleted) { 
      // 是否可以自动用券
      const canAutoUsePrime = eventInfo.value.handleAutoUsePrimeCoupon(cancelAutoUse)
      assignState({ canAutoUsePrime: canAutoUsePrime })

    } else { 
      // 取消选中产品包过滤之前自动使用的会员优惠券
      eventInfo.value.filterCouponParams({ scene: 'prime' })
    }

    // 更新包券权益
    getPrimeRightCouponInfo()

    // couponMixin getUpdateCoupon 更新券 => 更新计价 orign-update-total (/api/coupon/getCouponListForOrderStore/get)
    eventInfo.value.getUpdateCoupon({}, { isPrime: true, cb: opt => {
      if (!opt && !alreadyReset) handlePrimeErrCb({ flag: primeSeleted })
    } })
  }
  // 更新付费会员信息和付费会员已省金额
  const changePrimeInfo = () => {
    updatePrime()
    updatePrimeSavingSummary()
  }

  const initPrimeModule = ({ updatePayment, handleAutoUsePrimeCoupon, filterCouponParams, getUpdateCoupon }) => {
    eventInfo.value.updatePayment = updatePayment
    eventInfo.value.handleAutoUsePrimeCoupon = handleAutoUsePrimeCoupon
    eventInfo.value.filterCouponParams = filterCouponParams
    eventInfo.value.getUpdateCoupon = getUpdateCoupon
  }


  onMounted(() => {
    // 会费券更新数据
    upDatePrimeSceneStatus()

    // 监听触发付费会员计价
    window.checkoutEventCenter.on(
      'change-prime-calcu',
      ({ primeSeleted, recommendProductCode  }) => {
        changePrimeUpdateCalcu({ primeSeleted, recommendProductCode })
      }
    )
  })

  return {
    changePrimeInfo,
    autoPrimePackagefilter,
    changePrimeUpdateCalcu,
    initPrimeModule
  }
}

import schttp from 'public/src/services/schttp'
import { debuggerLog } from './utils'

const getBaseParams = () => ({
  startTime: 0,
  endTime: 0,
  descriptions: '',
  neurStep: '',
  neurPayId: '',
  callUrl: '',
  nextHttpCode: 0,
  nextResponse: '',
  billno: '',
  paymentMethod: ''
})

/**
 * 
 *
 * @class KafkaReport
 */
class KafkaReport {

  #tokenPayReportList = [
    'forterDeviceFingerprintID',
    'publicKeyObjKey',
    'channelSdkOnLoad',
    'encryptValid'
  ]

  tokenPayReportMap = new Map(this.#tokenPayReportList.map(key => [key, NaN]))

  kafkaInfo = getBaseParams()

  constructor() {
    this.init()
  }

  #getKafkaDescriptions = (reportMap = new Map()) => {
    let descriptions = '支付开始\n'
    reportMap.forEach((value) => {
      if (value) {
        descriptions += `${value}\n`
      }
    })
    return descriptions
  }

  sendData({ res = {}, resStatus } = {}) {
    const kafkaInfo = this.kafkaInfo
    kafkaInfo.nextResponse = JSON.stringify(res || {})
    kafkaInfo.nextHttpCode = resStatus
    // kafkaInfo.kafkaAddDes({ formData, res })
    kafkaInfo.descriptions = this.#getKafkaDescriptions(this.tokenPayReportMap)
    kafkaInfo.endTime = +new Date()

    let data = {
      neurStep: kafkaInfo.neurStep,
      neurPayId: kafkaInfo.neurPayId,
      myName: 'pc-client',
      startTime: kafkaInfo.startTime,
      endTime: kafkaInfo.endTime,
      costTime: kafkaInfo.endTime - kafkaInfo.startTime,
      myStatus: 1,
      descriptions: kafkaInfo.descriptions,
      callUrl: kafkaInfo.callUrl,
      nextStatus: kafkaInfo.nextHttpCode == 200 ? 1 : 2,
      nextHttpCode: kafkaInfo.nextHttpCode,
      nextResponse: kafkaInfo.nextResponse,
      billno: kafkaInfo.billno,
      paymentMethod: kafkaInfo.paymentMethod,
      siteUid: gbCommonInfo?.SiteUID,
      extend: kafkaInfo.nextHttpCode == 0 ? 'timeout' : ''
    }
    schttp({
      url: '/api/pay/sendKafka/create',
      method: 'POST',
      data
    })
    this.kafkaInfo.descriptions = ''
  }

  initTokenPayReport() {
    // this.#tokenPayReportList.forEach(key => {
    //   this.#tokenPayReportMap.set(key, NaN)
    // })

  }

  init() {
    debuggerLog('kafkaReport init')
  }

  addTokenPayReport(key, value) {
    debuggerLog('addTokenPayReport', { key, value })
    key && this.tokenPayReportMap?.set?.(key, value)
    // this.#tokenPayReportList.push(data)
  }

  updateKafkaData(params = {}) {
    const baseParams = getBaseParams()
    // 判断params的key是否都在baseParams对象内
    for (let key in params) {
      if (baseParams.hasOwnProperty(key)) {
        Object.assign(this.kafkaInfo, { [key]: params[key] })
      }
    }
  }
}

export default new KafkaReport()

<template>
  <div class="coupon-popover">
    <div 
      class="coupon-popover__coupon j-activity-left"
      :class="{
        'coupon-popover__coupon_orange': isPriceCoupon,
        'coupon-popover__coupon_green': isShippingCoupon,
      }"
    >
      <!-- 券面额 -->
      <div 
        ref="couponPopoverDiscountRef"
        class="coupon-popover__discount"
        v-html="couponDiscountText"
      >
      </div>
    </div>
    <Tips 
      :language="language"
      :description="couponDiscountDesc"
      :icon="{}"
      :bubble-copy-writing-by-abt="bubbleCopyWritingByAbt"
    />
  </div>
</template>

<script>
import { template } from '@shein/common-function'

import Tips from './Tips.vue'

const { GB_cssRight = false } = gbCommonInfo

const CouponTypeEnum = {
  PartFree: 'PartFreeCoupon', // 部分免邮券
  FreeShipping: 'FreeShippingCoupon', // 免邮券
  ImmediatelyDiscount: 'ImmediatelyDiscountCoupon', // 立减券
  Discount: 'DiscountCoupon', // 折扣券
}

export default {
  name: 'SaverCardPopover',
  components: {
    Tips,
  },
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    saverCardPromotion: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      couponType: '',
      couponDiscountText: '',
      couponDiscountDesc: '',
    }
  },
  computed: {
    isPriceCoupon() {
      return [
        CouponTypeEnum.ImmediatelyDiscount,
        CouponTypeEnum.Discount,
      ].includes(this.couponType)
    },
    isShippingCoupon() {
      return [
        CouponTypeEnum.FreeShipping,
        CouponTypeEnum.PartFree,
      ].includes(this.couponType)
    },
  },
  watch: {
    saverCardPromotion: {
      handler() {
        this.initInfo()
      },
      immediate: true,
      deep: true
    },
  },
  methods: {
    template,
    initInfo() {
      const counponOrder = {
        [CouponTypeEnum.FreeShipping]: 1,
        [CouponTypeEnum.Discount]: 2,
        [CouponTypeEnum.ImmediatelyDiscount]: 3,
        [CouponTypeEnum.PartFree]: 4 
      }

      if (this.saverCardPromotion?.length) {
        const newSaverCardPromotion = [...this.saverCardPromotion]
        
        newSaverCardPromotion.sort((a, b) => {
          return counponOrder[a.type] - counponOrder[b.type]
        })
        const { type, originInfo, usedCoupon } = newSaverCardPromotion?.[0]

        this.couponType = type
        this.getCouponDiscount(originInfo, usedCoupon)
      }
    },
    /**
     * @description: 获取劵的折扣价格
     * @param {*} couponInfo 当前需要显示的券信息
     * @return {*}
     */    
    getCouponDiscount(originInfo = {}, usedCoupon = {}) {
      if (this.couponType == CouponTypeEnum.FreeShipping) {
        this.couponDiscountText = this.language.SHEIN_KEY_PC_15911
        this.couponDiscountDesc = this.template(`<em>${this.language.SHEIN_KEY_PC_31438}</em>`, this.language.SHEIN_KEY_PC_31427)
      } else {
        const { couponRangeId } = usedCoupon
        const { other_coupon_rule = [], realDiscountPrice = {} } = originInfo
        const rule = other_coupon_rule.find(rule => +rule.coupon_range_id === +couponRangeId)
        let discount = ''

        // 折扣券
        if (this.couponType == CouponTypeEnum.Discount) {
          discount = GB_cssRight ? `%${rule.newPrice.amount}` :  `${rule.newPrice.amount}%`
        } else {
          discount = rule.newPrice.amountWithSymbol
        }
        
        this.couponDiscountText = this.template(discount, this.language.SHEIN_KEY_PC_15957)

        // 部分免邮券需要特殊处理
        let discountText = ''
        if (this.couponType == CouponTypeEnum.PartFree) {
          discountText = `<em>${this.template(discount, this.language.SHEIN_KEY_PC_31437)}</em>`
        } else {
          discountText = `<em>${this.template(realDiscountPrice?.amountWithSymbol, this.language.SHEIN_KEY_PC_31437)}</em>`
        }
        
        this.couponDiscountDesc = this.template(discountText, this.language.SHEIN_KEY_PC_31427)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.coupon-popover {
  display: flex;
  align-items: center;
  font-family: SF UI Text;
  &__coupon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 76px;
    height: 41px;
    flex-shrink: 0;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1px;
    position: relative;
    border: 0.5px solid rgba(194, 60, 22, 0.16);
    font-size: 14px;
    font-weight: 700;
    background-size: 100% 100%;

    &_orange {
      color: #F82854;
      background-image: url('//img.ltwebstatic.com/images3_ccc/2024/07/05/28/172016807152f869ff6feae34be41c5ad484014c12.webp');
    }

    &_green {
      color: #169E00;
      background-image: url('//img.ltwebstatic.com/images3_ccc/2024/07/05/99/1720168106129f92c0a8ef925c35dcbac06bc8a24f.webp');
    }
  }

  &__discount {
    font-size: 12px;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: center;
  }
}
</style>

<template>
  <!-- 积分文案弹窗 -->
  <s-popover 
    placemen="bottom-end" 
    trigger="hover"
    :prop-style="{width: '266px', boxShadow: '0px 2px 10px 2px rgba(0,0,0,0.1)', padding: '10px', lineHeight: '18px'}"
  >
    <div v-html="pointsTip"></div>

    <template #reference>
      <Icon
        name="sui_icon_doubt_16px_1"
        size="16px"
        color="#959595"
        class="tips-icon"
      />
    </template>
  </s-popover>
</template>
<script setup>
import { Icon } from '@shein-aidc/icon-vue3'
import { useStore } from 'vuex'
import { computed } from 'vue'
import { template } from '@shein/common-function'

const store = useStore()
const language = computed(() => store.state.language)
const caculateInfo = computed(() => store.state.checkout.mall_caculate_info)


/* 积分提示文案1 */
const pointsTip = computed(() => {
  // const { totalPointMultiple } = checkout.value?.results.carts.data
  // 多倍积分 totalPointMultiple
  // 普通订单, 普通订单 + 双倍积分, language.value.SHEIN_KEY_PC_32830
  // 普通订单+会员, 普通订单+双倍积分+会员, 普通订单+双倍积分+会员+联名卡 language.value.SHEIN_KEY_PC_32831

  // 多倍积分
  // const multiple = +totalPointMultiple > 1 && !!+getDoublePoints
  // prime_points 付费会员
  if(caculateInfo.value?.prime_points){
    // if (!caculateInfo.value?.point_grant_amount_info?.cycle_grant_amount) return ''
    const info = caculateInfo.value?.point_grant_amount_info
    const oldParams = [info?.cycle_used_grant_amount || 0, info?.cycle_grant_amount || 0]
    const maxPoints = info?.single_order_reward_points_limit || info?.cycle_grant_amount
    return template(...oldParams, maxPoints || 0, caculateInfo.value?.prime_points, language.value.SHEIN_KEY_PC_32831)
  }
  
  return language.value.SHEIN_KEY_PC_32830
})

</script>
<style lang="less" scoped>
.tips-icon{
  cursor: pointer;
}
</style>

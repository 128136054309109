<template>
  <s-popover
    class="insurance-popover__wrap"
    placemen="bottom"
    trigger="hover"
    @opened="handlePopOpen"
  >
    <p
      @click="insuranceContentClick"
      v-html="insuranceDescription"
    ></p>
    <template #reference>
      <Icon
        name="sui_icon_doubt_14px_1"
        color="#666666"
        size="15px"
      />
    </template>
  </s-popover>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { Icon } from '@shein-aidc/icon-vue3'

const { langPath } = gbCommonInfo

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

export default {
  name: 'InsurancePopover',
  components: {
    Icon,
  },
  props: {
    description: {
      type: String,
      default: ''
    },
    showGutter: {
      type: Boolean,
      default: true
    },
    insurancePopAnalysis: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    insuranceDescription() {
      if (!this.description) return ''
      //文章页<a href="{}xx">here</a> 替换成 <a href="us/article-xx.html">here</a>
      const regex = /{}([^"'}]*)/g
      const desc = this.description.replace(regex, (_match, p1) => {
        const article = (p1 || '').trim()
        return `${langPath}/article-${article}.html`
      })
      return desc.replace(/\s/g, ' ')
    }
  },
  methods: {
    // 文章页按钮点击
    insuranceContentClick(e) {
      if (e.target.tagName === 'A') {
        this.$emit('article-change')
      }
    },
    handlePopOpen() {
      if(!this.insurancePopAnalysis.isReport) return

      daEventCenter.triggerNotice({
        daId: this.insurancePopAnalysis.id,
        extraData: Object.assign({}, this.insurancePopAnalysis.params)
      })
    },
  },
  emits: ['article-change']
}
</script>

<style lang="less" scoped>
.insurance-popover__wrap {
  i {
    color: @sui_color_gray_dark2;
  }
}
</style>

import { sha256 } from 'js-sha256'
import MD5 from 'md5'

const paramsKey = ['cardno', 'expire_month', 'expire_year', 'secure_code']
//加密后的key
//!!! 按照 卡号，月份，年份，cvv 的排序
const keyTokey = ['cardNoHash', 'cardExpireMonthHash', 'cardExpireYearHash', 'cvvHash']
// 对加密值进行签名后的key
//!!! 按照 卡号，月份，年份，cvv 的排序
const keyToSign = ['cardNoCheck', 'cardExpireMonthCheck', 'cardExpireYearCheck', 'cvvCheck']

// cvv格式校验
export default function encryptParams({
  sourceData = {},
  publicKeyObj = {},
  encryptObj = {}
}) {
  let obj = { ...sourceData }
  let paymentHash = ''
  let safeAccountHash = ''
  paramsKey.some((key, index) => {
    let hashKey = keyTokey[index]
    let signKey = keyToSign[index]
    let val = '' + obj[key]

    //月份补零，比如 06
    if (key == 'expire_month' && +val < 10) {
      paymentHash += '0' + (+val)
      //年份补全，比如 2023
    } else if (key == 'expire_year' && val.length == 2) {
      paymentHash += '20' + (+val)
    } else {
      paymentHash += val
    }

    //二次加密值（给卡号生成唯一id）
    if (key === 'cardno' && val) {
      let accountHash = sha256(val)
      let saltPlaintext = accountHash.substring(2, 14)
      let salt = sha256(saltPlaintext)
      safeAccountHash = sha256(salt + accountHash)
      obj.safeAccountHash = safeAccountHash
    }

    if (encryptObj.encrypt(val)) {
      obj[hashKey] = encryptObj.encrypt(val)
      obj[key] = ''
      try {
        obj[signKey] = MD5(publicKeyObj.pub_id + obj[hashKey] + publicKeyObj.pub_id)
      } catch (e) {
        console.log('md5 fail')
        obj[signKey] = ''
      }
    } else {
      return true
    }
  })

  //上送是否选中过期年月，固定为FALSE
  obj.isExpiredCard = 'FALSE'

  if (paymentHash) {
    obj.paymentHash = sha256(paymentHash)
  }
  return obj
}

<template>
  <s-dialog
    v-model:visible="visibleDialog"
    :show-close="true"
    :append-to-body="true"
    class="bin-freeze-fail__dialog"
    @close-from-icon="handleClose"
    @close-from-mask="handleClose"
  >
    <div class="content">
      <div class="title">
        {{ language.SHEIN_KEY_PC_30615 }}
      </div>
      <div class="bin-discount">
        {{ language.SHEIN_KEY_PC_25756 }}
      </div>
    </div>
    <template #footer>
      <s-button-group width="100%">
        <s-button-group-item
          :type="['primary']"
          @click="emits('click-continue')"
        >
          {{ language.SHEIN_KEY_PC_25779 }}
        </s-button-group-item>
        <s-button-group-item
          @click="emits('click-cancel')"
        >
          {{ language.SHEIN_KEY_PC_25780 }}
        </s-button-group-item>
      </s-button-group>
    </template>
  </s-dialog>
</template>

<script setup>
import { ref, watch } from 'vue'
import { SDialog } from '@shein-aidc/sui-dialog'
import { SButtonGroup } from '@shein-aidc/sui-button-group'
import { SButtonGroupItem } from '@shein-aidc/sui-button-group-item'

const emits = defineEmits(['close', 'click-cancel', 'click-continue', 'update:visible'])

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  language: {
    type: Object,
    default: () => {}
  }
})

const visibleDialog = ref(props.visible)

watch(() => props.visible, (val) => {
  visibleDialog.value = val
})

const handleClose = () => {
  emits('update:visible', false)
  emits('close')
}
</script>

<style lang="less" scoped>
.bin-freeze-fail__dialog {
  .title {
    color: black;
    font-size: 16px;
    font-family: SF UI Text;
    font-weight: 700;
    word-wrap: break-word;
    margin-bottom: 8px;
    text-align: center;
  }
  .bin-discount {
    color: #666666;
    font-size: 16px;
    font-family: Arial;
    font-weight: 400;
    line-height: 17px;
    word-wrap: break-word;
    text-align: center;
  }
}
</style>

<template>
  <div
    v-if="cartsData.length"
    class="checkout-shopping-mall"
  >
    <checkout-cart 
      :class="{'is-single': !checkout.default_address.addressId}"
      :carts-info="cartsData" 
      :carts-mall-info="cartsMallInfo" 
      :quick-ship-info="quickShipInfo" 
      :mall-code="mallCode"
      @click-insurance="clickInsurance"
    />

    <shipping-options
      :mall-code="mallCode"
      :shipping-methods="shippingMethods"
      :carts-data="cartsData"
      :quick-ship-info="quickShipInfo"
      @click-shipping="clickShipping"
      @click-insurance="clickInsurance"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import checkoutMixin from '../checkoutMixin.js'

import shippingOptions from 'public/src/pages/checkout/components/shipping_method/ShippingOptions.vue'
import CheckoutCart from './CheckoutCart'


export default {
  name: 'ShoppingMall',
  components: {
    shippingOptions,
    CheckoutCart
  },
  mixins: [checkoutMixin],
  props: {
    mallCode: {
      type: [String, Number],
      default: '',
    },
    cartsData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState([
      'mallShippingMethods',
      'checkout',
    ]),
    shippingMethods() {
      const { mall_list = [] } = this.mallShippingMethods || {}
      const filterShip =
        mall_list.find((item) => item.mall_code == this.mallCode) || {}
      return filterShip?.shipping_methods || []
    },
    quickShipInfo() {
      const { mall_list = [] } = this.mallShippingMethods || {}
      const filterShip =  mall_list?.find(item => item.mall_code == this.mallCode) || {}
      return { desc_quick_ship_time: filterShip?.desc_quick_ship_time || '', quick_ship_time: filterShip?.quick_ship_time || '' }
    },
    cartsMallInfo() {
      return (
        this.checkout?.results?.carts?.mall_list?.find(
          (item) => item.mall_code == this.mallCode
        ) || {}
      )
    },
  },
  methods: {
    clickShipping({ item, index, mallCode, transport_type }) {
      this.$emit('click-shipping', {
        item,
        index,
        mallCode,
        transport_type,
      })
    },
    clickInsurance(mallCode) {
      this.$emit('click-insurance', mallCode)
    },
  },
  emits: ['click-shipping', 'click-insurance'],
}
</script>

<style lang="less" scoped>
.checkout-shopping-mall {
  background: #fff;
}
</style>

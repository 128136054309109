import { computed } from 'vue'

import { template } from '@shein/common-function'
import { XtraCouponType } from '../shared/config.js'

export default (props) => {
  const { language } = props

  const percentFormat = (num) => {
    const newNum = (num * 100) / 100
    const percentNum = newNum + '%'
    return percentNum
  }

  // 优惠券类型
  const couponType = computed(() => {
    const { applyFor } = props.coupon
    return [5, 6, 9].includes(+applyFor) ? 
      XtraCouponType.Shipping : 
      XtraCouponType.Product
  })

  // 优惠券面额
  const discount = computed(() => {
    const { applyFor, shippingDiscountType, ruleDimension, rule } = props.coupon
    if ([5, 6, 9].includes(+applyFor)) {
      if (shippingDiscountType == '1') {
        //  1部分抵扣
        if (ruleDimension == '1') {
          return template(
            rule[0].valueAmount.amountWithSymbol,
            language.SHEIN_KEY_PC_15957
          )
        } else if ([2, 5].includes(+ruleDimension)) {
          return template(
            percentFormat(rule[0].value),
            language.SHEIN_KEY_PC_15957
          )
        }
      } else if (shippingDiscountType == '0') {
        // 0全免
        return language.SHEIN_KEY_PC_15911
      }
    } else if (ruleDimension == '1') {
      // 减元
      return template(
        rule[0].valueAmount.amountWithSymbol,
        language.SHEIN_KEY_PC_15957
      )
    } else if ([2, 5].includes(+ruleDimension)) {
      return template(
        percentFormat(rule[0].value),
        language.SHEIN_KEY_PC_15957
      )
    }
    return ''
  })

  // 优惠券门槛信息
  const rule = computed(() => {
    return template(
      props.coupon.rule[0]?.minAmount?.amountWithSymbol,
      language.SHEIN_KEY_PC_30162
    )
  })

  return { couponType, discount, rule }
}

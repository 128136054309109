<template>
  <!-- 违禁品购买提示，比如：烟草 -->
  <div class="contraband-tips-wrapper">
    <div class="contraband-tips-wrapper__tips">
      <p class="contraband-tips-wrapper__text">
        {{ language.SHEIN_KEY_PC_30311 }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()
const language = computed(() => store.state.language)

</script>
<style lang="less" scoped>
.contraband-tips-wrapper {
  width: 100%;
  padding: 16px;
  background: var(---sui_color_prompt_common, #FFF);
  &__tips {
    padding: 8px;
    background: var(---sui_color_prompt_common, #EBF4FF);
    color: var(---sui_color_brand, #000);
    font-size: 12px;
  }
  &__text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>

<template>
  <div
    @mouseenter="showSwiperButton = true"
    @mouseleave="showSwiperButton = false"
    @click.stop="clickDetails"
  >
    <swiper-container
      ref="xtraListRef"
      init="false"
      class="xtra-list"
      :dir="GB_cssRight ? 'rtl' : 'ltr'"
    >
      <swiper-slide
        v-for="(coupon, index) in displayedCouponList.slice(0, entranceDisplayedNum)"
        :key="index"
        class="xtra-list__item"
      >
        <XtraCouponItem 
          :coupon="coupon"
        />
      </swiper-slide>

      <div
        v-if="displayedCouponList.length > entranceDisplayedNum"
        v-expose="{ id: '1-11-1-195' }"
        v-tap="{ id: '1-11-1-196' }"
        class="xtra-list__details swiper-slide"
      >
        <span>{{ language.SHEIN_KEY_PC_30078 }}</span>
        <sui_icon_more_circle_right_16px_1 
          class="xtra-list__details-icon"
          size="16px" 
          color="#878989"
        />
      </div>
      <!-- eslint-disable vue/no-deprecated-slot-attribute -->
      <div slot="container-start">
        <div
          v-show="showSwiperButton"
          class="xtra-list__button-prev"
          @click.stop="nextEl"
        >
          <Icon
            name="sui_icon_more_left_18px"
            size="18px"
            :is-rotate="GB_cssRight"
          />
        </div>
      </div>
      <div slot="container-end">
        <div
          v-show="showSwiperButton"
          class="xtra-list__button-next"
          @click.stop="prevEl"
        >
          <Icon
            name="sui_icon_more_right_18px"
            size="18px"
            :is-rotate="GB_cssRight"
          />
        </div>   
      </div>
    </swiper-container>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { template } from '@shein/common-function'
import { deepCopy } from 'public/src/pages/checkout/utils.js'

import { register } from 'swiper/element'
import { Navigation } from 'swiper/modules'
import { Icon, sui_icon_more_circle_right_16px_1 } from '@shein-aidc/icon-vue3'

import XtraCouponItem from './xtra_coupon_item'
typeof window !== 'undefined' && register()


export default {
  name: 'XtraCouponList',
  components: {
    Icon,
    sui_icon_more_circle_right_16px_1,
    XtraCouponItem
  },
  data() {
    return {
      isInitialized: false,
      displayedCouponList: [],
      xtraSwiper: null,
      showSwiperButton: false,
      // 点击次数：0-还没有点击；1-已点击一次；99-点击大于一次
      extraClick: 0,
      entranceDisplayedNum: 4,
      lastIndex: 2,
      minListLength: 3,
      updating: false,
    }
  },
  computed: {
    ...mapState([
      'coupon',
      'usedXtraCode',
      'xtraAggregatedCouponList',
      'xtraDisplayCouponInfo',
      'language',
      'locals',
    ]),
    GB_cssRight() {
      return this.locals.GB_cssRight
    },
    isMultStyle() {
      return this.displayedCouponList.length > this.minListLength
    }
  },
  watch: {
    'coupon.applyCouponsSuccess': {
      handler() {
        if (typeof window == 'undefined' || !this.isInitialized || this.updating) return
        this.updateCouponList()
      },
      immediate: true,
      deep: true
    },
    'xtraDisplayCouponInfo': {
      handler() {
        if (typeof window == 'undefined' || !this.isInitialized || this.updating) return
        this.updateCouponList()
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.updateCouponList()
  },
  mounted() {
    this.$nextTick(() => {
      this.initSwiper()
      this.isInitialized = true
    })
  },
  methods: {
    template,
    deepCopy,
    ...mapMutations(['assignState']),
    handleCouponList(topCoupons) {
      // 先空出放置置顶券的位置
      const sortedArray = new Array(topCoupons.length).fill()
      this.xtraAggregatedCouponList.forEach(coupon => {
        const index = topCoupons.findIndex(item => item.couponCode === coupon.couponCode)
        if (index > -1) {
          // 需要置顶的券：已勾选的券 > 最优券
          sortedArray[index] = { ...coupon, isSelected: topCoupons[index].isSelected }
        } else {
          // 其余券
          sortedArray.push({ ...coupon, isSelected: false })
        }
      })

      // 为防止找不到置顶券的数据，需过滤一下
      return sortedArray.filter(item => !!item)
    },
    updateCouponList() {
      this.updating = true
      let topCoupons = []
      // 55代表超省卡
      this.coupon.applyCouponsSuccess.forEach(coupon => {
        if (coupon.typeId == 55) {
          topCoupons.push({ ...coupon, isSelected: true })
        }
      })
      // 是否使用超省卡券
      const isCouponUsed = !!topCoupons.length && !!this.usedXtraCode
      
      const displayCoupon = this.xtraDisplayCouponInfo?.info
      if (isCouponUsed) {
        // 使用了超省卡券：更新超省卡券列表（劵列表顺序：本单已勾选的券、本单最优劵、接口返回的劵）
        const bestCouponInUsed = topCoupons.find(item => this.xtraDisplayCouponInfo?.info?.couponCode === item.couponCode)
        if (!bestCouponInUsed && !!displayCoupon) {
          topCoupons.push(displayCoupon)
        }
      } else if (!!displayCoupon){
        // 没有使用超省卡券：初始化超省卡券列表（置顶券为本单最优券）
        topCoupons = [displayCoupon]
      }
      this.displayedCouponList = this.handleCouponList(topCoupons)
      this.updating = false
    },
    initSwiper () {
      this.xtraSwiper = document.querySelector('.xtra-list')
      const swiperParams = {
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        spaceBetween: 8,
        normalizeSlideIndex: false,
        injectStyles: [
          `.swiper {
            min-height: 62px;
            display: flex;
            align-items: end;
          }
          `,
        ],
        modules: [Navigation],
        navigation: {
          nextEl: '.xtra-list__button-next',
          prevEl: '.xtra-list__button-prev'
        }
      }
      Object.assign(this.xtraSwiper, swiperParams)
      this.xtraSwiper.initialize()
    },
    clickDetails() {
      this.extraClick = this.extraClick === 0 ? 1 : 99
      this.$emit('clickDetails', true)
    }
  },
  emits: ['clickDetails']
}
</script>

<style lang="less" scoped>
.xtra-list {
  display: flex;
  flex-wrap: nowrap;
  margin-right: -12px;
  z-index: 0;

  &__details {
    width: 52px;
    height: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: @sui_color_gray_dark3;
    font-family: "SF Pro";
    font-size: 10px;
    line-height: 1.2;
    text-align: center;
    margin-top: 5px;
    
  }
  &__details-icon {
    margin-top: 4px;
  }

  &__button-prev,
  &__button-next {
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: rgba(255, 255, 255, 0.94);
    border-radius: 50%;
    z-index: 12;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
    > i {
      font-size: 16px;
    }
  }

  &__button-prev {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -18px;
  }
  &__button-next {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -18px;
  }
}
.new_common-swiperv2-checkout{
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(255, 255, 255, .94);
  border-radius: 50%;
  z-index: 12;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .08);
  color: #222;
  font-size: 16px;
}
.xtra-list__item{
  width: auto;
  flex-shrink: 0;
  margin-right: 8px;
}
</style>

<template>
  <div :class="['header dw-containerHer', `${autoType}_dw-containerHer`]">
    <Icon
      name="sui_icon_closed_18px_1"
      size="18px"
      class="close"
      @click="close"
    />
    <div
      v-if="autoType == 'prime'"
      class="logo"
    ></div>
    <div class="header-content">
      <div
        class="header-title"
        v-html="headerTitle"
      >
      </div>
      <div class="quarterly-block">
        <div :class="['polygon', `polygon_${autoType}`]"></div>

        <div :class="['quarterly', `quarterly_${autoType}`]">
          <div class="quarterly-text">
            <span class="quarterly-title">
              {{ quarterlyTitle }}
            </span>
            <span class="price">
              {{ arrival_price_with_symbol }}
            </span>
            <del
              v-if="isSuggested"
              class="price-del"
            >
              {{ price_local_with_symbol }}
            </del>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// components
import { Icon } from '@shein-aidc/icon-vue3'

// utils
import { computed } from 'vue'
import { template } from '@shein/common-function'

const props = defineProps({
  payMethodsListDrawerHeaderData: {
    type: Object,
    default: () => {},
  },
  language: {
    type: Object,
    default: () => {},
  },
  autoType: {
    type: String,
    default: 'prime'
  },
  isSuggested: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['close'])

const total_discount_price_with_symbol = computed(() => {
  return (
    props.payMethodsListDrawerHeaderData?.total_discount_price_with_symbol || 0
  )
})

const headerTitle = computed(() => {
  const price = `<span class="price" style="color: #F82854">
          ${total_discount_price_with_symbol.value}
        </span>`
  return template(price, props.language.SHEIN_KEY_PC_29642)
})

const quarterlyTitle = computed(() => {
  return template(props.payMethodsListDrawerHeaderData?.product_name_language, props.language.SHEIN_KEY_PC_29812)
})

const arrival_price_with_symbol = computed(() => {
  return props.payMethodsListDrawerHeaderData?.arrival_price_with_symbol || 0
})

const price_local_with_symbol = computed(() => {
  return props.payMethodsListDrawerHeaderData?.price_local_with_symbol || 0
})

const close = () => {
  emit('close')
}
</script>

<style lang="less" scope>
.dw-containerHer {
  background: linear-gradient(
    279deg,
    #ffdcb2 12.71%,
    #fff4d7 32.38%,
    #ffecd6 52.47%,
    #ffe1be 100.42%,
    #ffe7cb
  );
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;

  .bg-img {
    position: absolute;
    left: 0;
    top: 16px;
    left: -11px;
  }
}
.xtra_dw-containerHer{
  background: #FFF0F0;
}
.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 13px;

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .logo {
    background-image: url('/she_dist/images/checkout/auto_dw_logo-87f3402e69.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 16px;
    left: 0;
    width: 116px;
    height: 100px;
  }
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 111px;
  padding-top: 30px;
}

.header-title {
  color: black;
  font-size: 18px;
  font-weight: 860;
  text-transform: uppercase;
  word-wrap: break-word;
  display: flex;
  align-items: center;

  .price {
    color: #ff5656;
    font-size: 32px;
    font-family: SF Pro;
    font-weight: 860;
    word-wrap: break-word;
    margin-left: 4px;
  }
}
.quarterly-block {
  position: relative;
  margin-top: 18px;

  .polygon {
    width: 24px;
    height: 11px;
    background: url('/she_dist/images/checkout/Polygon-480dd658ca.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: -7px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
  .polygon_xtra {
    width: 16px !important; /* stylelint-disable-line declaration-no-important */
    height: 9px !important; /* stylelint-disable-line declaration-no-important */
    background-image: url('https://img.ltwebstatic.com/images3_ccc/2024/06/25/31/1719282792d9c012dcf22fe9f8d9125f9b05d6fcd1.png') !important; /* stylelint-disable-line declaration-no-important */
  }
}

.quarterly {
  display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 9px;
    // width: 170px;
    height: 22px;
    border-radius: 50px;
    background: linear-gradient(90deg, #F17E53 30.49%, #FF5656 69.65%);
    position: relative;
    z-index: 2;
    

  .quarterly-text {
    color: white;
    font-size: 12px;
    font-family: SF Pro;
    font-weight: 510;
    word-wrap: break-word;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .quarterly-title {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .price {
    font-weight: 700;
    margin: 0 4px;
  }
  .price-del {
    color: white;
    font-size: 10px;
    font-family: SF Pro;
    font-weight: 400;
    text-decoration: line-through;
    word-wrap: break-word;
    opacity: 0.8;
  }
}
.quarterly_xtra{
  background: linear-gradient(90deg, #DE00F1 5.49%, #F82854 98.64%);
}
</style>

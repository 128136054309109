<template>
  <PriceDetailV2 
    v-if="orderConfirmDiscountSwitchByAbt" 
    :pre-pay-un-pay-params="prePayUnPayParams"
  />
  <PriceDetail 
    v-else 
    :pre-pay-un-pay-params="prePayUnPayParams"
  />
  <!-- 积分 -->
  <div
    v-if="type == 'checkout_footer'"
    class="points-container_wrapper"
  >
    <div class="line"></div>
    <pointsContainer
      v-if="price.totalPointMultiple && price.totalPoints"
      :props-style="{ margin:0 }"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import PriceDetail from './PriceDetail.vue'
import PriceDetailV2 from './PriceDetailV2.vue'
import pointsContainer from 'public/src/pages/checkout/vue_tpls/points_task/points_container.vue'

const props = defineProps({
  prePayUnPayParams: {
    type: Object,
    default: () => ({})
  }
})

const store = useStore()

const price = computed(() => store.state.checkout.mall_caculate_info)

const orderConfirmDiscountSwitchByAbt = computed(() => store.getters.orderConfirmDiscountSwitchByAbt)
</script>

import infiniteScroll from '@sheinfe/vue-infinite-scroll'
import './analysis/ga_checkout.js'
import { createApp } from './app'

const { app, store } = createApp()

app.use(infiniteScroll)


if (window.gbCheckoutSsrData?.checkout) {
  store.replaceState({ ...store.state, ...window.gbCheckoutSsrData })
  console.info('%cgbCheckoutSsrData:', 'background:#35495e ; padding: 3px 3px; border-radius: 3px;  color: #fff', JSON.parse(JSON.stringify({ ...store.state, ...window.gbCheckoutSsrData })), '\n')
}

var vm = app.mount('#checkout-page')
window.GB_orderPlaceVue = vm

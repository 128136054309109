import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

// 获取国家列表中supportCountry
export const  getSupportCountry  = (all) => {
  const { SiteUID } = gbCommonInfo
  const supportCountry = all.filter((v) => {
    if (SiteUID == 'www') {
      return typeof v.siteUid == 'undefined' || v.siteUid == 'www'
    }
    if (SiteUID == 'rw') {
      return typeof v.siteUid == 'undefined' || v.siteUid == 'rw'
    }
    return v.siteUid == SiteUID
  })
  return supportCountry
}

// 获取siteuid对应的国家
export function getSiteUidCountry(list) {
  const { SiteUID } = gbCommonInfo
  const siteuidCountry = list.filter((v) => {
    if (SiteUID == 'www') {
      return typeof v.siteUid == 'undefined' || v.siteUid == 'm'
    }
    if (SiteUID == 'rw') {
      return typeof v.siteUid == 'undefined' || v.siteUid == 'm'
    }
    return v.siteUid == SiteUID
  })
  return siteuidCountry
}

export const setSiteUidDefaultCode = (all) => {
  if (all.length == 0) return
  const supportCountry = getSupportCountry(all)
  const countryCode = getFuzzyDefaultLocation(supportCountry)
  let supportCountryValues = supportCountry.map(i => i.value)
  if (countryCode) { // 存在默认国家则将它优先级设置为最高
    const target = countryCode.value
    const index = supportCountryValues.indexOf(target)
    if (index !== -1) {
      supportCountryValues.splice(index, 1)
      supportCountryValues.unshift(target)
    }
  }
  // supportCountryValues = supportCountryValues.slice(0, 5)
  if (typeof window != 'undefined') {
    window.localStorage.setItem('SITEUID_COUNTRYS', JSON.stringify(supportCountryValues))
  }
}

export function getFuzzyDefaultLocation(supportCountry) {
  const { SiteUID } = gbCommonInfo
  //模糊匹配
  const key = SiteUID.match(/^(rw)?([a-z]+)$/)?.[2] || ''
  const fuzzyCode = supportCountry.find((v) =>
    v.value.includes(key.toUpperCase())
  )
  if (fuzzyCode) return fuzzyCode
  return ''
}

// 获取站点默认对应的国家
export function getSiteToDefaultCountry(supportCountry) {
  const { countryAbbr: ipCountryCode } = UserInfoManager.getSync({ key: 'Country', actionType: 'getCountryCode' }) || {}
  const ipCountry = supportCountry.find((v) => v.value == ipCountryCode)
  if (ipCountry) return ipCountry

  const fuzzyCode = getFuzzyDefaultLocation(supportCountry)
  if (fuzzyCode) return fuzzyCode

  const siteuidCountry = getSiteUidCountry(supportCountry)
  if (siteuidCountry?.length > 0) return siteuidCountry[0]

  return supportCountry[0] || {}
}

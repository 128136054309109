<template>
  <div 
    class="prime-card-new"
    :class="{
      'prime-card-new_activity': hasActivity, 
      'prime-card-new_scale': isScale,
      'prime-card-new_selected': isSelected,
      'prime-card-new_auto-renewal': isAutoRenewal,
      'prime-card-new_single': primeProductList.length <= 1
    }"
    :style="{
      ...styleConfig[currentStyleType],
      ...cardBackground
    }"
  >
    <!-- 背景图上的logo -->
    <img 
      v-if="!showNewStyle"
      class="prime-card-new__logo"
      src="https://img.ltwebstatic.com/images3_ccc/2024/06/17/b7/1718605574332abf833f03cac69b92207b58e9b2d5.webp"
    />

    <!-- 顶部标题栏 -->
    <div class="prime-card-new__header">
      <div
        ref="headerTitleRef"
        class="header-title"
        :class="{ 'header-title_auto-renewal': isAutoRenewal }"
      >
        <div class="header-title__left">
          <div
            class="header-title__left-bg"
            :style="{ transform: `scaleX(${GB_cssRight ? -1 : 1})` }"
          ></div>
          <div 
            class="header-title__info-text"
            v-html="productName"
          >
          </div>
        </div>
        <div
          class="header-title__right-triangle"
          :style="{ transform: `scaleX(${GB_cssRight ? -1 : 1})` }"
        >
        </div>
      </div>
      <div
        v-if="isShowHistoryOmitAmount"
        ref="headerOmitAmountRef"
        class="header-omit-amount"
      >
        <div
          ref="headerOmitAmountTextRef"
          class="header-omit-amount__text not-fsp-element"
        >
          {{ historyOmitAmountText }} 
        </div>
      </div>
    </div>

    <!-- 横向列表主要权益 -->
    <div
      v-if="item.right_config_list.length > 2"
      class="prime-card-new__least-three-content"
      :class="{'prime-card-new__least-three-content_activity': !!activityType }"
    >
      <div
        ref="mutipleLeftRightsRef"
        class="left-rights"
      >
        <div class="left-rights__icon">
          <component
            :is="getRightIcon(item.right_config_list[0])"
            v-if="getRightIcon(item.right_config_list[0])"
            size="22px"
            :color="styleConfig[currentStyleType]['--benefitColor']"
          />
          <div 
            v-if="showThumb"
            class="left-rights__praise"
          >
            <sui_icon_like_completed_20px
              size="8px" 
              color="#fff"
            />
          </div>
        </div>
        <div
          ref="mutipleLeftRightsTextRef"
          class="left-rights__desc"
        >
          {{ item.right_config_list[0].right_type_name }} 
        </div>
      </div>
      <div class="middle-wrapper">
        <div class="middle-wrapper__part-line"></div>
      </div>
      <div class="right-rights">
        <div
          v-for="(right, index) in item.right_config_list.slice(1)"
          :key="right.right_type_id"
          class="single-rights-wrapper"
          :class="{
            'single-rights-wrapper_small': item.right_config_list.slice(1).length > 2
          }"
        >
          <component
            :is="getRightIcon(right)"
            v-if="getRightIcon(right)"
            size="11px"
            :color="styleConfig[currentStyleType]['--benefitColor']"
          />
          <div
            :ref="`viceRights_${index}_Ref`"
            class="single-rights-wrapper__desc"
          >
            {{ right.right_type_name }} 
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="prime-card-new__most-two-content"
      :class="{'prime-card-new__most-two-content_activity': !!activityType }"
    >
      <div
        v-for="(right, rightIndex) in item.right_config_list" 
        :key="right.right_type_id"
        class="single-rights-info"
      >
        <div
          class="single-rights-wrapper"
        >
          <div class="single-rights-wrapper__icon">
            <component
              :is="getRightIcon(right)"
              v-if="getRightIcon(right)"
              size="22px"
              :color="styleConfig[currentStyleType]['--benefitColor']"
            />
            <div
              v-if="rightIndex === 0 && showThumb"
              class="single-rights-wrapper__praise"
            >
              <sui_icon_like_completed_20px 
                size="8px"
                color="#fff"
              />
            </div>
          </div>
          <div class="single-rights-wrapper__desc">
            {{ right.right_type_name }} 
          </div>
        </div>
        <div
          v-if="rightIndex === 0 && item.right_config_list.length > 1"
          class="rights-divider"
        ></div>
      </div>
    </div>
    <!-- 底部 -->
    <div 
      v-if="!!activityType"
      class="prime-card-new__footer"
    >
      <div class="footer-left">
        <img
          class="footer-left__icon"
          :src="activityIconImg" 
        />
        <div 
          class="footer-left__tips"
        >
          {{ activityTips }}
        </div>
      </div>
      <div 
        v-if="countDownTimer && countDownTimer.length"
        class="footer-right"
      >
        <div 
          v-for="(text, index) in countDownTimer"
          :key="index"
          class="footer-right__count-item"
        >
          {{ text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { template, CountDown } from '@shein/common-function'
import { ActivityType, StyleType, styleConfig } from './primeConfig.js'

import { 
  sui_icon_like_completed_20px, 
  sui_icon_extrapoints_club_fill_14px, 
  sui_icon_shipping_club_fill_14px, 
  sui_icon_specialprice_club_fill_14px, 
  sui_icon_club_giftbox4_20px,
  sui_icon_creditback_24px 
} from '@shein-aidc/icon-vue3'


export default {
  name: 'PrimeOptionsCardNew',
  components: {
    sui_icon_like_completed_20px,
    sui_icon_extrapoints_club_fill_14px, 
    sui_icon_shipping_club_fill_14px, 
    sui_icon_specialprice_club_fill_14px,
    sui_icon_club_giftbox4_20px,
    sui_icon_creditback_24px
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isScale: {
      type: Boolean,
      default: false
    },
    hasActivity: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    // 是否为可自动续费产品包
    isAutoRenewal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timer: null,
      isTwoRowsFlag: false, // 副权益变成两行的时候
      styleConfig
    }
  },
  computed: {
    ...mapState([
      'checkout',
      'language',
      'locals',
      'primeProductList'
    ]),
    ...mapGetters([
      'sheinClubUIByAbt'
    ]),
    GB_cssRight() {
      return this.locals.GB_cssRight
    },
    PUBLIC_CDN() {
      return this.locals.PUBLIC_CDN
    },
    SiteUID() {
      return this.locals.SiteUID
    },
    // 是否展示历史已省金额
    isShowHistoryOmitAmount() {
      const { buy_when = -1, recent_order_saving_info = {} } = this.checkout?.results?.primeProductInfo?.data?.info || {}
      return [2, 3, 4].includes(Number(buy_when)) && recent_order_saving_info?.recent_saving_amount > 0
    },
    // 历史已省金额文本
    historyOmitAmountText() {
      const { recent_order_saving_info = {} } = this.checkout?.results?.primeProductInfo?.data?.info || {}
      const savingAmountSymbol = recent_order_saving_info?.recent_saving_amount_with_symbol || ''

      return this.template(`${savingAmountSymbol}`, this.language.SHEIN_KEY_PC_28813)
    },
    primeGift() {
      return this.item?.gift_info_list?.filter(item => item.gift_type == 1) || []
    },
    activityType() {
      let type = ''
      // 买一赠一活动
      if (this.primeGift.length > 0) {
        type = ActivityType.Gift
      } else if (this.hasDiscount) {
        // 折扣活动价
        type = ActivityType.Discount
      } 
      
      if (!this.hasActivity && !!type) this.$emit('handleActivityShow', !!type)
      
      return type
    },
    // 折扣活动价
    hasDiscount() {
      // 正式包(product_type=0) & promotion_type 价格类型：非空、非0- 日常价 才展示折扣文案
      return +this.item?.product_type == 0 && !!+this.item?.product_price_info?.promotion_type && (+this.item?.product_price_info?.special_price < +this.item?.product_price_info?.price_local)
    },
    activityTips() {
      switch(this.activityType) {
        case ActivityType.Gift:
          return this.template(
            this.primeGift[0]?.product_cycle_days,
            this.primeGift[0]?.gift_product_price_info?.special_price_with_symbol,
            this.language.SHEIN_KEY_PC_27870
          )
        case ActivityType.Discount:
          return this.template(
            this.item?.product_price_info?.reduce_price_with_symbol,
            this.language.SHEIN_KEY_PC_27871
          )
        default:
          return ''
      }
    },
    // 倒计时
    countDownTimer () {
      if (!this.timer) return []
      const { H: hour, M: minute, S: seconds } = this.timer?.timeObj || {}
      if (Number(hour) > 72) return []
      return [hour, ':', minute, ':', seconds]
    },
    productName() {
      const { product_activity_type, product_name_language } = this.item || {}
      const suffix = this.language.SHEIN_KEY_PC_29428
      let nameEle = product_name_language || ''
      if (product_activity_type != 1) return nameEle
      if (this.GB_cssRight) {
        nameEle = `
          <span class='header-title__suffix'>${suffix}</span>
          <span class='header-title__divider'></span>
          <span>${nameEle}</span>
        `
      } else {
        nameEle = `
          <span>${nameEle}</span>
          <span class='header-title__divider'></span>
          <span class='header-title__suffix'>${suffix}</span>
        `
      }
      return nameEle
    },
    showNewStyle() {
      return [2].includes(+this.sheinClubUIByAbt)
    },
    showThumb() {
      // 旧样式，或非ar地区，展示大拇指icon
      // 新样式一和二都不展示大拇指icon
      return ![1, 2].includes(+this.sheinClubUIByAbt) || this.SiteUID.indexOf('ar') < 0
    },
    currentStyleType() {
      // 新续费样式 > 新普通样式 > 旧续费样式 > 旧普通样式
      if (this.showNewStyle) {
        if (this.isAutoRenewal) return StyleType.NewAutoRenewal
        return StyleType.New
      }
      if (this.isAutoRenewal) return StyleType.OldAutoRenewal
      return StyleType.Old
    },
    cardBackground() {
      switch(this.currentStyleType) {
        case StyleType.New:
        case StyleType.NewAutoRenewal:
          return { backgroundImage: 'url(https://img.ltwebstatic.com/images3_ccc/2024/06/14/b4/171836300514cb7005d329eddacd1f6277829c5c65.webp)' }
        default:
          return { backgroundImage: 'url(https://img.ltwebstatic.com/images3_ccc/2024/06/17/53/17186131381d1f151cc451a1095a24283cc97907bc.webp)' }
      }
    },
    activityIconImg() {
      switch(this.currentStyleType) {
        case StyleType.New:
        case StyleType.NewAutoRenewal:
          return 'https://img.ltwebstatic.com/images3_ccc/2024/06/14/49/1718362392ea5ecddb2e05b1dd369d2a72b2566033.webp'
        default:
          return 'https://img.ltwebstatic.com/images3_ccc/2024/06/14/90/1718362432c4f710a3ac0157daba6d259067240bb8.webp'
      }
    }
  },
  watch: {
    isScale: {
      handler() {
        if (this.item?.right_config_list?.length > 2) {
          if(typeof window == 'undefined') return
          
          this.$nextTick(()=>{
            this.handleMainRightsFontSize()
            this.handleViceRightsFontSize()
          })
        }
      }
    }
  },
  mounted() {
    this.initTimer()
    this.$nextTick(()=>{
      this.handleMainRightsFontSize()
      this.handleViceRightsFontSize()
      this.handleOmitAmountScroll()
    })
  },
  methods: {
    template,
    // 初始化倒计时
    initTimer () {
      let endTime
      const { product_price_info } = this.item || {}
      switch(this.activityType) {
        case ActivityType.Gift:
          endTime = this.primeGift[0].end_time
          break
        case ActivityType.Discount:
          if (product_price_info?.countdown) {
            endTime = this.item?.product_price_info.end_time
          }
          break
      }
      if (!endTime) return
      // 有部分数据返回的时间戳单位为秒
      if (endTime.toString().length < 13) endTime *= 1000
      const newDate = new Date().getTime()
      const countdown = endTime - newDate
      if (countdown <= 0 || countdown > 72 * 60 * 60 * 1000) return
      this.timer = new CountDown()
      this.timer.start({
        seconds: Math.floor(countdown / 1000),
      })
    },
    getRightIcon(right) {
      // 『SHEIN中央：53840-付费会员积分发放,SHEIN欧洲：付费会员积分发放：53779-,SHEIN美国 ：52078-付费会员积分发放』
      // 积分
      if([53840, 53779, 52078].includes(+right?.right_type_id) || right?.right_type_code == 'MULTIPLE_POINTS') return 'sui_icon_extrapoints_club_fill_14px'
      // 免邮
      if (right?.right_type_id == 53) return 'sui_icon_shipping_club_fill_14px'
      // 折扣
      if (right?.right_type_id == 29) return 'sui_icon_specialprice_club_fill_14px'
      // 会员赠品
      if (right?.right_type_id == 4) return 'sui_icon_club_giftbox4_20px'
      // credit 返还
      if (right?.right_type_id == 500) return 'sui_icon_creditback_24px'
      return ''
    },
    // 处理【省略金额】的滚动样式
    handleOmitAmountScroll() {
      const transformClassName = this.GB_cssRight ? 'ar-omit-amount-scroll' : 'omit-amount-scroll' //阿拉伯语言需要单独设置动画

      if (!this.isShowHistoryOmitAmount) {
        this.$refs.headerOmitAmountTextRef?.classList?.remove(transformClassName)
        return
      }

      const oneElementWidth = 213 // 设计稿的父元素的宽度
      const originLeftTitleWidth = this.$refs.headerTitleRef?.offsetWidth || 0
      const originRightDescWidth = this.$refs.headerOmitAmountTextRef?.offsetWidth || 0
      const amountStyle = window.getComputedStyle(this.$refs.headerOmitAmountRef) || {}
      let paddingOffset = parseFloat(amountStyle.paddingLeft) || 12
      if (this.GB_cssRight) paddingOffset = parseFloat(amountStyle.paddingRight) || 12

      this.$refs.headerOmitAmountRef.style.width = `${oneElementWidth - originLeftTitleWidth + paddingOffset}px` // 设置右侧节省金额的文案的宽度

      // 如果文本的宽度超过父元素的宽度，就横向滚动跑马灯
      if ((oneElementWidth - originLeftTitleWidth) < originRightDescWidth) {
        this.$refs.headerOmitAmountTextRef?.classList?.add(transformClassName)
      } else {
        this.$refs.headerOmitAmountTextRef?.classList?.remove(transformClassName)
      }
    },
    // 处理主权益的字号
    handleMainRightsFontSize() {
      const mainRightsWidth =  this.$refs.mutipleLeftRightsRef?.offsetWidth || 0
      const mainRightsTextHeight = this.$refs.mutipleLeftRightsTextRef?.offsetHeight || 0
      const mainRightsTextWidth = this.$refs.mutipleLeftRightsTextRef?.offsetWidth || 0
      const maxHeight = 28
      const singleHeight = 14

      if (mainRightsWidth) {
        this.$refs.mutipleLeftRightsTextRef.style.width = `${mainRightsWidth}px`
      }
 
      if (singleHeight >= mainRightsTextHeight && mainRightsTextWidth < mainRightsWidth) {
        this.$refs.mutipleLeftRightsTextRef?.classList?.add('main-rights-font14')
      } else if (singleHeight < mainRightsTextHeight && mainRightsTextHeight < maxHeight) {
        this.$refs.mutipleLeftRightsTextRef?.classList?.add('main-rights-font12')
      } else if (mainRightsTextHeight > maxHeight || mainRightsTextHeight === maxHeight){
        this.$refs.mutipleLeftRightsTextRef.style.maxHeight = `${maxHeight}px`
        this.$refs.mutipleLeftRightsTextRef?.classList?.add('main-rights-font10')
      }
    },
    // 处理副权益的字号
    handleViceRightsFontSize() {
      if (this.item.right_config_list.length > 2) {
        if (this.isTwoRowsFlag) {
          if (this.$refs.viceRights_0_Ref && this.$refs.viceRights_1_Ref) {
            this.$refs.viceRights_0_Ref[0]?.classList?.add('vice-rights-font10')
            this.$refs.viceRights_1_Ref[0]?.classList?.add('vice-rights-font10')
            return
          }
        }
        const descMaxWidth = 106 // 设计稿文本最大的宽度
        const singleDescHeight = 15
        const isExistFirst = this.$refs.viceRights_0_Ref && this.$refs.viceRights_0_Ref?.length
        const isExistSecond = this.$refs.viceRights_1_Ref && this.$refs.viceRights_1_Ref?.length

        if (isExistFirst) {
          const textHeight = this.$refs.viceRights_0_Ref[0]?.offsetHeight
          const textWidth = this.$refs.viceRights_0_Ref[0]?.offsetWidth

          if (textHeight > singleDescHeight || textWidth >= descMaxWidth) {
            this.isTwoRowsFlag = true
            this.$refs.viceRights_0_Ref[0]?.classList?.add('vice-rights-font10')
          }
        }
        if (isExistSecond) {
          const textHeight = this.$refs.viceRights_1_Ref[0]?.offsetHeight
          const textWidth = this.$refs.viceRights_1_Ref[0]?.offsetWidth
          if (this.isTwoRowsFlag) {
            this.$refs.viceRights_1_Ref[0]?.classList?.add('vice-rights-font10')
            return
          }

          if (textHeight > singleDescHeight || textWidth >= descMaxWidth) {
            this.isTwoRowsFlag = true
            this.$refs.viceRights_1_Ref[0]?.classList?.add('vice-rights-font10')

            if (isExistFirst) {
              this.$refs.viceRights_0_Ref[0]?.classList?.add('vice-rights-font10')
            }
          }
        }
      }
    },
  },
  emits: ['handleActivityShow'],
}
</script>

<style lang="less" scoped>
.two-line-ellipsis() {
  overflow : hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@keyframes omitAmountMarquee {
  0% {
    left: 100%;
  }
  100% {
    left: 0%;
    transform: translateX(-100%);
  }
}

.omit-amount-scroll{
  animation: omitAmountMarquee 20s linear infinite;
}

@keyframes arOmitAmountMarquee {
  0% {
    right: 100%;
  }
  100% {
    right: 0%;
    transform: translateX(-100%);
  }
}
.ar-omit-amount-scroll{
  animation: arOmitAmountMarquee 20s linear infinite;
}
.prime-card-new {
  width: 100%;
  height: 84px;
  background-size: 100% 100%;
  border-radius: 9px;
  padding: 0 6px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: left bottom;
    
  &_scale {
    scale: 1.15;
  }

  &_activity {
    height: 92px;
  }

  &__logo {
    width: 94px;
    height: 74px;
    position: absolute;
    /*rtl:ignore*/
    left: 0;
    bottom: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    position: absolute;
    top: -1px;
    left: -0.5px;
    height: 18px;
    border-top-left-radius: 8px;
    overflow: hidden;
    .header-title {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      z-index: 1;
      &__left {
        height: 100%;
        padding: 0 2px 0 6px;
        position: relative;
      }
      &__left-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--nameBgColor);
      }
      &__info-text {
        overflow: hidden;
        max-width: 82px;
        text-overflow: ellipsis;
        padding-right: 1px;
        margin-top: 4px;
        font-family: 'PingFang HK';
        font-weight: 600;
        color: var(--nameColor);
        text-transform: capitalize;
        white-space: nowrap;
        font-size: 10px;
        z-index: 1;
        position: relative;
      }
      &__right-triangle {
        height: 100%;
        width: 13px;
        background: var(--nameTriangleBgColor);
        clip-path: path('M13.571 0L5.41131 14.9992C4.33925 16.8561 2.358 18 0.213867 18V0H14.071Z');
        margin-left: -1px;
      }
    }
    .header-omit-amount {
      position: relative;
      overflow: hidden;
      height: 100%;
      padding: 3px 3px 3px 12px;
      margin-left: -12px;
      background: var(--savedBgColor);
      &__text {
        position: absolute;
        white-space: nowrap;
        color: var(--savedTextColor);
        font-size: 10px;
      }
    }

    // 自动续费产品包样式
    .header-title_auto-renewal {
      /deep/ .header-title {
        &__info-text {
          overflow: visible;
          max-width: none;
        }
        &__divider {
          display: inline-block;
          width: 1px;
          height: 9px;
          background: var(--nameColor);
          opacity: .4;
        }
        &__suffix {
          font-family: Arial;
          color: var(--nameSuffixColor, --nameColor);
          font-weight: 860;
        }
      }
    }
  }

  &__least-three-content {
    display: flex;
    .left-rights {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 68px;
      align-self: center;

      &__icon {
        position: relative;
      }
      &__praise {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -2px;
        right: -3px;
        width: 11px;
        height: 11px;
        background: linear-gradient(264deg, #FF9F46 9.68%, #FF7F46 53.52%);
        border-radius: 50%;
      }

      &__desc {
        padding: 2px 5px 0;
        font-size: 12px;
        font-weight: 700;
        line-height: 1.1;
        text-align: center;
        word-break: break-word;
        color: var(--benefitColor, @sui_color_club_rosegold_dark1);
      }
      .main-rights-font14 {
        font-size: 14px;
        .two-line-ellipsis()
      }
      .main-rights-font12 {
        font-size: 12px;
        .two-line-ellipsis()
      }
      .main-rights-font10 {
        font-size: 10px;
        .two-line-ellipsis()
      }
    }
    .middle-wrapper {
      display: flex;
      align-items: center;
      width: 12px;
      max-height: 100%;

      &__part-line {
        width: 1px;
        height: 14px;
        background: var(--benefitColor, @sui_color_club_rosegold_dark1);
        opacity: .2;
      }
    }
    .right-rights {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 86px;
      max-width: 121px;
    }

    .single-rights-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      &__desc {
        color: var(--benefitColor, @sui_color_club_rosegold_dark1);
        font-size: 10px;
        margin-left: 2px;
        font-weight: 700;
        font-size: 12px;
        line-height: 1;
      }
      .vice-rights-font10 {
        font-size: 10px;
        .two-line-ellipsis()
      }
      &:last-child {
        margin-bottom: 0;
      }
    } 

    .single-rights-wrapper_small {
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }

      .single-rights-wrapper__desc {
        font-size: 10px;
        -webkit-line-clamp: 1;
      }
    }
  }

  &__most-two-content {
    display: flex;
    justify-content: space-around;
    max-width: 189px;
    min-width: 182px;
    line-height: 1;

    .single-rights-info {
      display: flex;
      align-items: center;
      width: 92px;
    }
    .single-rights-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 100%;
      z-index: @zindex-hack;
      color: var(--benefitColor, @sui_color_club_rosegold_dark1);

      &__icon {
        position: relative;
      }

      &__praise {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -2px;
        right: -3px;
        width: 11px;
        height: 11px;
        background: linear-gradient(264deg, #FF9F46 9.68%, #FF7F46 53.52%);
        border-radius: 50%;
      }

      &__desc {
        margin: 2px 3px 0;
        max-width: 82px;
        color: var(--benefitColor, @sui_color_club_rosegold_dark1);
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        .two-line-ellipsis();
        line-height: 1.1;
      }
    }

    .rights-divider {
      width: 1px;
      height: 16px;
      margin: 0 4px;
      background: var(--benefitColor, @sui_color_club_rosegold_dark1);
      opacity: .2;
      align-self: center;
      z-index: @zindex-hack;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: -0.5px;
    width: 100%;
    height: 22px;
    padding: 0 6px;
    border-radius: 0 0 8px 8px;
    transform-origin: center bottom;
    background: var(--activityBgColor);
    
    .footer-left {
      display: flex;
      align-items: center;
      &__icon {
        width: 12px;
      }
      &__tips {
        font-family: SF Pro;
        font-weight: 700;
        text-shadow: var(--activityTextShadow);
        font-size: 9px;
        color: var(--activityColor);
        line-height: 1;
        font-weight: 700;
        margin: 0 8px 0 3px;
        .two-line-ellipsis();
      }
    }

    .footer-right {
      display: flex;
      align-items: center;
      /*rtl:ignore*/
      direction: ltr;
      font-size: 8px;
      &__count-item {
        display: flex;
        justify-content: center;
        font-weight: 700;
        line-height: 11px;
      
        &:nth-child(odd) {
          width: 11px;
          height: 10px;
          border-radius: 2px;
          color: var(--activityTimerColor);
          background: rgba(255, 255, 255, 0.9);
        }

        &:nth-child(even) {
          margin: 0 1px;
          color: #fff;
        }
      }
    }
  }


  &_auto-renewal {
    &.prime-card-new_selected {
      border: 1.155px solid #FF5656;
    }
  }
}

.prime-card-new_single {
  .prime-card-new__least-three-content {
    width: 233px;
    justify-content: space-around;

    .left-rights {
      width: 100px;
    }
  }
}
</style>

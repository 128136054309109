<template>
  <div class="checkout-assets__effiency">
    <template
      v-for="item in handleComponentList"
    >
      <component
        :is="item.name"
        v-if="item.show || isClickedMore"
        :key="item.name"
        :is-folded="item.isFolded"
        v-bind="item.props"
      />
    </template>

    <div
      v-if="isShowMore && !isClickedMore"
      v-expose="{
        id: '1-11-1-127',
      }"
      v-tap="{
        id: '1-11-1-128',
      }"
      class="assets-more"
      @click="isClickedMore=true"
    >
      {{ language.SHEIN_KEY_PC_26080 }}
      <sui_icon_more_down_12px_1
        class="assets-more__icon"
        size="12px"
        color="#2d68a8"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'

import CheckoutSummaryCoupon from 'public/src/pages/checkout/components/summary/CheckoutSummaryCoupon.vue'
import CheckoutSummaryPoint from 'public/src/pages/checkout/components/summary/CheckoutSummaryPoint.vue'
import CheckoutSummaryGift from 'public/src/pages/checkout/components/summary/CheckoutSummaryGift.vue'
import CheckoutSummaryWallet from 'public/src/pages/checkout/components/summary/CheckoutSummaryWallet.vue'
import { sui_icon_more_down_12px_1 } from '@shein-aidc/icon-vue3'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

export default {
  name: 'AssetsListEffiency',
  directives: { tap, expose },
  components: {
    CheckoutSummaryCoupon,
    CheckoutSummaryPoint,
    CheckoutSummaryGift,
    CheckoutSummaryWallet,
    sui_icon_more_down_12px_1,
  },
  props: {
    giftcard: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isAllStore: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    currencyCode: {
      type: String,
      default: () => {
        return ''
      },
    },
  },
  data() {
    return {
      isClickedMore: false,
    }
  },
  computed: {
    ...mapState([
      'checkout',
      'language',
    ]),
    caculateInfo() {
      return this.checkout?.mall_caculate_info || {}
    },
    showPoints() {
      return +this.checkout.results?.points?.info?.point?.total > 0
    },
    showWallet() {
      const walletList = this.caculateInfo.wallet?.totalPrice || []
      return !!walletList.length
    },
    isShowMore() { // 存在钱包或者积分为空的场景展示more
      return !this.showPoints || !this.showWallet
    },
    componentList() {
      return [
        {
          name: 'CheckoutSummaryCoupon',
          props: {},
          type: 'coupon',
          show: true,
          sort: 0
        },
        {
          name: 'CheckoutSummaryWallet',
          props: {
            currencyCode: this.currencyCode,
          },
          type: 'wallet',
          show: this.showWallet,
          sort: 0
        },
        {
          name: 'CheckoutSummaryPoint',
          props: {},
          type: 'points',
          show: this.showPoints,
          sort: 0
        },
        {
          name: 'CheckoutSummaryGift',
          props: {
            giftcard: this.giftcard,
            isAllStore: this.isAllStore,
          },
          type: 'giftcard',
          show: true,
          sort: 0
        }
      ]
    },
    handleComponentList() {
      let list = this.componentList
      if (!this.showWallet && !this.showPoints) { // 钱包和积分均折叠：券、礼品卡、钱包、积分
        let tmpList = list.map(item => {
          let sort = 0
          let isFolded = 0 // 当前模块是否折叠
          if(item.type == 'coupon') sort = 0
          if(item.type == 'giftcard') sort = 1
          if(item.type == 'wallet') { 
            sort = 2
            isFolded = 1
          }
          if(item.type == 'points') {
            sort = 3
            isFolded = 1
          }

          return Object.assign({}, item, { sort: sort, isFolded })
        })

        return tmpList.sort((a, b) => a.sort - b.sort)
      } else if(!this.showWallet && this.showPoints) { // 仅折叠钱包：券、积分、礼品卡、钱包
        let tmpList = list.map(item => {
          let sort = 0
          let isFolded = 0 // 当前模块是否折叠
          if(item.type == 'coupon') sort = 0
          if(item.type == 'wallet') {
            sort = 3
            isFolded = 1
          }
          if(item.type == 'points') sort = 1
          if(item.type == 'giftcard') sort = 2

          return Object.assign({}, item, { sort: sort, isFolded })
        })

        return tmpList.sort((a, b) => a.sort - b.sort)
      } else if(this.showWallet && !this.showPoints) { // 仅折叠积分：券、钱包、礼品卡、积分
        let tmpList = list.map(item => {
          let sort = 0
          let isFolded = 0 // 当前模块是否折叠
          if(item.type == 'coupon') sort = 0
          if(item.type == 'wallet') sort = 1
          if(item.type == 'points') {
            sort = 3
            isFolded = 1
          }
          if(item.type == 'giftcard') sort = 2

          return Object.assign({}, item, { sort: sort, isFolded })
        })

        return tmpList.sort((a, b) => a.sort - b.sort)
      } else { // 都不折叠：券、钱包、积分、礼品卡
        let tmpList = list.map(item => {
          let sort = 0
          if(item.type == 'coupon') sort = 0
          if(item.type == 'wallet') sort = 1
          if(item.type == 'points') sort = 2
          if(item.type == 'giftcard') sort = 3

          return Object.assign({}, item, { sort: sort, isFolded: 0 })
        })

        return tmpList.sort((a, b) => a.sort - b.sort)
      }
    },
  },
}
</script>

<style lang="less">
.checkout-assets__effiency{
  background: #fff;
  padding: 16px;
  &>:last-child {
    margin-bottom: 0px;
  }

  .assets-more {
    color: @sui_color_link;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    &__icon {
      margin-left: 3px;
    }
  }

  .assets-item__effiency {
    .apply-box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .c-input {
        width: 100%;
      }
      .checkout-giftcard-form {
        width: calc(100% - 84px);
      }
      .card-num {
        width: 78%;
      }
      .password {
        width: 20%;
        .margin-l(2%);
        margin-top: 0;
      }

      .apply-box-btn {
        .margin-l(4px);
      }
    }
    .indicates {
      display: flex;
      align-items: center;
    }
  }
}
</style>

<template>
  <div 
    class="checkout-prime-benefit__wrap"
    :class="{'checkout-prime-benefit__wrap-ar': GB_cssRight}"
  >
    <div 
      class="checkout-prime-benefit__left"
      :class="{'checkout-prime-benefit__left-ar': GB_cssRight}"
    >
      <div class="checkout-prime-benefit__left-content">
        <img 
          :src="`${PUBLIC_CDN}/she_dist/images/prime/prime-logo-bd4fc14170.png`"
          alt=""
        />
      </div>
    </div>
    <div class="checkout-prime-benefit__right">
      <div class="checkout-prime-benefit__right-save">
        {{ discountTips }}
      </div>
      <div class="checkout-prime-benefit__right-tips">
        {{ roiTips }}
      </div>
    </div>
  </div>
</template>

<script>
import { template as _commonTemplate } from '@shein/common-function'
import { mapState } from 'vuex'

export default {
  name: 'PrimeBenefit',
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    discountInfo: {
      type: Object,
      default: () => {}
    },
    roiInfo: {
      type: [String, Number],
      default: 0
    },
  },
  computed: {
    ...mapState([
      'locals',
    ]),
    GB_cssRight() {
      return this.locals.GB_cssRight
    }, 
    PUBLIC_CDN() {
      return this.locals.PUBLIC_CDN
    },
    discountTips() {
      return this.template(this.discountInfo?.amountWithSymbol, this.language.SHEIN_KEY_PC_26279)
    },
    roiTips() {
      return this.template(this.roiInfo, this.language.SHEIN_KEY_PC_26281)
    },
  },
  methods: {
    template: _commonTemplate,
  }
}
</script>

<style lang="less" scoped>
.checkout-prime-benefit__wrap {
  width: 100%;
  height: auto;
  margin-top: 8px;
  display: flex;
  align-items: stretch;
  background: url('/she_dist/images/checkout/sui_img_paidmember_savebg-8e48be8a0e.png');
  background-size: 100% 100%;

  .checkout-prime-benefit__left {
    background: url('/she_dist/images/checkout/sui_img_paidmember_logobg-5902ffdc63.png');
    background-size: 100% 100%;
    width: 115px;
    padding: 10px;
    flex-shrink: 0;

    img {
      width: 90px;
      height: auto;
    }

    .checkout-prime-benefit__left-content {
      display: inline-flex;
      height: 100%;
      align-items: center;
    }
  }
  .checkout-prime-benefit__left-ar {
    background: url('/she_dist/images/checkout/sui_img_paidmember_logobg_ar-d60884669a.png');
    background-size: 100% 100%;
  }
  .checkout-prime-benefit__right {
    padding: 10px;
    .padding-l(12px);
    color: @sui_color_club_rosegold_dark1;

    .checkout-prime-benefit__right-save {
      font-size: 16px;
      font-weight: 700;
    }
    .checkout-prime-benefit__right-tips {
      font-size: 12px;
      font-weight: 400;
    }
  }
}
.checkout-prime-benefit__wrap-ar {
  background: url('/she_dist/images/checkout/sui_img_paidmember_savebg_ar-e385834402.png');
  background-size: 100% 100%;
}
</style>

import { computed } from 'vue'
import { template } from '@shein/common-function'
import { useMapState } from 'public/src/pages/store_pages/store/mapHook'

export default (props) => {
  const { 
    language,
    checkout, 
    coupon,
    usedXtraCode,
  } = useMapState([
    'language',
    'checkout', 
    'coupon',
    'usedXtraCode',
  ]) 

  const { discountInfo, displayCouponInfo, productInfo } = props

  // 标题
  const title = computed(() => {
    const languageMap = {
      'xtraDiscountTips': language.value.SHEIN_KEY_PC_30074,
      'caculateTips': language.value.SHEIN_KEY_PC_30075,
      'defaulTips': language.value.SHEIN_KEY_PC_30185
    }
    // 1. 使用的券是筛选出超省卡券的权益包卡券55
    // 2. 组装coupon_discount_info，根据筛选出的券，取对应discount_price值
    // 3. type: caculateTips取计价接口，xtraDiscountTips取试算接口，defaulTips兜底取超省卡查询接口
    const usedXtraCoupon = !!coupon.value?.applyCouponsSuccess?.some(f => [55].includes(f.typeId))
    const caculateDiscount = checkout.value?.mall_caculate_info?.save_card_discount_price
    const type = usedXtraCode.value && usedXtraCoupon && caculateDiscount ? 'caculateTips' : 'xtraDiscountTips'
    const discount = type == 'caculateTips' ? caculateDiscount : discountInfo.value?.total_discount
    const price = type == 'caculateTips' ? caculateDiscount?.amountWithSymbol : discountInfo.value?.total_discount?.amountWithSymbol
  
    // 兜底默认取值
    if(!discount || !discount?.amount || +discount?.amount <= 0){
      const roi_order_count = productInfo.value?.roi_order_count || 0
      return roi_order_count <= 0 ? '' : template(`<em>${roi_order_count}</em>`, languageMap.defaulTips)
    }
    return template(`<em>${price || 0}</em>`, languageMap[type])
  })

  // 副标题
  const subtitle = computed(() => {
    const languageMap = {
      'xtraDiscountTips': language.value.SHEIN_KEY_PC_30076,
      'caculateTips': language.value.SHEIN_KEY_PC_30077,
    }
    const xtraCouponListLen = displayCouponInfo.value?.count || 0
    return xtraCouponListLen ? `
        ${template(`${xtraCouponListLen}`, `${languageMap.xtraDiscountTips}`)}
        ${template( productInfo.value.product_cycle_days, `| ${languageMap.caculateTips}`)}
      ` : ''
  })

  return { title, subtitle }
}

// import JSEncrypt from 'jsencrypt'
import axios from 'axios'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
// import { ref, shallowRef } from 'vue'
import { getPublicKey } from 'public/src/pages/common/oneClickPay/fetcher.js'


export default () => {
  const getPubKey = async ({ countryCode = '' } = {}) => {
    const { JSEncrypt = function () {} } = await import('jsencrypt').catch(() => {})
    const { ENCRYPT_KEY_URL, NODE_SERVER_ENV } = typeof window === 'undefined' ? {} : gbCommonInfo
    let encryptValid = false
    let publicKeyObj = {}
    let encryptObj = null
    // console.log('getPubKey')
    encryptValid = false
  
    const countryData = await UserInfoManager.get({ key: 'Country', actionType: 'getCountryIdByIp' })
    const countryAbbr = countryData?.countryAbbr

    const localcountry = countryCode || countryAbbr || ''
    const url = ENCRYPT_KEY_URL + '/pubkey/'

    try {
      const res = NODE_SERVER_ENV === 'localhost' ? await getPublicKey({ countryCode: localcountry }) :  await axios.get(url, { params: { localcountry } })
      const data = NODE_SERVER_ENV === 'localhost' ? res : res?.data
      if (data) {
        publicKeyObj = data
        encryptObj = JSEncrypt && new JSEncrypt() || {}
        encryptObj?.setPublicKey?.(publicKeyObj?.key || '')
        encryptValid = true
      }
    } catch (error) {
      console.error(error)
    }
    return {
      encryptValid,
      publicKeyObj,
      encryptObj
    }
  }

  return {
    getPubKey
  }
}

<template>
  <div :class="['reward-container', taskPoints && 'reward-container_task']">
    <pointReward
      v-if="taskPoints"
      :task-points="taskPoints"
    >
      <template #order-points>
        <orderPoints
          :task-points="taskPoints"
        />
      </template>
    </pointReward>
    <!-- 没有任务积分 -->
    <orderPoints
      v-else
      :task-points="taskPoints"
    />
  </div>
</template>
<script setup>
import orderPoints from './order_points.vue'
import pointReward  from './points_reward.vue'

import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const checkout = computed(() => store.state.checkout)

/* 是否有任务积分 */
const taskPoints = computed(() => {
  // 是否开启任务积分
  const isOpenTaskPoints = checkout?.value?.TASK_POINTS_START == 1
  const taskPoints = isOpenTaskPoints ? checkout.value?.results.carts.cartInfo?.ug_benefit_info?.prizeValue : 0
  return !!+taskPoints ? +taskPoints : 0
})

</script>
  
<style lang='less' scoded>
  .reward-container {
    background: #ffffff;
    padding: 12px;
    margin: 10px 0;
  }
  .reward-container_task{
    background: linear-gradient(237deg, #FFF4E5 30.27%, #FFECD4 69.57%);
  }
</style>

<template>
  <div 
    class="xtra-item xtra-item-new"
    :class="{
      'xtra-item-multiple': +coupon.couponQuantity > 1
    }"
    :style="{
      ...couponConfig[couponType],
      '--coupon-circle-bg-color': entryCouponStyle?.couponCircleBgColor
    }"
  >
    <div 
      class="xtra-item__wrapper"
      :style="{
        backgroundImage: `url(${couponBgImage})`
      }"
    >
      <div 
        v-if="coupon.isSelected"
        class="xtra-item__selected"
        :style="{
          backgroundColor: couponType === XtraCouponType.Shipping ? '#D2ECD1' : '#FDD2DB'
        }"
      >
        <sui_icon_selected_12px 
          size="12px"
          :color="couponType === XtraCouponType.Shipping ? '#169E00' : '#F82854'"
        />
      </div>
      <div
        class="xtra-item__content"
        :style="{
          color: couponType === XtraCouponType.Shipping ? '#169E00' : '#F82854'
        }"
      >
        <!-- 券面额（TODO:后续需替换成组件库组件）-->
        <!-- <SAdapterText
          min-size="12"
          lines="2"
          :text="discountText"
        /> -->
        <div
          class="sui-adapter-text"
          v-html="discountText"
        ></div>
      </div>
      <div
        class="xtra-item__amount"
        :class="{
          'new-user-amount': coupon.privilegeAttributeId && coupon.privilegeAttributeId !== 1
        }"
      >
        <!-- 券数量 -->
        {{ getAmountText(coupon) }}
        <!-- <SAdapterText
          min-size="12"
          lines="1"
          :text="getAmountText(coupon)"
        /> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, nextTick, computed, inject } from 'vue'
// import { SAdapterText } from '@shein-aidc/sui-adapter-text'
import { sui_icon_selected_12px } from '@shein-aidc/icon-vue3'
import { couponConfig, XtraCouponType } from './config.js'
import useXtraCoupon from './hooks/useCoupon.js'
import { template } from '@shein/common-function'

const props = defineProps({
  coupon: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  entryCouponStyle: {
    type: Object,
    default: () => ({})
  }
})
const GB_cssRight = inject('GB_cssRight') || false

const discountRef = ref(null)
const scrollTextRef = ref(null)
const ellipsisVisible = ref(false)
const { couponType } = useXtraCoupon(props)

const resetDiscountStyle = () => {
  if (!discountRef.value) return
  ellipsisVisible.value = false
}

const updateDiscountStyle = () => {
  if (!discountRef.value) return
  const { clientHeight } = discountRef.value || {}
  if (clientHeight > 34) {
    // 超过两行，才缩小字号
    ellipsisVisible.value = true
  }
}
const updateScrollTextStyle = () => {
  if (!scrollTextRef.value) return
  const { scrollWidth, parentElement } = scrollTextRef.value || {}
  const parentWidth = parentElement.clientWidth
  if (scrollWidth <= parentWidth) {
    scrollTextRef.value.style.animation = 'none'
  } else {
    const time = Math.floor(+scrollWidth * (3 / 100))
    scrollTextRef.value.style.animationDuration = `${time}s`
  }
}

const getAmountText = (coupon) => {
  if (coupon.privilegeAttributeId && coupon.privilegeAttributeId !== 1) {
    // 新人券
    const res = coupon.newUserLabelText ? coupon.newUserLabelText : props.language.SHEIN_KEY_PC_32880
    return res + (coupon.couponQuantity > 1 ? ' x' + coupon.couponQuantity : '')
  }
  return 'x' + ' ' + coupon.couponQuantity
}

const percentFormat = (num) => {
  const newNum = (num * 100) / 100
  const percentNum = newNum + '%'
  return percentNum
}

const discountText = computed(() => {
  // 优惠券面额
  let text = ''
  const { applyFor, shippingDiscountType, ruleDimension, rule } = props.coupon
  if ([5, 6, 9].includes(+applyFor)) {
    if (shippingDiscountType == '1') {
      //  1部分抵扣
      if (ruleDimension == '1') {
        text = template(
          `<span>${rule[0].valueAmount.amountWithSymbol}</span>`,
          props.language.SHEIN_KEY_PC_15957
        )
      } else if ([2, 5].includes(+ruleDimension)) {
        text = template(
          `<span>${percentFormat(rule[0].value)}</span>`,
          props.language.SHEIN_KEY_PC_15957
        )
      }
    } else if (shippingDiscountType == '0') {
      // 0全免
      text = `<div>${props.language.SHEIN_KEY_PC_15911}</div>`
    }
  } else if (ruleDimension == '1') {
    // 减元
    text = template(
      `<span>${rule[0].valueAmount.amountWithSymbol}</span>`,
      props.language.SHEIN_KEY_PC_15957
    )
  } else if ([2, 5].includes(+ruleDimension)) {
    text = template(
      `<span>${percentFormat(rule[0].value)}</span>`,
      props.language.SHEIN_KEY_PC_15957
    )
  }

  const res = GB_cssRight ? text.replace(/(-?)(\d+(\.\d+)?)(%)/, '$4$2$1') : text
  return res
})

const couponBgImage = computed(() => {
  const coupon = props.coupon || {}
  if (couponType.value === XtraCouponType.Shipping) {
    // 免邮券
    return +coupon.couponQuantity > 1
      ? 'https://shein.ltwebstatic.com/svgicons/2024/10/09/17284762861809239916.svg'
      : 'https://shein.ltwebstatic.com/svgicons/2024/10/09/17284661473616415372.svg'
  } else if (coupon.privilegeAttributeId && coupon.privilegeAttributeId !== 1) {
    // 活动商品券（新人券）
    return +coupon.couponQuantity > 1
      ? 'https://shein.ltwebstatic.com/svgicons/2024/10/09/1728466206652856867.svg'
      : 'https://shein.ltwebstatic.com/svgicons/2024/10/09/17284763242041701758.svg'
  } else if (couponType.value === XtraCouponType.Product && !coupon.newUserLabelText) {
    // 常规商品券
    return +coupon.couponQuantity > 1
      ? 'https://shein.ltwebstatic.com/svgicons/2024/10/09/17284662392255923342.svg'
      : 'https://shein.ltwebstatic.com/svgicons/2024/10/09/1728476345286010203.svg'
  } 
  // 常规商品券
  return 'https://shein.ltwebstatic.com/svgicons/2024/10/09/1728476345286010203.svg'
})

watch(
  () => props.coupon,
  (newVal, oldVal) => {
    if(typeof window == 'undefined' || JSON.stringify(oldVal) === JSON.stringify(newVal)) return
    resetDiscountStyle()
    nextTick(() => {
      updateDiscountStyle()
      updateScrollTextStyle()
    })
  },
  {
    immediate: true,
    deep: true
  }
)
</script>

<style lang="less" scoped>
/* 定义动画 */
@keyframes scrollText {
  0% {
    left: 100%;
  }
  100% {
    left: 0%;
    transform: translateX(-100%);
  }
}
// 阿拉伯语言滚动
@keyframes scrollTextAr {
  0% {
    right: 100%;
  }
  100% {
    right: 0%;
    transform: translateX(-100%);
  }
}

.xtra-item {
  // width: 81px;
  height: 58px;
  position: relative;
  overflow: hidden;

  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__selected {
    position: absolute;
    left: 2px;
    // top: 4px;
    border-radius: 4px 0 11px;
    background-color: #FDD2DB;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 3px 1px 2px;
  }

  &__quantity {
    position: absolute;
    right: 0;
    top: 5px;
    padding: 1px 6px;
    border-radius: 0 4px;
    color: #F82854;
    background-color: #FDD2DB;
    font-family: "SF Pro";
    font-size: 10px;
    font-weight: 510;
    line-height: 1.2;
  }

  &__content {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--saver-red, #F82854);
    font-weight: 800;

    .sui-adapter-text {
      width: 80%;
      height: fit-content;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      font-size: 10px;

      /deep/ span {
        font-size: 14px;
      }

      /deep/ div {
        font-size: 12px;
      }
    }
  }

  &__amount {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #fff;
    padding: 0 3px;
    margin-top: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }

  .new-user-amount {
    font-size: 10px;
  }

  &__rule {
    width: 100%;
    font-family: "SF Pro";
    font-size: 10px;
    font-weight: 400;
    color: var(--ruleColor);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }
}

.xtra-item-multiple {
  height: 61px;
  margin-top: 0;
  
  .xtra-item__content {
    padding-top: 6px;
  }

  .xtra-item__amount {
    margin-top: 4px;
  }

  .xtra-item__wrapper {
    .xtra-item__selected {
      top: 3px;
    }
  }
}
</style>

<template>
  <div
    v-expose="{
      id: '1-11-1-200',
      data: {
        is_choosed: isShowWalletUsed ? 1 : 0,
        is_folded: isFolded,
      }
    }"
    class="check-coupon assets-item__effiency"
  >
    <div class="indicates">
      <span class="assets-item-title">
        <span>{{ language.SHEIN_KEY_PC_18434.replace(':', '') }}</span>
        <i
          v-if="caculateInfo.wallet && riskStr != 2"
          style="font-style: normal"
        >({{ language.SHEIN_KEY_PC_14540 }}:
          {{
            isWalletAvalabled.price
              ? isWalletAvalabled.price.amountWithSymbol
              : caculateInfo.wallet.subtractPrice.amountWithSymbol
                ? caculateInfo.wallet.subtractPrice.amountWithSymbol
                : 0
          }})</i>
      </span>

      <span
        v-if="isAllStore && IS_RW"
        class="only-for-self"
      >
        {{
          SiteUID.endsWith('th')
            ? language.SHEIN_KEY_PC_22764
            : language.SHEIN_KEY_PC_22763
        }}
      </span>
    </div>
    <div class="apply-box she-clearfix">
      <div
        class="sui-input she-fl c-input-wallet"
        :class="{'input-wallet__all': showAllApply}"
      >
        <s-input
          v-if="!checkUserWallet && riskStr != 2"
          type="text"
          readonly
          onfocus="this.removeAttribute('readonly');"
          :disabled="true"
        />
        <!-- 黑名单用户可以通过点击钱包应用来弹出校验弹窗 -->
        <s-input
          v-else-if="riskStr == 2"
          v-model="walletData"
          type="text"
          readonly
          onfocus="this.removeAttribute('readonly');"
          aria-describedby="wallet-ada-tip"
          :aria-label="walletAiraLabel"
          :class="[{ 'input-error-bg': status.error.wallet }]"
          :placeholder="walletPlaceholderInit()"
          :clearable="true"
          @input="setMaxWallet"
          @focus="isFocus=true"
          @blur="isFocus=false"
        >
          <template
            v-if="showAllApply"
            #suffix
          >
            <span
              class="wallet-apply-effiency"
              @click="applyAllsubmit('wallet')"
            >
              {{ language.SHEIN_KEY_PC_16662 }}
            </span>
          </template>
        </s-input>
        <template v-else>
          <s-input
            v-if="caculateData.use_wallet && !status.loading.wallet"
            v-model="caculateInfo.walletPrice.amount"
            type="text"
            readonly
            onfocus="this.removeAttribute('readonly');"
            :disabled="true"
          />
          <s-input
            v-else
            v-model="walletData"
            type="text"
            readonly
            onfocus="this.removeAttribute('readonly');"
            aria-describedby="wallet-ada-tip"
            :aria-label="walletAiraLabel"
            :class="[{ 'input-error-bg': status.error.wallet }]"
            :placeholder="walletPlaceholderInit()"
            :disabled="
              status.hasAddress == 0 ||
                caculateData.use_wallet ||
                !isWalletAvalabled
                ? true
                : false
            "
            :clearable="true"
            @input="setMaxWallet"
            @focus="isFocus=true"
            @blur="isFocus=false"
          >
            <template
              v-if="showAllApply"
              #suffix
            >
              <span
                class="wallet-apply-effiency"
                @click="applyAllsubmit('wallet')"
              >
                {{ language.SHEIN_KEY_PC_16662 }}
              </span>
            </template>
          </s-input>
        </template>
      </div>

      <s-button
        v-if="
          riskStr != 2 &&
            (status.hasAddress == 0 ||
              !status.isWalletAmoutHit ||
              !checkUserWallet)
        "
        :type="['H36PX']"
        :min-width="'96px'"
        :max-width="'131px'"
        class="she-fr apply-box-btn"
        disabled
      >
        {{ language.SHEIN_KEY_PC_14744 }}
      </s-button>
      <s-button
        v-else
        class="she-fr apply-box-btn"
        :type="['H36PX', status.loading.wallet ? 'primary' : 'default']"
        :min-width="'96px'"
        :max-width="'131px'"
        @click="
          apply({ type: 'wallet', isUse: caculateData.use_wallet ? 1 : 0, byUser: 1 })
        "
      >
        <template v-if="status.loading.wallet">
          <div class="la-ball-pulse la-ball-pulse-white">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </template>
        <template v-else>
          <template v-if="caculateData.use_wallet">
            {{ language.SHEIN_KEY_PC_15055 }}
          </template>
          <template v-else>
            {{ language.SHEIN_KEY_PC_14744 }}
          </template>
        </template>
      </s-button>
    </div>
    <p
      v-show="status.error.wallet"
      id="wallet-ada-tip"
      class="error-tip assets-item__tip"
      tabindex="0"
    >
      <span>
        <!-- 默认情况 -->
        {{ language.SHEIN_KEY_PC_14636 }}
      </span>
    </p>
    <p
      v-cloak
      v-show="status.success.wallet && isShowWalletUsed"
      class="success-tip assets-item__tip"
      tabindex="0"
    >
      {{ language.SHEIN_KEY_PC_16663 }}
    </p>

    <template v-if="riskStr != 2">
      <p
        v-if="
          status.hasAddress &&
            caculateInfo.wallet &&
            caculateInfo.wallet.subtractPrice &&
            caculateInfo.wallet.subtractPrice.amountWithSymbol
        "
        class="point-link assets-item__tip"
        tabindex="0"
      >
        <span
          :class="{ 'point-link-high': walletLimit.length || isShowReason }"
        >
          {{
            `${language.SHEIN_KEY_PC_14745}:${caculateInfo.wallet.subtractPrice.amountWithSymbol}`
          }}
        </span>
        <span
          v-if="showWalletTips"
          class="limit-reason-more"
          @click="viewLimitReason"
        >
          {{ language.SHEIN_KEY_PC_26079 }}
        </span>
      </p>
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { handleLimitGoods } from '../../config/tools'
import { daEventCenter } from 'public/src/services/eventCenter/index'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })


export default {
  props: {
    isAllStore: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    currencyCode: {
      type: String,
      default: () => {
        return ''
      },
    },
    isFolded: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      hasElseWallet: false, // 是否有其它钱包货币
      isFocus: false,
    }
  },
  computed: {
    ...mapState([
      'riskStr',
      'walletLimit',
      'walletFreez',
      'primeProductList',
      'usedPrimeIndex',
      'status',
      'checkout',
      'caculateData',
      'language',
      'locals',
    ]),
    IS_RW() {
      return this.locals.IS_RW
    },
    SiteUID() {
      return this.locals.SiteUID
    },
    caculateInfo() {
      return this.checkout?.mall_caculate_info || {}
    },
    showWalletTips() {
      let totalPrice = 0
      if(this.caculateInfo.wallet?.totalPrice?.length){
        this.caculateInfo.wallet?.totalPrice.map( item => totalPrice += Number(item?.price?.amount) || 0 )
      }
      const walletData = this.isWalletAvalabled.price?.amount || totalPrice || '0'

      if(+walletData <= 0) return false 

      return this.walletLimit.length || this.isShowReason
    },
    inputDisabled() {
      return (!this.checkUserWallet && this.riskStr != 2) || (this.caculateData?.use_wallet && !this.status.loading?.wallet) || (this.status.hasAddress == 0 || this.caculateData.use_wallet || !this.isWalletAvalabled ? true : false)
    },
    showAllApply() {
      const canApply = this.checkUserWallet && this.caculateData.use_wallet != 1

      if(!canApply || this.inputDisabled || this.isFocus || this.walletData) return false
      return true
    },
    isShowWalletUsed() {
      return this.caculateInfo?.walletPrice?.amount > 0
    },
    isShowReason() {
      return (
        (this.hasElseWallet ||
          this.walletFreez.restTxt ||
          this.walletFreez.unpaidTxt ||
          this.isAllStore ||
          this.showPrimeLimit) &&
        ((this.status.hasAddress && this.walletFreez.showReason) ||
          this.isAllStore)
      )
    },
    showPrimeLimit() {
      if (!this.primeProductList.length || this.usedPrimeIndex == -1)
        return false
      return true
    },
    checkUserWallet() {
      return +this.caculateInfo?.wallet?.subtractPrice?.amount > 0
    },
    walletData: {
      get() {
        return this.$store.state.walletData
      },
      set(newVal) {
        this.$store.commit('updateCheckoutState', {
          key: 'walletData',
          val: newVal,
        })
      },
    },
    isWalletAvalabled() {
      let _wallet = [],
          _useWallet = '',
          hasElseWallet = false

      if (this.caculateInfo.wallet && this.caculateInfo.wallet.totalPrice) {
        _wallet = this.caculateInfo.wallet.totalPrice.filter((item) => {
          return item.currencyCode == this.currencyCode
        })

        let allWallet = this.caculateInfo.wallet.totalPrice
        // 当钱包总货币大于1个且有当前货币钱包时，或者当前货币钱包没有但钱包总货币有
        if (
          (allWallet.length > 1 && _wallet.length) ||
          (!_wallet.length && allWallet.length)
        ) {
          hasElseWallet = true
        }
      }
      this.hasElseWallet = hasElseWallet
      this.assignState({ hasElseWallet: hasElseWallet })

      _useWallet = _wallet.length ? _wallet[0] : ''

      return _useWallet
    },
    walletAiraLabel() {
      let tip = ''
      if (this.caculateInfo.wallet && this.riskStr != 2) {
        tip =
          this.language.SHEIN_KEY_PC_18434 +
          this.language.SHEIN_KEY_PC_14540 +
          ' ' +
          (this.isWalletAvalabled?.price
            ? this.isWalletAvalabled?.price.amountWithSymbol
            : this.caculateInfo.wallet.subtractPrice.amountWithSymbol
              ? this.caculateInfo.wallet.subtractPrice.amountWithSymbol
              : 0)
      }
      return tip
    },
    mallStoreInfos() {
      return this.caculateInfo?.mall_store_infos || []
    },
    carts() {
      return this.checkout?.results?.carts?.carts || []
    },
  },
  watch: {
    mallStoreInfos: {
      deep: true,
      immediate: true,
      handler(v) {
        if (v.length) this.handleLimitData()
      },
    },
    carts: {
      deep: true,
      immediate: true,
      handler(v) {
        // 购物车数据发生变化时，更新礼品卡钱包限制商品数据
        if (v.length) this.handleLimitData()
      },
    },
  },
  created() {
    this.getWalletFreez()
  },
  methods: {
    ...mapMutations([
      'updateCheckoutState',
      'assignState',
      'changeParamsStatus',
    ]),
    viewLimitReason() {
      this.changeParamsStatus({
        type: 'freezTipsAndGoods',
        params: {
          show: true,
          type: 'wallet',
          dialogTitle: this.language.SHEIN_KEY_PC_22900
        },
      })
    },
    handleLimitData() {
      const { walletLimit = [] } = handleLimitGoods({
        mallStoreInfos: this.mallStoreInfos,
        carts: this.carts,
      })
      this.assignState({
        walletLimit: walletLimit,
      })
    },
    apply(obj) {
      window.checkoutEventCenter.emit('click-apply-assets', obj)
    },
    applyAllsubmit() {
      this.updateCheckoutState({
        key: 'walletData',
        val: this.caculateInfo.wallet.subtractPrice.amount,
      })
      this.status.isWalletAmoutHit = true
      window.checkoutEventCenter.emit('click-apply-assets', { type: 'wallet', isUseAll: true, byUser: 1 })

      daEventCenter.triggerNotice({
        daId: '1-11-1-142',
      })
    },
    walletPlaceholderInit() {
      let _walletPlaceholder = ''
      if (
        this.caculateInfo.orderCurrency &&
        this.caculateInfo.orderCurrency.trunc > 1 &&
        this.caculateInfo.orderCurrency.decimal_place == 0
      ) {
        _walletPlaceholder = this.language.SHEIN_KEY_PC_16679.replace(
          '{0}',
          this.caculateInfo.orderCurrency.trunc
        )
      } else {
        _walletPlaceholder = +this.caculateInfo.wallet?.subtractPrice?.amount > 0 ? this.caculateInfo.wallet.subtractPrice.amountWithSymbol : ''
      }
      return _walletPlaceholder
    },
    // 获取钱包冻结信息
    getWalletFreez() {
      // 逻辑变更
      let tradeCountInfo = this.checkout?.results?.walletInfo || {}
      this.changeParamsStatus({
        type: 'walletFreez',
        params: {
          showReason: true,
        },
      })
      let { data: tradeInfo = [] } = tradeCountInfo || {}
      if (tradeInfo && tradeInfo.length) {
        this.changeParamsStatus({
          type: 'walletFreez',
          params: {
            list: tradeInfo,
          },
        })
        let freezList = tradeInfo.filter(
          (item) => item.second_type == '2200' && item.trade_count > 0
        )
        let unpaidList = tradeInfo.filter(
          (item) => item.second_type == '2100' && item.trade_count > 0
        )
        if (freezList.length) {
          this.changeParamsStatus({
            type: 'walletFreez',
            params: {
              restTxt: true,
            },
          })
        }
        if (unpaidList.length) {
          this.changeParamsStatus({
            type: 'walletFreez',
            params: {
              unpaidTxt: true,
            },
          })
        }
      }
    },
    // points input max
    setMaxWallet() {
      if (this.riskStr == 2) return
      this.status.error.wallet = 0
      this.onWalletKeyUp()
      if (
        this.walletData * 1 >
        this.caculateInfo.wallet.subtractPrice.amount * 1
      ) {
        this.updateCheckoutState({
          key: 'walletData',
          val: this.caculateInfo.wallet.subtractPrice.amount,
        })
        this.status.error.maxAvailableWallet = 1
      } else {
        this.status.error.maxAvailableWallet = 0
      }
    },
    onWalletKeyUp() {
      let _data = this.walletData.toString(),
          _trunc = this.caculateInfo.orderCurrency.trunc, // 最小使用单位
          _decimal_place = this.caculateInfo.orderCurrency.decimal_place, // 小数点位数
          _minWallet = (_trunc + 'e-' + _decimal_place) * 1 //最少使用钱包值

      if (_decimal_place == 0) {
        //整数
        _data = _data.replace(/\D/g, '')
      } else if (_decimal_place > 0) {
        //小数
        _data = _data.replace(/[^0-9|.]/g, '')
        let _dataArr = _data.split('.')
        if (_dataArr.length > 1) {
          // 只允许一个小数点
          if (_dataArr.length > 2) {
            _dataArr.length = 2
          }
          // 小数位数不可超过decimal_place
          if (_dataArr[1].length > _decimal_place) {
            _dataArr[1] = _dataArr[1].substring(0, _decimal_place)
          }

          _data = _dataArr.join('.')
        }
      }
      // apply按钮置灰不可点击
      if (_data.length > 0) {
        this.status.isWalletAmoutHit = _data * 1 >= _minWallet ? true : false

        if (_data.indexOf('.') < 0) {
          _data = _data * 1
        }
      } else {
        this.status.isWalletAmoutHit = true
      }

      this.updateCheckoutState({
        key: 'walletData',
        val: _data,
      })
    },
  },
}
</script>

<style lang="less">
.only-for-self {
  .margin-l(5px);
}
.point-link-high {
  color: @sui_color_gray_dark1;
}

.limit-reason-more {
  .margin-l(4px);
  color: @sui_color_link;
  cursor: pointer;
}

.input-wallet__all {
  .sui-input__suffix-inner {
    min-width: 60px;
    max-width: 80px;
  }
}
.wallet-apply-effiency {
  color: @sui_color_link;
  font-size: 12px;
  cursor: pointer;

  overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    white-space: normal;
}

.success-tip{
  margin-top: 5px;
  color: @sui_color_success;
}
.c-order-summary .check-coupon{
		margin: 5px 0 30px 0;
		.apply-box{
			margin-top: 3px;
			>a{
				width: 35%;
				display: block;
			}
		}

		.apply-box{
			.card-num{
				width: calc(~"47% - 10px");
			}
			.password{
				width: 17%;
				.margin-l(1%);
				margin-top: 0;
			}
			>a{
				width: 35%;
				display: block;
			}
			.la-ball-pulse{
				>div{
					background-color:#fff;
				}
			}
		}
		.error-tip{
			margin-top: 5px;
			color: @sui_color_unusual;
		}
		.warn-tip{
			margin-top: 5px;
		}
		.success-tip{
			margin-top: 5px;
			color: @sui_color_success;
		}
		.point-link{
			margin-top: 5px;
			color: #767676;
			display: inline-block;
			text-decoration: none;
			a {
				cursor: pointer;
				color: @sui_color_link;
			}
		}
		.first-order-off{
			margin-top: 10px;
		}
		.flash-red #message {
			color: red;
    }
    .red-point-tips {
      a{ position: relative ; }
      a:after {
        content: '';
        display: block;
        position: absolute;
        width: 4px;
        height: 4px;
        background: #FF411C;
        border-radius: 50%;
        top: 0;
        .right(-8px);
      }
    }
		.pick-gift{
			background: #FFF7F0;
			border-radius: 2px;
			margin-top: 15px;
			padding: 12px 10px 10px;
			.flexbox();
			.align-center();
			.space-between();
			.pick-gift-left{
				.pick-gift-title{
					font-size: 12px;
					font-weight: bold;
					color: #222222;
					line-height: 14px;
					text-transform: capitalize;
					padding-bottom: 5px;
				}
				.pick-gift-desc{
					font-size: 12px;
					color: #222;
					font-weight: normal;
				}
			}
			.pick-gift-right{
				span{
					font-size: 12px;
					color: #222;
					font-weight: bold;
					cursor: pointer;
				}
			}
		}
	}
@media (max-width: 1440px) {
	.c-order-summary{
		.check-coupon{
			margin: 0 0 16px 0;
		}
		.check-coupon-bottom {
			margin-bottom: 0;
		}
	}
}
</style>

<template>
  <div
    v-if="shippingMethods.length > 2"
    class="shipping-options__contianer"
    @mouseenter="enterEvent"
    @mouseleave="leaveEvent"
  >
    <swiper-container
      ref="shippingRightSwiper"
      :dir="GB_cssRight ? 'rtl' : 'ltr'"
      init="false"
      class="shipping-drop shipping-options-right__wrap shipping-right-more"
    >
      <swiper-slide
        v-for="(item, index) in shippingMethods"
        :key="index"
        class="shipping-options__swiper-slide"
      >
        <ShippingOptionsItem 
          :item="item"
          :index="index"
          :default-shipping-item="defaultShippingItem"
          :is-click-more="true"
          :is-all-quick-ship="isAllQuickShip"
          :is-quick-ship-title="isQuickShipTitle"
          :desc-quick-ship-time="descQuickShipTime"
          :mall-code="mallCode"
          :prime-coupon-count="primeCouponCount"
          :more-position="morePosition"
          :quick-ship-info="quickShipInfo"
          :default-selected-index="defaultSelectedIndex"
          :add-item-content-info="addItemContentInfo"
          @click-shipping="clickShipping"
        />
      </swiper-slide>
      <!-- eslint-disable vue/no-deprecated-slot-attribute -->
      <div slot="container-start">
        <div
          v-show="showSwiperButton"
          :class="['swiper-button-prev', 'common-button-style', 'common-swiper_prev', 'shipping-options__prev']"
        >
          <Icon
            name="sui_icon_more_up_18px"
            size="18px"
            :is-rotate="GB_cssRight"
          />
        </div>
      </div>
      <div slot="container-end">
        <div
          v-show="showSwiperButton"
          :class="['swiper-button-next', 'common-button-style', 'common-swiper_next', 'shipping-options__next']"
        >
          <Icon
            name="sui_icon_more_down_18px"
            size="18px"
            :is-rotate="GB_cssRight"
          />
        </div>   
      </div>
    </swiper-container>
    <div class="swiper-scrollbar"></div>
  </div>

  <ul 
    v-else
    class="shipping-drop shipping-options-right__wrap"
  >
    <ShippingOptionsItem 
      v-for="(item, index) in shippingMethods"
      :key="index"
      :item="item"
      :index="index"
      :default-shipping-item="defaultShippingItem"
      :is-click-more="true"
      :is-all-quick-ship="isAllQuickShip"
      :is-quick-ship-title="isQuickShipTitle"
      :desc-quick-ship-time="descQuickShipTime"
      :mall-code="mallCode"
      :prime-coupon-count="primeCouponCount"
      :more-position="morePosition"
      :quick-ship-info="quickShipInfo"
      :default-selected-index="defaultSelectedIndex"
      :add-item-content-info="addItemContentInfo"
      @click-shipping="clickShipping"
    />
  </ul>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue3'
import { Navigation, Scrollbar } from 'swiper/modules'
import { register } from 'swiper/element'
import 'swiper/css/scrollbar'

typeof window !== 'undefined' && register()

import ShippingOptionsItem from 'public/src/pages/checkout/components/shipping_method/ShippingMethodItem.vue'

export default {
  name: 'ShippingOptionsRight',
  components: {
    ShippingOptionsItem,
    Icon,
  },
  props: {
    shippingMethods: {
      type: Array,
      default: () => []
    },
    defaultShippingItem: Object,
    isAllQuickShip: Boolean,
    isQuickShipTitle: Boolean,
    descQuickShipTime: String,
    mallCode: [String, Number],
    primeCouponCount: Number,
    morePosition: Number,
    quickShipInfo: Object,
    defaultSelectedIndex: {
      type: Number,
      default: 0
    },
    addItemContentInfo: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      showSwiperButton: false,
      shippingSwiper: null,
    }
  },
  computed: {
    ...mapState([
      'locals',
    ]),
    ...mapGetters([
      'orderInterceptByAbt',
    ]),
    GB_cssRight() {
      return this.locals?.GB_cssRight
    },
  },
  watch: {
    shippingMethods: {
      deep: true,
      handler(n) {
        if (n.length > 2) this.initSwiper(this.defaultShippingItem?.index)
      }
    },
  },
  mounted() {
    if(this.shippingMethods.length > 2) this.initSwiper(this.defaultShippingItem?.index)
  },
  methods: {
    enterEvent() {
      this.showSwiperButton = true
    },
    leaveEvent() {
      this.showSwiperButton = false
    },
    clickShipping({ item, addItemUpdate, isInterceptBack, skipShipIntercept }) {
      const index = this.shippingMethods.findIndex(_ => _.transport_type == item.transport_type)
      if (this.shippingMethods.length > 2) {
        this.slideToIndex(index)
      }
      this.$emit('click-shipping', { item, addItemUpdate, isInterceptBack, skipShipIntercept })
    },
    slideToIndex(index) {
      if (this.shippingSwiper) {
        this.shippingSwiper?.swiper.slideTo(index, 0, false)
      }
    },
    initSwiper(index) {
      this.shippingSwiper = null
      this.$nextTick(() => {
        this.shippingSwiper = this.$refs.shippingRightSwiper 
        if (this.shippingSwiper !== null) {
          let swiperParams = {
            slidesPerView: 'auto',
            slidesPerGroup: 1,
            spaceBetween: 8,
            normalizeSlideIndex: false,
            autoHeight: true,
            observer: true,
            observeParents: true,
            modules: [Navigation, Scrollbar],
            direction: 'vertical',
            navigation: {
              nextEl: `.shipping-options__next`,
              prevEl: `.shipping-options__prev`
            },
            scrollbar: {
              hide: false,
              el: '.swiper-scrollbar',
              dragClass: 'swiper-scrollbar-drag__new',
              draggable: true,
              snapOnRelease: true
            }
          }
          Object.assign(this.shippingSwiper, swiperParams)
          this.shippingSwiper.initialize()
          // msg类型为9时，默认选中，不展示勾选，不滚动
          const shippingMethod = this.defaultShippingItem.shipping_method || {}
          const cannotChecked = shippingMethod.is_available != 1 && this.orderInterceptByAbt
          if (!cannotChecked) {
            setTimeout(() => {
              this.slideToIndex(index)
            }, 0)
          }
        }
      })
    },
  },
  emits: ['click-shipping']
}
</script>

<style lang="less">
.shipping-options__contianer{
  min-height: 200px;
  position: relative;
}
.shipping-options-right__wrap {
  .swiper-slide {
    height: unset;
  }
  .shipping-options__swiper-slide{
    display: flex;
    height: unset;
    width: 100%;
    margin-bottom: 8px;
  }
  .common-swiper_prev {
    top: 2px;
    .left(50%);
    transform: translateX(-50%);
    margin-top: 0;
  }
  .common-swiper_next {
    bottom: 2px;
    top: unset;
    .left(50%);
    transform: translateX(-50%);
    margin-top: 0;
  }
}
.swiper-scrollbar {
    background: none;
}
.swiper-scrollbar-drag__new {
  background: #000;
  opacity: 0.3;
  cursor: pointer;
  border-radius: 10px;
}
.shipping-right-more {
  min-height: 200px;
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
  overflow: hidden;
}
.common-button-style{
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(255, 255, 255, .94);
  border-radius: 50%;
  z-index: 12;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .08);
  color: #222;
  font-size: 16px;
}
</style>

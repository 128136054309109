/**
 * 获取免邮凑单弹窗的配置参数
 * @param {String} mallCode - mallCode 参数，默认为 platform 
 * @param {Object} freeMallApiData  - 免邮接口返回的数据
 * @param {Array} cartApiData - 购物车接口返回的数据
 * @returns {Object}
 */
export function getOpts4FreeShipping({ mallCode = 'platform', mallShippingInfo = {}, cartsApiData = [] } = {}) {
  if (!(mallCode && mallShippingInfo && cartsApiData)) return console.error('[getOpts4FreeShipping]: 请检查参数 mallCode、freeMallApiData cartsApiData 是否传入')
  // 当前免邮凑单活动
  const freeShippingInfo = mallShippingInfo[mallCode] ?? {}

  // 获取当前档位
  const getFreeShippingSatisfied = (shippingInfoAvailable) => {
    const diffPrice = shippingInfoAvailable?.diff_price || {}
    const nowLevel = shippingInfoAvailable?.now_level || 0
    return diffPrice?.amount == 0 ? 1 : (nowLevel > 0 ? 2 : 0)
  }

  // 获取进度条
  const getFreeShippingProgressRate = (shippingInfoAvailable = {}) => {
    const { diff_price = {}, origin_price = {} } = shippingInfoAvailable
    if (!diff_price.amount || !origin_price.amount) return 0
    if (diff_price.amount <= 0) return 100
    return 100 - (diff_price.amount / origin_price.amount) * 100
  }

  const getAddOnType = () => {
    if(freeShippingInfo.isFirstFreeShipping) return 3
    return freeShippingInfo.activity_type > 0 ? [ '', 0, 1, 4 ][freeShippingInfo.activity_type] : ''
  }

  const getGoodsIdMap = () => cartsApiData?.carts.map((item) => item.product.goods_id)
  const getCatIdMap = () => cartsApiData?.carts.map((item) => item.product.cat_id)
  const promotion = {
    promotion_id: freeShippingInfo?.firstFreeShipping?.firstFreeShippingTip ? 998 : 999,
    addOnType: 'freeShipping',
    title: '',
    tip: (freeShippingInfo.isFirstFreeShipping ? freeShippingInfo.firstFreeShipping?.firstFreeShippingTip : freeShippingInfo.shippingTip) || '',
    showCountDown: !!freeShippingInfo.firstFreeShipping?.showCountDown, // 是否展示倒计时，目前只有首单免邮有倒计时
    countDownTimeStamp: freeShippingInfo.firstFreeShipping?.endTimestamp || 0, // 倒计时结束时间,
    progressRate: getFreeShippingProgressRate(freeShippingInfo.shippingInfoAvailable || {}), // 进度条进度
    isShowProgress: !!(freeShippingInfo.shippingInfoAvailable?.diff_price && freeShippingInfo.shippingInfoAvailable?.origin_price), // 是否展示进度条，目前运费活动返回的字段无法算出进度条进度，所不展示
    activityId: freeShippingInfo.shippingInfoAvailable?.activity_id || '', // 活动id，运费活动没有该字段
    activityType: freeShippingInfo.shippingInfoAvailable?.activity_type || 0, //活动类型，运费活动是3 免邮活动是1
    diffPrice: freeShippingInfo.shippingInfoAvailable?.diff_price || {}, // 差额
    progressComponentName: 'FreeShippingProgress', // 进度条组件
    isSatisfied: getFreeShippingSatisfied(freeShippingInfo.shippingInfoAvailable || {}),
    shippingMethodName: freeShippingInfo.shippingInfoAvailable?.shipping_method_name || '', // 运费活动名称，以此判断是否是运费活动切换了
    goodsPrice: (!freeShippingInfo.shippingInfoAvailable?.charging_type || freeShippingInfo.shippingInfoAvailable?.charging_type == 3) ? freeShippingInfo.shippingInfoAvailable?.diff_price?.usdAmount : 0, // 计价方式是否是金额类型，运费活动可能不是金额类型
    includeTspId: freeShippingInfo.shippingInfoAvailable?.add_on_goods_info_list?.filter(item => item.config_type == 1).map(item => item.goods_pool_id).join(','),
    excludeTspId: freeShippingInfo.shippingInfoAvailable?.add_on_goods_info_list?.filter(item => item.config_type == 2).map(item => item.goods_pool_id).join(','),
    mallCode: mallCode === 'platform' ? '' : mallCode, // mall code 平台级传空
    isCloseFreeShipping: false, // 购物车使用，是否关闭免邮凑单弹窗
    callShippingFreeMallCount: Date.now(), // 调用免邮接口的次数，凑单弹窗利诱点气泡使用,购物车才需要严格控制，其他模块不需要，所以默认值为当前时间戳
  }

  const props = {
    ...promotion,
    // 查询推荐接口的一些数据
    queryParams: {
      goodsIds: getGoodsIdMap(), // 商品ID,
      cateIds: getCatIdMap(), // 类目ID
      sceneId: 152, // 凑单列表值
      addOnType: getAddOnType(),
      mallCode: promotion?.mallCode,
      goodsPrice: promotion?.goodsPrice, // 凑单差额价格(美分)
      includeTspId: promotion?.includeTspId,
      excludeTspId: promotion?.excludeTspId,
      quickship_prior: 0, // 是否包含 quickship 商品
    },
    // 提供给埋点的一些参数
    saInfo: {
      // 营销 code
      promotion_code: promotion.activityId,
      // 加购来源
      state: 'shipping_add',
      // 场景值
      activity_from: 'shipping_add',
      abtest: 'addnewhotJson',
    },
    // 配置
    config: {
      isClickToDetail: false,
      isCloseIncentivePoint: false,
      continueBtnUseLangKey: '',
      titleUseLangKey: '',
    },
  }

  return props
}

/**
 * 获取结算页免邮凑单弹窗的配置参数
 * @description WIKI: 1311685888
 * @param {String} mallCode - mallCode 参数，默认为 platform 
 * @param {Object} freeMallApiData  - 免邮接口返回的数据
 * @param {Array} cartApiData - 购物车接口返回的数据
 * @param {Boolean} isQuickship - 运输方式返回了 quickship 提示文案
 * @param {String} transportType - 当前凑单的活动运输方式
 * @param {Boolean} isBuyNow - 立即购加车
 * @param {String} casualCheckoutNo - 立即购加车订单号
 * @returns {Object}
 */
export function getOpts4FreeShippingFromCheckout({ mallCode = 'platform', mallShippingInfo = {}, cartsApiData = [], isQuickship = false, transportType = '', isBuyNow = false, casualCheckoutNo = '' } = {}) {
  const props = getOpts4FreeShipping({ mallCode, mallShippingInfo, cartsApiData })
  props.countDownTimeStamp = 0
  props.saInfo.state = 'checkout_shipping_add'
  props.saInfo.activity_from = 'checkout_shipping_add'
  props.saInfo.promotion_code = props.activityId
  props.queryParams.quickship_prior = isQuickship ? 1 : 0
  props.config.isCloseIncentivePoint = true
  props.config.titleUseLangKey = 'SHEIN_KEY_PC_28506'
  props.config.continueBtnUseLangKey = 'SHEIN_KEY_PC_30310'
  props.config.isBuyNow = isBuyNow
  props.config.casualCheckoutNo = casualCheckoutNo

  const currMallActivityInfo = mallShippingInfo[mallCode] ?? {}
  const currMallShippingInfo = mallShippingInfo?.mall_transport_free_list?.find(item => item.mall_code === mallCode)?.transport_free_list?.find(item => item.transport_type === transportType) ?? {}
  // 活动暂停
  props.isActivityStop = (currMallActivityInfo.transportType !== transportType && currMallShippingInfo.is_free_shipping === 1) || (currMallActivityInfo.transportType === transportType && currMallActivityInfo.isFreeShipping)
  // 活动结束
  props.isActivityEnd = currMallActivityInfo.transportType !== transportType && currMallShippingInfo.is_free_shipping === 0

  console.log('[getOpts4FreeShippingFromCheckout.mallShippingInfo]', mallShippingInfo)
  console.log('[getOpts4FreeShippingFromCheckout.props]', props)
  return props
}


<template>
  <div
    v-if="recommendProducts && recommendProducts.goods_id "
    ref="singleProductRef"
    class="singleProduct-container"
    :data-singleid="`${recommendProducts.goods_id}`"
    @click.stop.prevent="selectEvent"
  >
    <SingleImageRowCard
      v-if="isHandling"
      :config="SingleImageRowCardConfig"
      :language="language"
      :item="recommendProducts"
      :constant-data="productItemMixin"
      :loading="addLoading"
      :style="{
        '--product-card____add-btn-border-color': '#FA6338',
        '--product-card____add-btn-bg': '#FA6338',
        '--product-card____add-btn-color': '#fff'
      }"
      @open-quick-add="addEvent"
    >
      <template #cardBottomAddCardLeft>
        <div
          v-if="isFeaturePrior"
          :style="{'font-size': '12px'}"
        >
          <CommonCartSize :item="buidProduct(productInfo)" />
        </div>
      </template>
    </SingleImageRowCard>

    <ClientOnly>
      <div
        v-if="pageLoading"
        class="single-container-modal"
      >
        <Loading
          :is-loading="true"
          bg-color="#FA6338"
        />
      </div>
    </ClientOnly>
  </div>
</template>
<script>
import { reactive, toRefs, computed, getCurrentInstance, ref, onMounted, nextTick, defineAsyncComponent } from 'vue'
import { transformImg } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { useStore } from 'vuex'
import SingleImageRowCard from 'public/src/pages/components/productItemV3/SingleImageRowCard.vue'
import CommonCartSize from 'public/src/pages/goods_detail_v2/components/RecommendRelatives/CommonCart/CommonCartSize.vue'
import productItemMixin from 'public/src/pages/components/productItem/mixin'
import { getFormatCommonCartProductItem, setCommonCartProductSkuInfo } from 'public/src/pages/goods_detail_v2/components/RecommendRelatives/CommonCart/utils.js'
import { dealProductsPretreatInfo } from 'public/src/services/goodsItemInfo/goodsPretreatInfo.js'
import { daEventCenterMap } from './config'
import { getGoodsListForAnalysis } from 'public/src/pages/checkout/utils.js'
import { goodsItem_config } from 'public/src/pages/checkout/vue_tpls/together_buy/components/config.js'
import { formatFn } from 'public/src/pages/checkout/hooks/useTogether.js'


export default {
  components: {
    SingleImageRowCard,
    CommonCartSize,
    Loading: defineAsyncComponent(() => import('public/src/pages/checkout/vue_tpls/together_buy/components/loading.vue')),
  },
  mixins: [productItemMixin],
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    checkout: {
      type: Object,
      default: () => {}
    },
    togetherBuy: {
      type: Object,
      defautlt: () => {}
    },
    recommendProducts: {
      type: Object,
      default: () => {}
    },
  },
  emits: ['isActiveEmit'],
  setup(props, { emit }){
    const instance = getCurrentInstance()
    const vuexStore = useStore()
    const globalProperties = instance.appContext.app.config.globalProperties
    const state = reactive({
      goodsListAllId: [],
      beforeInfo: '',
      pageLoading: false,
      addLoading: false,
      selectLoading: false,
      isHandling: false,
      SingleImageRowCardConfig: {
        ...goodsItem_config.SingleImageRowCardConfig,
        showSheinClubPrice: props.togetherBuy?.isPaid, //是否显示付费会员价格
        showSheinClubPriceOnSale: props.togetherBuy?.isPaid, //是否显示付费会员价格
        showSheinClubNewLabel: props.togetherBuy?.isPaid, //是否显示付费会员价格
        showSheinClubLabelInServicesLabel: true,
        showS3VipPriceOnSale: props.togetherBuy?.showS3VipPriceOnSale, // 主价格是否接入S3会员价
        showRrpPrice: props.togetherBuy?.showRrpPrice,      // 是否显示原价
      },
    })
    const singleProductRef = ref(null)
    const isFeaturePrior = computed(() =>{
      return props?.checkout?.checkoutBFFAbtInfo?.FeaturePriorWeb?.p?.isFeaturePrior == 1
    })
    const buyNowNo = computed(() =>{
      return vuexStore.state.buyNowNo
    })
    const isFastAddWeb = computed(() =>{
      return props?.checkout?.checkoutBFFAbtInfo?.FastAddWeb?.p?.isFastAddWeb == 1
    })
    const oneSkuObj = computed(() => {
      // 判断是否是单属性/多属性
      const { goods_id, mall_code, sale_attr_list } = props.recommendProducts || {}
      const { skc_sale_attr = [], sku_list = [],  } = sale_attr_list || {}
      const goods_list = getGoodsListForAnalysis([props.recommendProducts], +props.togetherBuy.OrderGoodsLocation, 1, null, state.SingleImageRowCardConfig)
      // sku_code 默认取第一个
      let isOneSku = false,
          sku_code = sku_list[0].sku_code || ''
      // 没有次级销售属性，但会有一个默认的sku_code，sku_code作为入参直接加车
      if ((!skc_sale_attr.length || skc_sale_attr.length == 1) && sku_list.length == 1) {
        isOneSku = true
      }

      return { isOneSku, sku_code, goods_id, mall_code, sale_attr_list, goods_list, skc_sale_attr }
    })
    const getAnalysisInfo = () => {
      const ActivityType = {
        Freeshipping: 'freeshipping',
        Coupon: 'coupon',
        Quickship: 'quickship'
      }
      const info = [ActivityType.Freeshipping, ActivityType.Coupon, ActivityType.Quickship]
      const { carts = [] } = props.checkout?.results?.carts || {}
      const { mall_list = [], actual_shipping_price } = props.checkout?.mall_caculate_info || {}
      const quickshipNum = carts.filter(cart => +cart.real_quick_ship).length
      let freeShipping = +actual_shipping_price <= 0 ? 1 : 0
      // 如果不是整车免运费，则需要分 mall 进行判断
      if (freeShipping === 0) {
        const transportType = vuexStore.state.defaultShipping[0]?.shipping_method?.transport_type
        const freeShippingNum = mall_list.filter(mall => (
          Array.isArray(mall?.shipping_price_all) && mall.shipping_price_all.some(item => item.transport_type == transportType && item.price.amount <= 0 ) 
        )).length
        // 0: 整车运费不为0；1: 整车运费为0；2: 部分 mall 运费为0
        if (freeShippingNum.length === 0) {
          freeShipping = 0
        } else if (freeShippingNum === mall_list.length) {
          freeShipping = 1
        } else {
          freeShipping = 2
        }
      }

      const map = {
        [ActivityType.Freeshipping]: freeShipping,
        [ActivityType.Coupon]: vuexStore.state.usableCouponList.length,
        // 0: 都不是 qs 商品；1: 都是 qs 商品；2: 部分商品为 qs 商品
        [ActivityType.Quickship]: quickshipNum ? (quickshipNum === carts.length ? 1 : 2) : 0
      }
      return info.map(type => `${type}\`${map[type]}`).join(',')
    }
    // 加车结果后埋点
    const reportAnalysisData = (analysisConfig) => {
      const { result, ...argumentsParams } = analysisConfig
      const { info, cart, msg, code } = result
      let findData = {}
      if(code == 0){
        findData = info.carts.find(f => f.id == cart.id)
      }
      const params = {
        goods_id: findData?.product.goods_id,
        mall_code: findData?.mall_code,
        quickship_tp: findData?.real_quick_ship,
        size: findData?.product.size,
        sku_code: findData?.product.sku_code,
        result_reason: msg,
        result: code == 0 ? 1 : 2,
        ...argumentsParams
      }
      daEventCenterMap['report-analysis-data'](params)
    }
    // 加车成功后埋点
    const reportSuccessData = () => {
      const afterInfo = getAnalysisInfo()
      daEventCenterMap['report-success-data']({ beforeInfo: state.beforeInfo, afterInfo })
      state.beforeInfo = afterInfo
    }
    // 加购按钮
    const addEvent = () => {
      if(state.addLoading || state.pageLoading) return
      state.addLoading = true

      const { isOneSku, sku_code, goods_id, mall_code } = oneSkuObj.value
      if(isOneSku) return handleRequestAdd({ goods_id, mall_code, sku_code, isOneSku })

      if(isFastAddWeb.value && isFeaturePrior.value){
        state.pageLoading = true
        handleRequestAdd({ goods_id, mall_code, sku_code, isOneSku })
      } else {
        openQuickView()
      }
    }
    // 点击热区唤起加车弹窗
    const selectEvent = () => {
      if(state.addLoading) return
      openQuickView()
    }

    // 打开加车弹窗
    const openQuickView = () => {
      const { goods_list } = oneSkuObj.value
      daEventCenterMap['click-together'](goods_list)
      state.addLoading = true
      const { goods_id, imgRatio } = props.recommendProducts || {}
      const { _cartInfo = {} } = buidProduct(props.recommendProducts) || {}
      import('public/src/pages/common/quickView').then(({ default: quickView }) => {
        quickView.show({
          imgRatio,
          goodsId: goods_id,
          analysis: {
            sa: {
              code: 'checkout',
              from: 'add_more_items'
            }
          },
          extendForm: {
            selectedAttrSkuCode: _cartInfo?.skuCode || '',
            selectedAttrIndex: _cartInfo?.selectedAttrIndex || {},
          },
          config: {
            showBestDealLabel: true,
            showFollowBeltByOrigin: true,
            showEstimatedPrice: true,
            isUpdateButton: false,
            hideViewFullDetail: true, // 隐藏商详
            hideWishlistBtn: true, // 不展示收藏功能按钮
            isBuyNow: !!buyNowNo.value,
            casualCheckoutNo: buyNowNo.value,
          },
          callback: {
            handleOpenQuickViewDone: () => {
              state.addLoading = false
            },
            handleAddToCartDone: (data) => {
              if (data?.code == '0') {
                // 存储加车信息
                if(!buyNowNo.value) {
                  vuexStore.commit('changeTogetherBuy', {
                    quickViewCartData: data
                  })
                } else {
                  vuexStore.commit('changeTogetherBuy', {
                    quickViewCartData: {
                      cart: data.info?.carts?.[0],
                      info: data.info.info
                    }
                  })
                }
                addQuickCall({ goods_id })
              } else if (data?.code == -2) {
                globalProperties.$message({
                  message: props.language.SHEIN_KEY_PC_28078,
                  type: 'error'
                })
              } else {
                globalProperties.$message({
                  message: props.language.SHEIN_KEY_PC_28079,
                  type: 'error'
                })
              }
            }
          }
        })
      })
    }

    // 直接加车
    const handleRequestAdd = async ({ goods_id, mall_code, sku_code }) =>{

      const { goods_list } = oneSkuObj.value
      daEventCenterMap['click-together'](goods_list)

      const messageError = (message) => {
        globalProperties.$message({
          message: message,
          type: 'error'
        })
      }
      const data = { goods_id,  mall_code, sku_code, quantity: 1, add_source: 12,
        trace_id: gbExposeTraceid('getProduct', { goods_id })
      }

      try {
        let res
        if(!buyNowNo.value) {
          res = await schttp({
            method: 'POST',
            url: '/api/cart/add_mall/create',
            data
          })
        } else {
          res = await schttp({
            method: 'POST',
            url: '/api/checkout/postRapidBatchAddCart/create',
            data: {
              product_list: [data],
              add_source: 12,
              checkout_no: buyNowNo.value
            }
          })
        }
        
        // 直接加车和购物车快捷加车返回不一样，需要注意
        const analysisConfig = { result: { ...res, cart: !!buyNowNo.value ? res.info?.carts?.[0] : res.info.cart, info: res.info.info } }
        reportAnalysisData(analysisConfig)
        if (res.code == 0) {
          // 存储加车信息
          vuexStore.commit('changeTogetherBuy', {
            quickViewCartData: { cart: !!buyNowNo.value ? res.info?.carts?.[0] : res.info.cart, info: res.info.info }
          })
          addQuickCall({ goods_id: data.goods_id })
        } else if (res?.code == -2) {
          messageError(props.language.SHEIN_KEY_PC_28078)
        } else {
          messageError(props.language.SHEIN_KEY_PC_28079)
        }

        state.addLoading = false
        state.pageLoading = false
      } catch (err) {
        state.addLoading = false
        state.pageLoading = false
        messageError('err')
      }
    }

    // 加完车后的回调
    const addQuickCall = ({ goods_id }) => {
      const el = singleProductRef.value

      el && emit('isActiveEmit', {
        el,
        goods_id
      })

      reportSuccessData()
    }

    // 销售属性
    const buidProduct = () => {
      const product = getFormatCommonCartProductItem(props.recommendProducts)
      const { sku_code } = oneSkuObj.value
      return setCommonCartProductSkuInfo(sku_code, product, props.language.SHEIN_KEY_PC_14617) || product
    }

    // 数据预处理
    const dealInitData = (val) => {
      state.isHandling = false
      let { promotionInfoFromServer, exposedGoodsId } = props.togetherBuy
      const product = !props.recommendProducts && val ? val : props.recommendProducts
      const newProdct = dealProductsPretreatInfo({
        products: [product],
        itemConfig: state.SingleImageRowCardConfig,
        promotionInfoFromServer,
        language: props.language,
      })
      let goods_id = product?.goods_id
      if(exposedGoodsId){
        goods_id = goods_id + ',' + exposedGoodsId
      }
      vuexStore.commit('changeTogetherBuy', {
        recommendProducts: newProdct,
        exposedGoodsId: goods_id
      })
      nextTick(() => {
        state.isHandling = true
        intersectionObserver()
      })
    }

    // 曝光埋点
    const intersectionObserver = () => {
      if (!IntersectionObserver) return
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          const singleId = entry?.target?.dataset?.singleid
          if (entry.isIntersecting && !state.goodsListAllId.includes(singleId)) {
            state.goodsListAllId.push(singleId)
            const goods_list = getGoodsListForAnalysis([props.recommendProducts], +props.togetherBuy.OrderGoodsLocation, 1, null, state.SingleImageRowCardConfig)
            daEventCenterMap['together-module_list']({ goods_list })
          }
        })
      }, { threshold: 0.5 })
      observer.observe(singleProductRef.value)
    }

    // 首次获取推荐数据
    const recommendProductsInit = async () => {
      try{
        const params = formatFn({ abt: props?.checkout?.checkoutBFFAbtInfo || {}, carts: props?.checkout?.results?.carts?.carts || [] })
        const result = await schttp({
          method: 'POST',
          url: '/api/checkout/recommendProducts/get',
          data: params,
        })
        if (result.code == 0) {
          const { products, promotionInfoFromServer, isRegulations, togetherBuyType, isPaid } = result.info
          vuexStore.commit('changeTogetherBuy', {
            recommendProducts: products,
            promotionInfoFromServer, togetherBuyType, isRegulations, isPaid
          })
        } else {
          vuexStore.commit('changeTogetherBuy', { togetherBuyType: 0 })
          globalProperties.$message({
            message: result.msg,
            type: 'error'
          })
        }
      }catch(err){
        vuexStore.commit('changeTogetherBuy', { togetherBuyType: 0 })  
      }
    }

    onMounted(async () => {
      window.appEventCenter.on('together-buy-updated', (val)=>{
        dealInitData(val)
      }) 
      await recommendProductsInit()
      dealInitData()

    })

    return {
      transformImg,
      isFeaturePrior,
      selectEvent,
      addEvent,
      singleProductRef,
      buidProduct,
      dealInitData,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less">
.singleProduct-container{
  min-height: 160px;
  margin-top: 18px;
  padding: 0 12px 10px 0;

  .add-bag-icon {
    background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyIgd2lkdGg9IjEyIiBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgMTIgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNi43NTExMyA1LjI1MTFWMS44MDQ2OUg1LjI1MTE1VjUuMjUxMUgxLjgwNjY0VjYuNzUxMDhINS4yNTExNVYxMC4xOTc0SDYuNzUxMTNWNi43NTEwOEgxMC4xOTkzVjUuMjUxMUg2Ljc1MTEzWiIgZmlsbD0iI0ZGRkZGRiI+CjwvcGF0aD4KPC9zdmc+) no-repeat;
  }
  .product-card__add-bag {
    background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiB2aWV3Qm94PSIwIDAgMjAgMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMiAzLjI1VjQuNzVINC4xNDA1Nkw1LjM0MDU2IDE0LjA1SDE3LjA0MjdMMTguMDA0MiA2LjczOTZIMTYuNDkxM0wxNS43MjcxIDEyLjU1SDYuNjU5NDRMNS40NTk0NCAzLjI1SDJaIiBmaWxsPSIjRkZGRkZGIj4KPC9wYXRoPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTExLjkgNC43NDcxOUgxMC40VjYuNzM2NjlIOC40MDAwMlY4LjIzNjY5SDEwLjRWMTAuMjI2NEgxMS45VjguMjM2NjlIMTMuOVY2LjczNjY5SDExLjlWNC43NDcxOVoiIGZpbGw9IiNGRkZGRkYiPgo8L3BhdGg+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNy4zMjk5NiAxNi4zQzcuMzI5OTYgMTUuNzQ3OCA2Ljg4MjI0IDE1LjMgNi4zMjk5NiAxNS4zQzUuNzc3NjcgMTUuMyA1LjMyOTk2IDE1Ljc0NzggNS4zMjk5NiAxNi4zQzUuMzI5OTYgMTYuODUyMyA1Ljc3NzY3IDE3LjMgNi4zMjk5NiAxNy4zQzYuODgyMjQgMTcuMyA3LjMyOTk2IDE2Ljg1MjMgNy4zMjk5NiAxNi4zWiIgZmlsbD0iI0ZGRkZGRiI+CjwvcGF0aD4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy4wNSAxNi4zQzE3LjA1IDE1Ljc0NzggMTYuNjAyMyAxNS4zIDE2LjA1IDE1LjNDMTUuNDk3OCAxNS4zIDE1LjA1IDE1Ljc0NzggMTUuMDUgMTYuM0MxNS4wNSAxNi44NTIzIDE1LjQ5NzggMTcuMyAxNi4wNSAxNy4zQzE2LjYwMjMgMTcuMyAxNy4wNSAxNi44NTIzIDE3LjA1IDE2LjNaIiBmaWxsPSIjRkZGRkZGIj4KPC9wYXRoPgo8L3N2Zz4=) no-repeat;
  }

  .single-selectBtn{
    border-radius: 39px;
    background: var(---sui_color_gray_weak2, #F6F6F6);
    min-height: 20px;
    padding: 4px 6px 4px 8px;
    width: fit-content;
    display:flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .img-container{
      background: #7B7E41;
      width: 12px;
      height: 12px;
      object-fit: contain;
      border-radius: 50%;
      margin-right: 3px;
    }
    span{
      color: var(---sui_color_gray_dark2, #666);
      font-family: "SF Pro";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .bar{
      margin: 0 3px;
    }
  }
  .product-card__add-btn {
    width: 58px;
  }
  .add-btn{
    border-radius: 100px;
    border: 1px solid #FA6338;
    background: #FA6338;
    display: flex;
    height: 28px;
    padding: 4px 15px;
    justify-content: center;
    align-items: center;
    width: fit-content;
    color: #FFF;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
  }
}
.single-container-modal{
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <div
    v-if="isShowReturnCoupon && isMultiReturnCoupon"
    v-expose="analysisData"
    class="mcheo-price-panel checkout-return-coupon__wrap"
  >
    <MultiReturnCoupon
      v-if="isMultiReturnCoupon"
      :language="language"
      :coupon-promotion-info="returnCouponPromotions"
      :count-timer-obj="countTimer && countTimer.timeObj"
      :show-count-down="isShowCountdown"
      :locals="locals"
    />
  </div>
  <div
    v-else-if="isShowReturnCoupon && couponItemInfo && isSingleReturnCoupon"
    v-expose="analysisData"
    class="mcheo-price-panel checkout-return-coupon__wrap"
  >
    <div
      class="mcheo-price-while return-coupon"
    >
      <div
        class="return-coupon__title"
        v-html="timeStepTxt"
      ></div>
      <div
        v-if="couponItemInfo"
        class="return-coupon__content"
      >
        <coupon-item
          :item="couponItemInfo"
          :language="language"
          :show-coupon-code="false"
          :is-only-time="true"
          :is-order-return="true"
          direction-type="row"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CouponItem from 'public/src/pages/components/coupon/mall/index.vue'
import MultiReturnCoupon from './MultiReturnCoupon.vue'

import { template, CountDown } from '@shein/common-function'
import { mapState } from 'vuex'
import schttp from 'public/src/services/schttp'
import { expose } from 'public/src/pages/common/analysis/directive.js'


export default {
  name: 'ReturnCoupon',
  directives: { expose },
  components: { CouponItem, MultiReturnCoupon },
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    scene: {
      type: String,
      default: '',
      required: true,
      validator: val => ['multiCoupon', 'singleCoupon'].includes(val)
    }
  },
  data () {
    return {
      hasTimeStep: false,
      countTimer: new CountDown(),
      couponItemInfo: null,
      isShowCountdown: false,
      isShowReturnCoupon: false
    }
  },
  computed: {
    ...mapState([
      'integrityPromotionInfo', 
      'updatingPrice',
      'checkout',
      'locals',
    ]),
    returnCouponPromotions () {
      let data = null
      for (const item in this.integrityPromotionInfo || {}) {
        if (this.integrityPromotionInfo[item].type_id == '21') {
          data = this.integrityPromotionInfo[item]
        }
      }
      
      return data
    },
    // 获取倒计时文案
    timeStepTxt () {
      if (!this.isShowCountdown) return this.language.SHEIN_KEY_PC_25653
      const { H, M, S } = this.countTimer?.timeObj || {}
      let spanTxt = txt => `<span>${txt}</span>`
      return this.template(
        spanTxt(H) + '<em>:</em>' + spanTxt(M) + '<em>:</em>' + spanTxt(S),
        this.language.SHEIN_KEY_PC_25654
      )
    },
    isMultiReturnCoupon () {
      return this.scene == 'multiCoupon' && this.returnCouponPromotions?.coupon_num > 1
    },
    isSingleReturnCoupon () {
      return this.scene == 'singleCoupon' && this.returnCouponPromotions?.coupon_num === 1
    },
    isCorrectScene () {
      return this.isMultiReturnCoupon || this.isSingleReturnCoupon
    },
    analysisData () {
      const is_couponbag = this.isMultiReturnCoupon ? 1 : 0
      return { 
        id: '1-11-1-115',
        data: { is_couponbag }
      }
    }
  },
  watch: {
    returnCouponPromotions () {
      this.initCoupon()
    },
    updatingPrice () {
      this.initCoupon()
    }
  },
  created () {
    const abtInfo = this.checkout?.checkoutBFFAbtInfo?.ReturnInform
    this.isShowReturnCoupon = abtInfo?.param?.['ControlPlaceOrderReturn'] == 'on'
  },
  mounted() {
    this.initCoupon({ initCountDown: true })
  },
  methods: {
    template,
    initCoupon(props) {
      const { initCountDown = false } = props ?? {}
      if (!this.isShowReturnCoupon) return
      if (this.returnCouponPromotions) {
        if (this.returnCouponPromotions?.promotion_range_info?.range == 0 || !this.isCorrectScene) return
        this.getCouponDetails(this.returnCouponPromotions?.coupons?.[0]?.coupon_code)
        if(initCountDown) this.getCountDownTips(this.returnCouponPromotions)
      }
    },
    // 倒计时提示语
    getCountDownTips (val) {
      const endDate = val?.end_time * 1000
      const stepTime = endDate - new Date().getTime()
      if (stepTime < 12 * 60 * 60 * 1000) {
        // 初始化倒计时
        this.countTimer.start({
          seconds: Math.ceil(stepTime / 1000),
          endFunc: () => {
            this.isShowReturnCoupon = false
          }
        })
        this.isShowCountdown = true
        return
      }
      this.isShowCountdown = false
    },
    // 获取优惠券详情
    async getCouponDetails (coupon) {
      const res = await schttp({
        method: 'POST',
        url: '/api/checkout/couponDetail/get',
        params: {
          coupon
        }
      })
      if (res?.code == '0') {
        this.couponItemInfo = res?.info?.coupon || null
      }
    }
  }
}
</script>

<style lang="less">
.checkout-return-coupon__wrap {
  background: #fff;
  padding: 16px;
  margin-bottom: 10px;
}
.return-coupon {
  &__title {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 12px;
    span {
      color: #fff;
      background-color: #000000;
      padding: 2px;
      font-size: 12px;
      /*rw:begin*/
      background-color: #fc4070;
    }
    em {
      font-weight: 400;
      color: #222;
      /*rw:begin*/
      color: #fc4070;
    }
  }
}
</style>

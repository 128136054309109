<template>
  <div
    v-for="(item, index) in autoDwContentArr"
    :key="index"
    class="product-container product-container_active"
  >
    <template v-if="['xtra'].includes(item.type)">
      <p
        v-if="autoType == 'common'"
        class="common_prodct-tips"
      >
        {{ language.SHEIN_KEY_PC_27055 }}
      </p>
      <xtraProductContent
        :current-price-discount="currentPriceDiscount"
        :current-days-price-discount="currentProductCycleDays"
        :current-product="currentProduct"
        :current-product-cycle-days="currentProductCycleDays"
        :auto-type="autoType"
        :is-suggested="isSuggested"
      />
    </template>

    <template v-if="['prime'].includes(item.type)">
      <p
        v-if="autoType == 'common'"
        class="common_prodct-tips"
      >
        {{ language.SHEIN_KEY_PC_31243 }}
      </p>
      <div class="prime_container_warrp">
        <div
          v-if="item.currentProduct && item.currentProduct.product_price_info"
          class="item-containerPro"
        >
          <div :class="['product-item opacityStyle']">
            <div
              class="auto-label"
            >
              {{ language.SHEIN_KEY_PC_29428 }}
            </div>
            <div class="item-header">
              {{ item.currentProduct.product_name_language }}
            </div>
            <div class="content">
              {{ item.currentProduct.product_price_info.arrival_price_with_symbol }}
              <del
                v-if="item.currentPriceDiscount && isSuggested"
                class="del"
              >
                {{ item.currentProduct.product_price_info.price_local_with_symbol }}
              </del>
            </div>
          </div>
          <div class="tips">
            {{ language.SHEIN_KEY_PC_29650 }}
          </div>
        </div>
  
        <Icon
          name="sui_icon_nav_back_24px"
          size="24px"
          color="#C96E3F"
          class="derection"
        />
  
        <div
          v-if="item.currentProductCycleDays && item.currentProductCycleDays.product_price_info"
          class="item-containerPro"
        >
          <div :class="['product-item']">
            <div class="item-header">
              {{ item.currentProductCycleDays.product_name_language }}
            </div>
            <div class="content">
              {{ item.currentProductCycleDays.product_price_info.arrival_price_with_symbol }}
              <del
                v-if="item.currentDaysPriceDiscount && isSuggested"
                class="del"
              >
                {{ item.currentProductCycleDays.product_price_info.price_local_with_symbol }}
              </del>
            </div>
          </div>
          <div class="tips select">
            {{ language.SHEIN_KEY_PC_29651 }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script setup>
import { inject, computed } from 'vue'
const { language } = inject('autoDwFatherProp') // 使用 inject 接收父组件提供的数据
import { Icon } from '@shein-aidc/icon-vue3'
import { useStore } from 'vuex'
import xtraProductContent from './xtra_product_content'

const store = useStore()

const props = defineProps({
  currentProduct: {
    type: Object,
    default: () => ({})
  },
  currentPriceDiscount: {
    type: Boolean,
    default: false
  },
  currentProductCycleDays: {
    type: Object,
    default: () => ({})
  },
  currentDaysPriceDiscount: {
    type: Boolean,
    default: false
  },
  autoType: {
    type: String,
    default: 'prime'
  }
})
const productAutoInfoPrime = computed(() => {
  return store.getters.productAutoInfo
})
const clubScribePriceByAbt = computed(() => {
  return store.getters?.clubScribePriceByAbt
})

const isSuggested = computed(() => {
  const commonPrime = props.autoType == 'prime' && !clubScribePriceByAbt.value
  const commonXtra = props.autoType == 'xtra' && store.state.checkout?.HIDE_SAVER_STRIKETHROUGH_PRICE == 'off'

  return (commonXtra || commonPrime) && !store.getters?.isSuggested
})
const autoDwContentArr = computed(() => {
  const { currentProduct, currentPriceDiscount, currentProductCycleDays, currentDaysPriceDiscount } = props
  const baseInfo = { type: 'xtra', currentProduct, currentPriceDiscount, currentProductCycleDays, currentDaysPriceDiscount }
  const currentPriceDiscountPrime = +productAutoInfoPrime.value?.currentProduct?.product_price_info?.price_local !== +productAutoInfoPrime.value?.currentProduct?.product_price_info?.special_price
  const currentDaysPriceDiscountPrime = +productAutoInfoPrime.value?.currentProductCycleDays?.product_price_info?.price_local !== +productAutoInfoPrime.value?.currentProductCycleDays?.product_price_info?.special_price
  const commonPrime = { type: 'prime', currentProduct: productAutoInfoPrime.value.currentProduct, currentPriceDiscount: currentPriceDiscountPrime, currentProductCycleDays: productAutoInfoPrime.value.currentProductCycleDays, currentDaysPriceDiscount: currentDaysPriceDiscountPrime }

  const map = {
    prime: [commonPrime],
    xtra: [baseInfo],
    common: [baseInfo, commonPrime],
  }
  return map[props.autoType]
})
</script>
<style lang="less" scoped>
.xtra_product-item{
  border:none !important;
  background: #FFF6F8 !important;
}
.product-container_active{
  margin-bottom: 15px;
  flex-direction: column;
}
.prime_container_warrp{
  display: flex;
  justify-content: space-between;
}
.common_prodct-tips{
  margin-bottom: 10px;
  font-weight: 700;
}
</style>

import { computed, onMounted, ref, watch } from 'vue'
import { omit } from 'lodash'
import { template } from '@shein/common-function'
import { useMapState, useMapGetters, useMapMutation as useMapMutations, useMapActions } from 'public/src/pages/store_pages/store/mapHook'
import { useDaEventCenter } from '../useDaEventCenter'
import { getSupportAutoRenewalPayLogoList } from '../utils'

// ###会费券模块
export const useMembership = ({ props }) => {
  const { virtualCouponInfo, showPrimeLimitModal } = useMapState([ 'virtualCouponInfo', 'showPrimeLimitModal' ])
  const { autoRenewalPrimeShowAbt, isShowPriceReminderByAbt, clubScribePriceByAbt } = useMapGetters([ 'productAutoInfo', 'autoRenewalPrimeShowAbt', 'isShowPriceReminderByAbt', 'clubScribePriceByAbt' ])
  const { upDatePrimeSceneStatus, changeParamsStatus, assignState } = useMapMutations(['upDatePrimeSceneStatus', 'changeParamsStatus', 'assignState'])
  const { clubDaEvent } = useDaEventCenter()
  // 优惠弹窗配置props
  const discountDialogProps = ref({ priceInfo: null, opened: false, iconTips: false })
  
  // 付费会员包数据重组场景
  const productList = computed(() => {
    const result = virtualCouponInfo.value.newPrimeProductList?.map(m => {
      const productInfo = m.primeProductList
      const currentPromotion_type = productInfo.product_price_info?.promotion_type != 0
      const isLegaDialog = isShowPriceReminderByAbt.value && currentPromotion_type
      const isAutoRenewal = autoRenewalPrimeShowAbt.value && productInfo.product_activity_type == 1
      const isOnlyAutoRenewal = [3, 4].includes(m.scence) && isAutoRenewal
      const showOfferLabel = +productInfo.product_price_info.special_price < +productInfo.product_price_info.price_local
      const sceneResult = {
        isVisible: [8, 10].includes(m.scence) ? true : false, // 优惠明细弹窗
        isTips: [6, 9].includes(m.scence) ? true : false, // 跳转用券列表
        isLegal: [3, 4].includes(m.scence) && isLegaDialog, // 法务弹窗
        iconTips: isLegaDialog, // 法务弹窗icon展示
        isCouponRightsCode: m.primeProductList?.product_price_info?.product_dues_coupon_rights?.coupon_code, // 会费券码
        isOnlyAutoRenewal, // 仅存在自动续费活动
        isAutoRenewal, // 是否是自动续费
        isRenewalLegalDialog: isOnlyAutoRenewal && isLegaDialog, // 仅存在自动续费活动且满足法务弹窗条件
        showOfferLabel // 包详细弹窗-按钮-是否展示折扣标签
      }  
      return {
        ...m.primeProductList,
        ...omit(m, ['primeProductList']),
        sceneResult
      }
    }) || []

    return result
  })
  
  // 法务弹窗配置props
  const limitModalProps = computed(() => ({
    opened: showPrimeLimitModal.value,
    setOpened: value => assignState({ showPrimeLimitModal: value })
  }))

  // 付费会员标签不同状态相应逻辑
  const handleOfferLabelClick = (productInfo, position) => {
    const { isVisible, isLegal, isTips, iconTips, isRenewalLegalDialog } = productInfo.sceneResult

    if (props.notSupport) return console.warn('no support club')

    if(isTips){
      // 用券列表
      clubDaEvent['go-coupon-list']({ position })
      changeParamsStatus({ type: 'coupon', params: { showCouponList: true }
      })
    } else if(clubScribePriceByAbt.value || isVisible){
      // 优惠明细
      discountDialogProps.value = {
        priceInfo: productInfo.product_price_info,
        opened: true,
        iconTips
      }
    } else if(isLegal || isRenewalLegalDialog){
      // 法务弹窗
      assignState({ showPrimeLimitModal: true })
    }
  }

  // 会费券更新场景
  upDatePrimeSceneStatus()
  
  return { productList, handleOfferLabelClick,  discountDialogProps, limitModalProps }
}

// ###自动续费切包弹窗模块
export const useAutoSwitchPackage = ({ productList }) => {
  const { productAutoInfo } = useMapGetters([ 'productAutoInfo'])
  const productAutoDrawerRef = ref(null)
  const nextPaymentInfo = ref(null)
  const eventInfo = ref({})
  
  // 初始化传入事件，解决传入报错问题
  const initSwitchPackageModules = ({ clubChangeInfo }) => {
    eventInfo.value.clubChangeInfo = clubChangeInfo
  }
  
  // 监听切换支付方式，需要自动续费包弹窗 发射的事件
  const onPaymentEvent = () => {
    window?.checkoutEventCenter?.on('payment-choosepayment-event', opt => {
      if (opt.currentAutoProductDwType == 'prime') {
        const { title = '', logo_url: logo = '', code = '' } = opt?.item || {}
        productAutoDrawerRef.value?.visibleEvent({
          visible: true,
          ...productAutoInfo.value,
          paymentInfo: { title, code, logo }
        })
        nextPaymentInfo.value = opt
      }
    })
  }
  
  // 自动续费回调
  const autoSubmit = ({ productCode, isdont }) => {
    const index = productList.value.findIndex(
      item => item.product_code === productCode
    )
    if (index !== -1) {
      // isdont, true => 产品包不选，支付方式切换非快捷
      window.checkoutEventCenter.emit('again-choose-payment', {
        paymentInfo: nextPaymentInfo.value,
        Index: index,
        isdont,
        iSautoPay: 'autoPay',
        afterCallBack: ({ Index, isdont, frLocation }) => {
          eventInfo.value.clubChangeInfo(Index, isdont, frLocation)
        }
      })
    }
  }
  
  onMounted(() => { onPaymentEvent() })
  
  return { productAutoDrawerRef, initSwitchPackageModules, autoSubmit }
}

// ###自动续费快捷支付方式弹窗模块
export const useAutoPaymentDialog = ({ productList }) => {
  // 快捷支付方式列表弹窗信息
  const payMethodsListDrawerHeaderData = ref(null)
  const payMethodsListDialogRef = ref(null)
  const eventInfo = ref({})

  // 自动续费支付方式列表弹窗
  const handlePayMethodsListDrawerShow = options => {
    const { product_name_language, product_price_info, product_code } = options || {}
    const { total_discount_price_with_symbol, arrival_price_with_symbol, price_local_with_symbol } = product_price_info || {}

    payMethodsListDrawerHeaderData.value = {
      product_name_language,
      total_discount_price_with_symbol,
      arrival_price_with_symbol,
      price_local_with_symbol,
      product_code
    }
    payMethodsListDialogRef.value?.handlerShow()
  }

  // 选择包
  const paymentUpdataSelectPackage = (val) => {
    const codeId = payMethodsListDrawerHeaderData.value?.product_code
    const nextSelectdPrimeIndex = productList.value?.findIndex( item => codeId == item.product_code )
    const resultIndex = nextSelectdPrimeIndex == -1 ? 0 : nextSelectdPrimeIndex
    const { fromLocation } = val || {}
    eventInfo.value.clubChangeInfo(resultIndex, false, fromLocation )
  }

  // 初始化传入事件，解决传入报错问题
  const initPaymentDialogModules = ({ clubChangeInfo }) => {
    eventInfo.value.clubChangeInfo = clubChangeInfo
  }

  return { payMethodsListDialogRef, payMethodsListDrawerHeaderData, handlePayMethodsListDrawerShow, paymentUpdataSelectPackage, initPaymentDialogModules }
}

// ###快捷支付方式气泡模块
export const useAutoBubble = ({ props, productList }) => {
  const { checkout, status, language } = useMapState(['checkout', 'status', 'language'])
  
  const logoList = ref([])
  const autoRenewalBubbleConfig = ref([])
  const closedAutoRenewalBubbleIndexList = ref([])
  const eventInfo = ref({})

  // 气泡配置props
  const payMethodBubbleProps = computed(() => {
    return { 
      logoList: logoList.value, 
      text: template(logoList.value.length, language.value.SHEIN_KEY_PC_29641),
      onClick: eventInfo.value.handlePayMethodsListDrawerShow
    }
  })
  
  // 快捷支付方式气泡关闭
  const handleBubbleClose = index => {
    closedAutoRenewalBubbleIndexList.value = [...closedAutoRenewalBubbleIndexList.value, index ]
  }
  
  // 快捷支付方式气泡是否显示
  const needSelectAutoRenewalPayMethod = productInfo => {
    const isRenewProductBag = productInfo.sceneResult.isAutoRenewal
    const hasAddress = checkout.value.default_address?.addressId == 0 ? false : true
  
    if (!hasAddress || !isRenewProductBag) return false

    const { paymentMethods = [], primeProductInfo = {} } = checkout.value.results
    const limitedPayTypeList = primeProductInfo?.limitedPayTypeList?.map(item => item?.code) || []
    const intersectionValue = paymentMethods.filter(item => limitedPayTypeList?.includes(item.code) && item?.enabled)
    const isInLimitPayment = limitedPayTypeList.includes(status.value.cardPay)
  
    // 不走后续逻辑：1、当前站点的支付方式与接口返回的自动续费支付方式限制列表无交集
    if (!intersectionValue?.length) return false

    // 出现气泡，并且控制是否出现弹窗
    // 1、先选择产品包后选择支付方式，或者无支付方式。
    // 2、先选择了支付方式后选择包，当前选择的支付方式如果在限制支付列表中就不展示气泡，否则，就展示气泡
    // 3、如果当前选择的支付方式是payPal/afterpay，并在接口返回的自动续费支付方式限制列表中，但是没有记住当前的卡号
    const payPalNotRememberCount = isInLimitPayment &&
      ['paypal-gapaypal'].includes(status.value.cardPay.toLowerCase()) &&
      props.paymentMethodsProps?.paypalGaVault?.radioType == 0
    const afterpayNotRememberCount = isInLimitPayment &&
      ['afterpay-card'].includes(status.value.cardPay.toLowerCase()) &&
      ((props.paymentMethodsProps?.afterPayVault?.type == 0 &&
        !props.paymentMethodsProps?.afterpayAccountInfo?.id) ||
        !props.paymentMethodsProps?.afterPayVault?.switch)
  
    return (
      status.value.cardPay === '' ||
      !isInLimitPayment ||
      payPalNotRememberCount ||
      afterpayNotRememberCount
    )
  }

  const initAutoBubbleModules = ({ handlePayMethodsListDrawerShow }) => {
    eventInfo.value.handlePayMethodsListDrawerShow = handlePayMethodsListDrawerShow 
  }
    
  watch(
    [ () => productList.value.length, closedAutoRenewalBubbleIndexList, () => status.value.cardPay, () => checkout.value.results,
      () => props.paymentMethodsProps?.paypalGaVault?.radioType, () => props.paymentMethodsProps?.afterPayVault?.radioType
    ],
    () => {
      // 快捷支付方式气泡是否显示
      autoRenewalBubbleConfig.value = productList.value.map((m, index) => {
        const needSelect = needSelectAutoRenewalPayMethod(m)
        return { needSelect, visible: closedAutoRenewalBubbleIndexList.value.includes(index) ? false : needSelect }
      })
    },
    {
      immediate: true
    }
  )

  watch(
    () => props.paymentMethodsProps,
    () => {
      // 自动续费限制支付方式
      logoList.value = getSupportAutoRenewalPayLogoList({
        banksConfig: props.banksConfig,
        limitedPayTypeList: checkout.value?.results?.primeProductInfo?.limitedPayTypeList,
        paymentMethods: checkout.value?.results?.paymentMethods,
        paymentMethodsProps: props.paymentMethodsProps
      })
    }
  )
  
  return {  autoRenewalBubbleConfig, payMethodBubbleProps, handleBubbleClose, needSelectAutoRenewalPayMethod, initAutoBubbleModules }
}

// ###更多包详情抽屉模块
export const useMorePackageDetail = ({ props, productList }) => {
  const { checkout, usedPrimeIndex, showPrimeDetail, lowPrimeProduct, usedPrimeCode, selectdPrimeIndex, primePopFrom } = 
      useMapState(['checkout', 'price', 'usedPrimeIndex', 'showPrimeDetail', 'lowPrimeProduct', 'usedPrimeCode', 'selectdPrimeIndex', 'primePopFrom'])
  const { assignState } = useMapMutations(['assignState'])
  const { getPrimeRightCouponInfo } = useMapActions(['getPrimeRightCouponInfo'])  
  const { clubDaEvent } = useDaEventCenter()
  
  const eventInfo = ref({})

  // club可为本单折扣的金额
  const discountPrice = computed(() => {
    const { prime_total_price, pay_member_discount_price } = checkout.value.mall_caculate_info
    return (usedPrimeIndex.value >= 0 ? prime_total_price : pay_member_discount_price) || {}
  })
  // 抽屉是否展示本单优惠
  const showBenefit = computed(() => {
    const isShowByAbt = checkout.value.checkoutBFFAbtInfo?.Checkoutmemberbenefit?.param?.checkout_member_benefit_guide == 'on' ? true : false
    // 当前本单可折扣金额 >= 当前付费会员产品包中最低的产品包的价格
    const { product_price_info = {} } = lowPrimeProduct.value
    const isShow = +discountPrice.value.amount >= +product_price_info.special_price
    return isShowByAbt && isShow
  })
  // 更多包详情弹窗配置
  const detailDrawerProps = computed(() => {
    return  {
      opened: showPrimeDetail.value,
      discountPrice: discountPrice.value,
      showBenefit: showBenefit.value,
      setOpened: setDetailDrawerOpened,
      onClose: handleDetailDrawerClose,
      onOfferLabelClick: eventInfo.value.handleOfferLabelClick,
      onProductSelect: handleDetailDrawerSelectPkg,
      onOk: handleDetailDrawerOk,
      onCloseFromIcon: handleDetailDrawerIconClose
    }
  })
  
  // 是否展示更多包详情抽屉
  const setDetailDrawerOpened = value => assignState({ showPrimeDetail: value })
    
  // 打开更多包详情弹窗
  const viewMorePackage = () => {
    if (props.notSupport) return
    setDetailDrawerOpened(true)
    assignState({ primePopFrom: 1 })
    clubDaEvent['view-more-product']()
  }
  
  // 点击外层关闭抽屉
  const handleDetailDrawerClose = () => {
    if(usedPrimeCode.value){
      eventInfo.value.upDatacClubProductInfo({ index: usedPrimeIndex.value, type: 'onlyRight' })
    } else {
      const defaultPrimeIndex = productList.value.findIndex(item => +item.is_recommend)
      eventInfo.value.upDatacClubProductInfo({ index: defaultPrimeIndex == -1 ? 0 : defaultPrimeIndex, type: 'onlyRight' })
      // 更新权益
      getPrimeRightCouponInfo()
    }
  }
  
  // 抽屉里选择包
  const handleDetailDrawerSelectPkg = index => {
    const productInfo = productList.value[index]
    clubDaEvent['handle-product-select']({ productInfo, index })
    eventInfo.value.upDatacClubProductInfo({ index, type: 'onlyRight' })
  }
    
  // 抽屉选择确认
  const handleDetailDrawerOk = () => {
    const selectedProduct = productList.value[selectdPrimeIndex.value]
    if (eventInfo.value.needSelectAutoRenewalPayMethod(selectedProduct)) {
      eventInfo.value.handlePayMethodsListDrawerShow(selectedProduct)
      return
    }
    clubDaEvent['handle-detail-drawer']({ selectedProduct, type: 'ok' })
    
    setDetailDrawerOpened(false)
    
    // 选包模块：location (1-随单购，2-弹窗，3-支付方式，4-下单页挽留弹窗)
    const fromLocation = primePopFrom.value == 1 || primePopFrom.value == 2 || primePopFrom.value == 3 ? 2 : ''
    eventInfo.value.clubChangeInfo(selectdPrimeIndex.value, false, fromLocation )
  }
    
  // 抽屉icon关闭
  const handleDetailDrawerIconClose = () => {
    setDetailDrawerOpened(false)
    const selectedProduct = productList.value[selectdPrimeIndex.value]
    clubDaEvent['handle-detail-drawer']({ selectedProduct, type: 'close' })
  }
  
  // 初始化传入事件，解决传入报错问题
  const initMorePackageModules = ({ clubChangeInfo, upDatacClubProductInfo, handlePayMethodsListDrawerShow, handleOfferLabelClick, needSelectAutoRenewalPayMethod }) => {
    eventInfo.value.clubChangeInfo = clubChangeInfo
    eventInfo.value.upDatacClubProductInfo = upDatacClubProductInfo
    eventInfo.value.handlePayMethodsListDrawerShow = handlePayMethodsListDrawerShow
    eventInfo.value.handleOfferLabelClick = handleOfferLabelClick
    eventInfo.value.needSelectAutoRenewalPayMethod = needSelectAutoRenewalPayMethod
  }
  
  return { detailDrawerProps, viewMorePackage, initMorePackageModules }
}
  

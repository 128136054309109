import { events } from '../constant'
import{ getCurrentScope } from 'vue'

export function useEventBus(key) {
  const scope = getCurrentScope?.()
  function on(listener) {
    const listeners = (events.get(key) || new Set())
    listeners.add(listener)
    events.set(key, listeners)

    const _off = () => off(listener)
    scope?.cleanups?.push(_off)
    return _off
  }

  function once(listener) {
    function _listener(...args) {
      off(_listener)
      listener(...args)
    }
    return on(_listener)
  }

  function off(listener) {
    const listeners = events.get(key)
    if (!listeners)
      return

    listeners.delete(listener)

    if (!listeners.size)
      reset()
  }

  function reset() {
    events.delete(key)
  }

  function emit(event, payload) {
    events.get(key)?.forEach(v => v(event, payload))
  }

  return { on, once, off, emit, reset }
}

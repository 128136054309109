<template>
  <div>
    <GoodsList 
      v-if="lowestPriceData.length"
      class="j-activity-left"
      :language="language"
      :goods-list="lowestPriceData"
      :is-show-goods-dicount="isShowGoodsDicount"
    />
    <Tips 
      v-if="tipsDescription && tipsDescription.length"
      :language="language"
      :icon="tipsIcon"
      :description="tipsDescription"
      :bubble-copy-writing-by-abt="bubbleCopyWritingByAbt"
    />
  </div>
</template>
<script>
import { template } from '@shein/common-function'
import GoodsList from './goods_info/GoodsList.vue'
import Tips from './Tips.vue'
import { iconConfig } from './config'

export default {
  name: 'LowestPricePopover',
  components: {
    GoodsList,
    Tips,
  },
  props: {
    checkout: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    lowestPriceBySomeDays: {
      type: Object,
      default: () => {}
    },
    bubbleCopyWritingByAbt: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    isShowGoodsDicount() {
      return !this.bubbleCopyWritingByAbt
    },
    lowestPriceData() {
      return this.lowestPriceBySomeDays?.lowestPriceData || []
    },
    tipsDescription() {
      const txt = this.template(this.lowestPriceBySomeDays.days, this.language.SHEIN_KEY_PC_31394)
      return this.template(`<em>${txt}</em>`, this.language.SHEIN_KEY_PC_31427)
    },
    tipsIcon() {
      return iconConfig?.lowestPrice || {}
    },
  },
  methods: {
    template
  }
}
</script>

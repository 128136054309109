<template>
  <div
    class="checkout-prime-new__wrap"
  >
    <div class="checkout-prime__wrap-header">
      <div 
        v-if="showNewStyle"
        ref="primeLogoRef"
        class="checkout-prime__logo_new"
      >
        <img 
          :src="primeLogoImg"
          width="96"
          height="18"
        />
      </div>
      <div 
        v-else-if="showNewLogo"
        class="checkout-prime__logo"
        :class="{
          'checkout-prime__logo_ar': GB_cssRight
        }"
      >
        <sui_icon_club_logo_15px 
          size="20px" 
          color="#c96e3f"
        />
        <div 
          ref="primeLogoRef"
          class="checkout-prime__title"
        >
          <div class="checkout-prime__title-club">
            SHEIN CLUB
          </div>
          <div class="checkout-prime__title-num">
            {{ buyEffectiveNum }}
          </div>
        </div>
      </div>
      <img
        v-else
        class="checkout-prime__wrap-logo"
        :src="primeLogoImg"
        alt=""
      />
      <div 
        v-if="showNewStyle"
        class="checkout-prime__wrap-tips"
      >
        {{ language.SHEIN_KEY_PC_31292 }}
      </div>
      <div 
        v-else
        class="checkout-prime__wrap-des"
        :class="{'no-support': notSupport || status.hasAddress == 0, 'high-light': isHighLightDeduce}"
        @click="viewMore"
        v-html="primeDeduceText"
      >
      </div>
    </div>

    <div 
      v-if="showNewStyle"
      class="checkout-prime__wrap-des_new"
      :class="{
        'no-support': notSupport || status.hasAddress == 0, 
        'high-light': isHighLightDeduce,
        'single-product': primeProductList.length <= 1
      }"
      @click="viewMore"
      v-html="primeDeduceText"
    >
    </div>

    <PrimeOptionsList 
      :not-support="notSupport || status.hasAddress == 0"
      :selected-prime="selectedPrime"
      :paypal-ga-vault="paypalGaVault"
      :banks-config="banksConfig"
      :payment-methods-props="paymentMethodsProps"
      @change-virtunal-dia="changeVirtunalDia"
      @choose-prime="reversePrime" 
      @change-active-index="changeActiveIndex"
      @handle-pay-methods-list-drawer-show="handlePayMethodsListDrawerShow"
    />

    <div
      class="checkout-prime__wrap-policy"
      :class="{
        'no-support': notSupport || status.hasAddress == 0,
        'single-product': primeProductList.length <= 1
      }"
      @click="clickTerm"
    >
      <span v-html="agreeText"></span>
      <ClientOnly>
        <sui_icon_doubt_16px_1
          v-if="activePrimeType === primeType.AutoRenewal"
          size="14px"
          style="cursor: pointer;"
          @click.stop="autoRenewalTCDialogVisible = true"
        />
      </ClientOnly>
    </div>
    <div
      v-if="notSupport"
      class="checkout-prime__wrap-tip"
    >
      <sui_icon_reported_14px_1
        size="12px"
        color="#bf4123"
      />
      {{ template(nowPayment.title, language.SHEIN_KEY_PC_22969) }}
    </div>

    <ClientOnly>
      <div v-if="isClient">
        <PrimeDetail
          ref="primeDetail"
          :paypal-ga-vault="paypalGaVault"
          :payment-methods-props="paymentMethodsProps"
          :banks-config="banksConfig"
          @choose-prime="reversePrime"
          @handle-pay-methods-list-drawer-show="handlePayMethodsListDrawerShow"
        />

        <!-- 自动续费协议弹窗 -->
        <RenewalTCDialog
          :visible="autoRenewalTCDialogVisible"
          :content="template(
            activePrime?.product_price_info?._specialPrice,
            activePrime?.product_cycle_days,
            language.SHEIN_KEY_PC_29426
          )"
          :active-prime="activePrime"
          @close-dialog="autoRenewalTCDialogVisible = false"
        />

        <s-dialog
          v-model:visible="showLimitModal"
          :show-close="true"
          append-to-body
          class="prime-limit__pop"
          @close="closePrimeLimit"
        >
          <div>
            {{ language.SHEIN_KEY_PC_27377 }}
          </div>
          <template #footer>
            <s-button
              :type="['H44PX', 'primary']"
              :width="'80%'"
              @click="closePrimeLimit"
            >
              {{ language.SHEIN_KEY_PC_15216 }}
            </s-button>
          </template>
        </s-dialog>

        <s-dialog
          v-if="descountDislogInfo && descountDislogInfo.product_price_info"
          v-model:visible="virtunalDiscountVisible"
          show-close
        >
          <template #icon>
            <img
              :src="imgAll.dialogHeaderBg"
              class="header-bg"
            />
          </template>
          <template #title> 
            <div class="header-bg-container">
              {{ language.SHEIN_KEY_PC_28842 }}
              <div class="pickColor_prime xtra-text">
                {{ descountDislogInfo.product_price_info.reduce_price_with_symbol }}
              </div>
            </div>
          </template>
          <template #footer>
            <div
              v-if="descountDialogData.length"
              class="virXrt_container"
            >
              <div
                v-for="item in descountDialogData"
                :key="item.rightValue"
                class="virXrt_line"
                :class="{'virXrt_take-price': item.isTakePrice}"
              >
                <div class="virXrt_line-left">
                  <span class="tips">{{ item.leftText }}</span> 
                  <Icon
                    v-if="item.isHasIcon && iconTips"
                    name="sui_icon_doubt_16px_1"
                    size="16px"
                    @click="clickDiscountTips"
                  /> 
                </div>
                <div
                  v-if="item.isSave"
                  class="pickColor_prime"
                >
                  - {{ item.rightValue }}
                </div>
                <div v-else>
                  {{ item.rightValue }}
                </div>
              </div>
            </div>
            <s-button
              :type="['primary']"
              width="100%"
              @click="virtunalDiscountVisible=false"
            >
              {{ language.SHEIN_KEY_PC_28851 }}
            </s-button>
          </template>
        </s-dialog>

        <PayMethodsListDialog 
          ref="PayMethodsListDialogRef" 
          :checkout="checkout"
          :language="language" 
          :payment-methods-props="paymentMethodsProps"
          :pay-methods-list-drawer-header-data="payMethodsListDrawerHeaderData"
          :choose-prime="reversePrime"
          :update-prime-index="updatePrimeIndex"
          auto-type="prime"
        />
      </div>
    </ClientOnly>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapMutations, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { template as _commonTemplate } from '@shein/common-function'

import couponMixin from '../../mixins/couponMixin'
import PrimeOptionsList from './prime_options_list'
import { Icon, sui_icon_club_logo_15px, sui_icon_doubt_16px_1, sui_icon_reported_14px_1 } from '@shein-aidc/icon-vue3'

const PrimeType = {
  Normal: 'normal',
  AutoRenewal: 'autoRenewal'
}

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

export default {
  name: 'PrimeOptionsNew',
  components: {
    Icon,
    sui_icon_club_logo_15px,
    sui_icon_doubt_16px_1,
    sui_icon_reported_14px_1,
    PrimeOptionsList,
    PrimeDetail: defineAsyncComponent(() => import('../prime_des/prime_detail')),
    RenewalTCDialog: defineAsyncComponent(() => import('./renewal_tc_dialog')),
    PayMethodsListDialog: defineAsyncComponent(() => import('../payment_list_dialog/pay_methods_list_dialog.vue')),
  },
  mixins: [couponMixin],
  props: {
    selectedPrime: {
      type: Object,
      default: () => {}
    },
    notSupport: {
      type: Boolean,
      default: false
    },
    nowPayment: {
      type: Object,
      default: () => {}
    },
    primeDeducePrice: {
      type: Object,
      default: () => {}
    },
    paypalGaVault: {
      type: Object,
      default: () => {}
    },
    banksConfig: {
      type: Object,
      default: () => {}
    },
    paymentMethodsProps: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isHighLightDeduce: false,
      primeType: PrimeType,
      descountDislogInfo: {},
      virtunalDiscountVisible: false,
      iconTips: false,
      autoRenewalTCDialogVisible: false,
      // 当前主屏幕上的产品包
      activePrime: {},
      payMethodsListDrawerHeaderData: null,
      isClient: false,
    }
  },
  computed: {
    ...mapGetters([
      'userBehaviorAbt',
      'autoRenewalPrimeShowAbt',
      'clubScribePriceByAbt',
      'sheinClubUIByAbt'
    ]),
    ...mapState([
      'primeSavingSummary',
      'primeProductList',
      'usedPrimeCode',
      'usedPrimeIndex',
      'selectdPrimeIndex',
      'status',
      'showPrimeLimitModal',
      'locals'
      // 'checkout'
    ]),
    PUBLIC_CDN() {
      return this.locals.PUBLIC_CDN
    },
    GB_cssRight() {
      return this.locals.GB_cssRight
    },
    SERVER_TYPE() {
      return this.locals.SERVER_TYPE
    },
    langPath() {
      return this.locals.langPath
    },
    SiteUID() {
      return this.locals.SiteUID
    },
    imgAll() {
      return {
        dialogHeaderBg: `${this.PUBLIC_CDN}/she_dist/images/checkout/prime_header_bg-1f2c24223a.png`,
      }
    },
    isShowRightsNewVersion() {
      // abt全推  MembershipChangeSwitchValue 写死等于 on
      return true
    },
    descountDialogData() {
      const product_price_info = this.descountDislogInfo?.product_price_info || {}
      const originText = this.SiteUID === 'de' ? this.language.SHEIN_KEY_PC_31129 : this.language.SHEIN_KEY_PC_30490
      let positiveValue = this.clubScribePriceByAbt ? [
        { leftText: originText, rightValue: product_price_info?.price_local_with_symbol }, // 原价
        { leftText: this.language.SHEIN_KEY_PC_30553, rightValue: product_price_info?.special_price_with_symbol, isTakePrice: true }, // 到手价
      ] : []
      // 活动优惠
      const isShowDialogDiscount = this.clubScribePriceByAbt && product_price_info?._discountPriceAmount > 0 || !this.clubScribePriceByAbt
      if (isShowDialogDiscount) {
        const discountPrice = {
          leftText: this.language.SHEIN_KEY_PC_28843,
          rightValue: product_price_info?._discountPrice,
          isHasIcon: true,
          isSave: true
        }
        positiveValue.length ? positiveValue.splice(1, 0, discountPrice) : positiveValue.push(discountPrice)
      }

      // 会费券优惠
      const isShowDialogCounpon = this.clubScribePriceByAbt && product_price_info?._conponPriceAmount > 0 || !this.clubScribePriceByAbt
      if (isShowDialogCounpon) {
        const conponPrice = {
          leftText: this.language.SHEIN_KEY_PC_28844,
          rightValue: product_price_info?._conponPrice,
          isSave: true
        }
        positiveValue.length > 1 ? positiveValue.splice(positiveValue.length - 1, 0, conponPrice) : positiveValue.push(conponPrice)
      }
      
      return positiveValue
    },
    primeLogoImg() {
      const logoImage = {
        old: [
          `${this.PUBLIC_CDN}/she_dist/images/checkout/prime_title_new-533bc12ce8.png`,
          `${this.PUBLIC_CDN}/she_dist/images/checkout/prime_title_new_ar-5a49c7decf.png`
        ],
        new: [
          'https://img.ltwebstatic.com/images3_ccc/2024/06/13/54/171826806807a1e3dbaa9aec956261587581a39dee.webp',
          'https://img.ltwebstatic.com/images3_ccc/2024/06/13/a9/1718269813b098aeac5ed901365e244b55f1d6a668.webp'
        ]
      }
      const versionKey = this.showNewStyle ? 'new' : 'old'
      return logoImage[versionKey][Number(this.GB_cssRight)]
    },
    showNewLogo() {
      let { primeProductInfo } = this.checkout.results || {}

      return this.userBehaviorAbt.CheckoutClubShow == 'Show' &&
        !!primeProductInfo.buyEffectiveNum
    },
    primeSavingSummaryInfo() {
      const savingSummary = this.primeSavingSummary || {}
      const summaryInfo = {
        amount: savingSummary.total_saving_amount_currency || '',
        amountWithSymbol: savingSummary.total_saving_amount_currency_with_symbol || '',
        usdAmount: savingSummary.total_saving_amount_usd || '',
        usdAmountWithSymbol: savingSummary.total_saving_amount_usd_with_symbol || ''
      }
      return summaryInfo
    },
    primeDeduceText() {
      const { 
        SHEIN_KEY_PC_27358, // save {0} after joining > 
        SHEIN_KEY_PC_27359, // Cost {0} per day to join >
        SHEIN_KEY_PC_27360, // Saved {0} in total >
        SHEIN_KEY_PC_28811, // Already saved {0} on this order!
        SHEIN_KEY_PC_28812, // Cost {0} per day to join!
        SHEIN_KEY_PC_32468, // 有省额，勾选后回本 (不含credit): You've saved {0} ! Cost recovered
        SHEIN_KEY_PC_32469, // 有省额，勾选前: Save {0} on this order, including credit >
        SHEIN_KEY_PC_32470, // 有省额，勾选后未回本: You've saved {0} , including credit ! 
        SHEIN_KEY_PC_32471, // 有省额，勾选后回本: You've saved {0} , including credit ! Cost recovered
      } = this.language
      // Credit
      const creditAmount = !!this.usedPrimeCode ? Number(this.checkout?.mall_caculate_info?.credit_total_amount?.amount) : Number(this.checkout?.mall_caculate_info?.prime_trial_credit_reward_price?.amount)
      const hasCreditAmount = creditAmount > 0
      
      // 开通付费金额
      const special_price = this.selectedPrime?.product_price_info?.special_price || 0

      const average_price = this.selectedPrime?.product_price_info?.average_daily_price_with_symbol || 0
      // 未勾选状态下折扣值
      const pay_member_discount_price = this.checkout?.mall_caculate_info?.prime_trial_total_discount_price || {}
      const primeDeducePrice = !!this.usedPrimeCode ? this.primeDeducePrice : pay_member_discount_price || {}

      //需要做勾选后文案的更改
      if (!!this.usedPrimeCode) {
        if(+primeDeducePrice.amount > 0) {
          if(+primeDeducePrice.amount < +special_price) { // 本单可省金额＜开通付费会员金额
            this.isHighLightDeduce = true
            return this.template('<i class="prime-deduce">' + primeDeducePrice.amountWithSymbol + '</i>', hasCreditAmount ? SHEIN_KEY_PC_32470  : SHEIN_KEY_PC_28811)
          } else { // 本单可省金额＞=开通付费会员金额
            this.isHighLightDeduce = false
            return  this.template('<i class="prime-deduce">' + primeDeducePrice.amountWithSymbol + '</i>', hasCreditAmount ? SHEIN_KEY_PC_32471 : SHEIN_KEY_PC_32468) 
          }
        } else { // 本单无可省金额
          this.isHighLightDeduce = true
          return this.template('<i class="prime-deduce">' + average_price + '</i>', SHEIN_KEY_PC_28812)
        }
      }

      if(+primeDeducePrice.amount > 0) {
        if(+primeDeducePrice.amount < +special_price) { // 本单可省金额＜开通付费会员金额
          this.isHighLightDeduce = true
          // 存在历史节省金额
          if(+this.primeSavingSummaryInfo.amount > 0 && this.primeSavingSummaryInfo.amountWithSymbol) return this.template('<i class="prime-deduce">' + this.primeSavingSummaryInfo.amountWithSymbol + '</i>', SHEIN_KEY_PC_27360)
          return this.template('<i class="prime-deduce">' + primeDeducePrice.amountWithSymbol + '</i>', hasCreditAmount ? SHEIN_KEY_PC_32469 : SHEIN_KEY_PC_27358)
        } else { // 本单可省金额＞=开通付费会员金额
          this.isHighLightDeduce = false
          return  this.template(
            '<i class="prime-deduce">' +
          primeDeducePrice.amountWithSymbol +
          '</i>',
            hasCreditAmount ? SHEIN_KEY_PC_32469 : SHEIN_KEY_PC_27358
          ) 
        }
      } else { // 本单无可省金额
        this.isHighLightDeduce = true
        return this.template('<i class="prime-deduce">' + average_price + '</i>', SHEIN_KEY_PC_27359)
      }
    },
    getArticleId () {
      let article_id = 0
      switch (this.SERVER_TYPE) {
        case 'central':
          article_id = 1540
          break
        case 'eur':
          article_id = 1544
          break
        case 'usa':
          article_id = 1438
          break
        default:
          article_id = 1438
          break
      }
      return article_id
    },
    agreeText() {
      const arcId = this.getArticleId
      let agreeText = this.template(`${this.langPath}/SHEIN-CLUB-TERMS-AND-CONDITIONS-a-${arcId}.html`, this.language.SHEIN_KEY_PC_27378)
      
      return agreeText
    },
    showLimitModal: {
      get () {
        return this.showPrimeLimitModal
      },
      set (visible) {
        this.assignState({
          showPrimeLimitModal: visible
        })
      }
    },
    buyEffectiveNum() {
      let { primeProductInfo } = this.checkout.results || {}

      return this.template(
        primeProductInfo.buyEffectiveNum,
        this.language.SHEIN_KEY_PC_28443
      )
    },
    activePrimeType() {
      if (!this.autoRenewalPrimeShowAbt) return this.primeType.Normal
      if (this.activePrime.product_activity_type == 1) return this.primeType.AutoRenewal
      return this.primeType.Normal
    },
    showNewStyle() {
      return this.isShowRightsNewVersion && 
        [1, 2].includes(+this.sheinClubUIByAbt)
    }
  },
  mounted(){
    this.isClient = true
    this.handleEventsListen()
    setTimeout(() => this.playAnimation(), 1000)
  },
  methods: {
    ...mapMutations(['assignState', 'updateSelectedPrime']),
    template: _commonTemplate,
    clickTerm(e) {
      if (this.notSupport || this.status.hasAddress == 0) {
        e.preventDefault()
      }
      if(e.target?.tagName == 'A') {
        daEventCenter.triggerNotice({
          daId: '1-11-1-86',
        })
      }
    },
    viewMore() {
      if (this.notSupport || this.status.hasAddress == 0) return
      this.assignState({
        showPrimeDetail: true,
        primePopFrom: 1,
      })
      daEventCenter.triggerNotice({
        daId: '1-11-1-80',
        extraData: {
          select_flag: !!this.usedPrimeCode ? '1' : '0',
        },
      })
      daEventCenter.triggerNotice({
        daId: '1-11-1-81',
      })
    },
    closePrimeLimit() {
      this.assignState({
        showPrimeLimitModal: false
      })
    },
    updatePrimeIndex() {
      const codeId = this.payMethodsListDrawerHeaderData?.product_code
      const selectdPrimeIndex = this.primeProductList?.findIndex(item => codeId == item.product_code)
      if (this.selectdPrimeIndex !== selectdPrimeIndex) {
        this.updateSelectedPrime({ index: selectdPrimeIndex == -1 ? 0 : selectdPrimeIndex, isUpdateRightList: true })
      }
    },
    reversePrime({ flag, fromLocation }) {
      if (flag) {
        if (this.usedPrimeIndex == this.selectdPrimeIndex) return

        daEventCenter.triggerNotice({
          daId: '1-11-1-79',
          extraData: {
            select_flag: flag ? '1' : '0',
            prime_product_id: this.primeProductList?.[this.selectdPrimeIndex]?.product_goods_id,
            sequence: this.selectdPrimeIndex > -1 ? this.selectdPrimeIndex + 1 : '',
            prime_level: this.primeProductList?.[this.selectdPrimeIndex]?.product_cycle_type || 0, //1-月卡、2-季卡、3-年卡、4-半年卡、5-周卡、6-半月卡、7-自定义周期
            select_product_activity_type: `${this.primeProductList?.[this.selectdPrimeIndex || 0]?.product_activity_type}` || '', //0-普通产品、1-自动续费、2-买赠奖品
            location: fromLocation || '', // 1-随单购，2-弹窗，3-支付方式，4-下单页挽留弹窗
          },
        })

        this.assignState({ 
          usedPrimeIndex: this.selectdPrimeIndex,
          usedPrimeCode: this.primeProductList?.[this.selectdPrimeIndex]?.product_code
        })

        let rightConfigList = this.primeProductList[this.selectdPrimeIndex || 0]?.right_config_list || []
        this.assignState({ 'rightConfigList': rightConfigList })
      } else {
        if (this.usedPrimeIndex == -1) return

        daEventCenter.triggerNotice({
          daId: '1-11-1-79',
          extraData: {
            select_flag: flag ? '1' : '0',
            prime_product_id: this.primeProductList?.[this.selectdPrimeIndex]?.product_goods_id,
            sequence: this.selectdPrimeIndex > -1 ? this.selectdPrimeIndex + 1 : '',
            prime_level: this.primeProductList?.[this.selectdPrimeIndex]?.product_cycle_type || 0, //1-月卡、2-季卡、3-年卡、4-半年卡、5-周卡、6-半月卡、7-自定义周期
            select_product_activity_type: `${this.primeProductList?.[this.selectdPrimeIndex || 0]?.product_activity_type}` || '', //0-普通产品、1-自动续费、2-买赠奖品
            location: fromLocation || '', // 1-随单购，2-弹窗，3-支付方式，4-下单页挽留弹窗
          },
        })

        this.assignState({ 
          usedPrimeIndex: -1,
          usedPrimeCode: ''
        })
      }
      // 
      const recommendProduct =
        this.primeProductList?.filter(item => +item.is_recommend) || []
      const recommendProductCode = recommendProduct.length
        ? recommendProduct[0].product_code
        : this.primeProductList?.[0]?.product_code || ''
      // console.log(recommendProductCode, 'recommendProductCode')
      
      window.checkoutEventCenter.emit('change-prime-calcu', {
        primeSeleted: flag,
        recommendProductCode: recommendProductCode,
      })
    },
    playAnimation() {
      if(typeof window == 'undefined') return
      const logo = this.$refs.primeLogoRef
      if (!logo && !this.showNewStyle && !this.showNewLogo) return
      const logoHeight = logo.getBoundingClientRect().height
      // 白色的动效条需要倾斜15度
      const radian = 15 * (Math.PI / 180)
      const stripHeight = logoHeight / Math.cos(radian)
      const strip = document.createElement('span')
      strip.className = 'logo-strip'
      strip.style.height = `${stripHeight}px`
      logo.appendChild(strip)

      setTimeout(() => {
        document.querySelector('.logo-strip')?.remove()
      }, 1500)
    },
    changeVirtunalDia(value){
      const { descountDislogInfo, virtunalDiscountVisible, iconTips } = value
      this.descountDislogInfo = descountDislogInfo
      this.virtunalDiscountVisible = virtunalDiscountVisible
      this.iconTips = iconTips
    },
    clickDiscountTips(){
      this.assignState({
        showPrimeLimitModal: true
      })
    },
    handleEventsListen() {
      window.appEventCenter?.on('change-discount-virtunal-Dia', (value) => {
        this.changeVirtunalDia(value)
      })
    },
    changeActiveIndex(index) {
      this.activePrime = this.primeProductList[index]
    },
    handlePayMethodsListDrawerShow(options) {
      const { product_name_language, product_price_info, product_code } = options || {}
      const {
        total_discount_price_with_symbol,
        arrival_price_with_symbol,
        price_local_with_symbol
      } = product_price_info || {}
      this.payMethodsListDrawerHeaderData = {
        product_name_language,
        total_discount_price_with_symbol,
        arrival_price_with_symbol,
        price_local_with_symbol,
        product_code
      }
      this.$refs?.PayMethodsListDialogRef?.handlerShow()
    },
  }
}
</script>

<style lang="less">
.pickColor_prime{
  color: #FA6338;
}
// 擦亮动画
@keyframes prime-strip-move {
  0% {
    left: 0; 
  }
  100% {
    left: calc(100% + .2667rem);
  }
}

@keyframes prime-strip-move-rtl {
  0% {
    right: 0; 
  }
  100% {
    right: calc(100% + .2667rem);
  }
}
.checkout-prime-new__wrap {
  background: #FFF7ED;
  margin: 10px 0;
  padding-bottom: 12px;

  .checkout-prime__wrap-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .checkout-prime__logo {
      height: 39px;
      padding-left: 8px;
      display: flex;
      align-items: center;
      position: relative;
      background-image: url(/she_dist/images/checkout/prime_title_bg-41fe6fc05a.png);
      background-size: 100% 100%;
      &:after {
        content: '';
        width: 20px;
        height: 39px;
        position: absolute;
        right: -20px;
        bottom: 0;
        background-image: url(/she_dist/images/checkout/prime_title_bg_triangle-f4c3e8f08c.png);
        background-size: 100% 100%;
      }
    }
    .checkout-prime__logo_ar {
      &:after {
        background-image: url(/she_dist/images/checkout/prime_title_bg_triangle_ar-64c3a2675f.png);
      }
      .checkout-prime__logo_strip {
        animation: prime-strip-move-rtl .5s 3;
      }
    }

    .checkout-prime__logo_new {
      position: relative;
      width: calc(96px - 0.2667rem);
    }

    .checkout-prime__title {
      margin-left: 4px;
      font-family: SF Pro;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1.2;
      text-wrap: nowrap;
      position: relative;
    }
    .checkout-prime__title-club {
      color: @sui_color_club_rosegold_dark2;
      font-size: 14px;
    }
    .checkout-prime__title-num {
      color: @sui_color_club_rosegold_dark3;
      font-size: 12px;
    }
      
    .logo-strip {
      width: 8px;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(95deg, rgba(255, 255, 255, .1), rgba(255, 255, 255, .5), rgba(255, 255, 255, .1));
      transform: rotate(15deg);
      animation: prime-strip-move .5s 3;
    }
  }
  // 旧logo样式
  .checkout-prime__wrap-logo {
    width: 117px;
    height: 26px;
  }

  .checkout-prime__wrap-tips {
    color: @sui_color_club_rosegold_dark1;
    text-align: right;
    font-family: "SF UI Text";
    font-size: 12px;
    margin: 4px 12px 0 0;
    white-space: nowrap;
    max-width: 252px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .checkout-prime__wrap-des {
    cursor: pointer;
    display: inline-block;
    .margin-l(15px);
    .margin-r(12px);
    padding-top: 8px;

    font-size: 12px;
    font-weight: 400;
    color: @sui_color_club_rosegold_dark2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    .prime-deduce {
      background-image: linear-gradient(200deg, #FF5656 66.07%, #FF9493 74.74%, #F00 82.28%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color: #FF5656;
      font-size: 18px;
      font-style: italic;
      font-weight: 600;
      padding: 0 2px;
    }
  }
  .high-light {
    padding-top: 4px;
  }

  .checkout-prime__wrap-des_new {
    max-width: calc(100% - 24px);
    padding: 0 12px;
    margin: 6px 0 -6px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 800;
    color: #D17000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .prime-deduce {
      color: #FB4E1D;
      font-style: normal;
    }

    &.single-product {
      padding-left: 24px;
    }
  }

  .checkout-prime__wrap-policy {
    padding: 0 12px;
    color: @sui_color_gray_light1;
    font-size: 10px;
    font-weight: 400;

    a {
      text-decoration: none;
    }

    &.single-product {
      padding-left: 24px;
    }
  }

  .checkout-prime__wrap-tip {
    padding: 0 12px;
    color: @sui_color_unusual;
    font-size: 12px;
    font-weight: 400;
  }

  .no-support {
    opacity: 0.3;
  }
}

.header-bg{
    position: absolute;
    top: 0;
    left: 0px;
  }
  .header-bg-container{
    position: relative;
    margin-top: -20px;
    font-size: 16px;
    .xtra-text{
      font-size: 24px;
      font-weight: 600;
      line-height: 1.7;
    }
  }

  .virXrt_container{
    margin-bottom: 36px;
    font-size: 14px;
    .virXrt_line{
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      .virXrt_line-left{
        display: flex;
        justify-content: center;
        align-items: center;
        .tips{
          margin-right: 5px;
        }
      }
    }
    .virXrt_take-price {
      font-weight: 600;
    }
  }

.prime-limit__pop {
  text-align: center;
}
</style>

<template>
  <div class="price-coupon-diff" v-if="hasShipPriceDiff.length">
    <s-alert type="warning" class="checkout-options__alert">
      <!-- 自营优惠差价 -->
      <div
        class="price-diff-tip"
        v-for="item in hasShipPriceDiff"
        :key="item.mall_code"
      >
        <strong v-if="isSiteMallList">{{ item.mall_name }}: </strong>
        <span v-html="freeShipDiffTips(item)"></span>
      </div>
    </s-alert>
  </div>
</template>

<script>
import { htmlDecode } from '@shein/common-function'

export default {
  props: {
    checkout: {
      type: Object,
      default: () => {
        return {}
      },
    },
    language: {
      type: Object,
      default: () => {
        return {}
      },
    },
    hasShipPriceDiff: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  computed: {
    // 站点mall 多mall站样式按多mall来.
    isSiteMallList() {
      return this.checkout?.results?.mallInfo?.mallInfoForSite?.length > 1
    },
  },
  methods: {
    freeShipDiffTips(item) {
      const { not_free_shipping_tips, not_free_shipping_diff } = item
      let tips = ''
      switch (not_free_shipping_tips) {
        case 1:
          tips = htmlDecode({ text: this.language.SHEIN_KEY_PC_16295 }) // coupon
          break
        case 2:
          tips = htmlDecode({ text: this.language.SHEIN_KEY_PC_16296 }) // point
          break
        case 3:
          tips = htmlDecode({ text: this.language.SHEIN_KEY_PC_16317 }) // both
          break
        case 4:
          tips = htmlDecode({ text: this.language.SHEIN_KEY_PC_16316 }) // or
          break
      }

      return tips.replace(
        '{money}',
        '<em class="tip-red">' +
          not_free_shipping_diff.amountWithSymbol +
          '</em>'
      )
    },
  },
}
</script>

<style lang="less">
.price-coupon-diff {
  margin-top: 5px;
  .price-diff-tip {
    font-size: 12px;
  }
  .sui-alert {
    padding: 10px;
  }
}
.c-order-summary {
	.checkout-options__alert {
		margin-top: 6px;
		padding: 10px;
		.text-red {
			color: @color_red_dark;
		}
	}
}
</style>

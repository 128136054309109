import { daEventCenter } from 'public/src/services/eventCenter/index'
import { useMapState } from 'public/src/pages/store_pages/store/mapHook'
import { computed } from 'vue'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

export const useDaEventCenter = () => {
  const { selectdPrimeIndex, usedPrimeCode, primePopFrom, rightConfigList, checkout } = 
      useMapState([ 'selectdPrimeIndex', 'usedPrimeCode', 'primePopFrom', 'rightConfigList', 'checkout' ])
  const clubDaEvent = {
    // 选择产品包
    'choose-club-product': ({ flag, selectedPrime, fromLocation }) => {
      daEventCenter.triggerNotice({
        daId: '1-11-1-79',
        extraData: {
          select_flag: flag ? '1' : '0',
          prime_product_id: selectedPrime.value?.product_goods_id,
          sequence:
              selectdPrimeIndex.value > -1 ? selectdPrimeIndex.value + 1 : '',
          prime_level: selectedPrime?.product_cycle_type || 0, //1-月卡、2-季卡、3-年卡、4-半年卡、5-周卡、6-半月卡、7-自定义周期
          select_product_activity_type:
              `${selectedPrime?.product_activity_type}` || '', //0-普通产品、1-自动续费、2-买赠奖品
          location: fromLocation || '' // 1-随单购，2-弹窗，3-支付方式，4-下单页挽留弹窗
        }
      })
    },
    // 更多产品包抽屉弹窗
    'view-more-product': () => {
      daEventCenter.triggerNotice({
        daId: '1-11-1-80',
        extraData: {
          select_flag: !!usedPrimeCode.value ? '1' : '0'
        }
      })
      daEventCenter.triggerNotice({
        daId: '1-11-1-81'
      })
    },
    // 跳转文章页
    'go-article-page': () => {
      daEventCenter.triggerNotice({
        daId: '1-11-1-86'
      })
    },
    // 跳转券列表
    'go-coupon-list': ({ position }) => {
      daEventCenter.triggerNotice({
        daId: '1-11-1-178',
        extraData: {
          type: 'club',
          position: position || 'popup'
        }
      })
    },
    // 更多产品包抽屉弹窗 ok/close 事件
    'handle-detail-drawer': ({ selectedProduct, type }) => {
      daEventCenter.triggerNotice({
        daId: '1-11-1-82',
        extraData: {
          click_type: type,
          prime_level: selectedProduct?.product_cycle_type || 0,
          select_product_activity_type:
                `${selectedProduct?.product_activity_type}` || '',
          location: primePopFrom.value || '' // 1-随单购模块、2-物流运输方式、3-下单页挽留弹窗
        }
      })
    },
    // 更多包抽屉里选择包
    'handle-product-select': ({ productInfo, index }) => {
      daEventCenter.triggerNotice({
        daId: '1-11-1-84',
        extraData: {
          prime_level: productInfo.product_cycle_type || '',
          prime_product_id: productInfo.product_goods_id,
          sequence: index + 1
        }
      })
    }
  }
  const primeOptionAnalysisData = computed(() => {
    if(typeof window == 'undefined') return {}

    return { 
      id: '1-11-1-78', 
      data: {
        select_flag: '',
        privilege_count: rightConfigList.value.length > 1 ? rightConfigList.value.length : 0,
        buy_num_accumulate: checkout.value.results?.primeProductInfo?.buyEffectiveNum
      }
    }
  })
  const eventCenterConfig = {
    'detailDrawer.benefit': {
      expose: {
        id: '1-11-1-147'
      }
    },
    'limitLabel.isTips': {
      trigger: {
        daId: '1-11-1-177',
        extraData: {
          type: 'club',
          position: 'popup'
        }
      }
    },
    'detailDrawer.proudctItem': {
      trigger: data => {
        return {
          daId: '1-11-1-83',
          extraData: {
            prime_level: data.product_cycle_type || ''
          }
        }
      }
    }
  }
  
  return { clubDaEvent, primeOptionAnalysisData, eventCenterConfig }
}

<template>
  <div
    class="prime-list"
    @mouseenter="enterEvent"
    @mouseleave="leaveEvent"
  >
    <swiper-container
      init="false"
      class="prime-list__container"
      :class="{
        'prime-list__container_single': !isMultStyle
      }"
      :dir="GB_cssRight ? 'rtl' : 'ltr'"
    >
      <swiper-slide
        v-for="(item, index) in primeProductList"
        :key="item.product_code"
        class="prime-list__item"
        :class="{
          'prime-list__item_scale': isReachedEnd,
          'prime-list__item_orange': showNewStyle,
          'prime-list__item_renewal': item.sceneResult.isAutoRenewal
        }"
        :style="{
          '--club-product-name-color':
            customProductStyleList[index]?.productNameColor,
          '--club-right-color': customProductStyleList[index]?.rightColor,
          '--club-right-icon-color':
            customProductStyleList[index]?.rightIconColor,
          '--club-selling-point-color':
            customProductStyleList[index]?.sellingPointColor,
          '--club-count-down-color':
            customProductStyleList[index]?.countDownColor,
          '--club-count-down-bg-color':
            customProductStyleList[index]?.countDownBgColor,
          '--club-checkbox-color': customProductStyleList[index]?.checkboxColor,
          '--club-selected-card-border-color':
            customProductStyleList[index]?.cardCheckedBorderColor,
          '--club-price-color': customProductStyleList[index]?.priceColor,
          '--club-line-price-color':
            customProductStyleList[index]?.linePriceColor,
          '--club-product-name-bg-color':
            customProductStyleList[index]?.productNameBgColor,
          '--club-discount-label-bg-color':
            customProductStyleList[index]?.discountLableBgColor,
          '--club-product-name-corner-bg-color':
            customProductStyleList[index]?.productNameCornerBgColor,
          '--club-history-saved-label-bg-color':
            customProductStyleList[index]?.historySavedLabelBgColor,
          '--club-discount-label-color':
            customProductStyleList[index]?.discountLabelColor
        }"
        @click="onPrimeCardClick(index)"
      >
        <RightPackageCard
          :class="{
            'prime-card-new_single': primeProductList.length <= 1
          }"
          :item="item"
          :is-scale="activeIndex === index"
          :has-activity="hasActivity"
          :is-selected="usedPrimeIndex === index"
          :is-auto-renewal="item.sceneResult.isAutoRenewal"
          :custom-style="customProductStyleList[index]"
          :free-gift-activity="freeGiftActivity"
          @handle-activity-show="handleActivityShow"
        />
        <div
          v-if="item.sceneResult"
          class="prime-list__price"
          :class="{
            'no-support': notSupport,
            'prime-list__price_scale': activeIndex === index
          }"
        >
          <s-radio
            :model-value="usedPrimeIndex"
            :label="index"
            class="prime-list__radio"
            theme="icon"
            :disabled="notSupport"
          />
          <div
            v-if="
              activeIndex === index && autoRenewalBubbleConfig[index]?.visible
            "
            class="prime-list__bubble bubble-wrapper"
          >
            <sui_icon_closed_filter_12px_1
              class="bubble-wrapper__close-btn"
              size="10px"
              color="#fff"
              @click.stop="onBubbleClose(index)"
            />
            <RenewalPayMethod
              :logo-list="payMethodBubbleProps?.logoList"
              :text="payMethodBubbleProps?.text"
              :on-click="() => handleBubbleClick(index)"
            />
          </div>
          <strong class="prime-list__price_discount">
            {{ item.product_price_info.special_price_with_symbol }}
          </strong>
          <del
            v-if="
              +item.product_price_info.special_price <
                +item.product_price_info.price_local && !showScribePrice
            "
            class="prime-list__price_local"
          >
            {{ item.product_price_info.price_local_with_symbol }}
          </del>
          <div
            v-if="showDiscount(item)"
            class="prime-list__limited"
            :class="
              customProductStyleList[index]?.discountLableBgColor
                ? undefined
                : {
                  'prime-list__limited_to-coupon': item.sceneResult.isTips,
                  'prime-list__limited_only-renewal':
                    item.sceneResult.isOnlyAutoRenewal
                }
            "
            @click.stop="onOfferLabelClick(item, 'page')"
          >
            <div class="prime-textWhiteSpace">
              <span v-if="!GB_cssRight">
                &#x202A;
                <sui_icon_random_discount_16px
                  v-if="item.sceneResult.isOnlyAutoRenewal"
                  size="12px"
                  color="#fff"
                />
                <span>{{ discountTips(item).tips }}</span>
              </span>
              <span v-else>
                <span>{{ discountTips(item).tips }}</span>
                <sui_icon_random_discount_16px
                  v-if="item.sceneResult.isOnlyAutoRenewal"
                  size="12px"
                  color="#fff"
                />
              </span>
            </div>
            <span
              v-if="GB_cssRight"
              class="prime-textWhiteSpace_pice"
            >
              {{ discountTips(item).price }}
            </span>
            <ClientOnly>
              <span
                v-if="interactionShow(item)"
                class="mr-5"
              >
                >
              </span>
            </ClientOnly>
          </div>
        </div>
      </swiper-slide>

      <!-- eslint-disable vue/no-deprecated-slot-attribute -->
      <div slot="container-start">
        <div
          v-show="isMultStyle && showSwiperButton"
          class="swiper-button-prev prime-new__common-prev"
        >
          <Icon
            name="sui_icon_more_left_18px"
            size="18px"
            :is-rotate="GB_cssRight"
          />
        </div>
      </div>
      <div slot="container-end">
        <div
          v-show="isMultStyle && showSwiperButton"
          class="swiper-button-next prime-new__common-next"
        >
          <Icon
            name="sui_icon_more_right_18px"
            size="18px"
            :is-rotate="GB_cssRight"
          />
        </div>
      </div>
    </swiper-container>
  </div>
</template>

<script>
import { template, CountDown } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import RightPackageCard from './RightPackageCard.vue'
import {
  Icon,
  sui_icon_closed_filter_12px_1,
  sui_icon_random_discount_16px
} from '@shein-aidc/icon-vue3'
import RenewalPayMethod from '../shared/RenewalPayMethod.vue'
import { register } from 'swiper/element'
import { Navigation } from 'swiper/modules'
import { getProductStyle } from '../shared/utils'

typeof window !== 'undefined' && register()

// prime_options_list.vue
export default {
  components: {
    Icon,
    RightPackageCard,
    sui_icon_closed_filter_12px_1,
    sui_icon_random_discount_16px,
    RenewalPayMethod
  },
  props: {
    notSupport: {
      type: Boolean,
      default: false
    },
    primeProductList: {
      type: Array,
      default: () => []
    },
    onOfferLabelClick: {
      type: Function,
      default: () => {}
    },
    onPrimeCardClick: {
      type: Function,
      default: () => {}
    },
    autoRenewalBubbleConfig: {
      type: Array,
      default: () => []
    },
    onBubbleClose: {
      type: Function,
      default: () => {}
    },
    usedPrimeIndex: {
      type: Number,
      default: -1
    },
    selectdPrimeIndex: {
      type: Number,
      default: -1
    },
    payMethodBubbleProps: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      primeSwiper: null,
      showSwiperButton: false,
      activeIndex: 0,
      lastActiveIndex: -1,
      isReachedEnd: false,
      hasActivity: false,
      freeGiftTimer: null
    }
  },
  inject: [
    'language',
    'showScribePrice',
    'eventCenterConfig',
    'styleConfig',
    'styleType',
    'GB_cssRight',
    'freeGiftInfo'
  ],
  computed: {
    isMultStyle() {
      return this.primeProductList.length > 1
    },
    showNewStyle() {
      return this.styleType === 'new'
    },
    customProductStyleList() {
      return this.primeProductList.map(m => {
        return {
          ...getProductStyle(
            m.product_code,
            this.styleConfig.productPackageStyle?.productPackageConfigList,
            this.styleConfig.productPackageStyle?.productPackageConfig
          )
        }
      })
    },
    freeGiftActivity() {
      if(this.freeGiftInfo){
        return {
          text: this.freeGiftInfo.text,
          countdown: this.freeGiftTimer?.timeObj
        }
      }

      return null
    }
  },
  watch: {
    usedPrimeIndex: {
      handler(n) {
        if (typeof n === 'number' && n > -1) {
          // 滚动
          if (this.isMultStyle) {
            this.primeSwiper?.swiper?.slideTo(n)
          } else {
            this.lastActiveIndex = this.activeIndex
            this.activeIndex = n
          }
        }
      },
      immediate: true
    },
    activeIndex: {
      handler(index) {
        this.$emit('changeActiveIndex', index)
      },
      immediate: true
    }
  },
  mounted() {
    this.initTimer()
    this.$nextTick(() => {
      this.initSwiper()
      this.initPrime()
    })
  },
  methods: {
    template,
    initTimer() {
      if (this.freeGiftInfo?.endTime) {
        this.freeGiftTimer = new CountDown()
        this.freeGiftTimer.start({
          seconds: Math.floor(this.freeGiftInfo.endTime - Date.now() / 1000),
          dayMode: true
        })
      }
    },
    enterEvent() {
      this.showSwiperButton = true
    },
    leaveEvent() {
      this.showSwiperButton = false
    },
    initSwiper() {
      if (!this.isMultStyle) return
      const swiperParams = {
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        slidesOffsetAfter: 12,
        normalizeSlideIndex: false,
        modules: [Navigation],
        navigation: {
          nextEl: '.prime-new__common-next',
          prevEl: '.prime-new__common-prev'
        },
        injectStyles: [
          `.swiper {
            overflow: visible;
          }
          `
        ],
        on: {
          slideChange: swiper => {
            this.$nextTick(() => {
              if (this.isReachedEnd && swiper.isEnd) return
              const lastListLength = this.primeProductList.length - 1
              // 拖拽到最后，有可能会出现超出数组长度的 index，此时赋值数组最后一位
              const activeIndex =
                swiper.activeIndex > lastListLength
                  ? lastListLength
                  : swiper.activeIndex
              this.isReachedEnd =
                activeIndex === this.primeProductList.length - 1
              // 当前包切换后才进行赋值
              if (activeIndex != this.activeIndex)
                this.lastActiveIndex = this.activeIndex
              this.activeIndex = activeIndex
            })
          }
        }
      }
      this.primeSwiper = document.querySelector('.prime-list__container')
      Object.assign(this.primeSwiper, swiperParams)
      this.primeSwiper.initialize()
    },
    initPrime() {
      if (typeof this.usedPrimeIndex !== 'number' || this.usedPrimeIndex < 0) {
        this.primeSwiper &&
          this.primeSwiper?.swiper?.slideTo(this.selectdPrimeIndex)
      }
    },
    discountTips(item) {
      const { isVisible, isTips, isLegal, isOnlyAutoRenewal } = item.sceneResult
      const product_price_info = item?.product_price_info || {}
      let resultInfo = {
        price: product_price_info?.reduce_price_with_symbol,
        tips: this.template(
          this.GB_cssRight ? '' : product_price_info?.reduce_price_with_symbol,
          this.language.SHEIN_KEY_PC_28840
        )
      }
      if (isOnlyAutoRenewal) {
        resultInfo = {
          tips: `${this.template(
            this.GB_cssRight ? '' : resultInfo.price,
            this.language.SHEIN_KEY_PC_29640
          )}`
        }
        return resultInfo
      }
      if (isVisible || isLegal) {
        const price = isLegal
          ? product_price_info.reduce_price_with_symbol
          : product_price_info?.total_discount_price_with_symbol
        resultInfo = {
          price,
          tips: `${this.template(
            this.GB_cssRight ? '' : price,
            this.language.SHEIN_KEY_PC_28840
          )}`
        }
        return resultInfo
      }
      if (isTips) {
        const eventData = this.eventCenterConfig['limitLabel.isTips']?.trigger

        eventData && daEventCenter.triggerNotice(eventData)

        const price =
          product_price_info?.product_dues_coupon_rights
            ?.discount_value_with_symbol
        resultInfo = {
          price,
          tips: this.template(
            this.GB_cssRight ? '' : price,
            this.language.SHEIN_KEY_PC_28841
          )
        }
        return resultInfo
      }
      return resultInfo
    },
    handleActivityShow(hasActivity) {
      this.hasActivity = hasActivity
    },
    handleBubbleClick(index) {
      this.payMethodBubbleProps?.onClick(this.primeProductList[index])
    },
    showDiscount(item) {
      const product_price_info = item.product_price_info
      const isProdctDuesCouponRights =
        item?.product_price_info?.product_dues_coupon_rights?.coupon_code
      return (
        +product_price_info.price_local > +product_price_info.special_price ||
        isProdctDuesCouponRights
      )
    },
    interactionShow(item) {
      const { isVisible, isTips, isLegal, isRenewalLegalDialog } =
        item.sceneResult
      return isVisible || isLegal || isTips || isRenewalLegalDialog
        ? true
        : false
    }
  },
  emits: ['changeActiveIndex']
}
</script>

<style lang="less" scoped>
.prime-list {
  width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;

  &__container {
    width: 100%;
    margin: 12px 0 8px;
    padding: 14px 0 0 12px;
    display: flex;
    overflow: hidden;
  }

  &__container_single {
    padding-left: 0;
    width: 88%;

    .prime-list__item {
      width: 86.9%;
      margin-right: 0;
    }
  }

  &__item {
    cursor: pointer;
    width: 213px;
    margin-right: 44px;
    &_scale:last-child {
      margin-left: -32px;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  &__price {
    display: flex;
    align-items: center;
    height: 24px;
    margin-top: 12px;
    line-height: 1;

    &_scale {
      width: 115%;
    }
    &_discount {
      font-size: 16px;
      .margin-r(4px);
    }
    &_local {
      font-size: 12px;
      .margin-r(4px);
      color: var(--club-line-price-color, @sui_color_gray_light1);
    }
  }

  &__radio {
    display: flex;
    align-items: center;
    margin: 0;
    margin-left: 2px;
  }

  &__bubble {
    position: absolute;
    left: 0;
    bottom: 29px;
  }

  .bubble-wrapper {
    max-width: 260px;
    padding: 8px;
    border-radius: 2px;
    box-sizing: border-box;
    background: var(---sui_color_black_alpha80, rgba(0, 0, 0, 0.8));
    color: #fff;
    font-size: 12px;
    &::before {
      position: absolute;
      bottom: -5px;
      left: 7px;
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid rgba(0, 0, 0, 0.8);
    }
    &__close-btn {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__limited {
    background: var(
      --club-discount-label-bg-color,
      linear-gradient(98deg, #c96e3f 48.66%, #eaa27e 100%)
    );
    border-radius: 6px 0px;
    font-size: 12px;
    color: var(--club-discount-label-color, #fff);
    font-weight: 700;
    padding: 2px 8px;
    display: flex;
    overflow: hidden;

    &_to-coupon {
      border: 1px solid rgba(218, 126, 80, 0.3);
      background: linear-gradient(0deg, #fee6d7 0%, #fee6d7 100%);
      color: #ca6f40;
    }

    &_only-renewal {
      background-image: none;
      background: linear-gradient(90deg, #f17e53 5.49%, #ff5656 81.87%);
      border-radius: 6px 0px;
    }
  }

  .prime-textWhiteSpace {
    font-size: 11px;
    line-height: 1.3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    unicode-bidi: embed;
  }
  .prime-textWhiteSpace_pice {
    font-size: 11px;
    line-height: 1.3;
  }

  .prime-list__price_discount {
    color: var(--club-price-color, @sui_color_club_rosegold_dark3);
  }

  &__item_orange,
  &__item_renewal {
    .prime-list__price_discount {
      color: var(--club-price-color, #fd5b46);
    }
  }

  &__item_orange .prime-list__limited {
    background: var(
      --club-discount-label-bg-color,
      linear-gradient(270deg, #e9c997 -0.05%, #ffefc9 100.05%)
    );
    color: var(--club-discount-label-color, #d43e2a);
  }
}

.place-order__effiency .prime-list {
  /* stylelint-disable-next-line selector-max-specificity */
  :deep(.prime-list__radio > .sui-radio__check) {
    .margin-r(6px);
    border-width: 1.5px;
    border-color: var(--club-checkbox-color, @sui_color_club_rosegold_dark3);
  }
  /* stylelint-disable-next-line selector-max-specificity */
  :deep(.sui-radio__checked > .sui-radio__check) {
    background-color: var(
      --club-checkbox-color,
      @sui_color_club_rosegold_dark3
    );
  }

  &__item_orange,
  &__item_renewal {
    /* stylelint-disable-next-line selector-max-specificity */
    :deep(.prime-list__radio > .sui-radio__check) {
      border-color: var(--club-checkbox-color, #ff5925);
    }
    /* stylelint-disable-next-line selector-max-specificity */
    :deep(.sui-radio__checked > .sui-radio__check) {
      background-color: var(--club-checkbox-color, #ff5656);
    }
  }
}

.prime-new__common-next,
.prime-new__common-prev {
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(255, 255, 255, 0.94);
  border-radius: 50%;
  z-index: 12;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  color: #222;
  font-size: 16px;
}
</style>

<template>
  <ul class="checkout-summary__discount-list">
    <DiscountItem 
      v-for="(item, index) in discountList"
      :key="index"
      :language="language"
      :goods-list="getGoodsList(item)"
      :type="type"
      :activity="item.name"
      :amount="item.discount_price"
      :coupon-code="item.coupon_code"
      :has-explain="hadExplainIcon(item)"
    />
  </ul>
</template>

<script setup>
import DiscountItem from './DiscountItem.vue'

const props = defineProps({
  language: {
    type: Object,
    default: () => {}
  },
  cartsList: {
    type: Array,
    default: () => []
  },
  discountList: {
    type: Array,
    default: () => []
  },
  type: { // promotion、coupon
    type: String,
    default: 'promotion'
  },
})

const getGoodsList = (info) => {
  const { cart_ids: ids, couponTypeId, src } = info

  if(couponTypeId) {
    return [{
      id: couponTypeId,
      src
    }]
  } else {
    if(!ids?.length) return []
  }

  const carts = props.cartsList || []
  return carts.filter(item => ids.includes(item.id))?.map(_ => { 
    return {
      id: _.id, 
      src: _.product?.goods_img || '',
      product: _.product
    }
  }) || []
}

const hadExplainIcon = (item) => {
  // 33 品类首津
  return item?.typeId == 33 && props.type == 'promotion'
}

</script>

<style lang="less" scoped>
.checkout-summary__discount-list {
  background: #F6F6F6;
  padding: 8px 12px;
}
</style>

<template>
  <div>
    <GoodsList 
      v-if="lowStockCartsData.lowStockProducts.length"
      class="j-activity-left"
      :language="language"
      :goods-list="lowStockCartsData.lowStockProducts"
      :under-price-abt-value="underPriceAbtValue"
    />
    <Tips 
      v-if="tipsDescription && tipsDescription.length"
      :language="language"
      :icon="tipsIcon"
      :description="tipsDescription"
      :bubble-copy-writing-by-abt="bubbleCopyWritingByAbt"
    />
  </div>
</template>

<script>
import { template } from '@shein/common-function'
import GoodsList from './goods_info/GoodsList.vue'
import Tips from './Tips.vue'
import { iconConfig } from './config'

export default {
  name: 'LowstockPopover',
  components: {
    GoodsList,
    Tips,
  },
  props: {
    checkout: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    underPriceAbtValue: {
      type: Boolean,
      default: false
    },
    lowStockCartsData: {
      type: Object,
      default: () => {}
    },
    bubbleCopyWritingByAbt: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tipsDescription() {
      const { lowStockProducts, isStockReason } = this.lowStockCartsData || {}
      if (lowStockProducts.length === 1) {
        if (isStockReason) {
          const stockNum = lowStockProducts[0]?.product?.real_stock
          return `<em>${this.template(stockNum, this.language.SHEIN_KEY_PC_29247)}</em> ${this.language.SHEIN_KEY_PC_29248}`
        }
        return `${this.language.SHEIN_KEY_PC_31924} <em>${this.language.SHEIN_KEY_PC_31925}</em>`
      }
      if (this.bubbleCopyWritingByAbt) {
        return this.template(`<em>${this.language.SHEIN_KEY_PC_31176}</em>`, this.language.SHEIN_KEY_PC_31427)
      }
      return `${this.language.SHEIN_KEY_PC_29249} <em>${this.language.SHEIN_KEY_PC_29250}</em>`
    },
    tipsIcon() {
      return iconConfig?.stock || {}
    },
  },
  methods: {
    template,
  }
}
</script>

<template>
  <div class="mcheo-card mcheo-payment pay-methods-list">
    <div
      v-if="selectPayTitle"
      class="cash-delivery"
    >
      <div class="left-green"></div>
      <div class="cash-right">
        <div class="title">
          <div class="logo">
            <img
              v-if="nowSelectPay.logo_url"
              class="logo-img"
              :src="handlerTransformImg({ img: nowSelectPay.logo_url })"
            />
          </div>
          <div class="title-content">
            {{ nowSelectPay.title }}
          </div>
        </div>
        <div class="tip">
          <Icon
            name="sui_icon_caution_18px_1"
            size="18px"
            color="#BF4123"
            class="warn-icon"
          />
          <span class="tip-content">
            {{ tipContent }}
          </span>
        </div>
      </div>
    </div>

    <ul class="c-place-order">
      <div class="pay-list__title">
        {{ language.SHEIN_KEY_PC_29645 }}
      </div>
      <payment-options
        :is-pay-methods-dialog="true"
        :status="status"
        :language="paymentMethodsProps.language"
        :checkout="paymentMethodsProps.checkout"
        :payment-list="paymentMethodsProps.paymentList"
        :banks-config="paymentMethodsProps.delayChangeBanksData"
        :payment-option="paymentMethodsProps.paymentOption"
        :installment-info="paymentMethodsProps.installmentInfo"
        :payment-description-abt="paymentMethodsProps.paymentDescriptionAbt"
        :google-valid="paymentMethodsProps.googleValid"
        :shake="paymentMethodsProps.shake"
        :need-pay-inline-payment-methods="
          paymentMethodsProps.needPayInlinePaymentMethods
        "
        :show-widget-form="paymentMethodsProps.showWidgetForm"
        :ppgv-account-info="paymentMethodsProps.ppgvAccountInfo"
        :afterpay-account-info="paymentMethodsProps.afterpayAccountInfo"
        :signup-account-info="paymentMethodsProps.signupAccountInfo"
        :paypal-ga-vault="tempPaypalGaVault"
        :afterpay-vault="tempAfterpayVault"
        :signup-pay-vault="tempSignupPayVault"
        :handle-start-select-bank="paymentMethodsProps.handleStartSelectBank"
        :is-auto-renewal="isAutoRenewal"
        :auto-type="autoType"
        @show-paypalga-type="showPaypalGaType"
        @toggle-ppgv-type="togglePpgvType"
        @toggle-afterpay-type="togglePpgvType"
        @unfold="paymentMethodsProps.unfold"
      />
    </ul>
  </div>
</template>

<script setup>
// components
import { Icon } from '@shein-aidc/icon-vue3'
import paymentOptions from '../../vue_tpls/paymentOptions.vue'
import { SToast } from '@shein-aidc/sui-toast'

// utils
import { computed, ref, defineExpose, onMounted, watch } from 'vue'
import { template, transformImg } from '@shein/common-function'
import { PPGA_CHECK_TYPE, AFTER_PAY_CHECK_TYPE } from 'public/src/pages/common/constants'

const props = defineProps({
  paymentMethodsProps: {
    type: Object,
    default: () => {},
  },
  language: {
    type: Object,
    default: () => {},
  },
  nowSelectPay: {
    type: Object,
    default: () => {},
  },
  checkout: {
    type: Object,
    default: () => {},
  },
  payMethodsListDrawerHeaderData: {
    type: Object,
    default: () => {},
  },
  isAutoRenewal: {
    type: Boolean,
    default: false
  },
  autoType: {
    type: String,
    default: 'prime'
  }
})

const emit = defineEmits(['changePay'])

const status = ref({
  isChoosePay: 0,
  cardPay: '', //支付方式
  newOrder: 0,
  paypalUrl: 0,
  flash: true,
  shipping: 1,
  checkout: 0,
  pay: {
    yandexShow: false,
    qiwiPhoneShow: false,
  },
  error: {
    nopayment: 0,
    coupon: 0,
    points: 0,
    couponCode: 0,
    pointsCode: 0,
    couponCodeNum: 0,
    maxAvailablePoint: 0,
    qiwiPhone: 0,
    txt: '',
  },
  loading: {
    coupon: 0,
    points: 0,
    twice: 0, // 避免触发两次
  },
  success: {
    coupon: 0,
    points: 0,
  },
  chooseBank: 0,
  chooseAddress: 1,
  addressCompOpen: 0,
  editIndex: 0,
  selectIndex: 0,
  hasAddress: '',
  addressAddTips: false,
  tipsText: '',
  disabled: {},
})
const nowSelectPayData = ref({})
const tempPaypalGaVault = ref({})
const tempAfterpayVault = ref({})
const tempSignupPayVault = ref({})

const selectPayTitle = computed(() => {
  return props.nowSelectPay?.title
})

const tipContent = computed(() => {
  const tips = {
    'common': props.language.SHEIN_KEY_PC_31240,
    'xtra': props.language.SHEIN_KEY_PC_31082,
    'prime': template(props.payMethodsListDrawerHeaderData?.product_name_language, props.language.SHEIN_KEY_PC_29644)
  }
  return tips[props.autoType]
})

// watch(
//   () => props.paymentMethodsProps.paypalGaVault,
//   data => {
//     tempPaypalGaVault.value = {
//       ...props.paymentMethodsProps.paypalGaVault,
//       radioType: PPGA_CHECK_TYPE.CHECKED,
//       type: PPGA_CHECK_TYPE.CHECKED,
//     }
//   },
//   {
//     immediate: true,
//     deep: true,
//   }
// )

const handlerTransformImg = img => {
  return transformImg(img)
}

const paymentDisplay = () => {
  return true
}

const payLimitTipShow = code => {
  let txt = ''
  if (
    props.paymentMethodsProps.checkout?.mall_caculate_info
      ?.paymentMethodAmountLimitInfo?.[code]
  ) {
    let item =
      props.paymentMethodsProps.checkout.mall_caculate_info
        .paymentMethodAmountLimitInfo[code]
    let { maxAmount, minAmount } = item
    if (minAmount && maxAmount) {
      txt = template(minAmount, maxAmount, props.language.SHEIN_KEY_PC_16932)
    } else if (!minAmount && maxAmount) {
      txt = template(maxAmount, props.language.SHEIN_KEY_PC_16955)
    } else if (minAmount && !maxAmount) {
      txt = template(minAmount, props.language.SHEIN_KEY_PC_16933)
    }
  }
  txt = txt.replace(/\s/g, ' ')
  return txt
}

// 置灰文案
const paymentDes = item => {
  let des = ''
  //如果支付可用
  if (item.show_description && item.enabled == 1 && item.description) {
    des = item.description
    des = des.replace(/\s/g, ' ')

    // 如果支付不可用，或者cod不可用时
  } else if (item.enabled != 1) {
    //按优先级展示置灰原因，越往下，优先级越高

    //业务配置置灰文案
    des = item.gray_description || des

    //金额限制
    if (
      props.paymentMethodsProps.checkout.mall_caculate_info
        .paymentMethodLimitInfo &&
      +props.paymentMethodsProps.checkout.mall_caculate_info
        .paymentMethodLimitInfo[item.code] == 0
    ) {
      des = payLimitTipShow(item.code) || des
    }
  }

  if (item.gray_reason == 1) {
    //业务配置置灰文案
    des = props.language.SHEIN_KEY_PC_25800 || ''
  }

  // 03 本地自营置灰 => wiki.pageId=1176265937
  if (item.gray_reason == 3) {
    //业务配置置灰文案
    des = props.language.SHEIN_KEY_PC_27000 || 'error 03'
  }

  return des
}

// 选择支付方式
const clickPayment = (id, code, item) => {
  if (item?.enabled === 0) return // 置灰情况下不能点击
  status.value.cardPay = code
  nowSelectPayData.value = {
    id,
    code,
    item,
  }
  if (
    props.paymentMethodsProps.needPayInlinePaymentMethods?.includes(
      item.code
    ) &&
    item.code.indexOf('klarna') > -1
  ) {
    props.paymentMethodsProps?.klarna(
      code,
      `#${item.code}-dialog-payments-container`,
      item.id
    )
  }

  emit('changePay')
}

const changeSelectPayment = () => {
  const { id, code, item } = nowSelectPayData.value
  window.checkoutEventCenter.emit('click-payment', {
    id,
    code,
    selectItem: item,
    isDoNeedMallCalculateTotal: true,
  })
}

const togglePpgvType = data => {
  const text = template(
    props.payMethodsListDrawerHeaderData?.product_name_language,
    props.language.SHEIN_KEY_PC_29646
  )
  const languageText = props.autoType == 'xtra' ? props.language.SHEIN_KEY_PC_31173 : text
  SToast(languageText)
}

const changePpgvType = () => {
  if (
    props.paymentMethodsProps.paypalGaVault.radioType ===
    tempPaypalGaVault.value.radioType || !['PayPal-GApaypal'].includes(status.value.cardPay)
  )
    return

  props.paymentMethodsProps.togglePPGVType(tempPaypalGaVault.value)
}

const changeAfterpayType = () => {
  if (
    props.paymentMethodsProps.afterpayVault.radioType ===
    tempAfterpayVault.value.radioType || !['afterpay-card'].includes(status.value.cardPay)
  )
    return

  props.paymentMethodsProps.toggleAfterpayType(tempAfterpayVault.value)
}

const changeSignupPayType = (paymentCode) => {
  const paymentVault = props.paymentMethodsProps.signupPayVault?.[paymentCode]
  const tempPaymentVault = tempSignupPayVault.value?.[paymentCode]
  if (
    paymentVault.radioType ===
    tempPaymentVault.radioType || ![paymentCode].includes(status.value.cardPay)
  )
    return

  props.paymentMethodsProps.toggleSignupPayType(paymentCode, tempSignupPayVault.value)
}

const changeBank = bankData => {
  props.paymentMethodsProps.updateBanksConfig(
    props.paymentMethodsProps.delayChangeBanksData
  )
}

const initData = () => {
  status.value.hasAddress =
    props.paymentMethodsProps.checkout.default_address.addressId == 0 ? 0 : 1
  status.value.cardPay = ''
  props.paymentMethodsProps.setDelayChangeBanksData(
    props.paymentMethodsProps.banksConfig
  )

  tempPaypalGaVault.value = {
    ...props.paymentMethodsProps.paypalGaVault,
    radioType: PPGA_CHECK_TYPE.CHECKED,
    type: PPGA_CHECK_TYPE.CHECKED,
  }

  tempAfterpayVault.value = {
    ...props.paymentMethodsProps.afterpayVault,
    radioType: AFTER_PAY_CHECK_TYPE.CHECKED,
    type: AFTER_PAY_CHECK_TYPE.CHECKED,
  }

  tempSignupPayVault.value = {
    ...props.paymentMethodsProps.signupPayVault,
  }
}

const showPaypalGaType = data => {
  props.paymentMethodsProps.paypalGaVault.product_name_language = props.payMethodsListDrawerHeaderData?.product_name_language
  props.paymentMethodsProps.showPaypalGaType(data, )
}

defineExpose({
  paymentDisplay,
  paymentDes,
  payLimitTipShow,
  clickPayment,
  changeSelectPayment,
  initData,
  changeBank,
  changePpgvType,
  changeAfterpayType,
  changeSignupPayType,
})

onMounted(() => {
  initData()
})
</script>

<style lang="less" scoped>
.pay-methods-list {
  margin-top: -13px;
  position: relative;
  z-index: 1;
  background: white;
  box-shadow: 0px 0px 7px rgba(130.19, 130.19, 130.19, 0.25);
  border: 13px solid rgba(255, 255, 255, 1);
  border-top-left-radius: 13px; /* 左上角圆角 */
  border-top-right-radius: 13px; /* 右上角圆角 */
  overflow-y: scroll;
  max-height: 380px;
  min-height: 238px;
  padding: 14px 35px 0 35px;
  border-bottom: unset;

  .cash-delivery {
    height: 64px;
    width: 100%;
    border-radius: 2px;
    border: 0.4px #c3c3c3 solid;
    border-left: unset;
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .left-green {
      height: 100%;
      width: 5px;
      background: #198055;
      margin-right: 13px;
    }
  }

  /deep/.check-title {
    display: none;
  }

  /deep/.payment-list {
    padding: unset;
  }

  /deep/ .checkout-payment__effiency {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  /deep/.payment-item {
    padding-left: 0;
  }

  /deep/.ppgv-arrow-effiency {
    display: none;
  }
}

.cash-right {
  .title {
    color: #222222;
    font-size: 14px;
    font-family: SF Pro;
    font-weight: 400;
    word-wrap: break-word;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    .logo {
      width: 30px;
      height: 20px;
      margin-right: 6px;
    }

    .logo-img {
      width: 100%;
      height: 100%;
    }

    .title-content {
      display: inline-block;
      max-width: 290px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .tip {
    color: #bf4123;
    font-size: 14px;
    font-family: SF Pro;
    font-weight: 400;
    text-transform: capitalize;
    word-wrap: break-word;
    display: flex;
    align-items: center;

    .tip-content {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-left: 4px;
    }
  }
}

.pay-list__title {
  color: black;
  font-size: 16px;
  font-family: SF Pro;
  font-weight: 590;
  text-transform: capitalize;
  word-wrap: break-word;
}
</style>



export const formatAccountChannel = (alias_type) => {
  let type = ''
  switch(alias_type) {
    case 1:
      type = 'email'
      break
    case 2:
      type = 'phone'
      break
    case 3:
      type = 'google'
      break
    case 4:
      type = 'facebook'
      break
    case 6:
      type = 'line'
      break
    case 7:
      type = 'vk'
      break
    case 8:
      type = 'kakao'
      break
    case 9:
      type = 'naver'
      break
    default:
      type = ''
      break
  }
  return type
}

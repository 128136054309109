<template>
  <!-- 所有促销活动(包含A类、B类) -->
  <PromotionIndex 
    v-if="!isSuggested"
    :name="language.SHEIN_KEY_PC_29869 + ':'"
    :total-price="caculateInfo.total_promotion_discount"
    :promotion-discount-list="promotionDiscountList"
  />
  <div v-else>
    <!-- A类促销活动 -->
    <PromotionIndex 
      :name="promotionNameA + ':'"
      :total-price="caculateInfo.every_body_promotion_discount"
      :promotion-discount-list="promotionDiscountListA"
    />
    <!-- 存在B类才展示everybody price -->
    <div 
      v-if="(caculateInfo.exclusive_promotion_discount && +caculateInfo.exclusive_promotion_discount.amount > 0) && (caculateInfo.every_body_price && +caculateInfo.every_body_price.amount > 0)"
      class="checkout-summary-promotion__everybody"
      :class="{'checkout-summary-promotion__everybody-body': showUnderline}"
    >
      <span class="price-detail-left">{{ language.SHEIN_KEY_PC_30551 + ':' }}</span>
      <span 
        class="price-detail-right"
      >
        {{ caculateInfo.every_body_price.amountWithSymbol }}
      </span>
    </div>
    <!-- B类促销活动 -->
    <PromotionIndex 
      :name="promotionNameB + ':'"
      :total-price="caculateInfo.exclusive_promotion_discount"
      :promotion-discount-list="promotionDiscountListB"
    />
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, ref, watch } from 'vue'
import { template } from '@shein/common-function'
import schttp from 'public/src/services/schttp'

import PromotionIndex from './PromotionIndex.vue'

const store = useStore()

const promotionDiscountList = ref([])
const promotionDiscountListA = ref([])
const promotionDiscountListB = ref([])

const isSuggested = computed(() => store.getters.isSuggested)
const isSuggestedInDe = computed(() => store.getters.isSuggestedInDe)
const language = computed(() => store.state.language)
const checkout = computed(() => store.state.checkout)
const promotionList = computed(() => store.state.promotionList || [])

const caculateInfo = computed(() => checkout.value?.mall_caculate_info || {})

const showUnderline = computed(() => {
  return +caculateInfo.value?.every_body_promotion_discount?.amount > 0 && +caculateInfo.value?.exclusive_promotion_discount?.amount > 0
})

const getCategoryName = (params) => {
  return new Promise(resolve => {
    schttp({
      url: `/api/checkout/promotion/getPromotionById`,
      method: 'POST',
      data: params,
    }).then(res => {
      if (res.code == 0 && res.info) {
        resolve(res.info)
      }
      resolve({})
    }).catch((err) => {
      console.log('err', err)
      resolve({})
    })
  })
}


const promotionNameA = computed(() => {
  return isSuggestedInDe.value ? 
    language.value.SHEIN_KEY_PC_31105 : 
    language.value.SHEIN_KEY_PC_30550
})

const promotionNameB = computed(() => {
  return isSuggestedInDe.value ? 
    language.value.SHEIN_KEY_PC_31106 : 
    language.value.SHEIN_KEY_PC_30552
})

const getPromotionDiscountList = async() => {
  const discountList = caculateInfo.value?.promotion_discount_info || []
  const detail = promotionList.value || []
 
  // 价格明细中促销去除展示 32
  let _discountList = await Promise.all(discountList.map(async item => {
    let target
    // 保价(保价活动不在A类B类中)
    const isInsured = item.discount_price4_insurable?.amountWithSymbol
    if(isInsured) { 
      target = {
        name: language.value?.SHEIN_KEY_PC_23163,
        id: 'insured_discount',
      }
    } else {
      target = detail.find(_ => _.id == item.id)
      // 33 是品类首津
      if (item.typeId == 33) {
        const promotionId = item?.promotion_ids?.[0]
        if (promotionId) { 
          const { list = [] } = await getCategoryName({ promotionId })
          const cat_name_multi = list?.find(val => val?.promotion_id == promotionId)?.category_info[0]?.cat_name_multi
          const name = !!cat_name_multi ? template(cat_name_multi, language.value?.SHEIN_KEY_PC_30876) : language.value?.SHEIN_KEY_PC_30873
          target && (target.name = name)
        }
      }
    }
    return Object.assign(item, { name: target?.name, discount_price: isInsured ? item.discount_price4_insurable : item.discount_price })
  }))

  _discountList?.filter(_ => _.typeId != 32) || []

  if(+caculateInfo.value?.other_discount_price?.amount > 0) {
    _discountList.push({
      name: language.value?.SHEIN_KEY_PC_17702,
      id: 'special',
      discount_price: caculateInfo.value?.other_discount_price,
      cart_ids: caculateInfo.value?.other_discount_for_cart_ids,
      every_body_price_type: 1, // 聚合在A类下展示
    })
  }

  promotionDiscountList.value = _discountList?.sort((a, b) => +b.discount_price?.amount - +a.discount_price?.amount) || []
  promotionDiscountListA.value = promotionDiscountList.value?.filter(_ => _.every_body_price_type == 1 && _.typeId != 33)
  promotionDiscountListB.value = promotionDiscountList.value?.filter(_ => _.every_body_price_type == 2) // 品类首津在B类活动
}

watch([() => caculateInfo.value?.promotion_discount_info, () => promotionList.value], ([newValA, oldValA], [newValB, oldValB]) => {
  if (newValA != oldValA || newValB != oldValB) {
    getPromotionDiscountList()
  }
}, {
  immediate: true
})
</script>

<style lang="less" scoped>
.checkout-summary-promotion__everybody {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
}
.checkout-summary-promotion__everybody-body {
  padding-bottom: 6px;
  margin-bottom: 6px;
  border-bottom: 1px solid #e5e5e5;
}
</style>

<template>
  <div 
    v-if="showSizeWrap"
    class="combobuy-popup__size-wrap"
  >
    <div
      :class="['combobuy-popup__size-content', { 'is-warn': warnStatus}, {'hascode-bg': _cartInfo.skuCode }]"
    >
      <img
        v-if="mainAttrImg"
        class="color-section"
        :src="mainAttrImg"
      />
      <div 
        class="text-hidden"
        :class="{ 'hascode-text': _cartInfo.skuCode }"
      >
        {{ mainAttrName }}
        {{ _skuText ? `${mainAttrName ? ' / ' : ''}${_skuText}` : '' }}
      </div>
      <Icon
        name="sui_icon_more_down_12px"
        size="12px"
        class="more-icon"
      />
    </div>
    <Icon
      v-if="warnStatus"
      class="warn-icon"
      :class="{'show': warnStatus}"
      name="sui_icon_reported_20px"
      size="18px"
      color="#BF4123"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CommonCartSize',
})
</script>

<script setup>
import { computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  }
})
const _cartInfo = computed(() => props.item._cartInfo || {})
const mainAttrName = computed(() => _cartInfo.value.mainAttrName)
const mainAttrImg = computed(() => _cartInfo.value.mainAttrImg)
const warnStatus = computed(() => _cartInfo.value.warnStatus)
// 如果未选中sku，使用type名称
const _skuText = computed(() => _cartInfo.value.skuCode ? _cartInfo.value.skuName : _cartInfo.value.skuTypeName)

const showSizeWrap = computed(() => {
  const { mainAttrName } = _cartInfo.value
  if(!mainAttrName && !_skuText.value) {
    return false
  }
  return true
})
</script>

<style lang="less" scoped>
.combobuy-popup {
  &__size-wrap {
    display: flex;
    align-items: center;
    cursor: pointer;
    max-width: 250px;
    .warn-icon {
      opacity: 0;
      transition: opacity 0.3s;
      margin-left: 4px;
      &.show {
        opacity: 1;
      }
    }
  } 
  &__size-content {
    height: 20px;
    padding: 4px 6px;
    border: 1px solid @sui_color_gray_weak1;
    color: @sui_color_gray_dark1;
    font-family: SF Pro;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 39px;
    background: @sui_color_white;
    overflow: hidden;
    transition: color 0.3s;
    overflow: hidden;
    &.is-warn {
      background: @sui_color_unusual_bg;
      color: @sui_color_highlight;
    }
    &.hascode-bg {
      background: @sui_color_gray_weak2;
      border: none;
    }
    .color-section {
      min-width: 12px;
      height: 12px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 2px;
    }
    .hascode-text {
      color: @sui_color_gray_dark2;
    }
    .text-hidden {
      // 超出省略
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .more-icon {
      margin-left: 2px;
    }
  }
}
</style>

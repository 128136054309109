import { createSSRApp as createVueApp } from 'vue'
import { registerBaseContext } from '../common/common_inject'
import { ClientOnly } from '@sheinfe/vue-client-only'

import App from './App.vue'
import store from 'public/src/pages/checkout/config/store.js'

export function createApp(context) {

  const app = createVueApp(App, {
    context
  })
  registerBaseContext(app, context)

  app.use(store)
  app.component('ClientOnly', ClientOnly)
  
  return { app, store }
}

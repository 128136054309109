<template>
  <component
    :is="saverComponent"
    v-if="saverOptionsVisible"
    :payment-methods-props="paymentMethodsProps"
  />
</template>

<script>
export default {
  name: 'XtraOptions'
}
</script>

<script setup>
import { computed } from 'vue'
import { useMapState, useMapGetters } from 'public/src/pages/store_pages/store/mapHook'

import OldXtraIndex from './OldXtraIndex.vue'
import SaverProductPackageAdapter from 'public/src/pages/checkout/components/product-package/saver/SaverProductPackageAdapter.vue'

const { 
  checkout,
  usedXtraCode,
} = useMapState([
  'checkout',
  'usedXtraCode'
])

const { 
  currentSaverProduct,
  saverModuleVisible
} = useMapGetters([
  'currentSaverProduct',
  'saverModuleVisible'
])

defineProps({
  paymentMethodsProps: {
    type: Object,
    default: () => {}
  }
})

const saverOptionsVisible = computed(() => {
  const productInfo = currentSaverProduct.value.productInfo || {}
  const discountInfo = currentSaverProduct.value.discountInfo || {}
  const isGainReturns = parseFloat(discountInfo.total_discount?.amount) >= parseFloat(productInfo.product_price_info?.special_price)
  if (checkout.value?.checkoutBFFAbtInfo?.NewUserSaver?.param?.NewUserSaver == 'new') {
    return isGainReturns || !!usedXtraCode.value
  }
  return saverModuleVisible.value
})

const saverComponent = computed(() => {
  const isNew = checkout.value?.checkoutBFFAbtInfo?.comfororder?.param?.comforsaver == 1
  return isNew ? SaverProductPackageAdapter : OldXtraIndex
})

</script>

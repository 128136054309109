<template>
  <div class="reward">
    <div class="top">
      <div class="left">
        <img
          :src="iconOptions.star"
          class="star"
        />
        <div
          class="points-total"
          v-html="pointsTotal"
        ></div>
        <points_tips />
      </div>
      <div class="right">
        <div class="shein-text-container">
          <div class="text-wrapper">
            <div class="text-main">
              {{ language.SHEIN_KEY_PC_31826 }}
            </div>
            <div class="text-stroke">
              <!-- 描边 -->
              {{ language.SHEIN_KEY_PC_31826 }}
            </div>
          </div>
        </div>
        <img
          :src="iconOptions.ipbg || defaultIconOpt.ipbg"
          class="ip-point"
        />
      </div>
    </div>
    <div class="glass-container">
      <slot name="order-points"></slot>
      <!-- Extra Bonus --->
      <div class="line"></div>
      <pointsExtra />
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import pointsExtra from './points_extrab.vue'
import { template } from '@shein/common-function'

import points_tips from './points_tips.vue'
import { defaultIconOpt } from './constant'
import { useStore } from 'vuex'

const store = useStore()

const checkout = computed(() => store.state.checkout)
const language = computed(() => store.state.language)  
const price = computed(() => store.state.checkout.mall_caculate_info)
const pointsTotal = computed(() => template(`<span style="color: #FA6338">${price.value.totalPoints}</span>`, language.value.SHEIN_KEY_PC_31703))
const iconOptions = computed(() => checkout?.value?.SHEINPOINTICON || defaultIconOpt)
// console.log(defaultIconOpt, 'iconOptions')

</script>
  
  <style lang='less' scoded>
  .reward {
    .top {
      display: flex;
      // justify-content: space-between;
      position: relative;
      margin-bottom: 14.5px;
      margin-top: 14.5px;
      .left {
        display: flex;
      }
      .right {
        position: absolute;
        right: 0;
        bottom: -28px;
        display: flex;
      }
      .ip-point{
        width: 55.82px;
        height: 62px;
      }
      // shein point 
      .shein-text-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        margin-right: 6px;
        .text-wrapper {
            position: relative;
            font-size: 12px;
            font-family: Arial, sans-serif;
            font-weight: 860;
            text-align: center;
            line-height: 1.2;
        }

        .text-main {
            color: white;
            position: relative;
            z-index: 1; /* 确保白色文字在上面 */
        }

        .text-stroke {
            position: absolute;
            top: 0;
            /* left: 50%;
            transform: translateX(-50%); */
            color: transparent;
            -webkit-text-stroke-width: 7px; /* 加粗描边 */
            -webkit-text-stroke-color: black;
            z-index: 0; /* 放在下面 */
            background: linear-gradient(180deg, #FFB61F, #FF7906);
            -webkit-background-clip: text !important;
            background-clip: text !important;
            -webkit-text-fill-color: transparent; /* 填充颜色透明 */
            -webkit-text-stroke: 7px transparent;
        }
    }

    }
    // 毛玻璃容器
    .glass-container{
      display: flex;
      padding: 10px 12px;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      border-radius: 4px;
      border: 1px solid #FFF;
      background: rgba(255, 255, 255, 0.65);
      backdrop-filter: blur(5px);
      .line {
        width: 100%;
        height: 0.5px;
        background: #E4E4E4;
      }
    }
    .points-total {
      color: #000;
      font-size: 14px;
      font-weight: 700;
      line-height: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 4px;
    }
    .star {
      margin-right: 4px;
      width: 16px;
      height: 16px;
    }
  }
  
  </style>
  


const GB_BI_PAGE_CHECKOUT = {

	saHandleApply:function(opt, param){
        let type = opt.type
        let from = opt.from
      	let activity_name = '', activity_param = {}

      	if (type == 'coupon') {
        	activity_name = from == 'couponList' ? 'click_popup_sidebarusecoupon' : 'click_coupon_code_apply';
        	activity_param = '';
      	} else if(type == 'points') {
        	activity_name = 'click_point_apply';
        	activity_param.points = param
      	} else {
      		activity_name = 'click_popup_edit_wallet_apply'
      	}
      	
      	sa('send',{
      		'activity_name': activity_name,
      		'activity_param':activity_param
      	})
    },
}

export default GB_BI_PAGE_CHECKOUT 
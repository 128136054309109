<template>
  <div class="order-points_container">
    <!-- 积分大概展示 -->
    <div class="points-total_container">
      <div :class="['left', taskPoints && 'normal-left']">
        <div class="no-task_points">
          <template v-if="taskPoints">
            <p class="normal-points">
              {{ language.SHEIN_KEY_PC_31717 }}
            </p>
          </template>
          <template v-else>
            <img
              :src="iconOptions.star"
              class="star"
            />
            <p
              class="points-total not-fsp-element"
              v-html="pointsTotal"
            ></p>
            <!-- 积分提示 -->
            <pointsTips />
          </template>

          <!-- 积分颗粒展示 -->
          <template v-if="!downStatus && atomsList.length">
            <template v-for="(item, index) in atomsList">
              <div
                v-if="item.type !== 'single'"
                :key="index"
                class="points-atom_item"
              >
                <img
                  v-if="item.iconImg"
                  :src="item.iconImg"
                />
                <span>+ {{ item.sourcePoint }}</span>
              </div>
            </template>
          </template>
        </div>
        <p
          v-if="taskPoints"
          class="tips"
        >
          {{ language.SHEIN_KEY_PC_31716 }}
        </p>
      </div>

      <div class="right">
        <span
          v-if="taskPoints"
          class="normal-total_points"
        >+{{ price.totalPoints + taskPoints }}</span>
        <Icon
          v-if="atomsList.length"
          name="sui_icon_more_down_16px"
          size="16px"
          :class="['down-icon', downStatus && 'down-icon_rotate']"
          @click="downStatus = !downStatus"
        />
      </div>
    </div>

    <!-- 积分明细详情 -->
    <!-- <transition name="down"> -->
    <pointsDetail
      v-if="downStatus && atomsList.length"
      :atoms-list="atomsList"
      :task-points="taskPoints"
      :opt-task-bg="optTaskBg"
    />
    <!-- </transition> -->
  </div>
</template>
<script setup>
import { computed, ref } from 'vue'
import { template } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue3'
import pointsDetail from './points_detail.vue'
import pointsTips from './points_tips.vue'
import { defaultIconOpt } from './constant'
import { useStore } from 'vuex'

defineProps({
  taskPoints: {
    type: Number,
    default: 0
  }
})

const store = useStore()

const language = computed(() => store.state.language)
const checkout = computed(() => store.state.checkout)
const price = computed(() => store.state.checkout.mall_caculate_info)
const optTaskBg = computed(() => iconOptions?.value.pointDetail || defaultIconOpt.pointDetail)

const downStatus = ref(false)

const pointsTotal = computed(() => template(`<span style="color: #FA6338">${price.value.totalPoints}</span>`, language.value.SHEIN_KEY_PC_31703))
const iconOptions = computed(() => checkout?.value?.SHEINPOINTICON || defaultIconOpt)

// 当此次下单的商品中存在不可参与多倍积分活动的商品，不展示倍数LOGO
const showDoubleLogo = computed(() => {
  return checkout.value?.results.carts.carts.filter(item => item.product.double_status == 0).length ? false : true
})

/* 积分明细颗粒 */
const atomsList = computed(() => {
  const { prime_points, rewardPoints, getDoublePoints } = price.value
  const { totalPointMultiple } = checkout.value?.results.carts.data

  const { SHEIN_KEY_PC_31704, SHEIN_KEY_PC_31705, SHEIN_KEY_PC_31706, SHEIN_KEY_PC_31707, SHEIN_KEY_PC_31715 } = language.value
  let result = []
  const multiple = +totalPointMultiple > 1 && !!+getDoublePoints
  if(multiple || prime_points){
    // 单倍积分(有单倍积分，且没有多倍积分，且有付费会员积分，则展示)
    if(!!+rewardPoints && !multiple){
      result.push({ type: 'single', text: SHEIN_KEY_PC_31704, sourcePoint: rewardPoints })
    }
    // 多倍积分
    if(multiple){
      const textMap = { '2': SHEIN_KEY_PC_31705, '3': SHEIN_KEY_PC_31706, '4': SHEIN_KEY_PC_31707 }
      const type = +totalPointMultiple > 2 
        ? +totalPointMultiple > 3 ? '4' : '3'
        : '2'
      result.push({
        type: 'multiple',
        text: textMap[type],
        sourcePoint: `${+getDoublePoints + (+rewardPoints ?? 0)}`, //多倍积分 + 单倍积分 = 倍数总积分
        iconImg: showDoubleLogo.value ? iconOptions.value[`pointMultiple${type}`] : null
      })
    }
    // 付费会员
    if(prime_points){
      result.push({ type: 'club', text: SHEIN_KEY_PC_31715, sourcePoint: prime_points, iconImg: iconOptions.value.club })
    }
  }
  return result
})
</script>
<style lang='less' scoded>
.order-points_container{
  width: 100%;
}

.points-total_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 25px;
    .left{
      // width: 89%;
      // display: flex;
      // flex-direction: column;
      .no-task_points{
        display: flex;
        align-items: center;
        min-height: 25px;
        flex-wrap: wrap;
        .star{
          margin-right: 4px;
          height: 18px
        }
        .points-total{
            color: #000;
            font-size: 16px;
            font-weight: 700;
            margin-right: 4px;
            max-width: 252px;
            line-height: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            min-height: 25px;
            line-height: 1.6;
        } 

        .normal-points{
          color: var(---sui_color_brand, #000);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .tips{
        color: var(---sui_color_gray_light1, #959595);
        font-size: 11px;
        font-weight: 400;
        margin-top: 3px;
      }
    }
    .normal-left{
      width: 78%;
    }
    .right{
      display: flex;
      .normal-total_points{
        color: #FF6B2D;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        margin-right: 2px;
      }
    }
}
/* 旋转 */
.down-icon{
    cursor: pointer;
    transform: 0;
    // transition: transform 0.5s;
}
.down-icon_rotate{
    transform: rotate(180deg); 
}
/* 内容显示隐藏 */
.down-enter-active, .down-leave-active {
  transition: all 0.5s;
}
.down-enter{
  opacity: 1;
}
 .down-leave-to {
  opacity: 0;
}

.points-atom_item{
      border-radius: 100px;
      border: 0.5px solid var(---sui_color_gray_weak1, #E5E5E5);
      background: #FFF;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 4px;
      color: #666;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      flex-shrink: 0;
      min-height: 15px;
      margin-left: 2px;
    img{
      max-height: 14px;
    }
    span{
      margin-left: 4px;
    }
}

</style>

const analysisCoupon = {
  click_moredealoff() {
    sa('send', { activity_name: 'click_moredealoff' })
  },
  clickCancelCoupon(opt) {
    if (opt.from == 'couponList') {
      sa('send', { activity_name: 'click_popup_sidebarcancelcoupon' })
      return
    }
    sa('send', {
      activity_name: 'click_coupon_code_cancel',
      activity_param: '',
    })
  },
}

export default analysisCoupon

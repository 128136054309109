import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import {
  couponCodeTips,
  isToLowerCase,
} from 'public/src/pages/checkout/config/tools.js'
import { htmlDecode, template, getQueryString } from '@shein/common-function'
import analysisCoupon from '../analysis/coupon'
import GB_BI_PAGE_CHECKOUT from '../analysis/bi.js'
import schttp from 'public/src/services/schttp'
const { IS_RW } = gbCommonInfo

export default {
  computed: {
    ...mapState([
      'firstCoupon',
      'caculateData',
      'couponsApply',
      'status',
      'paymentCouponListTxt',
      'language',
      'checkout',
      'coupon',
      'couponGiftInit',
      'isPrimeMember', 
      'primeReadCoupon',
      'autoCouponInfo',
      'bestCouponInfo',
      'myCouponListInfo',
      'bestCoupon',
      'usedXtraCode',
      'usedPrimeCode',
      'autoPrimeReadCoupon',
      'autoXtraReadCoupon',
      'is_manual_usedCoupon',
      'virtualCouponInfo',
      'buyNowNo',
      'isShowTokenPay',
      'handlerSelectBin',
      'tokenBinList',
      'defaultSelectTokenPay',
      'isShowTokenFrontInstallment',
      'installmentSelectBin',
      'usableCouponList',
    ]),
    ...mapGetters([
      'isCouponAutoUse', 
      'first_coupon_code',
      'isAutoUseWallet',
      'showNewCouponLanguage',
      'isSelectTokenPay'
    ]),
    // 站点mall 多mall站样式按多mall来.
    isSiteMallList() {
      return this.checkout?.results?.mallInfo?.mallInfoForSite?.length > 1
    },
    couponCodeErrorTips() {
      return couponCodeTips({
        language: this.language,
        status: this.status,
        paymentCouponListTxt: this.paymentCouponListTxt,
        msg: this.coupon.msg,
      })
    },
    couponErrorShow() {
      return this.status.error.coupon
    },
    checkCouponPrice() {
      const { amount: cAmount, amountWithSymbol: cAmountSymbol } =
        this.checkout?.mall_caculate_info?.couponPrice || {}

      return +cAmount > 0 ? cAmountSymbol : ''
    },
    applyCouponsSuccess() {
      return this.coupon.applyCouponsSuccess.map((item) => item.couponCode)
    },
    couponNewParams () {
      return this.caculateData?.prime_product_code ? { is_buy_club: 1, product_code: this.caculateData?.prime_product_code } : this.isPrimeMember ? {} : { is_buy_club: 0, product_code: '' }
    },
    isAutoUsePoint() {
      return this.checkout?.checkoutBFFAbtInfo?.orderautopoints?.param?.isAutoPoint != 0 && this.checkout?.mall_caculate_info?.pointPrice?.amount > 0
    },
    // 自动使用了积分/钱包
    isAutoUsePointsOrWallet() {
      const isAutoUseWallet = this.isAutoUseWallet && this.checkout?.mall_caculate_info?.walletPrice?.amount > 0
      return isAutoUseWallet || this.isAutoUsePoint
    },
    autoUseResourcesPrice() {
      return this.checkout?.mall_caculate_info?.auto_use_resources_price?.amountWithSymbol
    },
    nowBinsOpts() {
      let binsOpt = {}
      if (this.isShowTokenPay || this.isShowTokenFrontInstallment) {
        const routerPayCardData = (this.checkout?.results?.paymentMethods || [])?.find(item => item?.code === 'routepay-card')
        const installmentRouterPayCardData = (this.checkout?.results?.paymentMethods || [])?.find(item => item?.code === 'routepay-cardinstallment')

        let tempList = []
        if (this.isShowTokenPay) {
          tempList = [...(routerPayCardData?.card_token_list || [])]
        }
        if (this.isShowTokenFrontInstallment) {
          tempList = [...tempList, ...(installmentRouterPayCardData?.card_token_list || [])]
        }
        binsOpt.bins = [...new Set(tempList.map(item => item?.card_bin).filter(item => item && item?.length > 0))]
        binsOpt.need_select = ['routepay-card', 'routepay-cardinstallment'].includes(this.status?.cardPay) ? 1 : 0
        binsOpt.selected_bin = ''
        if (this.status?.cardPay === 'routepay-card') {
          const bin = this.handlerSelectBin || this.defaultSelectTokenPay?.card_bin || ''
          binsOpt.selected_bin = (bin && binsOpt.need_select === 1) ? bin : ''
        } 
        if (this.status?.cardPay === 'routepay-cardinstallment' && this?.isShowTokenFrontInstallment) {
          binsOpt.selected_bin = (this.installmentSelectBin && binsOpt.need_select === 1) ? this.installmentSelectBin : ''
        }
      }

      return binsOpt
    },
    // abt 是否展示 token 支付
    isShowTokenPaymentByAbt() {
      return this.checkout?.isShowTokenPaymentByAbt
    },

    isShowTokenFrontInstallmentAbt() {
      return  this.checkout?.abtInfo?.showTokenFrontInstallment?.param?.showTokenFrontInstallmentStatus !== '0'  // 展示卡分期token前置功能
    },

    isShowTokenPayment() {
      // 后端字段是否展示 token 支付
      const isShowRoutePayPaymentToken = this.checkout?.results?.paymentMethods?.find(
        item => {
          // shieldAddCard 0代表b不屏蔽，则走abt的判断逻辑
          return item?.code === 'routepay-card' && item?.shieldAddCard === 0 && (item.card_token_list || [])?.length > 0
        }
      )
      const isShowInstallmentPaymentToken = this.checkout?.results?.paymentMethods?.find(
        item => {
          return item?.code === 'routepay-cardinstallment' && item?.shieldAddCard === 0 && (item.card_token_list || [])?.length > 0
        }
      )
      return (
        this.isShowTokenPaymentByAbt && isShowRoutePayPaymentToken
      ) || (this.isShowTokenFrontInstallmentAbt && isShowInstallmentPaymentToken)
    }
  },
  methods: {
    ...mapMutations(['changeParamsStatus', 'assignState', 'upDateSceneStatusNew', 'upDatePrimeSceneStatus', 'updateState']),
    ...mapActions(['updateCart', 'filterCouponParams']),
    // 判断当前用户输入的券码，是否在最优券组合或other coupons组
    judgeAddCoupon(couponData) {
      let isIncludeList = false
      // shein 不需再进行 ab 判断
      if(!IS_RW) {
        this.myCouponListInfo?.bestCouponList?.forEach(item => {
          if(isToLowerCase(item.coupon, couponData)) isIncludeList = true
        })
        this.myCouponListInfo?.commonCouponList?.forEach(item => {
          if(isToLowerCase(item.coupon, couponData)) isIncludeList = true
        })
      }
      return isIncludeList
    },
    applyBestCoupon(couponCode) {
      // 手动用券后赋值，包括买券自动用券场景
      this.assignState({ 
        is_manual_usedCoupon: 1,
        showShipSwitch: false
      })

      const { applyCoupons = [] } = this.coupon
      const newCoupon = couponCode.toLowerCase()

      this.changeParamsStatus({ 
        type: 'coupon', 
        params: { 
          val: newCoupon,
          applyCoupons: applyCoupons.concat(newCoupon),
          errorCoupon: '',
          errorCouponList: []  
        } 
      })
      this.status.error.coupon = 0
      this.changeParamsStatus({
        type: 'caculateData',
        params: {
          coupon: this.coupon.applyCoupons.join(),
        },
      })

      this.getUpdateCoupon({
        add_coupon: this.couponsApply ? this.coupon.val : (this.coupon.lastAddCoupon || ''),
        latest_coupon: '',
        coupon: this.coupon.val
      })
    },
    newApplyCoupon({ item = {} }, opt = {}) {
      const { type, isSelected, isClicked, isAddCoupon = this.couponsApply } = opt
      const { applyCoupons = [] } = this.coupon
      const { coupon, mall = {}, store = {} } = item
      const store_code = store.store_code || ''
      const mall_code = mall.mall_code || ''
      const couponList = type == 'clickCoupon'
      const newCoupon = coupon.toLowerCase()
      let couponListStatus = {
        status: 'cancel',
        statusGa: 'CancelSelect',
      }

      this.changeParamsStatus({
        type: 'coupon',
        params: {
          val: newCoupon,
          errorCoupon: '',
          errorCouponList: [] 
        },
      })

      let isCancelled = applyCoupons.includes(newCoupon)
      if (isClicked) {
        // 如果是点击勾选，需要额外判断该张券是否已被使用，因为券码不唯一
        isCancelled = isSelected && isCancelled
      }
      if (isCancelled) {
        // 已使用，第二次点击将其取消
        this.changeParamsStatus({
          type: 'coupon',
          params: {
            val: '',
            applyCoupons: applyCoupons.filter((item) => item != newCoupon),
          },
        })
        analysisCoupon.clickCancelCoupon({
          type: 'coupon',
          from: couponList ? 'couponList' : '',
        })
      } else {
        this.changeParamsStatus({
          type: 'coupon',
          params: { applyCoupons: applyCoupons.concat(newCoupon) },
        })
        couponListStatus = {
          status: 'selected',
          statusGa: 'Select',
        }
        GB_BI_PAGE_CHECKOUT.saHandleApply({
          type: 'coupon',
          from: couponList ? 'couponList' : '',
        })
      }
      if (couponList) {
        daEventCenter.triggerNotice({
          daId: '1-11-1-25',
          extraData: {
            type: 'choose',
            store_code,
            mall_code,
            ...couponListStatus,
          },
        })
      }

      this.status.error.coupon = 0
      this.changeParamsStatus({
        type: 'caculateData',
        params: {
          coupon: this.coupon.applyCoupons.join(),
        },
      })

      // 手动用券后赋值，包括买券自动用券场景
      this.assignState({ 
        is_manual_usedCoupon: 1,
        showShipSwitch: false
      })

      this.getUpdateCoupon(
        {
          add_coupon: isAddCoupon
            ? this.coupon.val
            : this.coupon.lastAddCoupon || '',
          latest_coupon: this.coupon.val,
        },
        { ...opt }
      )
    },
    exposeUsedCouponGoods({ couponCode, applySuccessCoupon, usableCouponList }) {
      if (!couponCode || !applySuccessCoupon.length || !usableCouponList.length) return
      const applyCoupons = applySuccessCoupon.map(item => item.couponCode.toLowerCase()) || []
      const lastCouponSuccess = applyCoupons.includes(couponCode.toLowerCase())
      if (!lastCouponSuccess) return

      const usedCouponData = usableCouponList.filter(item => item.coupon.toLowerCase() == couponCode.toLowerCase()) || []
      const mall_cart_coupon_goods_lists = usedCouponData?.[0]?.mall_cart_coupon_goods_lists || []
      daEventCenter.triggerNotice({
        daId: '1-11-1-99',
        extraData: {
          coupon_code: couponCode,
          goods_data: !mall_cart_coupon_goods_lists.length ? '0' : '1'
        }
      })
    },
    exposeAutoCoupons() {
      const auto_coupon = getQueryString({ key: 'auto_coupon' }) || ''
      const autoUseByAbt = this.checkout.checkoutBFFAbtInfo?.Placeordercouponoptimization?.param?.place_order_coupon_optimization == 'on' ? true : false
      // 如果在实验组，在点击checkout和到达下单页时，均没有自动选中券码，则报 0
      // 在购物车页点击checkout 时有最优券券码， 进入下单页时没有自动用券券码，则报 1
      // 在购物车页点击checkout 时没有最优券券码， 进入下单页时有自动用券券码，则报 2
      // 在购物车页点击checkout 时有最优券券码， 进入下单页时有自动用券券码，但前后选中的券码不一致，则报 3 （如点击checkout 自动选中的券为A+B+C，到下单页时选中的券码是A+B+D）
      // 在购物车页点击checkout 时有最优券券码， 进入下单页时有自动用券券码，前后选中的券码完全一致，则报 4   （如点击checkout 自动选中的券为A+B+C，到下单页时选中的券码是A+B+C）
      // 非实验组，报 “-”
      let auto_coupon_res = '-'
      const applyCouponsSuccess = this.coupon?.applyCouponsSuccess || []
      if(autoUseByAbt) {
        if(!auto_coupon) { // 在购物车页点击checkout 时没有最优券券码
          auto_coupon_res = !!applyCouponsSuccess.length ? '2' : '0'
        } else {
          if(!applyCouponsSuccess.length) {
            auto_coupon_res = '1'
          } else {
            auto_coupon_res = applyCouponsSuccess?.map(item => item.couponCode?.toLowerCase())?.join(',') == auto_coupon.toLowerCase() ? '4' : '3'
          }
        }
      }
      daEventCenter.triggerNotice({
        daId: '1-11-1-146',
        extraData: { 
          automatically_result: auto_coupon_res
        }
      })
    },
    // 上报自动使用指定运输方式运费券
    reportShippingCoupon() {
      daEventCenter.triggerNotice({
        daId: '1-11-1-169',
        extraData: { 
          scenes: 'shipping_coupon_stackable',
          type: this.coupon?.applyCouponsSuccess?.some(coupon => (
            ![53, 55].includes(+coupon.typeId) && coupon.applyFor == 9
          )) ? 1 : 0
        }
      })
    },
    // 首次进入页面的券逻辑（该模块应该仅包括自动用券引导提示）
    handleFirstCouponTips(isFirst) {
      if(!isFirst) return

      // 自动用券提示
      this.exposeAutoCoupons()
      const applyCouponsSuccess = this.coupon?.applyCouponsSuccess || []
      if((!this.autoCouponInfo.alreadyShowed && applyCouponsSuccess.length) || this.isAutoUsePointsOrWallet) {
        this.changeParamsStatus({
          type: 'autoCouponInfo',
          params: {
            coupon: applyCouponsSuccess,
            couponGift: this.couponGift,
            show: true,
          }
        })

        let couponTips = template(applyCouponsSuccess.length, !!this.autoCouponInfo.couponGift ? this.language.SHEIN_KEY_PC_25980 : this.language.SHEIN_KEY_PC_25981)
        // 由 abt 控制的自动用券提示文案：TR-36687
        if (this.showNewCouponLanguage) {
          const tips = !!this.autoCouponInfo.couponGift ? this.language.SHEIN_KEY_PC_31135 : this.language.SHEIN_KEY_PC_31139
          const { coupon_discount_info: couponDiscountInfo = [] } = this.checkout?.mall_caculate_info || {}
          const { amount, amountWithSymbol } = couponDiscountInfo[0]?.discount_price || {}
          let savedPrice = amount
          let savedPriceWithSymbol = amountWithSymbol
          if (couponDiscountInfo.length > 1) {
            const symbol = amountWithSymbol.replace(amount, '{0}')
            savedPrice = couponDiscountInfo.reduce((acc, cur) => acc + Number(cur.discount_price.amount), 0)
            savedPriceWithSymbol = this.template(savedPrice.toFixed(2), symbol)
          }
          if (+savedPrice > 0) {
            couponTips = this.template(
              `<span style="color: var(--sui-color-promo, #FA6338);font-weight: 700;">${savedPriceWithSymbol}</span>`, 
              tips
            )
          }
        }
        if (!this.isAutoUsePointsOrWallet) {
          // 仅使用券
          // 自动用券后上报
          this.reportShippingCoupon()
          this.$message({
            htmlString: couponTips,
            type: 'success',
            offset: 186,
            onClose: () => {
              this.changeParamsStatus({
                type: 'autoCouponInfo',
                params: {
                  show: false,
                  alreadyShowed: true
                }
              })
            }
          })
        } else if (!(!this.autoCouponInfo.alreadyShowed && applyCouponsSuccess.length) && this.isAutoUsePointsOrWallet) {
          // 仅使用积分
          this.$message({
            htmlString: template(
              `<span style="color: var(--sui-color-promo, #FA6338);font-weight: 700;">${this.autoUseResourcesPrice}</span>`, 
              this.language.SHEIN_KEY_PC_27831
            ),
            type: 'info',
            offset: 186
          })
        } else {
          // 使用券 + 积分
          let tips = !!this.autoCouponInfo.couponGift ? this.language.SHEIN_KEY_PC_27833 : this.language.SHEIN_KEY_PC_27832
          if (this.showNewCouponLanguage) {
            tips = !!this.autoCouponInfo.couponGift ? this.language.SHEIN_KEY_PC_31137 : this.language.SHEIN_KEY_PC_31136
          }
          this.$message({
            htmlString: template(
              applyCouponsSuccess.length,
              `<span style="color: var(--sui-color-promo, #FA6338);font-weight: 700;">${this.autoUseResourcesPrice}</span>`, 
              tips
            ),
            type: 'info',
            offset: 186,
            onClose: () => {
              this.changeParamsStatus({
                type: 'autoCouponInfo',
                params: {
                  show: false,
                  alreadyShowed: true
                }
              })
            }
          })
        }
  
        daEventCenter.triggerNotice({
          daId: '1-11-1-145',
        })
        this.assignState({
          couponGiftInit: {
            fetchApi: true,
            showDialog: false,
          },
        })
      }

      daEventCenter.triggerNotice({
        daId: '1-11-1-169',
        extraData: {
          scenes: 'autopoint', // 自动使用积分的场景才会上报
          max_available_point: this.checkout?.mall_caculate_info?.maxAvailablePoint2,
          auto_point_result: this.isAutoUsePoint ? 1 : 0,
          is_below_auto_use_point_threshold: this.checkout?.mall_caculate_info?.is_below_auto_use_point_threshold
        }
      })
    },
    // 自动用券后更新券列表
    updateCouponListOnly (params = {}, opt = {}) {
      const couponAutoUseData = this.isCouponAutoUse ? { coupon: '', is_coupon_auto_use: 1, first_coupon_code: this.first_coupon_code } : {}
      const { couponData } = opt

      const options = {
        ...this.caculateData,
        ...params,
        ...(couponData ?? couponAutoUseData),
        coupon: this.checkout.mall_caculate_info?.coupon?.map(item => item?.couponCode?.toLowerCase()) || [],
        add_coupon: this.usableCouponList?.find(coupon => coupon.is_add == 1)?.coupon?.toLowerCase() || '',
        shein_club: this.couponNewParams,
        is_now_buy: !!this.buyNowNo ? 1 : 0,
        checkout_no: this.buyNowNo,
        ...this.nowBinsOpts
      }

      schttp({
        method: 'POST',
        url: '/api/coupon/getOnlyCouponListForOrderStore/get',
        data: options
      }).then(res => {
        this.updateCouponList(res)
      })
    },
    getUpdateCoupon(params = {}, opt = {}) {
      this.changeParamsStatus({ type: 'codConfirm', params: { applyType: 'coupon' } })
      // 价格和券列表串行更新
      const usedCoupon = this.coupon.val
      // isPrime 是否改变会员产品包刷新券信息
      const { isFirst, cb, type, isPrime, noLoading, isXtra } = opt
      if (this.coupon.couponLoading) return
      if (!noLoading && !isFirst && !isPrime && !isXtra && !this.isCouponAutoUse) {
        this.changeParamsStatus({
          type: 'coupon',
          params: {
            couponLoading: true,
          },
        })
      }
      const couponAutoUseData = this.isCouponAutoUse ? { coupon: '', is_coupon_auto_use: 1, first_coupon_code: this.first_coupon_code } : {}
      // 在没勾选进入勾选且没自动用券的情况，触发塞入会费券
      const { visibleMyCouponListMadal, virtualXtraCoupon, currentClickType, newPrimeProductList, couponVirtualCode } = this.virtualCouponInfo
      const vip_order_source = newPrimeProductList.find(f => f.product_code === this.caculateData?.prime_product_code)?.primeProductList.product_activity_type == 1 ? 1 : 0

      if(this.is_manual_usedCoupon == 1 && !this.isCouponAutoUse && !visibleMyCouponListMadal){
        if(this.usedXtraCode && virtualXtraCoupon?.coupon_code && currentClickType == 'xtra' && this.checkout.RiGHT_COUPON_AUTO == 'on'){
          const couponCode = this.caculateData.coupon.split(',').filter(f => f)
          if(!couponCode.includes(virtualXtraCoupon?.coupon_code) && virtualXtraCoupon?.coupon_code){ 
            couponCode.push(virtualXtraCoupon?.coupon_code)
            this.changeParamsStatus({
              type: 'caculateData',
              params: {
                coupon: couponCode.join(',')
              }
            })
          }
        }
        if(this.usedPrimeCode  && currentClickType == 'prime' && this.checkout.RiGHT_COUPON_AUTO == 'on'){
          const coupon_code = newPrimeProductList?.find(f => f.product_code === this.usedPrimeCode)?.product_dues_coupon_rights?.coupon_code
          const couponCode = this.caculateData.coupon.split(',').filter(f => f)
          if(coupon_code && !couponCode.includes(coupon_code)){
            couponCode.push(coupon_code)
            this.changeParamsStatus({
              type: 'caculateData',
              params: {
                coupon: couponCode.join(',')
              }
            })
          }
          // 当前包没会费券，但是券汇总里有券，需要剔除掉
          if(!coupon_code && couponVirtualCode.some(s => couponCode.includes(s))){
            this.filterCouponParams({ scene: 'prime' })
          }
        }
      }

      window.checkoutEventCenter.emit('orign-update-total', {
        opt: {
          ...this.caculateData,
          ...params,
          shein_club: this.couponNewParams,
          ...couponAutoUseData,
          vip_order_source,
          is_now_buy: !!this.buyNowNo ? 1 : 0,
          checkout_no: this.buyNowNo,
          ...this.nowBinsOpts
        },
        cb: (res, newRes) => {
          this.changeParamsStatus({
            type: 'coupon',
            params: {
              couponLoading: false,
            },
          })
          this.assignState({ 
            isCancelXtra: false
          })

          const { coupon = {}, calculate = {} } = newRes

          // 处理异常
          let errorInfo = {}
          if (calculate.code != 0) errorInfo = calculate
          if (coupon.code != 0) errorInfo = coupon
          if (!(errorInfo.code > 0)) {
            // 更新价格成功
            if (!this.isCouponAutoUse) {
              this.exposeUsedCouponGoods({ couponCode: params?.latest_coupon || '', applySuccessCoupon: calculate?.info?.coupon || [], usableCouponList: coupon?.info?.usableCouponList || [] })
            }
            // 更新券使用成功信息
            this.updateCouponRes(calculate, opt)
            // 更新券列表
            this.newUpdateCouponModule(coupon, opt)

            this.status.error.coupon = 0
            this.status.error.couponCode = 0
          } else {
            if (!isFirst) {
              this.updateCouponErr(errorInfo, opt)
            }

            this.changeParamsStatus({ type: 'coupon', params: { 
              errorCouponList: coupon.code == 0 ? coupon.info?.usableCouponList || [] : [],
            } })
          }


          // 点击优惠券按钮埋点
          if (this.couponsApply && !isPrime && !isXtra) {
            daEventCenter.triggerNotice({
              daId: '1-11-1-44',
              extraData: {
                apply_result: this.status.error.coupon == 0 ? 1 : 0,
                coupon_code: usedCoupon || '',
                errormessage: this.status.error.couponCode || 0,
              },
            })
            this.assignState({ couponsApply: false })
          }
          // 售光商品删券
          if (typeof cb == 'function' && type == 'soldOut') {
            cb(calculate)
          }
          // 使用/取消会员包失败
          if (typeof cb == 'function' && (isPrime || isXtra)) {
            cb(!(errorInfo.code > 0))
          }
          // 初始cb
          if ( typeof cb == 'function' && type == 'originCallBack'){
            cb(res)
          }
          // 点击具有重复券码的券
          if (typeof cb == 'function' && type == 'clickCoupon') {
            cb(res)
          }

          // 300627: 入参支付方式和卡bin优惠优惠券限制的支付方式无交集
          // 302458: 入参支付方式和卡bin优惠优惠券限制的支付方式一致，但是卡bin不一致
          if (['302458', '300627'].includes(calculate.code)) {
            this.changeParamsStatus({
              type: 'coupon',
              params: {
                errorCoupon: params?.latest_coupon || ''
              }
            })
            this.assignState({
              showPaymentCoupon: false
            })

            const routepayCardPayment = this.checkout?.results?.paymentMethods?.filter(item => ['routepay-card', 'routepay-cardinstallment'].includes(item.code) && item.enabled == 1) || []
            const isShowBinCouponsDrawerInNormalPay = routepayCardPayment && (coupon?.info?.usableCouponList || [])?.some(item => item?.card?.card_codes?.length > 0 && item?.is_check == 1)

            const nowList = coupon?.info?.errorCouponList?.length > 0
              ? coupon?.info?.errorCouponList
              : coupon?.info?.usableCouponList
            
            // 目前使用的卡 bin 优惠券的信息
            const nowUseCouponInfo = (nowList || [])?.find(item => 
              item.is_check != 0 && // 已经使用的券
              item?.card?.card_codes?.length > 0 // 卡bin优惠券
            ) || {}
            const isHadUsefulToken = nowUseCouponInfo?.card?.card_codes?.length > 0

            const nowCardCodesList = nowUseCouponInfo?.card?.card_codes || []
            const nowAllCardBinList = routepayCardPayment?.reduce((acc, item) => {
              return acc.concat(item?.card_token_list?.map(i => i?.card_bin) || [])
            }, [])
            // 是否有可用的卡 bin
            const isHadCanUseBin = nowCardCodesList.some(item => nowAllCardBinList.includes(item))
            
            if (isHadUsefulToken) {
              if (this.isShowTokenPayment) {
                if (calculate.code == '302458' && !isHadCanUseBin) {
                  this.$message({
                    message: calculate?.tips || '',
                    type: 'error'
                  })
                  return
                }
                this.assignState({
                  isShowBinCouponsDialog: true,
                  confirmUsePaymentCouponShow: false
                })
                return
              }
            }

            if (calculate.code == '300627') {
              if (isShowBinCouponsDrawerInNormalPay) {
                this.assignState({
                  isShowBinCouponsDialog: true
                })
              } else {
                this.assignState({
                  showPaymentCoupon: true
                })
                this.$message({
                  message: calculate?.tips || '',
                  type: 'error'
                })
              }
            }

          }
        },
        obj: {
          mallUrl: `/api/coupon/getCouponListForOrderStore/get`,
          isCoupon: true,
          type: 'coupon',
        },
      })
    },
    getFirstCouponRes() {
      let { couponCode = '', couponDiscountMsg } = this.firstCoupon

      schttp({
        method: 'POST',
        url: '/api/coupon/getCouponListForOrderStore/get',
        data: {
          ...this.caculateData,
          first_coupon_code: couponCode,
          shein_club: this.couponNewParams,
          is_now_buy: !!this.buyNowNo ? 1 : 0,
          checkout_no: this.buyNowNo,
          ...this.nowBinsOpts
        }
      }).then(res => {
        if (res?.calculate?.info?.coupon?.length) {
          // 可以使用首单券再展示对应文案
          this.assignState({
            firstCouponHtml: template(couponCode, couponDiscountMsg, htmlDecode({ text: this.language.SHEIN_KEY_PC_14755, isHtml: true }))
          })
        }
      }).catch(() => {
        return {}
      })
    },
    updateCouponErr(errorInfo, opt) {
      const { code, info, tips, msg } = errorInfo
      const { notTips } = opt
      // 多张券，保留之前使用成功的券
      const { applyCouponsSuccess } = this.coupon
      if (applyCouponsSuccess?.length) {
        let newApplyCoupons = applyCouponsSuccess.map((item) => {
          return item.couponCode.toLowerCase()
        })
        this.changeParamsStatus({
          type: 'coupon',
          params: {
            applyCoupons: newApplyCoupons,
          },
        })
        this.changeParamsStatus({
          type: 'caculateData',
          params: {
            coupon: newApplyCoupons.join(),
          },
        })
      } else {
        this.changeParamsStatus({
          type: 'coupon',
          params: {
            applyCoupons: [],
            val: '',
          },
        })
        this.changeParamsStatus({
          type: 'caculateData',
          params: {
            coupon: ''
          },
        })
      }

      // 错误提示语处理
      this.status.error.coupon = 1
      this.status.error.couponCode = code
      if (info?.couponMinOrder?.amountWithSymbol) {
        this.status.error.couponCodeNum = info.couponMinOrder.amountWithSymbol
      }
      if (code == 300634) {
        this.status.error.couponMinOrder = info?.couponMinOrder
        this.status.error.couponMaxOrder = info?.couponMaxOrder
        this.status.error.tips = tips
      }
      this.changeParamsStatus({
        type: 'coupon',
        params: {
          msg: tips || '',
        },
      })

      //优惠券使用报错弹窗
      if (this.couponCodeErrorTips && !notTips && !['302458'].includes(code)) {
        this.$message({
          message: this.couponCodeErrorTips,
          type: 'error',
          offset: 186,
        })
        this.calCbHasErrTip = true
      }

      daEventCenter.triggerNotice({
        daId: '1-11-1-24',
        extraData: {
          coupon: '',
          code: code || '',
          msg: msg || '',
        },
      })
    },
    filterRecommend (list) {
      const recommendCouponShowAbt = this.checkout.checkoutBFFAbtInfo?.OrderPageCouponTest?.param?.RecommendCouponShow == 'Hide' ? true : false

      return list?.filter(item => {
        return !recommendCouponShowAbt || item.list_type != 2 || item.is_add == 1
      })
    },
    exposeRecommendCouponInfo(bestCoupon) {
      if(this.bestCouponInfo.alreadyExposeCouponInfo) return

      this.changeParamsStatus({
        type: 'bestCouponInfo',
        params: {
          alreadyExposeCouponInfo: true,
        }
      })

      daEventCenter.triggerNotice({
        daId: '1-11-1-152',
        extraData: { 
          coupon_info: bestCoupon ? bestCoupon?.is_real_best : '-'
        }
      })
    },
    updateCouponList (res) {
      if (res.code == '0' && res.info) {
        let {
          bestCoupon, // 算法最优券
          usableCouponList = [], // 未用券
          disabledCouponList = [] // 不可用券
        } = res.info

        usableCouponList = this.filterRecommend(usableCouponList)
        disabledCouponList = this.filterRecommend(disabledCouponList)

        // 展示券列表入口
        let hasAvailableCoupon = false
        if (!!usableCouponList?.length) hasAvailableCoupon = true

        // 待更新的State
        const watingState = {
          usableCouponList,
          disabledCouponList,
          hasAvailableCoupon,
          isShowCouponError: false,
          bestCoupon,
        }

        this.assignState({ ...watingState })
      }
    },
    async newUpdateCouponModule(res, opt) {
      const { isFirst, isPrime, isXtra } = opt

      if (res.code == '0' && res.info) {
        this.updateCouponList(res)
        this.updateSceneVirtual()
        this.exposeRecommendCouponInfo(this.bestCoupon)
        this.handleFirstCouponTips(isFirst)

        // 购买付费会员自动用券场景、购买超省卡场景 增加使用成功提示
        if (this.isCouponAutoUse) {
          const applyCouponsSuccess = this.coupon?.applyCouponsSuccess || []
          const primeCoupon = applyCouponsSuccess.filter(item => item.applyFor == 9 && item.typeId == 53) || []
          const xtraCoupon = applyCouponsSuccess.filter(item => item.typeId == 55) || []
          const newPrimeReadCoupon = primeCoupon.map(i => i.couponCode)
          const newXtraReadCoupon = xtraCoupon.map(i => i.couponCode)

          if(primeCoupon.length) {
            this.assignState({ 
              showShipSwitch: true,
            })
          }

          if(!this.autoCouponInfo.show) {
            const isToastXtra = !!this.usedXtraCode && !!xtraCoupon.length
            // 自动用券后上报
            if (isToastXtra || primeCoupon.length) this.reportShippingCoupon()
            if(isXtra && isToastXtra) {
              this.$message({
                message: this.template(xtraCoupon.length, this.language.SHEIN_KEY_PC_26938),
                type: 'info',
                offset: 186,
              })
              this.assignState({ 
                autoXtraReadCoupon: newXtraReadCoupon
              })
              return
            }
            if(isToastXtra) {
              // 相同自动用券提示后 不在提示
              const isTips = this.autoXtraReadCoupon.toString() == newXtraReadCoupon.toString()
              if (!isTips) {
                let switchTips = this.template(xtraCoupon.length, this.language.SHEIN_KEY_PC_26938)
                this.$message({
                  message: switchTips,
                  type: 'info',
                  offset: 186,
                })

                this.assignState({ 
                  autoXtraReadCoupon: newXtraReadCoupon
                })
              }
            } else if(primeCoupon.length) {
              // 相同自动用券提示后 不在提示
              const isTips = this.autoPrimeReadCoupon.toString() == newPrimeReadCoupon.toString()
              if (!isTips) {
                let switchTips = this.template(primeCoupon.length, this.language.SHEIN_KEY_PC_24009)
                this.$message({
                  message: switchTips,
                  type: 'info',
                  offset: 186,
                })

                this.assignState({ 
                  autoPrimeReadCoupon: newPrimeReadCoupon
                })
              }
            } else {
              this.assignState({ 
                autoPrimeReadCoupon: [],
                autoXtraReadCoupon: []
              })
            }
          }
        }

        // 首次自动用券，买券不需要提示,随单购场景不需要提示
        if (isFirst || isPrime || isXtra) return
      }
    },
    updateCouponRes(res, opt = {}) {
      if(res.code != '0') return

      const { coupon = [], couponGift = null } = res?.info || {}
      const { isFirst, check, isPrime, isXtra, isModifyCart } = opt
      const couponLth = coupon.length
      const lastCoupon = this.coupon.val
      const applyCoupons =
        (coupon && coupon.map((item) => item.couponCode.toLowerCase())) || []
      const lastCouponSuccess = applyCoupons.includes(lastCoupon.toLowerCase())
      // 记录点击券列表的inx
      if (check?.coupon && couponLth) {
        coupon.forEach((item) => {
          if (isToLowerCase(item.couponCode, check.coupon)) {
            item.index = check.index
          }
        })
      }

      // 更新使用成功的券列表
      this.changeParamsStatus({
        type: 'coupon',
        params: {
          lastCoupon: lastCouponSuccess ? lastCoupon : '',
          applyCouponsSuccess: coupon || [],
          applyCoupons,
          isAutoUse: !this.is_manual_usedCoupon
        },
      })
      // 更新使用成功的券参数
      if (applyCoupons.length && !this.isCouponAutoUse) {
        this.changeParamsStatus({
          type: 'caculateData',
          params: {
            coupon: applyCoupons.join(),
          },
        })
      }

      // couponGift 更新券赠品
      this.assignState({
        isShowCouponError: false,
        couponGift,
      })

      // 使用 券赠品 的券 成功后打开赠品选择页 注意多mall场景第二次选择其他券的时候不需要打开
      if (
        isToLowerCase(lastCoupon, couponGift?.rules?.[0]?.couponCode) &&
        !isPrime && !isXtra && !isModifyCart
      ) {
        this.$nextTick(() => {
          this.assignState({
            couponGiftInit: {
              fetchApi: true,
              showDialog: true,
            },
          })
        })
      }

      // 首次 不需要更新购物车，如果是券赠品 删券，和更换券都要更新购物车
      if (isFirst || isModifyCart) return
      this.updateCart()
    },
    updateFirstCouponRes({ couponInfo, mallCaculateInfo }) {
      let { autoUse, couponCode = '', couponDiscountMsg } = this.firstCoupon
      let first_coupon_code = !!(+autoUse) && couponCode

      // 是否取消首单券使用
      if (!!(!first_coupon_code && couponCode)) {
        if (mallCaculateInfo?.info?.coupon?.length) {
          // 可以使用首单券再展示对应文案
          this.assignState({
            firstCouponHtml: template(couponCode, couponDiscountMsg, htmlDecode({ text: this.language.SHEIN_KEY_PC_14755, isHtml: true }))
          })
        }
      }

      this.updateCouponRes(mallCaculateInfo, { isFirst: true })
      this.newUpdateCouponModule(couponInfo, { isFirst: true })

      if (first_coupon_code) {
        // 可以使用首单券再展示对应文案
        this.assignState({
          firstCouponHtml: template(couponCode, couponDiscountMsg, htmlDecode({ text: this.language.SHEIN_KEY_PC_14755, isHtml: true }))
        })
      }
    },
    async getFirstUseCoupon() {
      let { autoUse, couponCode = '', couponDiscountMsg } = this.firstCoupon
      let first_coupon_code = !!(+autoUse) && couponCode
      let is_coupon_auto_use = 1
      // 是否取消首单券使用
      if (!!(!first_coupon_code && couponCode)) {
        this.getFirstCouponRes()
      }

      if (first_coupon_code || is_coupon_auto_use) {
        window.checkoutEventCenter.emit('orign-update-total', {
          opt: {
            ...this.caculateData,
            first_coupon_code: first_coupon_code ? couponCode : '',
            is_coupon_auto_use,
          },
          cb: (res) => {
            if (res.code == 0) {
              // 设置使用成功券
              this.updateCouponRes(res)

              const resCoupon = res.info.coupon
              if (resCoupon?.length && !is_coupon_auto_use) {
                this.changeParamsStatus({
                  type: 'coupon',
                  params: {
                    val: resCoupon[0].couponCode,
                  },
                })

                // 更新券列表
                let coupon = this.coupon.applyCoupons.join()
                this.changeParamsStatus({ type: 'caculateData', params: { 
                  coupon,
                } })
              }

              this.getUpdateCoupon({}, { isFirst: true })

              if (first_coupon_code) {
                // 可以使用首单券再展示对应文案
                this.assignState({
                  firstCouponHtml: template(couponCode, couponDiscountMsg, htmlDecode({ text: this.language.SHEIN_KEY_PC_14755, isHtml: true }))
                })
              }

            } else {
              // 首次用券失败，异步更新券列表
              this.clearCouponUpdateCalculator({ isFirst: true })
            }
          },
          obj: {
            isCoupon: true,
          },
        })
      } else {
        this.clearCouponUpdateCalculator({ isFirst: true })
      }
    },
    updateCouponGiftCart() {
      // 使用券赠品 刷新下单页 购物车还有赠品 首次计算价格没有用券 判断购物车是否有赠品的情况去更新购物车
      const isPromotion = this.checkout.results.carts.carts.filter(
        (item) => item.promotion_type_id == 1000
      )
      if (isPromotion.length) {
        this.updateCart()
      }
    },
    clearCouponUpdateCalculator(opt) {
      // 清除已有券信息 更新券列表 & 价格
      this.changeParamsStatus({
        type: 'caculateData',
        params: {
          coupon: '',
        },
      })
      this.getUpdateCoupon({}, opt)
    },
    focusCouponInput() {
      this.status.error.coupon = 0
    },
    /**
     * @description 是否为未满足门槛券 reason = 4 or 9
     * @param {string} reason 
     * @returns {boolean}
     */
    isThresholdNotMet(reason) {
      return ['4', '9'].includes(reason)
    },
    /**
     * @description 是否展示 未满足门槛券 优惠券适用范围内的商品弹窗入口
     * @param {*} couponItem 
     * @returns {boolean}
     */
    showEntrance(couponItem = {}) {
      if (!this.isThresholdNotMet(couponItem?.reason)) return false
      // 适用范围商品不为空
      return !!couponItem?.apply_goods?.cart_coupon_goods_lists?.length
    },
    // 点击门槛提示
    handleClickThresholdTip(couponItem = {}) {
      daEventCenter.triggerNotice({
        daId: '1-11-1-96',
        extraData: {
          coupon_code: couponItem.couponCode || couponItem.coupon || '',
          is_available: '0',
        }
      })
      // 无入口
      if (!this.showEntrance(couponItem)) return
      this.showCouponLimitedDialog(couponItem)
    },
    // 更新场景
    updateSceneVirtual(){
      this.upDateSceneStatusNew()
      this.upDatePrimeSceneStatus()
    },
    // click_coupon上报
    clickCouponFn(extraData = {}){
      daEventCenter.triggerNotice({
        daId: '1-11-1-133',
        extraData
      })
    }
  }
}

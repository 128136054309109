<template>
  <swiper-container
    ref="labelSwiperRef"
    init="false"
    class="labelSwiper-container"
    :dir="GB_cssRight ? 'rtl' : 'ltr'"
  >
    <swiper-slide
      v-for="(item,index) in config"
      :key="index"
      class="swiper-slide swiper-no-swiping"
    >
      <div class="item-container">
        <QuickShipTag
          v-if="item.quickship && qsMallList && qsMallList.last_quickship_tag"
          :cart-qs-tag-info="{
              ...quickShipInfoTag.qsDataNoLabelProps,
              themVarText: { color: '#fff' },
              themVarIcon: { color: '#fff' }
          }"
          :qs-mall-list="qsMallList"
          :last-qs-time="true"
        />
        <template v-else>
          <Icon
            :name="item.icon"
            size="15px"
            color="#fff"
          />
          <span :class="['text', item.quickship && 'font-ita']">{{ item.text }}</span>
        </template>
      </div>
    </swiper-slide>
  </swiper-container>
</template>
<script setup>
import { ref, nextTick, watchEffect } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { register } from 'swiper/element'
import { Autoplay } from 'swiper/modules'
import QuickShipTag from 'public/src/pages/cart_new/components/list/newList/QuickShipTag.vue'
import { useGetGuickShip } from 'public/src/pages/checkout/hooks/useQuickShip.js'
const { GB_cssRight } = gbCommonInfo

typeof window !== 'undefined' && register()
const { quickShipInfoTag, qsMallList } = useGetGuickShip()

const labelSwiperRef = ref(null)
let numIndex = ref(0)
const props = defineProps({
  config: {
    type: Array,
    default: () => ([])
  }
})
const initSwiper = () => {
  nextTick(() => {
    labelSwiperRef.value && Object.assign(labelSwiperRef.value, {
      direction: 'vertical',
      modules: [Autoplay],
      autoplay: {
        delay: 250,
        disableOnInteraction: false
      },
      noSwipingClass: 'item-container',
      loop: true,
      observer: true,
      observeParents: true,
      noSwiping: true,
      on: {
        slideChangeTransitionEnd: async (swiper) => {
          numIndex.value++
          if(numIndex.value == props.config.length){
            swiper.autoplay.stop()
            numIndex.value = 0
            await timerAutoPlay(swiper)
          }
        }
      }
    })
    labelSwiperRef.value.initialize()
  })
}

const timerAutoPlay = async (swiper) => {
  return new Promise(res => {
    setTimeout(() => {
      swiper.autoplay.start() // 播放完一轮后停止3秒,再播放
      res(true)
    }, 3000)
  })
}

watchEffect(()=>{
  if (typeof window != 'undefined' &&  props.config) {
    initSwiper()
  }
})
</script>
<style lang="less" scoped>
.labelSwiper-container{
    height: 20px;
}
.item-container{
    display: flex;
    color: #fff;
    height: 100%;
    align-items: center;
    .text{
        margin-left: 3px;
    }
    .font-ita{
      font-style: italic;
    }
}
</style>

export const defaultIconOpt = {
  'star': 'https://img.ltwebstatic.com/images3_ccc/2024/07/08/9b/1720438342a8ed9fa8d4959a1cf1e0016e4e06aabc.png',
  'club': 'https://img.ltwebstatic.com/images3_ccc/2024/07/08/8c/172042806446090076c871f2cc49fb93ca49dafbcc.png',
  'pointMultiple2': 'https://img.ltwebstatic.com/images3_ccc/2024/07/08/32/17204281860d2b3a09bbfa6c44fd11d8a8ab7f61f8.png',
  'pointMultiple3': 'https://img.ltwebstatic.com/images3_ccc/2024/07/09/28/17205109831dcb631d43076fde064cd58923e81b0c.png',
  'pointMultiple4': 'https://img.ltwebstatic.com/images3_ccc/2024/07/09/e8/1720510941a99723d8404c87f640cb3e921429b31a.png',
  'pointBg': 'background: linear-gradient(305deg, #FFF4E5 4.25%, #FFECD4 99.14%)',
  'ipbg': 'https://img.ltwebstatic.com/images3_ccc/2024/07/11/53/1720667152bb90efdb3e51b5ad566326ce10fff86f.png',
  'pointDetail': '#FFF3E2',
}

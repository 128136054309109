<template>
  <div class="checkout-prime__wrap not-fsp-element">
    <img
      class="checkout-prime__wrap-logo"
      :src="primeLogoImg"
      alt=""
    />

    <div
      v-if="selectedPrime && selectedPrime.product_code"
      class="checkout-prime__wrap-body"
      @click="clickPrime"
    >
      <s-radio
        v-model="primeSeleted"
        :toggle="true"
        :disabled="notSupport || status.hasAddress == 0"
        :label="true"
        :size="'radio20'"
        :theme="'icon'"
        gap="0"
        class="checkout-prime__wrap-radio"
        style="align-items: start;"
      >
        <div style="white-space: normal;">
          <div
            class="checkout-prime__wrap-name"
            :class="{ 'no-support': notSupport || status.hasAddress == 0 }"
          >
            <strong
              class="discount"
              :style="{ opacity: isLimited && !animateEndFlag ? 0 : 1 }"
            >{{
              selectedPrime.product_price_info.special_price_with_symbol
            }}</strong>
            <strong
              v-if="isLimited && !animateEndFlag"
              class="discount-local"
            >{{
              selectedPrime.product_price_info.price_local_with_symbol
            }}</strong>
            <template 
              v-if="isLimited"
            >
              <del
                class="checkout-prime__wrap-local"
                :style="{ opacity: animateEndFlag ? 1 : 0 }"
              >
                {{ selectedPrime.product_price_info.price_local_with_symbol }}
              </del>
              <span
                class="prime-limited prime-limited-color"
                v-html="discountTips"
              >
              </span>
              <span class="checkout-prime__wrap-title">
                {{ selectedPrime.product_name_language }}
              </span>
            </template>
            <template v-else>
              <span class="checkout-prime__wrap-title">
                {{ selectedPrime.product_name_language }}
              </span>
            </template>
          </div>
          <!-- 优惠倒计时 -->
          <p
            v-if="isLimited && selectedPrime.product_price_info.countdown"
            class="checkout-prime__countdown"
            :class="{ 'no-support': notSupport || status.hasAddress == 0 }"
          >
            {{ timestepTips }}
          </p>
          <div
            class="checkout-prime__wrap-des prime-gap"
            :class="{ 'no-support': notSupport || status.hasAddress == 0 }"
            v-html="primeDeduceText"
          >
          </div>

          <PrimeOptionsGoods :class="{ 'no-support': notSupport || status.hasAddress == 0 }" />

          <div
            class="checkout-prime__wrap-link prime-gap"
            :class="{ 'no-support': notSupport || status.hasAddress == 0 }"
            @click.stop.prevent="viewMore"
          >
            {{
              primeSeleted
                ? language.SHEIN_KEY_PC_22968
                : language.SHEIN_KEY_PC_22964
            }}
            >
          </div>
          <div
            v-if="notSupport && status.cardPay"
            class="checkout-prime__wrap-tip prime-top"
          >
            <sui_icon_reported_14px_1
              size="12px"
              color="#bf4123"
            />
            {{ template(nowPayment.title, language.SHEIN_KEY_PC_22969) }}
          </div>
        </div>
      </s-radio>
    </div>

    <ClientOnly>
      <PrimeDetail
        v-if="isClient"
        ref="primeDetail"
        @choose-prime="choosePrime"
      />
    </ClientOnly>
  </div>
</template>

<script>
import PrimeOptionsGoods from './prime_options_goods'
import { sui_icon_reported_14px_1 } from '@shein-aidc/icon-vue3'

import couponMixin from '../../mixins/couponMixin'

import { template as _commonTemplate, getQueryString, CountDown } from '@shein/common-function'
import { defineAsyncComponent } from 'vue'
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'


daEventCenter.addSubscriber({ modulecode: '1-11-1' })

export default {
  name: 'PrimeOptions',
  components: {
    PrimeDetail: defineAsyncComponent(() => import('../prime_des/prime_detail')),
    PrimeOptionsGoods,
    sui_icon_reported_14px_1,
  },
  mixins: [couponMixin],
  props: {
    selectedPrime: {
      type: Object,
      default: () => {}
    },
    notSupport: {
      type: Boolean,
      default: false
    },
    nowPayment: {
      type: Object,
      default: () => {}
    },
    primeDeducePrice: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      timer: null,
      animateEndFlag: false,
      primeSeleted: false,
      isInitInx: false, // 是否设置过默认index
      isClient: false,
    }
  },
  computed: {
    ...mapState([
      'primeProductList',
      'usedPrimeIndex',
      'selectdPrimeIndex',
      'status',
      'caculateData',
      'isAgreePrime',
      'usedPrimeCode',
      'locals',
    ]),
    ...mapGetters([
      'isNewPrimeVersion',
    ]),
    PUBLIC_CDN() {
      return this.locals.PUBLIC_CDN
    },
    GB_cssRight() {
      return this.locals.GB_cssRight
    },
    // 倒计时文案
    timestepTips () {
      if (!this.timer) return ''
      const { H, M, S } = this.timer?.timeObj || {}
      if (Number(H) > 72) return ''
      return this.template(
        `${H}:${M}:${S}`,
        this.language.SHEIN_KEY_PC_25760
      )
    },
    paymentMethods() {
      return this.checkout?.results?.paymentMethods || []
    },
    primeDeduceText() {
      const { SHEIN_KEY_PC_25149, SHEIN_KEY_PC_25150, SHEIN_KEY_PC_25152 } = this.language
      const special_price = this.selectedPrime?.product_price_info?.special_price || 0
      const average_price = this.selectedPrime?.product_price_info?.average_daily_price_with_symbol || 0
      // 未勾选状态下折扣值
      const pay_member_discount_price = this.checkout?.mall_caculate_info?.pay_member_discount_price || {}
      const primeDeducePrice = !!this.usedPrimeCode ? this.primeDeducePrice : pay_member_discount_price || {}

      if(+primeDeducePrice.amount > 0) {
        if(+primeDeducePrice.amount < +special_price) { // 本单可省金额＜开通付费会员金额
          return this.template('<i class="prime-deduce">' + primeDeducePrice.amountWithSymbol + '</i>', SHEIN_KEY_PC_25149)
        } else { // 本单可省金额＞=开通付费会员金额
          return SHEIN_KEY_PC_25150
        }
      } else { // 本单无可省金额
        return this.template('<i class="prime-deduce">' + average_price + '</i>', SHEIN_KEY_PC_25152)
      }
    },
    primeLogoImg() {
      return this.GB_cssRight
        ? `${this.PUBLIC_CDN}/she_dist/images/checkout/prime_logo_ar-3f89d550f4.png`
        : `${this.PUBLIC_CDN}/she_dist/images/checkout/prime_logo-639985dbc9.png`
    },
    isLimited () {
      const selectedPrime = this.selectedPrime
      return (+selectedPrime.product_price_info.special_price < +selectedPrime.product_price_info.price_local)
    },
    discountTips() {
      return this.template(
        this.selectedPrime?.product_price_info?.price_discount_rate * 1000 / 10,
        this.language.SHEIN_KEY_PC_25759
      )
    },
  },
  watch: {
    'selectedPrime.product_name_language': {
      handler () {
        this.initSelectedPrime()
      },
      immediate: true
    },
    usedPrimeIndex: {
      handler(n) {
        if (n != -1) {
          this.primeSeleted = true
        } else {
          this.primeSeleted = false
          this.initSelectedIndex()
        }
      },

      immediate: true,
    },
  },
  mounted() {
    this.isClient = true
    this.initSelectedPrime()
  },
  methods: {
    ...mapMutations(['assignState', 'changeParamsStatus', 'updateSelectedPrime']),
    ...mapActions(['getPrimeRightCouponInfo']),
    template: _commonTemplate,
    initSelectedPrime() {
      if(!this.selectedPrime.product_name_language) return

      if (this.isLimited) {
        if (this.selectedPrime?.product_price_info?.countdown) {
          this.initTimer()
        }
        this.$nextTick(() => {
          this.animateBegin()
        })
      }
    },
    // 折扣动画
    animateBegin () {
      if(typeof window == 'undefined') return
      this.animateEndFlag = false
      setTimeout(() => {
        const eles = {
          localEle: document.querySelector('.checkout-prime__wrap-body .checkout-prime__wrap-local'),
          titleEle: document.querySelector('.checkout-prime__wrap-body .checkout-prime__wrap-title'),
          discountEle: document.querySelector('.checkout-prime__wrap-body .discount'),
          discountLocalEle: document.querySelector('.checkout-prime__wrap-body .discount-local'),
          limitEle: document.querySelector('.checkout-prime__wrap-body .prime-limited')
        }
        const localElePaddingLeft = parseFloat(window.getComputedStyle(eles.localEle)?.paddingLeft) || 0
        const localEleWidth = eles.localEle?.offsetWidth - localElePaddingLeft
        
        eles.discountEle?.animate({ opacity: 0 }, { duration: 0, fill: 'forwards' })
        eles.discountLocalEle?.animate({ opacity: 1 }, { duration: 0, fill: 'forwards' })
        eles.localEle?.animate({ opacity: 0 }, { duration: 0, fill: 'forwards' })
        eles.titleEle?.animate(this.GB_cssRight ? { marginRight: `-${localEleWidth}px` } : { marginLeft: `-${localEleWidth}px` }, { duration: 0, fill: 'forwards' })
        
        eles.limitEle?.classList.remove('prime-limited-animate')
        // 动画第一步
        // 折扣标签放大为2倍，然后缩小为原来大小
        setTimeout(() => {
          /** animateBegin 动画开始的地方 */
          if (!eles.limitEle) return
          eles.limitEle.classList.add('prime-limited-animate')
          
          // 动画第二步
          // 原价透明度变为0，折扣价格透明度变为1
          // 划线价透明度变为1，产品名称和折扣标签位移
          setTimeout(() => {
            const animatieOptions = {
              duration: 500,
              fill: 'forwards'
            }
            
            eles.discountEle?.animate({ opacity: 1 }, animatieOptions)
            eles.discountLocalEle?.animate({ opacity: 0 }, animatieOptions)
            eles.localEle?.animate({ opacity: 1 }, animatieOptions)
            eles.titleEle?.animate(this.GB_cssRight ? { marginRight: '-4px' } : { marginLeft: '-4px' }, animatieOptions)
            setTimeout(() => { this.animateEndFlag = true }, 500)

            // 第三步，扫光动画，UI要求需要两根白光
            setTimeout(() => {
              const move1 = document.createElement('span')
              const move2 = document.createElement('span')
              move1.className = 'prime-limited-white-move'
              move2.className = 'prime-limited-white-move2'
              eles.limitEle.append(move1, move2)
              // 删除扫光标签
              setTimeout(() => {
                document.querySelector('.prime-limited-white-move')?.remove()
                document.querySelector('.prime-limited-white-move2')?.remove()
              }, 500)
              /** animateEnd 动画结束的地方 */
            }, 1000)
          }, 500)
        }, 1000)
      }, 100)
    },
    // 初始化倒计时
    initTimer () {
      if (!this.selectedPrime?.product_price_info?.end_time) return
      const endTime = this.selectedPrime?.product_price_info?.end_time
      const newDate = new Date().getTime()
      const countdown = endTime - newDate
      this.timer = new CountDown()
      this.timer.start({
        seconds: Math.floor(countdown / 1000),
        endFunc: () => {
        }
      })
    },
    initSelectedIndex() {
      // 未勾选时设置默认选择index,只设置一次
      if (this.isInitInx) return
      const selectdPrimeIndex = this.primeProductList?.findIndex(
        (item) => +item.is_recommend
      )
      this.updateSelectedPrime({ index: selectdPrimeIndex == -1 ? 0 : selectdPrimeIndex, isUpdateRightList: true })
      if(typeof window != 'undefined') this.getPrimeRightCouponInfo()

      this.isInitInx = true
      this.handleCartPrime()
    },
    viewMore() {
      if (this.notSupport || this.status.hasAddress == 0) return
      this.$refs.primeDetail.init()
      daEventCenter.triggerNotice({
        daId: '1-11-1-80',
        extraData: {
          select_flag: this.primeSeleted ? '1' : '0',
        },
      })
      daEventCenter.triggerNotice({
        daId: '1-11-1-81',
      })
    },
    choosePrime(flag) {
      this.primeSeleted = flag
      this.reversePrime()
    },
    reversePrime() {
      if (this.primeSeleted) {
        if (this.usedPrimeIndex == this.selectdPrimeIndex) return

        daEventCenter.triggerNotice({
          daId: '1-11-1-79',
          extraData: {
            select_flag: this.primeSeleted ? '1' : '0',
            prime_product_id: this.primeProductList?.[this.selectdPrimeIndex]?.product_goods_id,
            sequence: this.selectdPrimeIndex > -1 ? this.selectdPrimeIndex + 1 : '',
          },
        })

        this.assignState({ 
          usedPrimeIndex: this.selectdPrimeIndex,
          usedPrimeCode: this.primeProductList?.[this.selectdPrimeIndex]?.product_code
        })

        let rightConfigList = this.primeProductList[this.selectdPrimeIndex || 0]?.right_config_list || []
        this.assignState({ 'rightConfigList': rightConfigList })
      } else {
        if (this.usedPrimeIndex == -1) return

        daEventCenter.triggerNotice({
          daId: '1-11-1-79',
          extraData: {
            select_flag: this.primeSeleted ? '1' : '0',
            prime_product_id: this.primeProductList?.[this.selectdPrimeIndex]?.product_goods_id,
            sequence: this.selectdPrimeIndex > -1 ? this.selectdPrimeIndex + 1 : '',
          },
        })

        this.assignState({ 
          usedPrimeIndex: -1,
          usedPrimeCode: ''
        })
      }
      window.checkoutEventCenter.emit('change-prime-calcu', {
        primeSeleted: this.primeSeleted,
      })
    },
    clickPrime() {
      if (this.notSupport || this.status.hasAddress == 0) return
      if (this.isAgreePrime || this.usedPrimeCode) {
        this.$nextTick(() => {
          this.reversePrime()
        })
      } else {
        this.$nextTick(() => {
          this.primeSeleted = false
          this.$refs.primeDetail.init()
          daEventCenter.triggerNotice({
            daId: '1-11-1-79',
            extraData: {
              select_flag: '',
            },
          })
        })
      }
    },
    handleCartPrime() {
      if(typeof window == 'undefined') return

      // 购物车是否传入付费会员勾选情况
      const cartPrimeCode = getQueryString({ key: 'prime_product_code' })
      if(!cartPrimeCode) return

      this.assignState({
        isAgreePrime: true
      })
      this.choosePrime(true)
    },
  },
  emits: ['change-prime-calcu'],
}
</script>

<style lang="less">
// 缩放动画
@keyframes prime-limited {
  0% {
    transform: scale(0);
  }
  45% {
    transform: scale(1.5);
  }
  75% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(.9);
  }
}
// 扫光动画
@keyframes prime-white-move {
  0% {
    .left(0);
  }
  100% {
    .left(calc(100% + 10px));
  }
}
// 扫光动画2
@keyframes prime-white-move2 {
  0% {
    .left(-10px);
  }
  100% {
    .left(100%);
  }
}
.checkout-prime {
  &__wrap {
    margin-bottom: 15px;
  }
  &__wrap-logo {
    height: 26px;
    width: 100%;
  }
  &__wrap-body {
    background: #FFFAF6;
    padding: 16px 12px;
    .prime-limited-color {
      display: none;
    }
    .prime-limited-animate {
      display: inline-block;
      animation: prime-limited 1s;
    }
  }
  &__wrap-radio {
    width: calc(100% - 22px);
    .prime-gap {
      margin-top: 8px;
    }
    .prime-top {
      margin-top: 8px;
    }
    .no-support {
      opacity: 0.3;
    }
    .sui-radio__check {
      vertical-align: top;
      flex-shrink: 0;
    }
    .sui-radio__label {
      display: inline-block;
      min-width: 0;
    }
  }
  &__wrap-name {
    color: #222;
    font-size: 14px;
    position: relative;
    display: inline-flex;
    .discount {
      color: #c96e3f;
      opacity: 0;
    }
    .discount-local {
      color: #c96e3f;
      position: absolute;
      .left(0);
      opacity: 1;
    }
  }
  &__wrap-des {
    color: @sui_color_gray_dark2;
    font-size: 12px;
    .prime-deduce {
      color: @sui_color_highlight;
      font-style: normal;
    }
  }
  &__wrap-link {
    color: @sui_color_link;
    font-size: 12px;
  }
  &__wrap-tip {
    color: @sui_color_unusual;
    font-size: 12px;
  }
  &__wrap-title {
    color: #c96e3f;
    font-size: 14px;
    .padding-l(4px);
  }
  &__wrap-local {
    color: @sui_color_gray_light1;
    .padding-l(4px);
    opacity: 0;
  }
  &__countdown {
    margin-top: 6px;
    color: @sui_color_discount_dark;
    font-size: 12px;
  }
}
.prime-limited-color {
  transform-origin: left;
  .margin-l(4px);
}
.white-move () {
  content: ' ';
  width: 8px;
  height: 20px;
  display: block;
  position: absolute;
  top: -2px;
  background: linear-gradient(95deg, rgba(255, 255, 255, .1), rgba(255, 255, 255, .5), rgba(255, 255, 255, .1));
  transform: rotate(15deg);
  animation: prime-white-move .5s;
}
.prime-limited-white-move {
  .white-move();
  .left(0);
}
.prime-limited-white-move2 {
  .white-move();
  .left(-10px);
}

.place-order__effiency {
  .checkout-prime__wrap {
    .checkout-prime__wrap-radio {
      display: flex;
      width:auto;
      overflow: hidden;
    }
  }
}

</style>
<style lang="less" scoped>
.prime-limited-color {
  background: linear-gradient(97.82deg, #FF5656 1.43%, #C73BFF 100%) !important; /* stylelint-disable-line declaration-no-important */
	color: #fff !important; /* stylelint-disable-line declaration-no-important */
	border: none !important; /* stylelint-disable-line declaration-no-important */
}

.prime-limited {
	border-radius: 6px 0px;
	padding: 2px 6px;
	font-size: 12px;
	font-weight: bold;
	transform: scale(.9);
	display: inline-block;
	line-height: 1.2;
	vertical-align: text-bottom;
}

</style>

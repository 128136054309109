const XtraCouponType = {
  // 商品券
  Product: 'Product',
  // 运费券
  Shipping: 'Shipping'
}

const couponConfig =  {
  [XtraCouponType.Product]: {
    // 券面额文字颜色
    '--discountColor': '#F82854',
    // 券门槛文字颜色
    '--ruleColor': '#F82854B3',
    // 渐变背景颜色
    '--bgColor': 'linear-gradient(180deg, #FFEEF3 33.04%, #FDD2DB 176.79%)',
    // 边框颜色
    '--borderColor': '#FDD2DB',
    // 新人券主题颜色，包括文字颜色、icon颜色、标签背景颜色
    '--newUserThemeColor': '#ED1D49'
  },
  [XtraCouponType.Shipping]: {
    // 券面额文字颜色
    '--discountColor': '#169E00',
    // 券门槛文字颜色
    '--ruleColor': '#58BA4A',
    // 渐变背景颜色
    '--bgColor': 'linear-gradient(180deg, #F3FAF6 33.04%, #D1FFE5 176.79%)',
    // 边框颜色
    '--borderColor': '#CBE2C7',
    // 新人券主题颜色，包括文字颜色、icon颜色、标签背景颜色
    '--newUserThemeColor': '#169E00'
  }
}

export {
  XtraCouponType,
  couponConfig
}

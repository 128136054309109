// 欧盟国家id
const EurCheckSite = [14, 21, 33, 54, 56, 57, 58, 68, 73, 74, 82, 85, 98, 104, 106, 116, 122, 123, 131, 150, 172, 173, 177, 192, 193, 198, 206, 13, 153, 225]

// 美国国家id
const UsCheckSite = [226]

// 印度国家id
const InCheckSite = [100]

// 中东国家id
const ArCheckSite = [17, 113, 162, 175, 186, 224]

// 特殊中东站点国家id ps: 约旦(109)调转到ar，所以归属到ar
const SpecialArCheckSite = [109]

// 东南亚国家id
const RowCheckSite = [32, 36, 62, 89, 101, 115, 124, 128, 129, 146, 170, 191, 199, 209, 212, 218, 233, 197]

// 欧盟ROE站跳转
const RoeCheckSite = [2, 5, 11, 27, 81, 99, 125, 139, 140, 143, 161, 184, 187, 231]
const RoeJumpSite = ['roe']

// 需要跳转的域名
// 欧盟国家域名
const REurJumpSite = ['rwit', 'rwes', 'rwfr', 'rwde', 'rwau', 'rwuk']
const SEurJumpSite = ['it', 'eur', 'se', 'es', 'pt', 'nl', 'fr', 'de', 'pl', 'au', 'uk', 'nz']
const EurJumpSite = SEurJumpSite.concat(REurJumpSite)

// 美国国家域名
const RUsJumpSite = ['rwus']
const SUsJumpSite = ['us']
const UsJumpSite = SUsJumpSite.concat(RUsJumpSite)

// 印度国家域名
const RInJumpSite = ['rwin']
const SInJumpSite = ['in']
const InJumpSite = SInJumpSite.concat(RInJumpSite)

// 中东国家域名
const RArJumpSite = ['rwar']
const SArJumpSite = ['ar']
const ArJumpSite = SArJumpSite.concat(RArJumpSite)

// 东南亚国家域名
const RowJumpSite = ['asia', 'th', 'sg', 'ph', 'tw', 'my', 'id', 'vn', 'hk', 'il', 'jp', 'ru']

// 俄罗斯/白俄罗斯
const RuCheckSite = [178, 20]
const RuJumpSite = ['ru']

// 加拿大
const CndCheckSite = [38]
const CndJumpSite = ['ca', 'rwca']

export {
  UsJumpSite,
  InJumpSite,
  ArJumpSite,
  EurJumpSite,
  UsCheckSite,
  InCheckSite,
  ArCheckSite,
  EurCheckSite,
  RowCheckSite,
  RowJumpSite,
  SpecialArCheckSite,
  RoeCheckSite,
  RoeJumpSite,
  RuCheckSite,
  RuJumpSite,
  CndCheckSite,
  CndJumpSite
}

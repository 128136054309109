<template>
  <div class="extra-container">
    <div>
      <div class="extra-bonus-box">
        <div
          class="extra-bonus"
        >
          {{ language.SHEIN_KEY_PC_31708 }}
        </div>
        <div class="timer-box">
          <span>{{ timeText.hours_transform }}</span> : <span>{{ timeText.minutes_transform }}</span> : <span>{{ timeText.seconds_transform }}</span>
        </div>
      </div>
      <div class="after-order">
        <!-- Get it immediately after order -->
        {{ language.SHEIN_KEY_PC_31709 }}
      </div>
    </div>
    <div>
      <div class="amount">
        +{{ taskPoints }}
      </div>
    </div>
  </div>
</template>
<script setup>
// SHEIN_KEY_PWA_32116
import { onMounted, ref, computed } from 'vue'
import { useCountDown } from 'public/src/pages/checkout/utils.js'
import { useStore } from 'vuex'

const store = useStore()


const checkout = computed(() => store.state.checkout)
const language = computed(() => store.state.language)  
const taskPoints = computed(() => {
  // mock
  const taskPoints = checkout?.value?.results?.carts?.cartInfo?.ug_benefit_info?.prizeValue
  return Number(taskPoints) || 0
})
const countDown = computed(() => {
  // mock
  const taskPoints = checkout?.value?.results?.carts?.cartInfo?.ug_benefit_info?.countDown
  return  Number(taskPoints) || 0
})
const timeText = ref('')
const timeDownFn = () => {
  const startTime = +Date.parse(new Date()) / 1000
  const endTime = startTime + countDown.value
  useCountDown({
    startTime,
    endTime,
    onCompleted() {
      console.log('已过期---', )
    },
  }, info => {
    const { hours_transform = '00', minutes_transform = '00', seconds_transform = '00' } = info || {}
    timeText.value = { hours_transform, minutes_transform, seconds_transform }
  })
}
onMounted(async () => {
 
  timeDownFn()
})
</script>
  
  <style lang='less' scoded>
  .extra-container{
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;
      .extra-bonus-box{
          display: flex;
          align-items: center;
          gap: 11px;
          align-self: stretch;
      }
      .timer-box{
          display: flex;       
          justify-content: center;
          align-items: center;
          gap: 4px; 
          color: var(---sui_color_brand, #000);
          font-family: "SF Pro";
          font-size: 9px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;             
          span{
              border-radius: 1px;
              padding: 1px 2px;
              background: var(---sui_color_brand, #000);
              color: var(---sui_color_white, #FFF);
              font-family: "SF Pro";
              font-size: 9px;
              font-style: normal;
              font-weight: 590;
              line-height: normal;
          }
      }
      .extra-bonus {
          background: linear-gradient(90deg, #FF553B 0%, #FF8A01 100%);
          background-clip: text !important;
          -webkit-background-clip: text !important;
          -webkit-text-fill-color: transparent;
          font-family: "SF Pro";
          font-size: 14px;
          font-style: normal;
          font-weight: 860;
          line-height: normal;
          text-transform: capitalize;
      }
      .after-order{
          color: #FFA703;
          font-family: "SF Pro";
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
      }
      .amount{
  
          color: #FF6B2D;
          text-align: center;
          font-family: "SF Pro";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 110%; /* 17.6px */
      }
  }
  </style>
  

<template>
  <div 
    class="checkout-xtra-coupon__wrap" 
    :style="couponWrapBg"
  >
    <coupon-small 
      class="checkout-xtra-coupon__content"
      :in-checkout="true" 
      :item="item" 
      :language="language"
      :coupon-right-type="type == 1 ? 'crown' : 'normal'"
      :has-crown="false"
      :has-expective="+totalDiscount.amount > 0 ? true : false"
      :expective-number="totalDiscount.amountWithSymbol"
    />

    <div class="checkout-xtra-coupon__total">
      <div class="checkout-xtra-coupon__total-left">
        <div class="checkout-xtra-coupon__total-num">
          {{ template(total, language.SHEIN_KEY_PC_26949) }}
        </div>
        <p class="checkout-xtra-coupon__total-name">
          {{ language.SHEIN_KEY_PC_26927 }}
        </p>
      </div>
      <sui_icon_more_circle_right_16px_1
        size="16px"
        :is-rotate="GB_cssRight"
        color="#ffffff"
      />
    </div>
  </div>
</template>

<script>
import couponSmall from './xtra_small_oupon.vue'
import { mapState } from 'vuex'
import { template as _commonTemplate } from '@shein/common-function'
import { sui_icon_more_circle_right_16px_1 } from '@shein-aidc/icon-vue3'


export default {
  name: 'XtraCoupon',
  components: {
    couponSmall,
    sui_icon_more_circle_right_16px_1,
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    type: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    },
  },
  computed: {
    ...mapState([
      'xtraDiscountInfo',
      'language',
      'locals',
    ]),
    PUBLIC_CDN() {
      return this.locals.PUBLIC_CDN
    },
    GB_cssRight() {
      return this.locals.GB_cssRight
    },
    couponWrapBg() {
      return !this.GB_cssRight ? {
        backgroundImage: `url(${this.PUBLIC_CDN}/she_dist/images/checkout/sui_img_savercard_bg-50a64e4113.png)`,
        backgroundSize: '100% 100%'
      } : {
        backgroundImage: `url(${this.PUBLIC_CDN}/she_dist/images/checkout/sui_img_savercard_bg_ar-858a1d43d1.png)`,
        backgroundSize: '100% 100%'
      }
    },
    totalDiscount() {
      return this.xtraDiscountInfo.total_discount || {}
    },
  },
  methods: {
    template: _commonTemplate,
  }
}
</script>

<style lang="less" scoped>
.checkout-xtra-coupon__wrap{
  width: 100%;
  max-width: 336px;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;

  .checkout-xtra-coupon__content {
    width: 188px;
    height: 61px;
  }

  .checkout-xtra-coupon__total {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    color: #FFF;

    .checkout-xtra-coupon__total-left {
      text-align: center;
      .margin-r(4px);
    }

    .checkout-xtra-coupon__total-num {
      font-size: 22px;
      font-weight: 700;
    }

    .checkout-xtra-coupon__total-name {
      font-size: 12px;
      font-weight: 400;

      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      word-break: break-all;
    }
  }
}
</style>
